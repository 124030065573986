<template>
  <table class="table">
    <tr>
      <th class="d-table-cell sortable" @click="sortBy('project')">
        <i v-if="orderBy.column === 'project'" class="bg-transparent fas" :class="sortIcon"></i>
        Project
      </th>
      <th class="d-table-cell sortable" @click="sortBy('person')">
        <i v-if="orderBy.column === 'person'" class="bg-transparent fas" :class="sortIcon"></i>
        Person
      </th>
      <th class="d-table-cell sortable" @click="sortBy('last_known_user_costs.cost')">
        <i v-if="orderBy.column === 'last_known_user_costs.cost'" class="bg-transparent fas" :class="sortIcon"></i>
        Cost
      </th>
      <th class="d-table-cell sortable" @click="sortBy('hourly_rate')" title="What you charge to the client.">
        <i v-if="orderBy.column === 'hourly_rate'" class="bg-transparent fas" :class="sortIcon"></i>
        Rate
      </th>
      <th class="d-table-cell sortable" @click="sortBy('profit')" title="Profit = Rate - Profit">
        <i v-if="orderBy.column === 'profit'" class="bg-transparent fas" :class="sortIcon"></i>
        Profit
      </th>
      <th class="d-table-cell sortable" @click="sortBy('gross_margin')">
        <i v-if="orderBy.column === 'gross_margin'" class="bg-transparent fas" :class="sortIcon"></i>
        Gross Margin
      </th>
    </tr>
    <tr :key="row.id" v-for="row in grossMarginPerResource">
      <td>{{row.project}}</td>
      <td>{{row.person}}</td>
      <td>
        <ShowCost :cost="row.cost"></ShowCost>
      </td>
      <td>
        <ShowCost :cost="row.hourly_rate"></ShowCost>
      </td>
      <td>
        <ShowCost :cost="row.profit"></ShowCost>
      </td>
      <td>
        <i class="fas fa-circle" :class="`text-${row.status}`"></i>
        {{row.gross_margin.toFixed(2)}}%
      </td>
    </tr>
    <tr v-if="grossMarginPerResource && grossMarginPerResource.length === 0">
      <td class="text-center" colspan="5">No records found</td>
    </tr>
  </table>
</template>

<script>

import Helper from '../Helper';

export default {
  extends: Helper,
  props: ['grossMarginPerResource', 'orderBy'],
};
</script>
