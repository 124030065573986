<style scoped>
.progress-custom {
  height: 1.2rem;
}
</style>

<template>
  <div>
    <nav>
      <div class="breadcrumb p-2" v-if="learning_path">
        <div class="col-md-2 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/my_career">
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              {{learning_path.name}}
            </li>
          </ol>
        </div>
        <div class="col-md-8 m-auto p-0 d-flex justify-content-center">
          <ul class="nav nav-pills">
            <template v-for="level in levels">
              <li class="nav-item sortable" :key="`l-${level.id}`">
                <a class="nav-link" :class="{'active': level.id === selectedLevel}" @click="selectedLevel = level.id">
                  {{level.name}}
                </a>
              </li>
            </template>
          </ul>
        </div>
        <div class="col-md-2">
        </div>
      </div>
    </nav>
    <div class="d-flex justify-content-center align-items-center m-4">
      <div class="col-xs-12 col-md-6 col-lg-3 p-0 mt-2" >
        <div class="d-flex flex-row justify-content-center align-items-center ml-4">
          <h4 class="me-2 fw-bold">{{ percentage }}%</h4>
          <h6 v-if="percentage >= 100">Completed</h6>
          <h6 v-else>In progress</h6>
        </div>
        <div class="progress-custom bg-light-gray overflow-hidden modal-dialog-scrollable d-flex rounded-pill">
          <div class="transition-05 bg-success opacity-75 d-flex flex-column justify-content-center" :class="{ 'bg-company-fiveth-ligth' : percentage >= 100  }" role="progressbar" :style="`width: ${percentage}%`" aria-valuenow="0" aria-valuemin="0"
            aria-valuemax="100">
          </div>
        </div>
      </div>
    </div>
    <div v-if="learning_path_node_user">
      <template v-for="node in nodes">
        <Node :key="node.id" :record="node" :learning_path_node_user="learning_path_node_user"></Node>
      </template>
    </div>
  </div>
</template>

<script>
import { LEARNING_PATH_LEVELS_QUERY, LEARNING_PATH_NODE_USER_QUERY } from './queries';
import Node from './Node.vue';

export default {
  components: { Node },
  data() {
    return {
      selectedLevel: 0,
    };
  },
  apollo: {
    learning_path: {
      query: LEARNING_PATH_LEVELS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    learning_path_node_user: {
      query: LEARNING_PATH_NODE_USER_QUERY,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  watch: {
    learning_path() {
      if (!this.learning_path.levels.map((l) => l.id).includes(this.selectedLevel) && this.learning_path.levels.length > 0) {
        this.selectedLevel = this.learning_path.levels[0].id;
      }
    },
  },
  computed: {
    levels() {
      return this.learning_path.levels || [];
    },
    nodes() {
      return this.levels.find((level) => level.id === this.selectedLevel).nodes;
    },
    nodeIds() {
      return this.nodes.map((node) => node.id);
    },
    percentage() {
      if (this.learning_path_node_user && this.levels && this.selectedLevel) {
        const completedNodes = this.learning_path_node_user.filter((node) => node.is_successful && this.nodeIds.includes(node.learning_path_level_node_id.toString()));
        return Math.round((completedNodes.length / this.nodes.length) * 100);
      }
      return 0;
    },
  },
};
</script>
