import { gql } from 'apollo-boost';

import VERTICAL_MARKET_FRAGMENT from './fragments';

export default gql`
  query($page: Int,
        $name: String) {
    vertical_markets(
      page: $page,
      name: $name,
      orderBy: { column: "id", order: ASC }) @connection(key: "vertical_markets", filter: ["name"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...vertical_market
      }
    }
  }
  ${VERTICAL_MARKET_FRAGMENT}
`;
