import { gql } from 'apollo-boost';

import TECHNOLOGY_FRAGMENT from './fragments';

export default gql`
  query($page: Int,
        $name: String) {
    technologies(
      page: $page,
      name: $name,
      orderBy: { column: "id", order: ASC }) @connection(key: "technologies", filter: ["name"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...technology
      }
    }
  }
  ${TECHNOLOGY_FRAGMENT}
`;
