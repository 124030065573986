import { gql } from 'apollo-boost';

export default gql`
  query(
    $query: String,
    $candidate_status_id: ID,
    $country_id: ID,
    $page: Int
  ){
  resume_candidate_full_text_search(
    query: $query,
    candidate_status_id: $candidate_status_id,
    country_id: $country_id,
    page: $page
  ) @connection(key: "resume_candidate_full_text_search", filter: ["query", "name", "candidate_status_id", "country_id"])
  {
    paginatorInfo {
      hasMorePages
    }
    data {
      id
      first_name
      last_name
      email
      phone_number
      score
      resume_url
      english_level
      notes
      salary_expectation_range
      candidate_status_id
      candidate_status {
        id
        name
      }
      technologies{
        id
        name
      }
      availability
      is_working
      company
      application_channel
      job_experience_started_date
      country_id
      country {
        id
        name
      }
    }
  }
}`;

export const QUERY_CANDIDATE = gql`
    query($id: ID!){
        candidate(id: $id){
            id
            first_name
            last_name
            email
            phone_number
            score
            resume_url
            english_level
            notes
            salary_expectation_range
            candidate_status_id
            candidate_status {
                id
                name
            }
            technologies {
                id
                name
            }
            availability
            is_working
            company
            application_channel
            job_experience_started_date
            country_id
        }
    }
`;
