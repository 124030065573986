import { gql } from 'apollo-boost';

export default gql`
  query{
    stats_per_country {
      id
      name
      users
      clients
      topo_name
    }
  }
`;
