<template>
  <th class="sortable" :class="css_class" @click="sortBy(sort_by_field)">
    <i v-if="orderBy.column === sort_by_field" class="bg-transparent fas" :class="sortIcon"></i>
    <slot></slot>
  </th>
</template>
<script>

export default {
  props: ['sort_by_field', 'orderBy', 'css_class'],
  computed: {
    sortIcon() {
      return this.asc() ? 'fa-sort-alpha-down' : 'fa-sort-alpha-down-alt';
    },
  },
  methods: {
    asc() {
      return this.orderBy.order === 'ASC';
    },
    sortBy(column) {
      this.orderBy.column = column;
      this.orderBy.order = this.asc() ? 'DESC' : 'ASC';
      this.$emit('refresh');
    },
  },
};
</script>
