import { gql } from 'apollo-boost';

export const LEARNING_PATH_LEVELS_WITH_NODES_FRAGMENT = gql`
  fragment learning_path_levels_with_nodes on LearningPath {
    id
    name
    levels {
      id
      name
      nodes {
        id
        name
        description
        evaluation_methodology
        documentation_links
      }
    }
  }
`;

export const LEARNING_PATH_NODE_USER_FRAGMENT = gql`
  fragment learning_path_node_user on LearningPathLevelNodeUser {
    id
    learning_path_level_node_id
    is_successful
    evaluated_at
    notes
    evaluated_by_user {
      id
      name
    }
    status
  }`;

export const LEARNING_PATH_NODE_USER_PERCENTAGE_FRAGMENT = gql`
fragment learning_path_node_user_percentage on LearningPathCompletedLevels {
  id
  name
  total_levels
  completed_nodes
  pending_nodes
  total_nodes
}`;
