<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Talented People</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-lg-3 col-sm-6">
                  <label for="technologies" class="pe-1">Technologies:</label>
                  <TechnologyPicker
                    :selected="technologies"
                  ></TechnologyPicker>
                </div>
                <div class="col-lg-3 col-sm-6">
                  <label for="vertical_markets" class="pe-1">Vertical Markets:</label>
                  <VerticalMarketPicker
                    :selected="vertical_markets"
                  ></VerticalMarketPicker>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="talented_people">
      <div class="col-md-12 text-nowrap scroll-x h-100">
        <table class="table">
          <tr>
            <th class="d-table-cell">
              Person
            </th>
            <th class="d-table-cell">
              Projects
            </th>
          </tr>
          <tr v-if="talented_people && talented_people.length === 0 && technologies.length > 0">
            <td class="text-center" colspan="4">No records found</td>
          </tr>
          <tr :key="row.id" v-for="row in talented_people">
            <td>
              <router-link :to="{ name: 'resume', params: { id: row.id }}" target="_blank">
                {{row.name}}
              </router-link>
            </td>
            <td>
              <span :key="project.id" v-for="project in projects(row.projects)" class="badge bg-primary me-1 ms-1">
                {{project}}
              </span>
              <strong title="Completed Projects">({{row.total_projects}})</strong>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <Empty v-if="!talented_people?.length" icon="fas fa-star"/>
  </div>
</template>

<script>

import TALENTED_PEOPLE from './queries';
import TechnologyPicker from '../../technologies/Picker.vue';
import VerticalMarketPicker from '../../vertical_markets/Picker.vue';
import { SpinnerRouterLink } from '../../shared';

export default {
  components: { TechnologyPicker, VerticalMarketPicker, SpinnerRouterLink },
  data() {
    return {
      technologies: [],
      vertical_markets: [],
    };
  },
  methods: {
    projects(projects) {
      return projects.split(',');
    },
  },
  apollo: {
    talented_people: {
      query: TALENTED_PEOPLE,
      errorPolicy: 'all',
      variables() {
        return {
          technology_ids: _.compact(_.map(this.technologies, (t) => parseInt(t.id, 10))),
          vertical_market_ids: _.compact(_.map(this.vertical_markets, (vm) => parseInt(vm.id, 10))),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
