import { gql } from 'apollo-boost';

export const LEARNING_PATH_LEVELS_FRAGMENT = gql`
  fragment learning_path_levels on LearningPath {
    id
    levels {
      id
      name
      learning_path_id
      order
    }
  }
`;

export const LEARNING_PATH_LEVEL_FRAGMENT = gql`
  fragment learning_path_level on LearningPathLevel {
    id
    name
    learning_path_id
    order
  }
`;
