import { gql } from 'apollo-boost';
import { LEARNING_PATH_LEVELS_FRAGMENT } from './fragments';

export default gql`
  query($id: ID){
    learning_path(id: $id){
      ...learning_path_levels
    }
  }
  ${LEARNING_PATH_LEVELS_FRAGMENT}
`;
