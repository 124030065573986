import { gql } from 'apollo-boost';

// //////////////////////////
// SWITCH_IS_ACTIVE MUTATION
export const SWITCH_IS_ACTIVE = gql`mutation($id: ID!, $is_active: Boolean!){
  updateUser(id: $id, is_active: $is_active){
    id
    is_active
  }
}`;

// ////////////////////////////
// WHITE_LIST_DOMAIN MUTATIONS
export const CREATE_WHITE_LIST_DOMAIN = gql`mutation($domain: String!){
  createWhiteListDomain(domain: $domain) {
    id
    domain
  }
}`;

export const UPDATE_WHITE_LIST_DOMAIN = gql`mutation($id: ID!, $domain: String!){
  updateWhiteListDomain(id: $id, domain: $domain){
    id
    domain
  }
}`;

export const DELETE_WHITE_LIST_DOMAIN = gql`mutation($id: ID!){
  deleteWhiteListDomain(id: $id){
    id
  }
}`;

// ////////////////////////
// LOGIN_REQUEST MUTATIONS
export const ACCEPT_LOGIN_REQUEST = gql`mutation($id: ID!){
  acceptLoginRequest(id: $id)
}`;

export const DENY_LOGIN_REQUEST = gql`mutation($id: ID!){
  denyLoginRequest(id: $id) {
    id
  }
}`;
