<style scoped>
.badge-pill-custom {
  width: 4em;
  height: 4em;
}
</style>

<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-12 m-auto p-0 d-flex flex-row">
          <ol class="breadcrumb p-0 m-auto w-100">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              My Career
            </li>
          </ol>
        </div>
      </div>
    </nav>
    <div>
      <Greeting :learning_path="learning_path_completed_levels"></Greeting>
      <div class="row">
        <div class="col-sm-6 col-md-4 pb-2 mb-4" v-for="learning_path in learning_path_completed_levels" :key="learning_path.id">
          <div class="card h-100 p-4 company-text-primary shadow border-0">
            <div class="row d-flex justify-content-between align-items-center">
              <div class="col-xs-12 col-sm-8">
                <div class="box text-md-start text-center">
                  <span class="mb-2 badge bg-company-fiveth-ligth"><i class="fas fa-user-graduate me-1"></i>Path</span>
                  <h3>{{ learning_path.name }}</h3>
                </div>
              </div>
              <div class="col-xs-12 col-sm-4 text-md-start text-center">
                <div class="box company-text-secondary d-flex flex-column float-sm-end">
                  <span class="mb-2 badge bg-company-fiveth-ligth"><i class="fa-solid fa-layer-group me-1"></i>Nodes: {{ learning_path.total_nodes }}</span>
                  <span class="mb-2 badge bg-company-secondary-ligth"><i class="fa-solid fa-share-nodes me-1"></i>Levels: {{ learning_path.total_levels }}</span>
                </div>
              </div>
            </div>
            <div class="mt-3">
              <div class="row d-flex justify-content-between align-items-center">
                <div class="col-xs-12 col-sm-2 p-0 p-md-2 d-flex justify-content-center">
                  <div class="d-flex justify-content-center align-items-center badge-pill-custom rounded-circle bg-company-fourth-ligth fs-6" v-if="completition_percentage(learning_path.total_nodes, learning_path.completed_nodes) >= 100">
                    <i class="fas fa-trophy fa-2xl"></i>
                  </div>
                  <div class="d-flex justify-content-center align-items-center badge-pill-custom rounded-circle bg-company-secondary-ligth fs-6" v-else>
                    <i class="fas fa-trophy fa-2xl"></i>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-10 p-0 mt-2 mt-md-0">
                  <div class="d-flex flex-row align-items-center ml-4">
                    <h4 class="me-2 fw-bold">
                      {{ completition_percentage(learning_path.total_nodes, learning_path.completed_nodes) }}%
                    </h4>
                    <h6 v-if="completition_percentage(learning_path.total_nodes, learning_path.completed_nodes) >= 100">
                      Completed
                    </h6>
                    <h6 v-else>In progress</h6>
                  </div>
                  <div class="col-12">
                    <div class="d-flex progress-custom bg-light-gray placeholder-lg overflow-hidden rounded-pill">
                      <div class="progress-bar-custom transition-05 bg-success opacity-75 d-flex flex-column justify-content-center" :class="{ 'bg-company-fiveth-ligth' : completition_percentage(learning_path.total_nodes, learning_path.completed_nodes) >= 100  }" role="progressbar" :style="`width: ${completition_percentage(learning_path.total_nodes, learning_path.completed_nodes)}%`" aria-valuenow="0" aria-valuemin="0"
                        aria-valuemax="100">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4">
              <router-link :to="{ name: 'my_career_detail' , params: { id : learning_path.id } }" class="btn btn-company-primary float-end">Details</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { LEARNING_PATH_NODE_USER_PERCENTAGE_QUERY } from './queries';
import Greeting from './Greeting.vue';

export default {
  components: { Greeting },
  apollo: {
    learning_path_completed_levels: {
      query: LEARNING_PATH_NODE_USER_PERCENTAGE_QUERY,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    completition_percentage(totalNodes, completedNodes) {
      return Math.round((completedNodes / totalNodes) * 100);
    },
  },
};
</script>
