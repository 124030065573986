<template>
<div :id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting"
    v-bind:record="record"
    @close="is_editting = false"
    @update="update"
  ></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <div v-if="record.image_url">
        <img :src="record.image_url" class="img-fluid"/>
      </div>
      <h5 v-if="!record.documentation" class="card-title">
        {{record.name}}
      </h5>
      <div>
        {{record.description}}
      </div>
      <div>
        <strong>Price:</strong> {{record.price}} Gcoins
      </div>
      <div v-if="record.countries.length > 0">
        <strong>Available only in:</strong> <span :key="name" :title="name" v-for="(flag, name) in flags"> {{flag}} </span>
      </div>
      <h5 v-if="record.documentation" class="card-title">
        <a :href="record.documentation" target="_blank">{{record.description}}</a>
      </h5>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateProduct')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteProduct')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_PRODUCT } from './mutations';
import Form from './Form.vue';
import { Confirmation } from '../shared';
import RequestBuilder from './RequestBuilder';
import { ErrorHandler, Flags } from '../../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  computed: {
    flags() {
      const countries = this.record.countries.map((country) => country.name);
      return _.pickBy(Flags, (flag, name) => _.includes(countries, name));
    },
  },
  methods: {
    update(product) {
      if (typeof (product.image_url) === 'string') {
        this.$apollo.mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            id: this.record.id,
            product: RequestBuilder.build(product),
          },
        }).catch((error) => {
          ErrorHandler.handle(error, this);
        }).then(() => {
          this.is_editting = false;
        });
        return;
      }
      const keySplit = product.image_url.prev_url.split('/').reverse();
      this.$s3Delete({ Key: `${keySplit[1]}/${keySplit[0]}` }).then(() => {});

      const fileName = `products/${Date.now()}_${product.image_url.name}`;
      const params = { Key: fileName, Body: product.image_url };
      this.$s3Upload(params).then(() => {
        product.image_url = this.$s3Location(fileName);
        this.$apollo.mutate({
          mutation: UPDATE_PRODUCT,
          variables: {
            id: this.record.id,
            product: RequestBuilder.build(product),
          },
        }).catch((error) => {
          ErrorHandler.handle(error, this);
        }).then(() => {
          this.is_editting = false;
        });
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
