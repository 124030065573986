<style scoped>
.chart-row {
  height: 185px;
}

.display-5 {
  font-size: 2.8rem;
}

.display-6 {
  font-size: 2.1rem;
}

.display-7 {
  font-size: 1.6rem;
}
</style>
<template>
  <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
    <div class="card mb-3 pb-2 h-100" v-if="indicator">
      <div v-if="!readOnlyMode" class="d-flex justify-content-end m-3">
        <button :title="!indicator.is_favorite ? 'Mark as favorite' : null" @click="$emit('favorite', indicator.id)"
          :class="[indicator.is_favorite ? 'btn btn-company-secondary' : 'btn btn-company-primary', 'rounded-circle']">
          <i class="fa-solid fa-heart fa-lg"></i>
        </button>
      </div>
      <div :class="['d-flex justify-content-center align-items-center', readOnlyMode ? 'mt-5' : null]">
        <h1 class="company-text-primary">
          {{ indicator.prefix }}{{ indicator.most_recent_value }}{{ indicator.suffix }}
        </h1>
      </div>
      <div>
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="company-text-primary">{{ indicator.name }}</h5>
            <span class="badge rounded-2 btn-company-primary text-capitalize" title="Updated">{{ indicator.frequency }}</span>
          </div>
          <p class="company-text-primary mb-0">{{ indicator.description }}</p>
          <p class="company-text-primary"><small class="text-body-secondary">Last updated {{ indicator.last_updated_at }}</small></p>
          <template v-if="!readOnlyMode">
            <div class="d-flex gap-1">
              <button type="button" class="btn btn-company-third" @click="refresh()" v-if="$can('RefreshIndicator')">
                <i class="fas fa-sync" :class="{ 'fa-spin': refreshing }"></i>
                Refresh
              </button>
              <router-link class="btn btn-company-primary" v-if="$can('FindIndicator')"
                :to="{ name: 'indicator_detail', params: { id: indicator.id } }">
                <i class="fas fa-chart-line"></i>
                Details
              </router-link>
              <button v-if="$can('CreateIndicatorAlarms')" type="button" @click="() => showAlarms(true)"
                class="btn btn-company-primary">
                <i class="fa-solid fa-bell"></i>
                Alarms
              </button>
            </div>
          </template>
        </div>
      </div>
      <div class="m-2">
        <div class="col-md-12">
          <div :class="{ 'visually-hidden': hidden }" class="chart-row" :id="indicator.id"></div>
          <Alarms ref="alarms" :class="{ 'visually-hidden': !hidden }" :indicator="indicator" :cancel="showAlarms"></Alarms>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import REFRESH_INDICATOR from './mutations';
import Alarms from './Alarms.vue';

export default {
  components: { Alarms },
  data() {
    return {
      refreshing: false,
      hidden: false,
    };
  },
  props: {
    indicator: { type: Object },
    readOnlyMode: { type: Boolean, default: false },
  },
  computed: {
    details() {
      return _.sortBy(this.indicator.details.data, [(d) => parseInt(d.id, 10)]);
    },
    categories() {
      return _.map(this.details, 'created_at');
    },
    series() {
      return _.map(this.details, (v) => parseFloat(v.value));
    },
  },
  methods: {
    showAlarms(bool) {
      this.hidden = bool;
      if (this.hidden) this.$nextTick(() => this.$refs.alarms.$refs.notify.focus());
    },
    refresh() {
      this.refreshing = true;
      this.$apollo.mutate({
        mutation: REFRESH_INDICATOR,
        variables: {
          id: this.indicator.id,
        },
      }).then((result) => {
        this.indicator.details.data = _.uniqBy([...this.indicator.details.data, ...result.data.refreshIndicator.details.data], 'id');
        this.drawChart();
        this.refreshing = false;
      }).catch((error) => {
        this.$toasted.error(`Unable to refresh indicator: ${error.message}`);
        this.refreshing = false;
      });
    },
    drawChart() {
      Highcharts.chart(this.indicator.id, {
        chart: {
          type: 'area',
        },
        tooltip: {
          backgroundColor: 'white',
          valuePrefix: this.indicator.prefix || undefined,
          valueSuffix: this.indicator.suffix || undefined,
        },
        title: {
          text: '',
        },
        xAxis: {
          categories: this.categories,
          title: {
            enabled: false,
          },
          labels: {
            enabled: false,
          },
          lineWidth: 0,
        },
        yAxis: {
          title: {
            enabled: false,
          },
          labels: {
            enabled: true,
          },
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          area: {
            marker: {
              enabled: true,
              symbol: 'circle',
              radius: 4,
              fillColor: '#ec663b',
            },
          },
        },
        series: [{
          name: this.indicator.name,
          lineColor: '#ec663b',
          color: '#ffe9e1',
          data: this.series,
        }],
      });
    },
  },
  mounted() {
    this.drawChart();
  },
};
</script>
