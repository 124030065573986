import { gql } from 'apollo-boost';

import HEALTH_SCORE_PROJECT_VALUE_FRAGMENT from './fragments';

export const CREATE_HEALTH_SCORE_PROJECT_VALUE = gql`
  mutation($healthScoreProjectValue: HealthScoreProjectValueInput!){
    createHealthScoreProjectValue(input: $healthScoreProjectValue){
      ...healthScoreProjectValue
    }
  }
  ${HEALTH_SCORE_PROJECT_VALUE_FRAGMENT}
`;

export const UPDATE_HEALTH_SCORE_PROJECT_VALUE = gql`
  mutation($id: ID!, $healthScoreProjectValue: HealthScoreProjectValueInput!){
    updateHealthScoreProjectValue(id: $id, input: $healthScoreProjectValue){
      ...healthScoreProjectValue
    }
  }
  ${HEALTH_SCORE_PROJECT_VALUE_FRAGMENT}
`;

export const DELETE_HEALTH_SCORE_PROJECT_VALUE = gql`
  mutation($id: ID!){
    deleteHealthScoreProjectValue(id: $id){
      id
    }
  }
`;
