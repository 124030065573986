import { gql } from 'apollo-boost';

export default gql`
  fragment profile on User {
    id
    github
    bio
    hobbies
    soft_skills
    technical_skills
    education
    personal_phone
    phone_extension
    gender
    date_of_birth
    job_title{
      id
      external_description
    }
    technologies {
      id
      name
    }
    external_companies {
      id
      name
      start_date
      end_date
      external_projects {
        id
        name
        description
        duration
        external_company_id
      }
    }
  }
`;
