export default class MenuItem {
  constructor(name, icon, isShown, children, route = null) {
    this.name = name;
    this.icon = icon;
    this.isShown = isShown;
    this.children = children;
    this.route = route;
  }

  get anchor() {
    return this.name.toLowerCase().replace(/\s/g, '');
  }
}
