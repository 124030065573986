import { gql } from 'apollo-boost';

import PAID_TIME_OFF_HISTORY_FRAGMENT from './fragments';

export default gql`
  query($page: Int, $user_id: ID, $start_date: DateTime, $end_date: DateTime, $orderBy: [OrderByClause!]) {
    paid_time_off_history(page: $page, user_id: $user_id, start_date: $start_date, end_date: $end_date, orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data { ...paid_time_off_history }
    }
  }
  ${PAID_TIME_OFF_HISTORY_FRAGMENT}
`;
