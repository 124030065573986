<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord" @close="selectedRecord = null" @insert="insert">
    </Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Badges</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6">
              <Search :search.sync="search" :inputs="['name']"></Search>
            </div>
            <div class="col-12 col-sm-6 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = {}"
                v-if="$can('CreateBadge')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="badges">
      <Empty v-if="!badges.data.length" icon="fa-solid fa-certificate" :can_create="true" @callCreate="selectedRecord = {}"/>
      <Card :key="index" v-for="(record, index) in badges.data" :record="record" @remove="remove"></Card>
    </div>
    <SkeletonCards v-if="badges && badges.paginatorInfo.hasMorePages" :cards=4
      :executeScroll="badges != undefined" @showMore="showMore('badges')"></SkeletonCards>
  </div>
</template>

<script>
import BADGES_QUERY from './queries';
import { DELETE_BADGE, CREATE_BADGE } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  apollo: {
    badges: {
      query: BADGES_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    remove(badge) {
      this.$apollo.mutate({
        mutation: DELETE_BADGE,
        variables: {
          id: badge.id,
        },
        update: (store, { data: { deleteBadge } }) => {
          const data = store.readQuery({ query: BADGES_QUERY, variables: this.queryVariables() });
          data.badgesdata = data.badges.data.filter((t) => t.id !== deleteBadge.id);
          store.writeQuery({ query: BADGES_QUERY, variables: this.queryVariables(), data });
          const keySplit = badge.image_url.split('/').reverse();
          this.$s3Delete({ Key: `${keySplit[1]}/${keySplit[0]}` }).then(() => {});
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(badge) {
      const fileName = `badges/${Date.now()}_${badge.image_url.name}`;
      const params = { Key: fileName, Body: badge.image_url };
      this.$s3Upload(params).then(() => {
        badge.image_url = this.$s3Location(fileName);
        // We save the user input in case of an error
        const newBadge = badge;
        this.selectedRecord = null;
        this.$apollo.mutate({
          mutation: CREATE_BADGE,
          variables: {
            badge,
          },
          update: (store, { data: { createBadge } }) => {
            const data = store.readQuery({ query: BADGES_QUERY, variables: this.queryVariables() });
            data.badges.data.unshift(createBadge);
            store.writeQuery({ query: BADGES_QUERY, variables: this.queryVariables(), data });
          },
        }).catch((error) => {
          ErrorHandler.handle(error, this);
          // We restore the initial user input
          this.selectedRecord = newBadge;
        });
      });
    },
  },
};
</script>
