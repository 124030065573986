export default class RequestBuilder {
  static build(client) {
    const requestBody = _.pick(client, ['name', 'is_prospect', 'email', 'website', 'country_id', 'is_direct']);
    client.contacts = client.contacts || [];
    requestBody.contacts = {
      delete: _.map(_.filter(client.contacts, 'deleted'), 'id'),
      upsert: [
        ..._.map(_.filter(client.contacts, (c) => !c.id), (c) => _.pick(c, ['name', 'position', 'email', 'phone', 'notes'])),
        ..._.map(_.filter(client.contacts, (c) => c.id && !c.deleted), (c) => _.pick(c, ['id', 'name', 'position', 'email', 'phone', 'notes'])),
      ],
    };
    return requestBody;
  }
}
