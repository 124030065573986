import { useVuelidate } from '@vuelidate/core';
import { required, maxLength, minLength } from '@vuelidate/validators';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      localRecord: {
        name: { required, minLengthValue: minLength(7) },
        acquired_date: { required, minLengthValue: minLength(8) },
        type: { required, maxLengthValue: maxLength(255) },
        serial_number: { required, maxLengthValue: maxLength(255) },
        internal_key: { required, maxLengthValue: maxLength(255) },
        model: { required, maxLengthValue: maxLength(255) },
        location: { maxLengthValue: maxLength(255) },
      },
    };
  },
};
