<template>
  <div :id="record.id" class="pb-2" :class="(is_editting)? 'col-md-12' : 'col-xl-3 col-lg-4'">
    <Form
      v-if="is_editting"
      v-bind:record="record"
      @close="is_editting = false"
      @update="update"
    ></Form>
    <div v-if="!is_editting" class="card h-100">
      <div class="card-body pb-0">
        <h5 class="card-title">
          {{ record.model }}
        </h5>
          <h6 class="card-subtitle mb-2 text-body-secondary">
            {{ record.internal_key }}
        </h6>
        <h6 class="card-subtitle mb-2 text-body-secondary">
          {{ record.serial_number }}
        </h6>
        <div v-if="record.type" class="badge bg-company-secondary me-1">
          {{ record.type }}
        </div>
        <div v-if="record.location" class="badge bg-company-primary me-1">
          {{ record.location }}
        </div>
        <div v-if="record.state" class="badge bg-company-fourth-ligth me-1">
          {{ record.state }}
        </div>
      </div>
      <div class="card-body m-0" v-html="record.name"></div>
      <div class="card-body pt-0 pb-0" v-if="record.acquired_date">
        <div class="row">
          <div class="col-md-5 text-nowrap">
            <strong>Adquired:</strong>
          </div>
          <div class="col-md-7">
            <span class="badge bg-primary bg-info text-dark me-1">
              {{ $displayDate(record.acquired_date) }}
            </span>
            ({{ record.acquired_date_diff }})
          </div>
        </div>
      </div>
      <div class="card-body pt-0 pb-0" v-if="record.user">
        <div class="row">
          <div class="col-md-5 text-nowrap">
            <strong>Assigned to:</strong>
          </div>
          <div class="col-md-7">
            <span class="badge bg-primary bg-info text-dark me-1">
              {{ record.user.name }}
            </span>
          </div>
        </div>
      </div>
      <div class="card-body" v-if="!readOnlyMode">
        <button
          v-if="
            !unassign &&
            assigning == false &&
            !confirmation &&
            $can('UpdateEquipment')
          "
          class="btn btn-company-primary"
          @click="is_editting = true"
        >
          <i class="fas fa-pen"></i>
          Edit
        </button>

        <Confirmation
          v-if="!unassign && assigning == false && $can('UpdateEquipment')"
          v-model="confirmation"
          @accepted="remove()"
        />
        <button
          v-if="
            assigning === false &&
            record.user_id == null &&
            $can('UpdateEquipment')
          "
          class="btn btn-company-primary"
          @click="assigning = record.user_id"
        >
          <i class="fas fa-user"></i>
          Assign
        </button>
        <Confirmation
          v-if="
            assigning == false &&
            !confirmation &&
            record.user_id != null &&
            $can('UpdateEquipment')
          "
          v-model="unassign"
          :icon="'fas fa-user-slash'"
          :text="'Unassign'"
          @accepted="assignTo(null)"
        />
        <div v-if="assigning != false">
          <Select
            v-model="assigning"
            v-if="assigning != false"
            :name="`assigning`"
            :query="usersQuery"
            :queryVariables="{
              orderBy: [{ column: 'name', order: 'ASC' }],
              is_active: true,
              is_external: false,
            }"
          ></Select>
          <div class="mt-2">
            <button class="btn btn-company-primary" @click="assigning = false">
              <i class="fas fa-times"></i>
              Cancel
            </button>
            <button
              class="btn btn-company-secondary"
              @click="assignTo(assigning)"
            >
              <i class="fas fa-check"></i>
              Save
            </button>
          </div>
        </div>
      </div>
      <div class="row justify-content-start mt-2 mx-1">
        <div class="col-auto">
          <Confirmation
            v-if="recoveryMode"
            text="Recover"
            v-model="confirmationRecover"
            @accepted="recover()"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UPDATE_EQUIPMENT } from './mutations';
import Form from './Form.vue';
import { USERS, ErrorHandler } from '../../shared';
import { Select, Confirmation } from '../shared';

export default {
  components: { Form, Select, Confirmation },
  props: {
    record: { type: Object, default: null },
    readOnlyMode: { type: Boolean, default: false },
    is_modal: { type: Boolean, default: false },
    recoveryMode: { type: Boolean, default: false },
  },
  data() {
    return {
      is_editting: false,
      is_recovering: false,
      confirmation: false,
      confirmationRecover: false,
      assigning: false,
      unassign: false,
      usersQuery: USERS,
    };
  },
  methods: {
    update(record) {
      this.$apollo
        .mutate({
          mutation: UPDATE_EQUIPMENT,
          variables: {
            id: record.id,
            record: _.pick(record, [
              'acquired_date',
              'type',
              'location',
              'state',
              'serial_number',
              'internal_key',
              'model',
              'name',
              'user_id',
            ]),
          },
        })
        .catch((error) => {
          ErrorHandler.handle(error, this);
        })
        .then(() => {
          this.is_editting = false;
        });
    },
    assignTo(id) {
      this.record.user_id = id;
      this.update(this.record);
      this.assigning = false;
      this.unassign = false;
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
    recover() {
      this.$emit('recover', this.record);
      this.is_recovering = false;
      this.confirmationRecover = false;
    },
  },
};
</script>
