<template>
  <div class="mb-5 card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Candidate</span>
        <span v-if="record.id">{{ localRecord.first_name }}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="mb-3 col-md-12">
          <DropArea @drop="setFile" :message="'Drop resume here'"/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="row">
            <div class="mb-3 col-md-6">
              <div class="company-control">
                <label for="name">Name</label>
                <input
                  v-focus
                  type="text"
                  class="form-control"
                  :class="{'is-invalid': v$.localRecord.first_name.$invalid}"
                  id="first_name"
                  v-model="localRecord.first_name"
                />
                <div v-for="error of v$.localRecord.first_name.$silentErrors" :key="error.$uid">
                  <div class="text-danger">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="company-control">
                <label for="name">Last name</label>
                <input
                  type="text"
                  class="form-control"
                  :class="{'is-invalid': v$.localRecord.last_name.$invalid}"
                  id="last_name"
                  v-model="localRecord.last_name"
                />
                <div v-for="error of v$.localRecord.last_name.$silentErrors" :key="error.$uid">
                  <div class="text-danger">{{ error.$message }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-6">
              <div class="company-control">
                <label for="email">Email</label>
                <input
                  type="email"
                  class="form-control"
                  :class="{'is-invalid': v$.localRecord.email.$invalid}"
                  id="email"
                  v-model="localRecord.email"
                />
                <div v-for="error of v$.localRecord.email.$silentErrors" :key="error.$uid">
                  <div class="text-danger">{{ error.$message }}</div>
                </div>
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="company-control">
                <label for="phone">Phone N.</label>
                <input
                  type="text"
                  class="form-control"
                  id="phone"
                  v-model="localRecord.phone_number"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-6">
              <div class="company-control">
                <label for="english_level"> English </label>
                <select id="english_level" v-model="localRecord.english_level" class="form-control">
                  <option :key="level" v-for="level in englishLevels" :value="level"> {{level}} </option>
                </select>
              </div>
            </div>
            <div class="mb-3 col-md-6">
              <div class="company-control">
                <label for="status"> Status </label>
                <select id="status" v-model="localRecord.candidate_status_id" class="form-control">
                  <option :key="status.id" v-for="status in candidate_statuses" :value="status.id"> {{status.name}} </option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-12">
              <div class="company-control">
                <label for="salary_expectation_range"> Salary Expectation Range </label>
                <input id="salary_expectation_range" class="form-control" v-model="localRecord.salary_expectation_range"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-12">
              <div class="company-control">
                <label for="country">Country</label>
                <select id="country" class="form-control form-select" v-model="localRecord.country_id">
                  <template v-if="countries">
                    <option :key="country.id" v-for="country in countries" :value="country.id">
                      {{country.name}}
                    </option>
                  </template>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-12">
              <TechnologyPicker :selected="localRecord.technologies || []">Technologies:</TechnologyPicker>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-12">
              <div class="company-control">
                <label for="availability">Availability</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="A specific period of time: Immediately, 1 week, 1 month, 3 months."
                  id="availability"
                  v-model="localRecord.availability"
                />
              </div>
            </div>
          </div>
           <div class="row">
            <div class="mb-3 col-md-6">
              <span>
                <div class="company-control">
                  <label for="is_working">Working </label>
                  <div class="mt-2">
                    <input type="radio" id="yes" v-bind:value="true" v-model="localRecord.is_working">
                    <label for="one">Yes</label>
                    <input type="radio" id="no" v-bind:value="false" v-model="localRecord.is_working">
                    <label for="two">No</label>
                  </div>
                </div>
              </span>
            </div>
            <div v-if="localRecord.is_working" class="mb-3 col-md-6">
              <div class="company-control">
                <label for="company">Company</label>
                <input
                  :disabled="!localRecord.is_working"
                  type="text"
                  class="form-control"
                  id="company"
                  v-model="localRecord.company"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-12">
              <div class="company-control">
                <label>Application Channel</label>
                 <select
                   class="form-control"
                   id="application_channel"
                   v-model="localRecord.application_channel"
                   aria-label="Choose comunication channel"
                 >
                   <option v-bind:key="channelOption.value" v-for="channelOption in channelOptions" v-bind:value="channelOption.value">
                     {{ channelOption.text }}
                   </option>
                 </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-12">
              <div class="company-control">
                <label class="me-sm-2" for="date">
                  Job Experience Started Date
                </label>
                <DatePicker :date="localRecord.job_experience_started_date"
                            placeholder="Select Date"
                            css_class='w-100'
                            @change="localRecord = { ...localRecord, job_experience_started_date: $event }"></DatePicker>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="mb-3 col-md-12">
              <div class="company-control">
                <label for="notes">Notes:</label>
                <trix-editor @trix-change="localRecord.notes = $event.target.value; $forceUpdate()"
                placeholder="Notes here...." class="form-control"></trix-editor>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button
        :disabled="v$.localRecord.$invalid || is_uploading"
        type="button"
        class="btn btn-company-primary"
        @click="save()"
      >
      <i :class="{'fas fa-save' : !is_uploading }"></i>
      <i :class="{'fas fa-sync fa-spin': is_uploading }"></i>
          Save
      </button>
      <button
        :disabled="is_uploading"
        type="button"
        class="btn btn-company-secondary"
        @click="$emit('close')"
      >
      <i class="fas fa-times"></i>
      Cancel
      </button>
    </div>
  </div>
</template>

<script>
import Pizzip from 'pizzip';
import Validations from './Validations';
import TechnologyPicker from '../technologies/Picker.vue';
import {
  ErrorHandler, ALLOWED_RESUME_TYPES, Blinkable, dropdownWithAlias,
} from '../../shared';
import { DropArea } from '../shared';

export default {
  mixins: [Blinkable, Validations],
  components: { DropArea, TechnologyPicker },
  props: ['record', 'is_uploading'],
  apollo: {
    candidate_statuses: {
      query: dropdownWithAlias('candidate_statuses'),
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables() {
        return { type: 'CandidateStatus', orderBy: [{ column: 'id', order: 'ASC' }] };
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
    countries: {
      query: dropdownWithAlias('countries'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Country', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      localRecord: structuredClone(this.record),
      englishLevels: ['A1', 'A2', 'B1', 'B2', 'C1', 'C2'],
      channelOptions: [
        { text: 'Email', value: 'Email' },
        { text: 'LinkedIn', value: 'LinkedIn' },
        { text: 'Referred', value: 'Referred' },
        { text: 'Website', value: 'Website' },
      ],
    };
  },
  methods: {
    setFile(file) {
      const fileExtension = file.name.split('.').pop();
      const reader = new FileReader();
      this.localRecord.file = file;
      if (fileExtension === 'docx' || fileExtension === 'doc') {
        reader.onload = (e) => {
          const resumeText = this.processDocx(e);
          const textFormat = resumeText.replace(/\s+/g, '\n');
          const fields = this.extractFields(textFormat);
          this.setFields(fields);
          this.localRecord.resume_text = resumeText;
        };
        reader.readAsBinaryString(file);
      } else if (fileExtension === 'html') {
        reader.onload = (e) => {
          const resumeText = this.processHtml(e);
          const fields = this.extractFields(resumeText);
          this.setFields(fields);
          this.localRecord.resume_text = resumeText;
        };
        reader.readAsText(file);
      } else if (fileExtension === 'pdf') {
        this.processPDF(file).then((resumeText) => {
          const fields = this.extractFields(resumeText);
          this.setFields(fields);
          this.localRecord.resume_text = resumeText;
        });
      } else {
        this.$toasted.error(`The file extension is not supported. Only supports: ${ALLOWED_RESUME_TYPES}`);
      }
    },
    processHtml(e) {
      const htmlString = e.target.result;
      const stripedHtml = htmlString.replace(/<[^>]+>/g, '');
      return stripedHtml;
    },
    processDocx(e) {
      const content = e.target.result;
      const zip = new Pizzip(content);
      const uint8array = zip.files['word/document.xml']._data.getContent();
      const string = new TextDecoder('utf-8').decode(uint8array);
      let cleanString = string.replaceAll(/<wp:posOffset>[\s\S]*?<\/wp:posOffset>/g, ' ');
      cleanString = cleanString.replaceAll(/<wp14:pctHeight>[\s\S]*?<\/wp14:pctHeight>/g, ' ');
      cleanString = cleanString.replaceAll(/<wp14:pctWidth>[\s\S]*?<\/wp14:pctWidth>/g, ' ');
      cleanString = cleanString.replaceAll(/<[^>]+>/g, ' ');
      return cleanString;
    },
    processPDF(file) {
      const pdfjsLib = window['pdfjs-dist/build/pdf'];
      pdfjsLib.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.js';
      const url = window.URL.createObjectURL(file);
      const pdfjs = pdfjsLib.getDocument(url);
      return pdfjs.promise.then((pdf) => {
        const maxPages = pdf.numPages;
        const promises = [];
        for (let i = 1; i <= maxPages; i++) {
          const currentPage = pdf.getPage(i);
          promises.push(currentPage.then((page) => {
            const textContent = page.getTextContent();
            return textContent.then((text) => text.items.map((s) => s.str).join(' '));
          }));
        }
        // Wait for all pages and join text
        return Promise.all(promises).then((texts) => texts.join(''));
      });
    },
    save() {
      const event = this.localRecord.id ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
    extractFields(text) {
      const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;
      // Extract the title of the resume
      const nameRegex = /(?<=^[\s"']*)(\w+)/;
      const lastnameRegex = /(?<=^[\s"']*\w+\s+)(\w+)/;
      const phoneRegex = /[+]?[-\s.]?[0-9]{8,12}/;
      const englishLevelRegex = /([AaBbCc]\d)/;
      return {
        name: nameRegex.exec(text)?.[0],
        lastname: lastnameRegex.exec(text)?.[0],
        email: emailRegex.exec(text)?.[0],
        phone: phoneRegex.exec(text)?.[0],
        englishlevel: englishLevelRegex.exec(text)?.[0],
      };
    },
    setFields(fields) {
      const {
        name, lastname, email, phone, englishlevel,
      } = fields;
      this.localRecord = {
        ...this.localRecord,
        first_name: name,
        last_name: lastname,
        email,
        phone_number: phone,
        english_level: englishlevel,
      };
    },
  },
};
</script>
