<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-12 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li aria-current="page" class="breadcrumb-item active fw-bold">
              Suggestions Box
            </li>
          </ol>
        </div>
      </div>
    </nav>
    <div class="container-fluid p-0">
      <template>
        <div class="text-center row pt-3">
          <div class="col-md-12 text-start">
            <h5>
              Do you have any suggestion?
              <b>Type it bellow</b><br>
              <span class="text-body-secondary">Suggestions are anonymous. 😉</span>
            </h5>
          </div>
        </div>
        <div class="text-center border-top border-bottom py-1 my-2">
          <div class="py-2">
            <input v-focus type="text" class="form-control" v-model="form.title" placeholder="Title">
          </div>
        </div>
        <div>
          <div class="py-2 option-text" id="SuggestionBoxTrix">
            <trix-editor @trix-change="form.suggestion = $event.target.value; $forceUpdate()"
            placeholder="We appreciate your comments 😊"></trix-editor>
          </div>
        </div>
        <div class="d-flex justify-content-center pt-3">
          <button :disabled="form.title === null" type="button" class="btn btn-company-primary py-2 px-5" @click="save()" >
            <span class="h4 py-2">
              Submit
              <i class="fas" :class="loading ? 'fa-sync fa-spin' : 'fa-paper-plane'"></i>
            </span>
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import { CREATE_SUGGESTION } from './mutations';
import { ErrorHandler } from '../../shared';

export default {
  data() {
    return {
      loading: false,
      form: {
        title: null,
        suggestion: null,
      },
    };
  },
  computed: {
    localRecord() {
      return {
        ...this.form,
        status: 'pending',
      };
    },
  },
  methods: {
    save() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: CREATE_SUGGESTION,
        variables: {
          input: this.localRecord,
        },
      }).then(() => {
        this.$toasted.success('Suggestion was recorded!');
        this.form = { title: null, suggestion: null };
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
