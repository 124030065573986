<style scoped>
.Popover__Container>.Popover__Content {
    display: none;
}
.Popover__Container>.Popover__Title {
    display: none;
}
</style>
<template>
  <div ref="container" class="Popover__Container">
    <div ref="title" class="Popover__Title">
      <slot name="title"></slot>
    </div>
    <div ref="content" class="Popover__Content">
      <slot name="content"></slot>
    </div>
    <div ref="caller" class="Popover__Caller">
      <slot name="caller"><a>Im a popOverItem</a></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    placement: {
      type: String,
      default: 'auto',
    },
    trigger: {
      type: String,
      default: 'click',
    },
    boundary: {
      type: String,
      default: 'window',
    },
  },

  mounted() {
    const { content, title, caller } = this.$refs;
    // PopOver Definition
    return new window.bootstrap.Popover(this.$refs.caller, {
      container: caller,
      title,
      html: true,
      placement: this.placement,
      sanitize: false,
      customClass: 'noselect',
      content,
      boundary: this.boundary,
      trigger: this.trigger,
    });
  },
};

</script>
