<template>
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-6 d-flex">
          <div class="mb-3 mx-4">
            <label>
              <i class="fa-solid fa-calendar"></i>
              Start Date
            </label>
            <DatePicker :date="start_date" placeholder="Start Date" @change="start_date = $event"></DatePicker>
          </div>
          <div class="mb-3">
            <label>
              <i class="fa-solid fa-calendar"></i>
              End Date
            </label>
            <DatePicker :date="end_date" placeholder="End Date" @change="end_date = $event"></DatePicker>
          </div>
          <div class="mx-2">
            <button type="button" class="btn btn-company-primary" @click="getHours()">
              <i class="fas fa-search"></i>
              Search
            </button>
          </div>
        </div>
        <div class="col-md-6 d-flex justify-content-end mb-3" v-if="!loading">
          <div class="mb-3 me-4 my-auto">
            <div class="form-check form-switch">
              <input v-model="overwrite" @click="overwrite = !overwrite" type="checkbox" class="form-check-input" id="overwrite">
              <label class="form-check-label" for="overwrite">Overwrite</label>
            </div>
          </div>
          <div class="me-4">
            <button type="button" class="btn btn-company-primary" @click="save()">
              <i class="fas fa-save"></i>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <div v-if="loading" class="d-flex justify-content-center">
        <i class="fas fa-sync mt-3 mb-5" :class="{'fa-spin': loading || loading}"></i>
      </div>
      <div v-if="!loading && (!myHoursGetLogHours || myHoursGetLogHours.length === 0)" class="d-flex justify-content-center">
        <Empty icon="fas fa-project-diagram"/>
      </div>
      <table v-if="!loading && myHoursGetLogHours && myHoursGetLogHours.length > 0" class="table">
        <tr>
          <td>
            <div class="form-check">
              <div class="form-check">
                <input id="check_all" type="checkbox" class="form-check-input" @change="selectedAll($event)">
                <label class="form-check-label" for="check_all"></label>
              </div>
            </div>
          </td>
          <th>User</th>
          <th>Hours</th>
          <th>Detail</th>
          <th>Date</th>
          <th>My Hours User Id</th>
          <th>My Hours Project Id</th>
        </tr>
        <tr v-for="(item, index) in myHoursGetLogHours" :key="index">
          <td>
            <div class="form-check" v-if="item.user">
              <div class="form-check">
                <input :id="`check_${index}`" type="checkbox" class="form-check-input" v-model="item.checked">
                <label class="form-check-label" :for="`check_${index}`"></label>
              </div>
            </div>
          </td>
          <td>{{item.user ? item.user.name : 'There is no mapping assign to this user'}}</td>
          <td>{{item.hours}}</td>
          <td>{{item.note}}</td>
          <td>{{dateFormat(item.date)}}</td>
          <td>{{item.my_hours_user_id}}</td>
          <td>{{item.my_hours_project_id}}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { MY_HOURS_QUERY } from './queries';
import { SAVE_MY_HOURS } from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';

export default {
  data() {
    return {
      loading: false,
      loadingHours: false,
      overwrite: false,
      myHoursGetLogHours: null,
      start_date: moment().clone().startOf('isoWeek').format('yyyy-MM-DD'),
      end_date: moment().clone().endOf('isoWeek').format('yyyy-MM-DD'),
    };
  },
  methods: {
    getHours() {
      this.loading = true;
      this.$apollo
        .query({
          query: MY_HOURS_QUERY,
          errorPolicy: 'all',
          variables: {
            start_date: this.start_date,
            end_date: this.end_date,
            project_id: parseInt(this.$route.params.id, 10),
          },
          error(error) {
            this.loading = false;
            this.$toasted.error(error.message);
          },
        }).then((result) => {
          this.loading = false;
          this.myHoursGetLogHours = result.data.myHoursGetLogHours;
        })
        .catch((error) => {
          this.loading = false;
          ErrorHandler.handle(error, this);
        });
    },
    dateFormat(date) {
      return moment(String(date)).format('DD/MM/YYYY');
    },
    selectedAll($event) {
      this.myHoursGetLogHours.forEach((item) => {
        if (item.user) {
          item.checked = $event.target.checked;
        }
      });
    },
    save() {
      this.loading = true;
      const list = this.myHoursGetLogHours?.filter((item) => item.checked)
        .map((element) => ({
          user_id: element.user_id,
          hours: element.hours,
          created_at: element.date,
          activities: element.note,
        }));
      if (!list || list.length <= 0) {
        this.loading = false;
        this.$toasted.error('You must have at least one item checked');
        return;
      }
      this.$apollo.mutate({
        mutation: SAVE_MY_HOURS,
        variables: {
          project_id: this.$route.params.id,
          overwrite: this.overwrite,
          start_date: this.start_date,
          end_date: this.end_date,
          items: list,
        },
      }).then(() => {
        this.loading = false;
        this.$toasted.success('Hours saved');
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.loading = false;
      });
    },
  },
};
</script>
