<template>
<div :id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting" :record.sync="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{record.name}}
      </h5>
      <div class="row">
        <div class="col-md-6">
          <strong>From: {{$displayDate(record.start_date)}}</strong>
        </div>
        <div class="col-md-6">
          <strong>To: {{$displayDate(record.end_date)}}</strong>
        </div>
      </div>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdatePerformanceEvaluationCycle')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeletePerformanceEvaluationCycle')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_PERFORMANCE_EVALUATION_CYCLE } from './mutations';
import Form from './Form.vue';
import { ErrorHandler } from '../../shared';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(performanceEvaluationCycle) {
      this.$apollo.mutate({
        mutation: UPDATE_PERFORMANCE_EVALUATION_CYCLE,
        variables: {
          id: performanceEvaluationCycle.id,
          performance_evaluation_cycle: _.pick(performanceEvaluationCycle, ['name', 'is_active', 'start_date', 'end_date']),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
