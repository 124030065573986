<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord"
                                   :is_uploading="isUploading"
                                   @close="selectedRecord = null"
                                   @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Candidates</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-11 m-auto">
              <Search
                :search.sync="search"
                :inputs="['description', 'candidateStatus', 'countries']"
              ></Search>
            </div>
            <div class="col-12 col-sm-1 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = {}" v-if="$can('CreateCandidate')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="resume_candidate_full_text_search">
      <Empty v-if="!resume_candidate_full_text_search.data.length" icon="fas fa-address-book" :can_create="true" @callCreate="selectedRecord = {}"/>
      <Card :key="record.id" v-for="record in resume_candidate_full_text_search.data" :record="record" @remove="remove" @open-resume="openResume" @refresh="refresh"></Card>
    </div>
    <SkeletonCards v-if="resume_candidate_full_text_search && resume_candidate_full_text_search.paginatorInfo.hasMorePages" :cards=3 :executeScroll="resume_candidate_full_text_search != undefined" @showMore="showMore('resume_candidate_full_text_search')"></SkeletonCards>
  </div>
</template>

<script>
import RESUME_CANDIDATE_FULL_TEXT_SEARCH_QUERY from './queries';
import { DELETE_CANDIDATE, CREATE_CANDIDATE } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import { ErrorHandler, PaginationHandler } from '../../shared';
import RequestBuilder from './RequestBuilder';
import SearchBuilder from './SearchBuilder';

export default {
  extends: PaginationHandler,
  components: {
    Form,
    Card,
    Search,
    SkeletonCards,
    SpinnerRouterLink,
  },
  computed: {
    showFullTextSearchResults() {
      return this.resume_candidate_full_text_search?.length > 0;
    },
  },
  apollo: {
    resume_candidate_full_text_search: {
      errorPolicy: 'all',
      query: RESUME_CANDIDATE_FULL_TEXT_SEARCH_QUERY,
      variables() {
        return {
          page: 1,
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
      isUploading: false,
    };
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    refresh() {
      this.$apollo.queries.resume_candidate_full_text_search.refetch();
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_CANDIDATE,
        variables: {
          id: record.id,
        },
        update: (store, { data: { deleteCandidate } }) => {
          const data = store.readQuery({ query: RESUME_CANDIDATE_FULL_TEXT_SEARCH_QUERY, variables: this.queryVariables() });
          data.resume_candidate_full_text_search.data = data.resume_candidate_full_text_search.data.filter((c) => c.id !== deleteCandidate.id);
          store.writeQuery({ query: RESUME_CANDIDATE_FULL_TEXT_SEARCH_QUERY, variables: this.queryVariables(), data });
        },
      }).catch((error) => {
        this.$toasted.error(
          `Unable to delete record: ${error.message}`,
        );
      });
    },
    insert(record) {
      if (record.file) {
        // Add timestamp to avoid replacing files with same name, example CV.pdf
        const fileName = `${Date.now()}_${record.file.name}`;
        this.isUploading = true;
        const params = { Key: fileName, Body: record.file };
        this.$s3Upload(params).then(() => {
          const newCandidate = record;
          newCandidate.resume_url = this.$s3Location(fileName);
          this.selectedRecord = null;
          this.$apollo.mutate({
            mutation: CREATE_CANDIDATE,
            variables: {
              input: RequestBuilder.build(newCandidate),
            },
            update: (store, { data: { createCandidate } }) => {
              const data = store.readQuery({ query: RESUME_CANDIDATE_FULL_TEXT_SEARCH_QUERY, variables: this.queryVariables() });
              data.resume_candidate_full_text_search.data.unshift(createCandidate);
              store.writeQuery({ query: RESUME_CANDIDATE_FULL_TEXT_SEARCH_QUERY, variables: this.queryVariables(), data });
              this.isUploading = false;
            },
          }).catch((error) => {
            ErrorHandler.handle(error, this);
            this.selectedRecord = newCandidate;
            this.isUploading = false;
          });
        });
      }
    },
    openResume(url) {
      const key = decodeURI(url.split('/').pop());
      this.$s3GetSignedUrl(key).then((s3Url) => window.open(s3Url, '_blank'));
    },
  },
};
</script>
