import { gql } from 'apollo-boost';
import { NET_PROMOTER_SCORE_STATISTICS_FRAGMENT } from './fragments';

export const NPS_ANSWERS_QUERY = gql`
  query(
    $page: Int
    $id: ID,
    $client_id: ID,
    $project_id: ID,
    $start_date: DateTime,
    $end_date: DateTime,
    $score: Int,
    $orderBy: [OrderByClause!]
  ){
    netPromoterScoreAnswers(
      page: $page,
      id: $id,
      client_id: $client_id,
      project_id: $project_id,
      start_date: $start_date,
      end_date: $end_date,
      score: $score,
      orderBy: $orderBy
    ){
      paginatorInfo {
        hasMorePages
      }
      data{
        id
        score
        reason
        created_at
        client {
          id
          name
        }
        project{
          id
          name
        }
      }
    }
  }
`;

export const INTERNAL_NPS_ANSWERS_QUERY = gql`
  query(
    $page: Int
    $user_id: ID,
    $end_date: DateTime,
    $start_date: DateTime,
    $score: Int,
    $orderBy: [OrderByClause!]
  ){
    internalNetPromoterScoreAnswers(
      page: $page,
      user_id: $user_id,
      start_date: $start_date,
      end_date: $end_date,
      score: $score,
      orderBy: $orderBy
    ){
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        score
        reason
        created_at
        user {
          id
          name
          avatar
        }
      }
    }
  }
`;

export const NET_PROMOTER_SCORE_SURVEYS = gql`
  query(
        $first: Int!,
        $page: Int!,
        $state: String,
        $client_id: ID,
        $project_id: ID,
        $only_my_projects: Boolean,
        $user_id: ID,
        $orderBy: [OrderByClause!]) {
    projects(
      first: $first,
      page: $page,
      state: $state,
      client_id: $client_id,
      project_id: $project_id,
      only_my_projects: $only_my_projects,
      user_id: $user_id,
      orderBy: $orderBy) @connection(key: "net_promoter_score_survey", filter: ["client_id", "project_status_id", "state", "only_my_projects", "user_id"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        name
        last_known_net_promoter_score
        last_known_net_promoter_score_date
        client{
          id
          name
        }
      }
    }
  }
`;

export const NET_PROMOTER_SCORE_STATISTICS = gql`
  query($client_id: ID, $project_id: ID, $start_date: DateTime, $end_date: DateTime) {
    netPromoterScoreStatistics (client_id: $client_id, project_id: $project_id, start_date: $start_date, end_date: $end_date){
      ...npsFragment
    }
  }
  ${NET_PROMOTER_SCORE_STATISTICS_FRAGMENT}
`;

export const INTERNAL_NET_PROMOTER_STATISTICS = gql`
  query($start_date: DateTime, $end_date: DateTime){
    internalNetPromoterScoreStatistics(start_date: $start_date, end_date: $end_date){
      ...npsFragment
    }
  }
  ${NET_PROMOTER_SCORE_STATISTICS_FRAGMENT}
`;
