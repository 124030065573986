import { gql } from 'apollo-boost';

export const INDICATOR_QUERY = gql`
query($id: ID!, $start_date: DateTime, $end_date: DateTime){
  indicator(id: $id){
    id
    name
    frequency
    description
    last_updated_at
    most_recent_value
    prefix
    suffix
    details(start_date: $start_date, end_date: $end_date, first: 500) {
      data {
        id
        value
        created_at
      }
    }
  }
}
`;

export const PUBLIC_INDICATORS_QUERY = gql`
  query($orderBy: [OrderByClause!]) {
    publicIndicators(orderBy: $orderBy) {
      id
      name
      frequency
      description
      last_updated_at
      most_recent_value
      prefix
      suffix
      details {
        data {
          id
          value
          created_at
        }
      }
    }
  }
`;

export const INDICATORS_QUERY = gql`
  query($page: Int!, $is_visible: Boolean, $orderBy: [OrderByClause!], $name: String, $id: ID) {
    indicators(page: $page, is_visible: $is_visible, orderBy: $orderBy, name: $name, id: $id) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        name
        frequency
        description
        last_updated_at
        most_recent_value
        prefix
        suffix
        is_favorite
        alarms {
          id
          threshold_type
          threshold_value
          notification_recipients
        }
        details {
          data {
            id
            value
            created_at
          }
        }
      }
    }
  }
`;
