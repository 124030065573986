<template>
<div class="mb-5 card">
  <div class="card-header d-flex">
    <div class="col p-0">
      <span v-if="!record.id">New Evaluation</span>
      <span v-if="record.id">{{localRecord.user.name}} - {{localRecord.cycle.name}}</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body d-grid">
    <div class="mb-3" v-if="$route.name !== 'My Performance Evaluations'">
      <label for="performance_evaluation_form_id" class="pe-2">
        Evaluation Form: <i v-if="$apollo.queries.evaluationFormsDropDown.loading" class="fas fa-spinner fa-spin"></i>
      </label>
      <select class="form-control form-select" :class="{'is-invalid': v$.localRecord.performance_evaluation_form_id.$invalid}" v-model="localRecord.performance_evaluation_form_id">
        <option :key="`f-${index}`" v-for="(form, index) in evaluationFormsDropDown" :value="form.id">
          {{form.name}}
        </option>
      </select>
      <div v-for="error of v$.localRecord.performance_evaluation_form_id.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3" v-if="$route.name !== 'My Performance Evaluations'">
      <label for="user_id" class="pe-2">Person to evaluate: <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i></label>
      <Select v-model="localRecord.user_id"
              :name="`user_id`"
              :query="usersQuery"
              :queryVariables="{ orderBy: [{ column: 'name', order: 'ASC' }], is_active: true, is_external: false }"></Select>
      <div v-for="error of v$.localRecord.user_id.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3">
      <label for="evaluator_id" class="pe-2">Evaluator: <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i></label>
      <Select v-model="localRecord.evaluator_id"
              :name="`evaluator_id`"
              :query="usersQuery"
              :queryVariables="{ orderBy: [{ column: 'name', order: 'ASC' }], is_active: true, is_external: false }"></Select>
      <div v-for="error of v$.localRecord.evaluator_id.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3">
      <label for="performance_evaluation_cycle_id" class="pe-2">Evaluation Cycle: <i v-if="$apollo.queries.evaluationCyclesDropDown.loading" class="fas fa-spinner fa-spin"></i></label>
      <select class="form-control form-select" :class="{'is-invalid': v$.localRecord.performance_evaluation_cycle_id.$invalid}" v-model="localRecord.performance_evaluation_cycle_id">
        <option :key="`c-${index}`" v-for="(cycle, index) in evaluationCyclesDropDown" :value="cycle.id">
          {{cycle.name}}
        </option>
      </select>
      <div v-for="error of v$.localRecord.performance_evaluation_cycle_id.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3" v-if="$route.name !== 'My Performance Evaluations'">
      <label for="evaluator_id" class="pe-2">Status: <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i></label>
      <select class="form-control form-select" v-model="localRecord.status">
        <option>Created</option>
        <option>In Progress</option>
        <option>Closed</option>
      </select>
    </div>
    <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Save
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>

<script>
import Validations from './Validations';
import { Blinkable, USERS, dropdownWithAlias } from '../../shared';
import { Select } from '../shared';

export default {
  mixins: [Blinkable, Validations],
  components: { Select },
  apollo: {
    evaluationCyclesDropDown: {
      query: dropdownWithAlias('evaluationCyclesDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'PerformanceEvaluationCycle', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    evaluationFormsDropDown: {
      query: dropdownWithAlias('evaluationFormsDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'PerformanceEvaluationForm', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      usersQuery: USERS,
      localRecord: structuredClone(this.record),
    };
  },
  props: ['record'],
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
