<template>
<div :id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h6 v-if="record.difficulty === 'Easy'" class="card-title mb-2 badge bg-company-fiveth-ligth m-1">
        <i class="fa-solid fa-arrow-right-long"></i>
        {{record.difficulty}}</h6>
      <h6 v-if="record.difficulty === 'Normal'" class="card-title mb-2 badge bg-company-primary m-1">
        <i class="fa-solid fa-arrow-trend-up"></i>
        {{record.difficulty}}
      </h6>
      <h6 v-if="record.difficulty === 'Hard'" class="card-title mb-2 badge bg-company-third-ligth m-1">
        <i class="fa-solid fa-arrow-up-long"></i>
        {{record.difficulty}}
      </h6>
      <p v-html='record.question'></p>
      <hr>
      <p v-html='record.answer'></p>
    </div>
    <div class="card-body pt-0" v-if="record.tags.length > 0">
      <div class="row">
        <div class="col-md-12">
          <strong>Tags:</strong>
          <br>
          <span :key="tag.id" v-for="tag in record.tags" class="badge bg-company-secondary me-1">{{tag.name}}</span>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button v-if="!confirmation && $can('UpdateInterviewQuestion')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('UpdateInterviewQuestion')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>

import RequestBuilder from './RequestBuilder';
import { UPDATE_INTERVIEW_QUESTION } from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';
import Form from './Form.vue';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_INTERVIEW_QUESTION,
        variables: {
          id: record.id,
          interviewQuestion: RequestBuilder.build(record),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.record.name = record.name;
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
