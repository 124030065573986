<template>
  <div class="row task-container flex-nowrap">
    <div class="input-group col-md-8 p-0 flex-fill">
      <span class="input-group-text grabbable text-default rounded-0">
        <i class="fas fa-arrows-alt-v"></i>
      </span>
      <input v-if="isDuplicated" type="text" class="form-control rounded-0 w-25 text-danger" placeholder="Name" v-model="bucket_estimation_item.name" title="Duplicated Line"/>
      <input v-if="!isDuplicated" type="text" class="form-control rounded-0 w-25" placeholder="Name" v-model="bucket_estimation_item.name" :title="bucket_estimation_item.name"/>
    </div>
    <div class="input-group col-md-4 p-0 flex-fill">
      <input type="number" class="form-control rounded-0" placeholder="Hours" v-model="bucket_estimation_item.hours"/>
      <span class="input-group-text sortable rounded-0">
        <input type="checkbox" class="form-check-input sortable" v-model="bucket_estimation_item.is_completed"/>
      </span>
      <span class="input-group-text sortable text-danger rounded-0" @click="$emit('deleteLine', bucket_estimation_item)">
        <i class="fas fa-times"></i>
      </span>
    </div>
  </div>
</template>
<script>
export default { props: ['bucket_estimation_item', 'isDuplicated'] };
</script>
