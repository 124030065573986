import { gql } from 'apollo-boost';
import INTERVIEW_FRAGMENT from './fragments';

export default gql`
  query ($page: Int, $user_id: ID, $notes: String, $is_successful: Boolean,) {
    interviews(
      page: $page,
      user_id: $user_id,
      notes: $notes,
      is_successful: $is_successful,
      orderBy: { column: "id", order: ASC }) @connection(key: "interviews", filter: ["notes", "is_successful", "user_id"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...interview
      }
    }
  }
  ${INTERVIEW_FRAGMENT}
`;
