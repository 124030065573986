import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      localRecord: {
        user_id: { required },
        evaluator_id: { required },
        performance_evaluation_cycle_id: { required },
        performance_evaluation_form_id: { required },
      },
    };
  },
};
