<template>
  <div class="row mb-2">
    <div class="col-md-12">
      <div class="card">
        <div class="card-body">
          <h5 class="card-title">
            <span v-if="record.client">{{ record.client.name }} / <strong v-if="record.project">{{ record.project.name }}</strong></span>
            <span v-if="record.user">
              <img width="30rem" class="rounded-circle" :src="record.user.avatar">
              {{ record.user.name }}
            </span>
            <span v-if="!record.user && !record.client">
              <i class="fas fa-mask"></i>
              Anonymous
            </span>
            <span class="float-end">Score: <span :class="scoreClassColor">{{ `${record.score}/10` }}</span></span>
          </h5>
          <blockquote>
            <p class="mb-0" v-html="record.reason"></p>
          </blockquote>
          <span>{{timeAgo}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  computed: {
    timeAgo() {
      return moment(this.record.created_at, 'YYYY-MM-DD').endOf('day').fromNow();
    },
    scoreClassColor() {
      if (this.record.score >= 9 && this.record.score <= 10) return 'text-success';
      if (this.record.score >= 7 && this.record.score <= 8) return 'text-warning';
      return 'text-danger';
    },
  },
};
</script>
