<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Promotions</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search
                :search.sync="search"
                :inputs="['users']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="promotions">
      <div class="col-md-12">
        <div class="text-nowrap h-100 scroll-x">
          <table class="table">
            <tr>
              <th class="d-table-cell">#</th>
              <SortByColumn class="d-table-cell" :sort_by_field="'user_id'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'date'" :orderBy="orderBy" @refresh="refresh">Date</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'from_job_title_id'" :orderBy="orderBy" @refresh="refresh">Past</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'to_job_title_id'" :orderBy="orderBy" @refresh="refresh">Present</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'next_job_title_id'" :orderBy="orderBy" @refresh="refresh">Future</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'next_promotion_date'" :orderBy="orderBy" @refresh="refresh">Next promotion date</SortByColumn>
            </tr>
            <tr :key="index" v-for="(row, index) in promotions.data">
              <td>{{index + 1}}</td>
              <td>{{row.user.name}}</td>
              <td>{{$displayDate(row.date)}}</td>
              <td>
                <span class="badge bg-danger position-badge text-white">
                  {{row.from_job_title.internal_description}}
                </span>
              </td>
              <td>
                <span class="badge bg-success position-badge text-white">
                  {{row.to_job_title.internal_description}}
                </span>
              </td>
              <td>
                <span class="badge bg-primary position-badge text-white">
                  {{row.next_job_title.internal_description}}
                </span>
              </td>
              <td>
                {{$displayDate(row.next_promotion_date)}}
                <span :class="nextPromotionDateDiffColor(row.next_promotion_date_diff)">({{row.next_promotion_date_diff}})</span>
              </td>
            </tr>
            <tr v-if="promotions && promotions.length === 0">
              <td class="text-center" colspan="7">No records found</td>
            </tr>
            <SkeletonRow
              v-if="promotions && promotions.paginatorInfo.hasMorePages"
              :colspan=7
              :executeScroll="promotions != undefined"
              @showMore="showMore('promotions')"
            ></SkeletonRow>
          </table>
        </div>
      </div>
    </div>
    <Empty v-if="!promotions?.data.length" icon="fas fa-seedling"/>
  </div>
</template>

<script>

import PROMOTIONS_QUERY from './queries';
import {
  Search, SkeletonRow, SortByColumn, SpinnerRouterLink,
} from '../../shared';
import { PaginationHandler } from '../../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Search, SkeletonRow, SortByColumn, SpinnerRouterLink,
  },
  data() {
    return {
      orderBy: { column: 'date', order: 'DESC' },
      search: { },
    };
  },
  apollo: {
    promotions: {
      query: PROMOTIONS_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          page: 1,
          orderBy: [this.orderBy],
          ...this.search,
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    queryVariables() {
      return { orderBy: [this.orderBy], ...this.search };
    },
    refresh() {
      this.$apollo.queries.promotions.refetch();
    },
    nextPromotionDateDiffColor(nextPromotionDateDiff) {
      return nextPromotionDateDiff.includes('ago') ? 'text-danger' : 'text-success';
    },
  },
};
</script>
