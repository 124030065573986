<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <ul class="breadcrumb p-0 m-auto">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="fas fa-home"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active fw-bold" aria-current="page">
                Learning Paths Evaluation - <span class="text-primary" v-if="user">{{ user.name }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-3">
              <Search
                :search.sync="search"
                :inputs="['learningPaths', 'inputname']"
                class="pe-5"
              ></Search>
            </div>
            <div class="col-md-9">
              <div class="company-control">
                <div v-if="learningPathLevelsDropdown && this.search.learning_path_id">
                  <label for="learning_path_level_id">Level:</label>
                </div>
                <div v-if="learningPathLevelsDropdown && this.search.learning_path_id">
                  <select id="learning_path_level_id" v-model="learning_path_level_id" class="form-control form-select me-5" @change="refresh()">
                    <option :key="`l-${level.id}`" v-for="level in learningPathLevelsDropdown" :value="level.id">{{level.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <template v-if="show_learning_path_level_node_users_with_optional_filter">
      <div class="row">
        <Card
          v-for="record in show_learning_path_level_node_users_with_optional_filter.data"
          :key="record.id"
          :record="record"/>
        <div class="col-md-12" v-if="show_learning_path_level_node_users_with_optional_filter.data.length === 0">
          <p>There are no pending evaluations for this learning path.</p>
        </div>
      </div>
      <SkeletonCards v-if="show_learning_path_level_node_users_with_optional_filter && show_learning_path_level_node_users_with_optional_filter.paginatorInfo.hasMorePages" :cards=2
                      :executeScroll="show_learning_path_level_node_users_with_optional_filter != undefined" @showMore="showMore('show_learning_path_level_node_users_with_optional_filter')"></SkeletonCards>
    </template>
  </div>
</template>

<script>
import { LEARNING_PATH_LEVEL_NODE_USER_QUERY, USER_QUERY } from './queries';

import { Search, SkeletonCards } from '../../shared';
import Card from './Card.vue';
import { PaginationHandler, LEARNING_PATH_LEVELS } from '../../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Card,
    SkeletonCards,
    Search,
  },
  apollo: {
    show_learning_path_level_node_users_with_optional_filter: {
      query: LEARNING_PATH_LEVEL_NODE_USER_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          status: 'Ready for evaluation',
          user_id: this.$route.params.id,
          learning_path_level_id: this.learning_path_level_id,
          learning_path_id: this.search.learning_path_id,
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    learningPathLevelsDropdown: {
      query: LEARNING_PATH_LEVELS,
      errorPolicy: 'all',
      variables() {
        return { learning_path_id: this.search.learning_path_id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    user: {
      query: USER_QUERY,
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      learning_path_level_id: null,
    };
  },
  methods: {
    queryVariables() {
      return { status: 'Pending', user_id: this.$route.params.id };
    },
    refresh() {
      this.$apollo.queries.learning_path_level_node_users.refetch();
    },
  },
};
</script>
