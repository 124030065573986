import { useVuelidate } from '@vuelidate/core';
import { required, email, maxLength } from '@vuelidate/validators';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      localRecord: {
        first_name: { required, maxLengthValue: maxLength(255) },
        last_name: { required, maxLengthValue: maxLength(255) },
        email: { required, email, maxLengthValue: maxLength(255) },
      },
    };
  },
};
