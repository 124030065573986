<template>
  <tr v-if="localRecord">
    <td>{{index + 1}}</td>
    <template v-if="!editing">
      <td>
        <ShowCost :cost="localRecord.cost"></ShowCost>
      </td>
      <td>{{$displayDate(localRecord.date)}}</td>
    </template>
    <template v-else>
      <td>
        <input  v-focus
                v-model="localRecord.cost"
                :class="{'is-invalid': v$.localRecord.cost.$invalid}"
                type="number"
                class="form-control"
                placeholder="Cost in $"
                aria-label="Cost"
                required>
        <div v-for="error of v$.localRecord.cost.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </td>
      <td>
        <div class="mb-2">
          <DatePicker :date="localRecord.date"
                      placeholder="Select Date"
                      :css_class="{'is-invalid': v$.localRecord.date.$invalid}"
                      @change="localRecord = { ...localRecord, date: $event }"></DatePicker>
        </div>
        <div v-for="error of v$.localRecord.date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </td>
    </template>
    <td>{{localRecord.time}} Days</td>
    <td>
      <button v-if="!editing" class="btn btn-company-primary" @click="editing = true">Edit</button>
      <button v-if="editing" class="btn btn-company-primary" @click="cancel">Cancel</button>
      <button :disabled="v$.localRecord.$invalid" v-if="editing" class="btn btn-company-secondary" @click="save" >Save</button>
    </td>
  </tr>
</template>

<script>
import Validations from './Validations';
import { ShowCost } from '../shared';

export default {
  extends: Validations,
  props: ['userCost', 'index'],
  components: { ShowCost },
  data() {
    return {
      editing: false,
      localRecord: structuredClone(this.userCost),
    };
  },
  methods: {
    save() {
      this.$emit('costUpdated', this.localRecord);
      this.editing = false;
    },
    cancel() {
      this.editing = false;
      this.$emit('cancel', this.localRecord);
    },
  },
};
</script>
