import { gql } from 'apollo-boost';

export default gql`
  fragment estimation on Estimation {
    id
    name
    notes
    client_id
    client {
      id
      name
    }
    bucket_estimations{
      id
      total_hours
      bucket {
        id
        name
        is_parallel
      }
    }
    buckets {
      id
      name
    }
    projects{
      id
    }
    technologies {
      id
      name
    }
  }
`;
