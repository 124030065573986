import { gql } from 'apollo-boost';

export const PEOPLE_IN_BENCH_WITH_COSTS = gql`
  query($orderBy: [OrderByClause!]) {
    peopleInBench(orderBy: $orderBy) {
      id
      person
      internal_description
      last_known_cost
      last_project
      time_in_bench
      since
    }
  }
`;

export const PEOPLE_IN_BENCH = gql`
  query($orderBy: [OrderByClause!]) {
    peopleInBench(orderBy: $orderBy) {
      id
      person
      internal_description
      last_project
      time_in_bench
      since
    }
  }
`;
