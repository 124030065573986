<style scoped>
.pop_over_list-group {
  max-height: 50vh;
  overflow-y: scroll;
}

</style>
<template>
  <div id="sidebar-container" class="transition-05 sidebar-expanded d-none d-md-block company-background-color sidebar-settings ps-0 pe-0" :style="sidebarStyle">
    <ul class="list-group">
      <!-- Dynamic menu -->
      <FastNavigation></FastNavigation>
      <template v-for="item in menu_items">
        <!-- Items -->
        <a :key="`a-${item.anchor}`" v-if="item.isShown && !popover_enabled" :href="`#${item.anchor}`" data-bs-toggle="collapse" aria-expanded="false" class="company-background-color list-group-item list-group-item-action flex-column align-items-start">
          <div class="d-flex w-100 justify-content-between align-items-center">
            <div>
              <i :class="`${item.icon} fa-fw mpop_over_list-groupe-3 me-xxl-2`"></i>
              <span>{{item.name}}</span>
            </div>
            <i class="fas fa-sort-down submenu-icon"></i>
          </div>
        </a>
        <div :key="`div-${item.anchor}-${child.anchor}`" :id="item.anchor" class="collapse sidebar-submenu" v-for="child in item.children">
          <router-link :key="`rl-${item.anchor}-${child.anchor}`" v-if="child.isShown" class="list-group-item list-group-item-action company-background-color" :to="child.route">
            <i :class="`${child.icon} fa-fw me-3`"></i>
            <span>{{child.name}}</span>
          </router-link>
        </div>
        <!-- PopOver Items -->
        <popover :key="`p-${item.anchor}`" ref="popover" placement="auto" trigger="hover" v-if="item.isShown && popover_enabled">
          <template v-slot:title>
            <i :class="`${item.icon} fa-fw me-3`"></i>
            {{item.name}}
          </template>
          <template v-slot:content>
            <ul class="list-group">
              <template v-for="child in item.children">
                <router-link :key="`prl-${item.anchor}-${child.anchor}`" v-if="child.isShown" class="list-group-item list-group-item-action company-background-color" :to="child.route" >
                  <i :class="`${child.icon} fa-fw me-3`"></i>
                  <span>{{child.name}}</span>
                </router-link>
              </template>
            </ul>
          </template>
          <template v-slot:caller>
            <a :href="`#${item.anchor}`" aria-expanded="false" class="company-background-color list-group-item list-group-item-action flex-column align-items-start">
              <div class="d-flex w-100 justify-content-start align-items-center">
                <i :class="`${item.icon} fa-fw me-3`"></i>
                <span class="d-none">{{item.name}}</span>
              </div>
            </a>
          </template>
        </popover>
      </template>
      <!-- Collapsable -->
      <a data-bs-toggle="collapse" href="#" class="company-background-color list-group-item list-group-item-action d-flex align-items-center" @click="enablePopOvers">
        <div class="d-flex w-100 justify-content-start align-items-center">
          <i v-if="collapsed" id="collapse-icon" class="fa-solid fa-angle-double-right fa-fw me-3"></i>
          <i v-else id="collapse-icon" class="fa-solid fa-angle-double-left fa-fw me-3"></i>
          <span v-if="!collapsed" id="collapse-text">Collapse</span>
        </div>
      </a>
    </ul>
  </div>
</template>

<script>
import popover from '../pop_over/Index.vue';
import BaseMenu from './BaseMenu';
import FastNavigation from '../fast_navigation/Index.vue';

export default {
  extends: BaseMenu,
  components: {
    popover, FastNavigation,
  },
  data() {
    return {
      popover_enabled: false,
      collapsed: false,
    };
  },
  computed: {
    sidebarStyle() {
      return this.collapsed ? 'width: 52px;' : '';
    },
  },
  methods: {
    enablePopOvers() {
      if (this.popover_enabled) {
        this.$root.$emit('bv::disable::popover');
        this.popover_enabled = false;
      } else {
        this.$root.$emit('bv::enable::popover');
        this.popover_enabled = true;
      }
      this.collapsed = this.popover_enabled;
    },
  },
};
</script>
