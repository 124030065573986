import { gql } from 'apollo-boost';

export default gql`
  query($orderBy: [OrderByClause!]) {
    peopleAllocation(orderBy: $orderBy) {
      user_id
      person
      position
      end_date
      status
    }
  }
`;
