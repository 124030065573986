<template>
  <table class="table m-0 table-hover">
    <thead>
      <tr>
        <th colspan="4" class="text-center p-1">Access Requests</th>
      </tr>
    </thead>
    <tbody>
      <template v-if="login_requests" >
        <tr :key="index" v-for="(request, index) in login_requests">
          <td class="align-middle p-1 ps-2" :title="request.name">{{request.email}}</td>
          <td class="align-middle p-1">{{request.created_at_diff}}</td>
          <td class="text-end align-middle p-1 pe-2">
            <button class="btn btn-company-primary px-1 py-0 my-1" @click="$emit('accept', request.id)"><i class="fas fa-check"></i></button>
            <button class="btn btn-company-secondary px-1 py-0 my-1" @click="$emit('deny', request.id)"><i class="fas fa-ban"></i></button>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['record'],
  computed: {
    login_requests() {
      return { ...this.record };
    },
  },
};
</script>
