<template>
  <div class="container-md">
    <div v-if="$apollo.loading" class="mx-auto" style="width: 100px;">
      <div  class="spinner-border" role="status">
        <span class="visually-hidden"></span>
      </div>
    </div>
    <div v-if="!$apollo.loading" class="card h-100 ">
      <div class="card-body pb-0">
        <h1 v-if="candidate && candidate.first_name" class="card-title">
          {{candidate.first_name}}
          {{candidate.last_name}}
          ({{candidate.score}})
        </h1>
        <h3 v-if="candidate && candidate.email" class="card-subtitle mb-2 text-body-secondary">{{candidate.email}}</h3>
      </div>
      <div v-if="candidate && candidate.phone_number" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p  class="h4"><strong>Phone:</strong> {{candidate.phone_number}}</p>
          </div>
        </div>
      </div>
      <div v-if="candidate && candidate.english_level" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p class="h4"><strong>English Level:</strong> {{candidate.english_level}}</p>
          </div>
        </div>
      </div>
      <div v-if="candidate && candidate.salary_expectation_range" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p class="h4"><strong>Salary Expectation Range:</strong> {{candidate.salary_expectation_range}}</p>
          </div>
        </div>
      </div>
      <div v-if="candidate && candidate.technologies" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p class="h4">
              <strong>Technologies:</strong>
            </p>
          </div>
          <div class="col-md-12">
            <h4>
              <span :key="technology.id" v-for="technology in candidate.technologies" class="badge bg-company-secondary me-1">{{technology.name}}</span>
            </h4>
          </div>
        </div>
      </div>
      <div v-if="candidate && candidate.candidate_status && candidate.candidate_status.name" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p class="h4"><strong>Status:</strong> <span>{{candidate.candidate_status.name}}</span></p>
          </div>
        </div>
      </div>
      <div v-if="candidate && candidate.availability" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p class="h4"><strong>Availability:</strong> <span v-if="candidate.availability">{{candidate.availability}}</span></p>
          </div>
        </div>
      </div>
      <div v-if="candidate && candidate.is_working" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p class="h4"><strong>Currently working at:</strong> <span v-if="candidate.company">{{candidate.company}}</span></p>
          </div>
        </div>
      </div>
      <div v-if="candidate && !candidate.is_working" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p class="h4"><strong>Currently:</strong> <span>unemployed</span></p>
          </div>
        </div>
      </div>
      <div v-if="candidate && candidate.application_channel" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p class="h4"><strong>Application Channel:</strong> <span>{{candidate.application_channel}}</span></p>
          </div>
        </div>
      </div>
      <div v-if="candidate && candidate.job_experience_started_date" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p class="h4"><strong>Job Experience Started Date:</strong> <span>{{candidate.job_experience_started_date}}</span></p>
          </div>
        </div>
      </div>
      <div v-if="candidate.notes" class="card-body pt-0 pb-0">
        <div class="row">
          <div class="col-md-12">
            <p v-html="candidate.notes"></p>
          </div>
        </div>
      </div>
      <div class="card-body d-flex justify-content-between">
        <button v-if="candidate && candidate.resume_url" class="btn btn-company-primary" @click="openResume">
          <i class="fas fa-file-download"></i>
          Resume
        </button>
        <router-link class="btn btn-company-primary" to="/external_candidates" >Go Back</router-link>
      </div>
    </div>
  </div>
</template>

<script>

import { QUERY_CANDIDATE } from './queries';

export default {
  name: 'ExternalCandidatesProfile',
  data() {
    return {
      candidate: null,
    };
  },
  apollo: {
    candidate: {
      query: QUERY_CANDIDATE,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache-and-network',
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    openResume() {
      const key = decodeURI(this.candidate.resume_url.split('/').pop());
      this.$s3GetSignedUrl(key).then((s3Url) => window.open(s3Url, '_blank'));
    },
  },
};
</script>
