<template>
<div :id="record.id"  class="col-md-3 pb-2">
  <div class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{record.assigned_to.name}}
      </h5>
      <h6 class="card-subtitle mb-2 text-body-secondary text-capitalize">
        {{record.status}}
      </h6>
    </div>
    <div class="card-body pt-0 pb-0 mb-3" v-if="$can('UpdateUser')">
      <div class="row">
        <div class="col-md-12">
          <strong>Todo:</strong>
        </div>
        <div class="col-md-12" v-html="record.name">
        </div>
      </div>
    </div>
    <div class="card-body">
      <Confirmation v-if="$can('UpdateProjectActions')" :text="'Mark as completed'" :icon="'fas fa-check'"
        v-model="confirmation"
        @accepted="update('completed')"
      />
    </div>
    <div class="card-footer">
      Created by: {{record.user.name}}
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_ACTIONS } from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';
import { Confirmation } from '../shared';

export default {
  components: { Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(status) {
      this.$apollo.mutate({
        mutation: UPDATE_ACTIONS,
        variables: {
          id: this.record.id,
          input: {
            assign_to_id: this.record.assign_to_id,
            project_id: this.record.project.id,
            name: this.record.name,
            status,
          },
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
        this.$emit('refresh');
      });
    },
  },
};
</script>
