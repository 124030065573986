<template>
  <div class="ms-3 me-3 m-2">
    <div class="d-flex">
      <p class="mt-2 mb-2 me-3"><i class="fas fa-bolt company-text-third fs-5"></i></p>
      <input list="routes" class="form-control" placeholder="Where should I take you?" v-model="selected_route" @change="navigate">
      <datalist id="routes">
        <option :key="index" v-for="(route, index) in routes" :value="route.name"></option>
      </datalist>
    </div>
  </div>
</template>

<script>

import BaseMenu from '../main_menu/BaseMenu';

export default {
  extends: BaseMenu,
  computed: {
    routes() {
      return _.flatMap(this.menu_items, (item) => item.children.filter((child) => child.isShown)
        .map((child) => ({
          path: child.route,
          name: child.name,
        })));
    },
  },
  data() {
    return {
      selected_route: '',
    };
  },
  methods: {
    navigate() {
      const route = this.routes.find((r) => r.name === this.selected_route);
      if (route) {
        this.$router.push(route.path).catch(() => {});
        this.selected_route = '';
      }
    },
  },
};
</script>
