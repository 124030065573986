<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Hours per project</SpinnerRouterLink>
          </div>
        </div>
        <div class="col-md-12 m-auto">
          <Search :search.sync="search"
                  :inputs="['projects', 'users', 'dateRanges', 'onlyMyProjectsCheckbox']"
                  :default_values="defaultDates"></Search>
        </div>
      </div>
    </nav>
    <div class="row" v-if="hoursPerProject">
      <div class="col-md-12 scroll-x text-nowrap h-100">
        <Empty v-if="!hoursPerProject.length" icon="fas fa-clock"/>
        <table v-if="hoursPerProject.length" class="table">
          <tr>
            <SortByColumn class="d-table-cell" :sort_by_field="'person'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
            <SortByColumn class="d-table-cell" :sort_by_field="'project'" :orderBy="orderBy" @refresh="refresh">Project</SortByColumn>
            <SortByColumn class="d-table-cell" :sort_by_field="'type'" :orderBy="orderBy" @refresh="refresh">Project Type</SortByColumn>
            <SortByColumn class="d-table-cell" :sort_by_field="'hours'" :orderBy="orderBy" @refresh="refresh">Hours</SortByColumn>
            <SortByColumn class="d-table-cell" :sort_by_field="'costs'" :orderBy="orderBy" @refresh="refresh">Costs</SortByColumn>
          </tr>
          <tr :key="index" v-for="(row, index) in hoursPerProject">
            <td>
              <router-link :to="{ path: '/reports/timesheet', query: { user_id: row.user_id, start_date: search.start_date, end_date: search.end_date }}" target="_blank" class="text-decoration-none">
                    {{row.person}}
              </router-link>
            </td>
            <td>
              <router-link :to="{ path: '/reports/timesheet', query: { project_id: row.project_id, start_date: search.start_date, end_date: search.end_date }}" target="_blank" class="text-decoration-none">
                  {{row.project}}
              </router-link>
            </td>
            <td>{{row.type}}</td>
            <td>{{row.hours}}</td>
            <td v-if="$can('ReadCostPerUser')">${{row.costs | formatNumber}}</td>
          </tr>
          <tr v-if="hoursPerProject && hoursPerProject.length === 0">
            <td class="text-center" :colspan="$can('ReadCostPerUser') ? 5 : 4">No records found</td>
          </tr>
          <tr v-if="hoursPerProject && hoursPerProject.length > 0">
            <td class="text-end" colspan="3">
              <strong>Total</strong>
            </td>
            <td><strong>{{totalBy('hours')}}</strong></td>
            <td v-if="$can('ReadCostPerUser')"><strong>${{totalBy('costs') | formatNumber}}</strong></td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment';
import { HOURS_PER_PROJECT, HOURS_PER_PROJECT_WITH_COSTS } from './queries';
import { Search, SortByColumn, SpinnerRouterLink } from '../../shared';
import SearchBuilder from '../../../shared/SearchBuilder';

export default {
  components: { Search, SortByColumn, SpinnerRouterLink },
  data() {
    return {
      orderBy: { column: 'project', order: 'ASC' },
      search: { only_my_projects: false },
    };
  },
  computed: {
    defaultDates() {
      return {
        start_date: moment().subtract(1, 'weeks').startOf('isoweek').format('YYYY-MM-DD'),
        end_date: moment().subtract(1, 'weeks').endOf('isoweek').format('YYYY-MM-DD'),
      };
    },
  },
  apollo: {
    hoursPerProject: {
      query() {
        return this.$can('ReadCostPerUser') ? HOURS_PER_PROJECT_WITH_COSTS : HOURS_PER_PROJECT;
      },
      errorPolicy: 'all',
      variables() {
        return {
          orderBy: [this.orderBy],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.hoursPerProject.refetch();
    },
    totalBy(key) {
      return _.sumBy(this.hoursPerProject, key).toFixed(2);
    },
  },
};
</script>
