import { gql } from 'apollo-boost';
import PAID_TIME_OFF_REQUEST_FRAGMENT from './fragments';

export default gql`
  mutation($id: ID!, $action: String!) {
    resolvePaidTimeOffRequest(id: $id, action: $action) {
      ...paid_time_off_request
    }
  }
  ${PAID_TIME_OFF_REQUEST_FRAGMENT}
`;
