<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Template</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="mb-auto">
          <label for="name">Name</label>
          <input v-focus type="text" class="form-control" :class="{ 'is-invalid': v$.localRecord.name }" id="name"
            v-model="localRecord.name">
          <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-auto">
          <div >
            <label for="text">Description</label>
          </div>
          <div v-if="notification_setting.liquid_variables" class="btn-group mb-2" role="group">
            <button :disabled="!localRecord.text" v-for="variable in notification_setting.liquid_variables.split(',')" :key="variable" type="button"
              class="btn btn-outline-dark" @click="insertVariable(variable)">
              {{ variable }}
            </button>
          </div>
          <input type="hidden" id="text" v-model="localRecord.text">
          <trix-editor input="text" @trix-change="localRecord.text = $event.target.value; $forceUpdate();"
          placeholder="Enter content"></trix-editor>
          <div v-for="error of v$.localRecord.text.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mt-2">
          <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
            <i class="fas fa-save"></i>
            Save
          </button>
          <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
            <i class="fas fa-times"></i>
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { gql } from 'apollo-boost';
import Validations from './Validations';
import Blinkable from '../../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  props: ['record'],
  apollo: {
    notification_setting: {
      query: gql`query ($id: ID!) { notification_setting (id: $id) { liquid_variables }}`,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
    insertVariable(variable) {
      const element = document.querySelector('trix-editor');
      const positions = element.editor.getSelectedRange();
      element.editor.setSelectedRange(positions);
      element.editor.insertHTML(`{{ ${variable} }}`);
    },
  },
};
</script>
