import { gql } from 'apollo-boost';

import { LEARNING_PATH_NODE_USER_FRAGMENT } from './fragments';

export default gql`
  mutation($learning_path_node_user: LearningPathLevelNodeUserInput!){
    updateLearningPathNodeUserStatus(input: $learning_path_node_user){
      ...learning_path_node_user
    }
  }
  ${LEARNING_PATH_NODE_USER_FRAGMENT}
  `;
