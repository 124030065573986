import { gql } from 'apollo-boost';

export default gql`
    fragment projectRisk on ProjectRisk {
        id
        name
        description
        probability
        impact
        project_id
        risk
        created_at
    }
`;
