<template>
  <div>
    <Form v-if="record" v-bind:record="record"
                                @close="record = null"
                                @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-2 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link to="/projects">Projects</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              <router-link :to="`/projects?name=${project?.name}`">{{ project?.name }}</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              Risks
            </li>
          </ol>
        </div>
        <div class="col-md-8 m-auto p-0">
          <Search
            :search.sync="search"
            :inputs="['name']"
          ></Search>
        </div>
        <div class="col-md-2 text-end">
          <button type="button" class="btn btn-company-primary" @click="newRisk()" v-if="$can('CreateProjectRisk')">
            <i class="fas fa-plus"></i>
            New
          </button>
        </div>
      </div>
    </nav>
    <div class="row" v-if="projectRisks">
      <Card :key="index" v-for="(record, index) in projectRisks.data" :record="record" @remove="remove"></Card>
      <Empty v-if="!projectRisks.data.length" icon="fas fa-project-diagram"  :can_create="true" @callCreate="newRisk"/>
    </div>
    <SkeletonCards v-if="projectRisks && projectRisks.paginatorInfo.hasMorePages" :cards=2 :executeScroll="projectRisks != undefined" @showMore="showMore('projectRisks')"></SkeletonCards>
  </div>
</template>

<script>
import { gql } from 'apollo-boost';
import PROJECT_RISK_QUERY from './queries';
import { CREATE_PROJECT_RISK, DELETE_PROJECT_RISK } from './mutations';
import { Search, SkeletonCards } from '../shared';
import Form from './Form.vue';
import Card from './Card.vue';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, SkeletonCards, Search,
  },
  apollo: {
    project: {
      query: gql`query($id: ID!) { project(id: $id) { id name }}`,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    projectRisks: {
      query: PROJECT_RISK_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, project_id: this.$route.params.id, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      record: null,
    };
  },
  methods: {
    queryVariables() {
      return { project_id: parseInt(this.$route.params.id, 10), ...SearchBuilder.build(this.search) };
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_PROJECT_RISK,
        variables: {
          id: parseInt(record.id, 10),
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      }).then(() => {
        this.projectRisks.data.splice(this.projectRisks.data.indexOf(record), 1);
      });
    },
    newRisk() {
      this.record = { project_id: parseInt(this.$route.params.id, 10), description: '' };
    },
    insert(risk) {
      // We save the user input in case of an error
      risk.project_id = parseInt(this.$route.params.id, 10);
      risk.probability = parseInt(risk.probability, 10);
      risk.impact = parseInt(risk.impact, 10);
      const newRisk = risk;
      this.$apollo.mutate({
        mutation: CREATE_PROJECT_RISK,
        variables: {
          projectRisk: risk,
        },
      }).then((response) => {
        this.record = null;
        this.projectRisks.data.unshift(response.data.createProjectRisk);
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        // We restore the initial user input
        this.record = newRisk;
      });
    },
  },
};
</script>
