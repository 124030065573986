<template>
<div :id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{record.internal_description}}
      </h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">{{record.external_description}}</h6>
      <h6 class="card-subtitle mb-2 text-body-secondary">{{record.user_count}} users with this position</h6>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateJobTitle')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteJobTitle')"
        v-model="confirmation"
        @accepted="remove()"
        class="d-inline-flex"
      />
    </div>
  </div>
</div>
</template>

<script>
import { UPDATE_JOB_TITLE } from './mutations';
import Form from './Form.vue';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(jobTitle) {
      this.$apollo.mutate({
        mutation: UPDATE_JOB_TITLE,
        variables: {
          id: jobTitle.id,
          job_title: _.pick(jobTitle, ['internal_description', 'external_description']),
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to save record: ${error.message}`);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
