<style scoped>
  .drop-area {
    border: 3px dashed #e9ecef;
  }
  .drop-area:hover {
    background-color:#e9ecef;
  }
  .drop-area:hover #icon {
    transform: scale(1.1);
  }
</style>

<template>
  <div class="drop-area sortable d-flex flex-column align-items-center justify-content-center rounded p-1" @dragover.prevent @drop.prevent="handleDrop" @click="trigger">
    <label class="d-flex sortable flex-column align-items-center" for="file">
      <span>
        <i id="icon" class="transition-05 fas fa-cloud-upload-alt fa-3x"></i>
      </span>
       <span>{{message}}</span>
    </label>
    <input id="file" ref="fileInput" class="d-none" type="file" @change="handleClick"/>
    <span class="text-body-secondary">
      <small>{{fileName}}</small>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    message: String,
  },
  data() {
    return {
      fileName: '',
    };
  },
  methods: {
    sanitizedFile(file) {
      return new File([file], file.name.replace(/[^a-zA-Z0-9-.]/g, '_'), { type: file.type });
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.fileName = file.name;
      this.$emit('drop', this.sanitizedFile(file));
    },
    handleClick(event) {
      const file = event.target.files[0];
      this.fileName = file.name;
      this.$emit('drop', this.sanitizedFile(file));
    },
    trigger() {
      this.$refs.fileInput.click();
    },
  },
};
</script>
