<template>
  <div>
    <div id="component" class="vh-100 mw-100 mt-xxl-5 col-xxl-4 d-flex flex-column align-items-center sticky mx-auto">
      <h1 class="display-5 text-center"><b>Get Into your Company</b></h1>
      <h5 class="text-body-secondary">Enter your company's URL</h5>
      <br v-if="error">
      <div class="card w-75 border-danger py-2 px-3 text-justiy" v-if="error">
        <p class="m-0 text-danger">The company you're searching for doesn't exist. You can also try
          <router-link to="/register_company">
            Register a new Company
          </router-link>
        </p>
      </div>
      <br>
      <div class="fs-4 position-relative h-25 w-75 overflow-hidden">
        <div class="position-absolute ms-xxl-3 mt-xxl-2">
          <label for="subdomain"><span :class="{'text-body-secondary select:disabled': !tenant.subdomain, 'opacity-0 select:disabled': tenant.subdomain}">{{tenant.subdomain || placeholder}}</span><b>.{{tenant.domain}}</b></label>
        </div>
        <input class="text-black bg-transparent fs-4 mb-3 mt-1 pt-2 pe-3 pb-2 ps-3 position-absolute form-control form-control-lg" id="subdomain" type="text" v-model="tenant.subdomain" @keyup="search" v-focus>
        <input class="text-black d-none form-control">
      </div>
      <br>
      <button class="mt-5 fs-5 btn btn-company-primary w-75" @click="search" :disabled="searching">
        <div v-if="searching" class="mh-25 mw-25 style-chooser spinner spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        {{searching ? 'Searching' : 'Continue'}}
      </button>
      <br>
      <div class="d-flex w-75">
        <span class="text-body-secondary">Don't have a company yet? let's try &nbsp;</span>
        <router-link to="/register_company">
          <b>Register my Company</b>
        </router-link>
      </div>
    </div>
    <div class="rounded-bottom rounded-circle bg-opacity-10 bg-dark vw-100 h-25 bottom-0 start-0 position-absolute overflow-hidden"><span class="d-block"></span></div>
  </div>
</template>

<script>
import FindTenant from './queries';
import { ErrorHandler } from '../../shared';

export default {
  data() {
    return {
      placeholder: 'mycompany',
      tenant: {
        subdomain: '',
        domain: window.location.host,
      },
      searching: false,
      error: false,
    };
  },
  methods: {
    search(event = { }) {
      if (event.keyCode ? event.keyCode !== 13 : false) return;
      this.searching = true;
      const domain = (`${this.tenant.subdomain}.${window.location.hostname}`).toLowerCase();
      this.$apollo.query({
        query: FindTenant,
        variables: { domain },
      }).then(({ data }) => {
        if (data.find_tenant === 1) {
          window.location.replace(`http://${this.tenant.subdomain}.${this.tenant.domain}`);
        } else {
          this.error = true;
        }
        this.searching = false;
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.searching = false;
      });
    },
  },
};

</script>
<style lang="scss" scoped>
  .spinner {
    width: 1.3rem;
    height: 1.3rem;
  }
</style>
