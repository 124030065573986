<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Projects Summary</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-11">
              <Search
                :search.sync="search"
                :inputs="['name', 'clients', 'status', 'projectType', 'users', 'onlyMyProjectsCheckbox']"
              ></Search>
            </div>
            <div class="col-1">
              <button class="btn btn-company-primary" @click="exportFile()" v-if="$can('ExportProjectSummaryToExcel')">
                <i v-if="is_exporting" class="fas fa-sync fa-spin"></i>
                <i v-if="!is_exporting" class="fa fa-file-excel"></i>
                Export
              </button>
            </div>
          </div>
        </div>

        <div class="container-fluid mt-3">
          <div class="row">
            <div class="col order-last text-end">
              <div class="d-flex justify-content-end" v-if="projects">
                <div class="pe-1">
                  <span>
                    <i class="fas fa-circle text-success"></i>
                    <strong>{{green}}</strong>
                  </span>
                </div>
                <div class="pe-1">
                  <span>
                    <i class="fas fa-circle text-danger"></i>
                    <strong>{{red}}</strong>
                  </span>
                </div>
                <div class="pe-1">
                  <span>
                    <i class="fas fa-circle text-warning"></i>
                    <strong>{{yellow}}</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="projects?.data.length">
      <div class="col-md-12 text-nowrap scroll-x h-100">
        <table class="table">
          <tr>
            <th class="d-table-cell">#</th>
            <SortByColumn class="d-table-cell" :sort_by_field="'name'" :orderBy="orderBy" @refresh="refresh">Project Name</SortByColumn>
            <SortByColumn class="d-table-cell" :sort_by_field="'client_id'" :orderBy="orderBy" @refresh="refresh">Client</SortByColumn>
            <th class="d-table-cell">Manager</th>
            <SortByColumn class="d-table-cell" :sort_by_field="'type'" :orderBy="orderBy" @refresh="refresh">Type</SortByColumn>
            <SortByColumn class="d-table-cell" :sort_by_field="'end_date'" :orderBy="orderBy" @refresh="refresh">End Date</SortByColumn>
            <SortByColumn class="d-table-cell text-center" :sort_by_field="'project_status_id'" :orderBy="orderBy" @refresh="refresh">Status</SortByColumn>
            <SortByColumn class="d-table-cell text-center" :sort_by_field="'last_known_net_promoter_score'" :orderBy="orderBy" @refresh="refresh">Last score</SortByColumn>
          </tr>
          <tr :key="index" v-for="(project, index) in projects.data">
            <td>{{index + 1}}</td>
            <td>{{project.name}}
              <router-link :to="`/projects/${project.id}/actions`" v-if="project.actions.length > 0" target="_blank">
                {{[project.actions.length]}}
              </router-link>
              <router-link :to="`/projects/${project.id}/actions`" v-if="project.actions.length == 0" target="_blank">
                <i class="fas fa-plus"></i>
              </router-link>
              </td>
            <td>{{project.client.name}}</td>
            <td>{{managers(project)}}</td>
            <td>{{project.type}}</td>
            <td>{{$displayDate(project.end_date)}}</td>
            <td class="text-center">
              <i class="fas fa-circle" :class="classByStatus(project.project_status.name)"></i>
            </td>
            <td v-if="project.last_known_net_promoter_score" class="text-center">{{project.last_known_net_promoter_score}}</td>
            <td v-else class="text-center">-</td>
          </tr>
        </table>
      </div>
    </div>
    <Empty v-if="!projects?.data.length" icon="fas fa-clipboard-list"/>
  </div>
</template>

<script>

import PROJECTS_QUERY from './queries';
import EXPORT_QUERY from './mutations';
import { SearchBuilder, Base64File } from '../../../shared';
import { Search, SortByColumn, SpinnerRouterLink } from '../../shared';

export default {
  components: {
    Search, SortByColumn, SpinnerRouterLink,
  },
  data() {
    return {
      is_exporting: false,
      search: { only_my_projects: false },
      orderBy: { column: 'project_status_id', order: 'DESC' },
    };
  },
  computed: {
    red() {
      return this.filterByColor('Red').length;
    },
    yellow() {
      return this.filterByColor('Yellow').length;
    },
    green() {
      return this.filterByColor('Green').length;
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.projects.refetch();
    },
    filterByColor(color) {
      return this.projects.data.filter((project) => project.project_status.name === color);
    },
    classByStatus(status) {
      const cssClasses = { red: 'text-danger', yellow: 'text-warning', green: 'text-success' };
      return cssClasses[status.toLowerCase()] || '';
    },
    managers(project) {
      return project.managers.map((m) => m.user.name).join(', ');
    },
    exportFile() {
      this.is_exporting = true;
      this.$apollo.mutate({
        mutation: EXPORT_QUERY,
        variables: {
          page: 1, first: 500, state: 'In Progress', status: 'pending', orderBy: [this.orderBy], ...SearchBuilder.build(this.search),
        },
      }).then((res) => {
        Base64File.download(res.data.exportProjectSummaryToExcel, 'project_summary.xlsx');
      }).finally(() => {
        this.is_exporting = false;
      });
    },
  },
  apollo: {
    projects: {
      query: PROJECTS_QUERY,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables() {
        return {
          page: 1, first: 500, state: 'In Progress', status: 'pending', orderBy: [this.orderBy], ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
