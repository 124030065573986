import { gql } from 'apollo-boost';

import { USER_FRAGMENT } from './fragments';

export const UPDATE_USER = gql`
  mutation($id: ID!, $user: UserInput!){
    updateUser(id: $id, input: $user){
      ...user
    }
  }
  ${USER_FRAGMENT}
`;

export const DELETE_USER = gql`
  mutation($id: ID!){
    deleteUser(id: $id){
      id
    }
  }
`;

export const DELETE_EXTERNAL_COMPANY = gql`
  mutation($id: ID!){
    deleteExternalCompany(id: $id){
      id
    }
  }
`;

export const DELETE_EXTERNAL_PROJECT = gql`
  mutation($id: ID!){
    deleteExternalProject(id: $id){
      id
    }
  }
`;

export const EXPORT_RESUME_TO_WORD = gql`
  mutation($id: ID!){
    exportResumeToWord(id: $id)
  }
`;

export const EXPORT_USERS = gql`
  mutation($name: String, $email: String, $country_id: ID){
    exportUsersToExcel(name: $name, email: $email, country_id: $country_id)
  }
`;
