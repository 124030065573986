<template>
  <div :id="integration.id" class="col-md-3 pb-2">
    <div class="card h-100">
      <div class="card-body pb-0">
        <h5 class="card-title">
          {{ integration.name }}
        </h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <div class="form-check form-switch" v-if="$can('UpdateIntegrations')">
              <input v-model="integration.is_active" @click="integration.is_active ? changeState() : is_editting = true" type="checkbox" class="form-check-input" :id="`switch${integration.id}`">
              <label class="form-check-label" :for="`switch${integration.id}`">Active</label>
            </div>
          </div>
          <div class="col-6">
            <div class="custom-control" v-if="$can('UpdateIntegrations')">
              <router-link class="btn btn-company-primary" :to="`integrations/${integration.id}/mappings`" >Mapping</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import UPDATE_INTEGRATION from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';

export default {
  props: ['integration'],
  methods: {
    changeState() {
      this.integration.is_active = false;
      this.update(this.integration);
    },
    update(integration) {
      this.$apollo
        .mutate({
          mutation: UPDATE_INTEGRATION,
          variables: {
            id: integration.id,
            integration: _.pick(integration, ['name', 'user', 'password', 'is_active']),
          },
        })
        .catch((error) => {
          ErrorHandler.handle(error, this);
        })
        .then(() => {
          this.$emit('refresh');
        });
    },
  },
};
</script>
