import { ShowCost } from '../../shared';

export default {
  components: { ShowCost },
  computed: {
    sortIcon() {
      return this.asc() ? 'fa-sort-alpha-down' : 'fa-sort-alpha-down-alt';
    },
    projects() {
      return _.uniq(_.map(this.grossMarginPerResource, (gm) => gm.project));
    },
    users() {
      return _.uniq(_.map(this.grossMarginPerResource, (gm) => gm.person));
    },
  },
  methods: {
    averageBy(key, value, sumKey) {
      const selected = this.filterBy(key, value);
      return (_.sumBy(selected, sumKey) / selected.length).toFixed(2);
    },
    filterBy(key, value) {
      return _.filter(this.grossMarginPerResource, (gm) => gm[key] === value);
    },
    asc() {
      return this.orderBy.order === 'ASC';
    },
    sortBy(column) {
      this.orderBy.column = column;
      this.orderBy.order = this.asc() ? 'DESC' : 'ASC';
      this.$emit('refetch');
    },
  },
};
