<style scoped>
  .avatar-circle {
    width: 160px;
    height: 160px;
    }
  .initials {
    color: #fff;
    font-family: "Roboto";
  }
</style>
<template>
  <div class="avatar-circle fw-bolder rounded-circle text-center" :style="{ 'background-color': ramdomColor() }">
    <span class="initials" :style="{ 'font-size': font_size }">{{ initials }}</span>
  </div>
</template>
<script>
export default {
  props: ['user_name', 'font_size'],
  computed: {
    initials() {
      const splitString = this.user_name.split(' ');
      return splitString[0].substring(0, 1);
    },
  },
  methods: {
    ramdomColor() {
      const colors = [
        '#5a8770',
        '#b2b7bb',
        '#6fa9ab',
        '#f5af29',
        '#0088b9',
        '#f18636',
        '#d93a37',
        '#a6b12e',
        '#5c9bbc',
        '#f5888d',
        '#9a89b5',
        '#407887',
        '#9a89b5',
        '#5a8770',
        '#d33f33',
        '#a2b01f',
        '#f0b126',
        '#0087bf',
        '#f18636',
        '#0087bf',
        '#b2b7bb',
        '#72acae',
        '#9c8ab4',
        '#5a8770',
        '#eeb424',
        '#407887',
      ];
      const randomIndex = Math.floor(Math.random() * colors.length);
      return colors[randomIndex];
    },
  },
};
</script>
