export default class RequestBuilder {
  static build(user, keys = ['github', 'bio', 'hobbies', 'soft_skills', 'technical_skills', 'education', 'gender', 'date_of_birth']) {
    const requestBody = _.pick(user, keys);
    if (user.technologies) {
      requestBody.technologies = {
        sync: user.technologies.filter((tech) => tech.id).map((tech) => tech.id),
        create: user.technologies.map((tech) => tech).filter((tech) => !tech.id),
      };
    }
    if (user.external_companies) {
      requestBody.external_companies = {
        upsert: user.external_companies.map((c) => {
          const company = _.pick(c, ['id', 'name', 'start_date', 'end_date']);
          company.external_projects = {
            upsert: c.external_projects.map((project) => _.pick(project, ['id', 'name', 'description', 'duration', 'external_company_id'])),
          };
          return company;
        }),
      };
    }
    return requestBody;
  }
}
