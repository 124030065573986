export default class RequestBuilder {
  static build(record) {
    const keys = ['technology_id', 'question', 'answer', 'difficulty'];
    const requestBody = _.pick(record, keys);
    if (record.tags) {
      requestBody.tags = {
        sync: record.tags.filter((tag) => tag.id).map((tag) => tag.id),
        create: record.tags.map((tag) => tag).filter((tag) => !tag.id),
      };
    }
    return requestBody;
  }
}
