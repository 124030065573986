import { useVuelidate } from '@vuelidate/core';
import {
  required, email, maxLength, sameAs,
} from '@vuelidate/validators';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      localRecord: {
        email: { required, email, maxLengthValue: maxLength(255) },
        oldPassword: { required, maxLengthValue: maxLength(255) },
        password: { required, maxLengthValue: maxLength(255) },
        confirmNewPassword: { required, sameAsPassword: sameAs(this.localRecord.password), maxLengthValue: maxLength(255) },
      },
    };
  },
};
