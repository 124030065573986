<template>
<div :id="record.id"  class="pb-2" :class="is_editting ? 'col-md-12' : 'col-md-4'">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <template v-if="$can('ReadTopUserSkills')">
      <div v-if="!record.avatar && !show_chart" class="d-flex flex-column align-items-center text-center mt-4 mb-4" @dblclick="show_chart=true">
        <ProfileImage :user_name="record.name" :font_size="'100px'"></ProfileImage>
      </div>
      <div class="d-flex flex-column align-items-center" v-if="record.avatar">
        <img class="rounded-circle mt-4 mb-4" height="120px" :src="record.avatar" v-if="!show_chart" @dblclick="show_chart=true">
      </div>
    </template>
    <template v-else>
      <div v-if="!record.avatar" class="d-flex flex-column align-items-center text-center mt-4 mb-4">
        <ProfileImage :user_name="record.name" :font_size="'100px'"></ProfileImage>
      </div>
      <div v-if="record.avatar" class="d-flex flex-column align-items-center text-center">
        <img class="rounded-circle mt-4 mb-4" height="120px" :src="record.avatar" v-if="!show_chart">
      </div>
    </template>
    <SpiderWebChart :user_id="record.id" v-if="show_chart" @hide="show_chart=false"></SpiderWebChart>
    <div class="card-body pb-0">
      <h5 class="card-title">
        <span :title="record.country.name" v-if="record.country">{{flag}}</span>
        {{record.name}}
        ({{record.job_title ? record.job_title.internal_description : '-'}})
      </h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">
        {{record.email}}
      </h6>
    </div>
    <div class="card-body pt-0 pb-0" v-if="$can('UpdateUser') && (record.is_external || !record.is_billable)">
      <span v-if="!record.is_billable" class="badge bg-secondary bg-company-secondary">Non Billable</span>
      <span v-if="record.is_external" class="badge bg-secondary bg-company-secondary">External</span>
    </div>
    <div class="card-body pt-0 pb-0 mt-3" v-if="$can('UpdateUser')">
      <div class="row">
        <div :class="record.partner_id ? 'col-md-4' : 'col-md-6'">
          <strong>Legal ID:</strong> {{record.identification}}
        </div>
        <div :class="record.partner_id ? 'col-md-4' : 'col-md-6'" v-if="record.english_level">
          <strong>English:</strong> {{record.english_level}}
        </div>
        <div class="col-md-4" v-if="record.partner_id">
          <strong>Partner:</strong> {{record.partner ? record.partner.name : ''}}
        </div>
      </div>
    </div>
    <div class="mt-3" v-if="$can('ReadInternalNetPromoterScore')">
      <div class="row">
        <div :class="record.last_known_internal_net_promoter_score != null ? 'col-12 text-center' : 'd-none'">
          <strong>Net Promoter Score:</strong> {{record.last_known_internal_net_promoter_score}}
        </div>
      </div>
    </div>
    <hr>
    <div class="card-body pt-0 pb-0 mb-3" v-if="$can('UpdateUser')">
      <div class="row">
        <div class="col-md-12">
          <strong>Active Projects:</strong>
        </div>
        <div class="col-md-12">
          <template v-for="project in record.active_projects">
            <span :key="`p-${project.id}`" class="badge bg-company-secondary me-1">
              {{project.name}}
            </span>
          </template>
        </div>
      </div>
    </div>
    <div class="card-body pt-0 pb-0 mb-3" v-if="$can('UpdateUser')">
      <div class="row">
        <div class="col-md-12">
          <strong>Technologies:</strong>
        </div>
        <div class="col-md-12">
          <template v-for="(technology, index) in record.technologies">
            <span
              v-if="index < 8 || show_all_technologies"
              :key="`t-${technology.id}`"
              class="badge bg-company-secondary me-1">
                {{technology.name}}
            </span>
          </template>
          <span
            v-if="record.technologies.length > 8"
            class="badge bg-primary bg-company-primary me-1 mb-4 sortable"
            @click="show_all_technologies = !show_all_technologies">
              {{show_all_technologies ? 'Show less...' : 'Show more...' }}
          </span>
        </div>
      </div>
    </div>
    <div class="card-body pt-0 mb-3" v-if="$can('UpdateUser')">
      <div class="row">
        <div class="col-md-4" v-if="record.roles.length > 0">
          <strong>Roles:</strong>
          <br>
          <span :key="`r-${role.id}`" v-for="role in record.roles" class="badge bg-company-secondary me-1">{{role.name}}</span>
        </div>
        <div class="col-md-5" v-if="record.business_units.length > 0">
          <strong>Business Units:</strong>
          <br>
          <span :key="`b-${business_unit.id}`" v-for="business_unit in record.business_units" class="badge bg-company-secondary me-1">{{business_unit.name}}</span>
        </div>
        <div class="col-md-3" v-if="record.tags.length > 0">
          <strong>Tags:</strong>
          <br>
          <span :key="`ta-${tag.id}`" v-for="tag in record.tags" class="badge bg-company-secondary me-1">{{tag.name}}</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col" v-if="record.address">
          <strong>Address: </strong>
          <p>{{ record.address }}</p>
        </div>
        <div class="col" v-if="record.notes">
          <strong>Notes: </strong>
          <p>{{ record.notes }}</p>
        </div>
      </div>
    </div>
    <div class="card-body pt-0 mb-4 mb-sm-3 mb-md-2 align-items-center justify-content-start">
      <button v-if="!confirmation && $can('UpdateUser')" class="btn btn-company-primary m-1" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteUser')"
        v-model="confirmation"
        @accepted="remove"
      />
      <router-link class="btn btn-company-primary m-1" v-if="!confirmation && $can('ReadPromotions')" :to="{ name: 'promotions', params: { id: record.id }}" target="_blank">
        <i class="fas fa-seedling"></i>
        Promotions
      </router-link>
      <router-link class="btn btn-company-primary m-1" v-if="!confirmation" :to="{ name: 'resume', params: { id: record.id }}" target="_blank">
        <i class="fas fa-file"></i>
        Resume
      </router-link>
      <router-link class="btn btn-company-primary m-1" v-if="!confirmation && $can('ReadInterviews')" :to="{ name: 'interviews', params: { id: record.id }}" target="_blank">
        <i class="fas fa-comment"></i>
        Interviews
      </router-link>
      <router-link class="btn btn-company-primary m-1" v-if="!confirmation && $can('ReadCostPerUser')" :to="`/cost_per_user?user_id=${record.id}`" target="_blank">
        <i class="fas fa-dollar-sign"></i>
        Costs
      </router-link>
      <router-link class="btn btn-company-primary"
                   v-if="!confirmation && $can('AdminUserAttachments')"
                   :to="{ name: 'user_attachments', params: { id: record.id }}">
        <i class="fas fa-paperclip"></i>
        Attachments
      </router-link>
      <router-link v-if="!confirmation && $can('ReadAnyProfile')" class="btn btn-company-primary m-1" :to="{ name: 'profile', params: { id: record.id }}">
        <i class="fas fa-pen"></i>
        Update Profile
      </router-link>
      <router-link v-if="!confirmation && $can('EvaluateLearningPaths')" class="btn btn-company-primary m-1" :to="{ name: 'learning_path_evaluations', params: { id: record.id }}">
        <i class="fas fa-book-open-reader"></i>
        Learning Paths
      </router-link>
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_USER } from './mutations';
import Form from './Form.vue';
import SpiderWebChart from './SpiderWebChart.vue';
import RequestBuilder from './RequestBuilder';
import { ErrorHandler, Flags } from '../../shared';
import { Confirmation, ProfileImage } from '../shared';

export default {
  components: {
    Form, SpiderWebChart, ProfileImage, Confirmation,
  },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
      show_chart: false,
      show_all_technologies: false,
    };
  },
  computed: {
    flag() {
      return Flags[this.record.country.name];
    },
  },
  methods: {
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          id: record.id,
          user: RequestBuilder.build(record),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.record.country = record.country;
        this.is_editting = false;
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
