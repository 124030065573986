<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">People in Bench</SpinnerRouterLink>
          </div>
        </div>
      </div>
    </nav>
    <div class="row scroll-x h-100" v-if="peopleInBench">
      <div class="col-md-12 text-nowrap">
        <table class="table">
          <thead>
            <tr>
              <th class="d-table-cell">#</th>
              <SortByColumn class="d-table-cell" :sort_by_field="'person'" :orderBy="orderBy" @refresh="refresh">Name</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'internal_description'" :orderBy="orderBy" @refresh="refresh">Position</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'since'" :orderBy="orderBy" @refresh="refresh">Time in bench</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'last_project'" :orderBy="orderBy" @refresh="refresh">Last project</SortByColumn>
              <SortByColumn class="d-table-cell" v-if="$can('ReadCostPerUser')" :sort_by_field="'last_known_cost'" :orderBy="orderBy" @refresh="refresh">Cost</SortByColumn>
              <th class="d-table-cell">Resume</th>
            </tr>
          </thead>
          <tbody>
            <tr :key="row.id" v-for="(row, index) in peopleInBench">
              <td>{{index + 1}}</td>
              <td>
                <router-link :to="`/users?id=${row.id}`" target="_blank" class="text-decoration-none">
                  {{row.person}}
                </router-link>
              </td>
              <td>{{row.internal_description}}</td>
              <td>
                <span v-if="row.since"> Since {{$displayDate(row.since)}}</span>
                <span v-if="row.time_in_bench"> ({{row.time_in_bench}})</span>
              </td>
              <td>{{row.last_project}}</td>
              <td v-if="$can('ReadCostPerUser')">
                <ShowCost :cost="row.last_known_cost"></ShowCost>
              <td>
                <router-link class="btn btn-secondary" target="_blank" :to="{ name: 'resume', params: { id: row.id }}">
                  <i class="fas fa-file"></i>
                  Resume
                </router-link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Empty v-if="!peopleInBench?.length" icon="fas fa-university"/>
  </div>
</template>

<script>

import { PEOPLE_IN_BENCH, PEOPLE_IN_BENCH_WITH_COSTS } from './queries';
import { SortByColumn, SpinnerRouterLink, ShowCost } from '../../shared';

export default {
  components: { SortByColumn, SpinnerRouterLink, ShowCost },
  data() {
    return {
      orderBy: { column: 'since', order: 'ASC' },
    };
  },
  methods: {
    refresh() {
      this.$apollo.queries.peopleInBench.refetch();
    },
  },
  apollo: {
    peopleInBench: {
      query() {
        return this.$can('ReadCostPerUser') ? PEOPLE_IN_BENCH_WITH_COSTS : PEOPLE_IN_BENCH;
      },
      errorPolicy: 'all',
      variables() {
        return {
          orderBy: [this.orderBy],
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
