export default class RequestBuilder {
  static build(partner) {
    const keys = [
      'name', 'type', 'website', 'notes',
    ];
    const requestBody = _.pick(partner, keys);
    requestBody.contacts = {
      delete: (partner.contacts || []).filter((contact) => contact.deleted).map((contact) => contact.id),
      upsert: (partner.contacts || []).filter((contact) => !contact.id)
        .map((contact) => ({
          name: contact.name,
          position: contact.position,
          email: contact.email,
          phone: contact.phone,
          notes: contact.notes,
        }))
        .concat(
          (partner.contacts || []).filter((contact) => contact.id)
            .map((contact) => ({
              id: contact.id,
              name: contact.name,
              position: contact.position,
              email: contact.email,
              phone: contact.phone,
              notes: contact.notes,
            })),
        ),
    };
    return requestBody;
  }
}
