<template>
  <div :id="record.id" class="col-md-3 pb-2">
    <Form v-if="is_editting" v-bind:record="record"
                              @close="is_editting=false"
                              @update="update"></Form>
    <div v-if="!is_editting" class="card h-100">
      <div class="card-body pb-0">
        <h5 class="card-title">
          <span :title="record.country.name" v-if="record.country">{{flag}}</span>
          {{record.name}}
        </h5>
        <h6 class="card-subtitle mb-2 text-body-secondary">
          {{$displayDate(record.date)}}
        </h6>
      </div>
      <div class="card-body">
        <button v-if="!confirmation && $can('UpdateHoliday')" class="btn btn-company-primary" @click="is_editting = true">
          <i class="fas fa-pen"></i>
          Edit
        </button>
        <Confirmation v-if="!is_editting && $can('DeleteHoliday')" v-model="confirmation" @accepted="remove()"/>
      </div>
    </div>
  </div>
</template>

<script>

import { UPDATE_HOLIDAY } from './mutations';
import Form from './Form.vue';
import { ErrorHandler, Flags } from '../../../shared';
import { Confirmation } from '../../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  computed: {
    flag() {
      return Flags[this.record.country.name];
    },
  },
  methods: {
    update(holiday) {
      this.$apollo.mutate({
        mutation: UPDATE_HOLIDAY,
        variables: {
          id: this.record.id,
          holiday: _.pick(holiday, ['name', 'date', 'country_id']),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
