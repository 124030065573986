import MenuItem from './MenuItem';

const permissionsForProject = ['ReadProjects', 'ReadBusinessUnits', 'ReadCostsByJobTitle', 'ReadCostPerUser', 'ReadProjectRoles', 'ReadClients', 'ReadPartners', 'ReadTechnologies', 'ReadVerticalMarkets'];
const permissionsForSecurity = ['ReadUsers', 'ReadBots', 'ReadIntegrations', 'ReadRoles', 'ReadUserDirectory', 'ManageGatekeeper'];
const permissionsForReports = ['ReadAccountManagement', 'LogTime', 'ReadProjectMemberHistory', 'ReadHoursPerProject', 'ReadPeopleAllocation', 'ReadPeopleAllocationPerProject', 'ReadPeopleInBench',
  'ReadProjectsSummary', 'ReadPromotions', 'ReadTalentedPeople', 'ReadTechnologyDistribution', 'ReadTimeLine', 'ReadEmbedReport', 'ReadProjectMetrics', 'ReadProjects', 'ReadCostPerUser',
  'ReadCountries', 'ReadClients'];
const permissionsForTalentManagement = ['ReadUsers', 'ReadJobTitles', 'ReadPromotions', 'ReadPeopleVacation', 'ReadPaidTimeOffRequestDays', 'ReadMyPaidTimeOffRequests', 'ReadPaidTimeOffRequests',
  'ReadHolidays', 'ReadBirthdaysAndAnniversaries', 'ReadSuggestion', 'ReadLearningPaths'];
export default {
  data() {
    return {
      menu_items: [
        new MenuItem('Customer Portal', 'fas fa-users', this.$can('ReadMyTeam'), [
          new MenuItem('My Teams', 'fas fa-users', this.$can('ReadMyTeam'), [], '/customer_portal/my_teams'),
          new MenuItem('Holidays', 'fas fa-calendar-xmark', this.$can('ReadTeamHolidays'), [], '/customer_portal/team_holidays'),
          new MenuItem('Contracts', 'fas fa-paperclip', this.$can('ReadTeamAttachments'), [], '/customer_portal/contracts'),
        ]),
        new MenuItem('Projects', 'fas fa-project-diagram', permissionsForProject.some((permission) => this.$can(permission)), [
          new MenuItem('Business Units', 'fas fa-briefcase', this.$can('ReadBusinessUnits'), [], '/business_units'),
          new MenuItem('Margin Calculator', 'fas fa-calculator', this.$can('ReadCostsByJobTitle'), [], '/margin_calculator'),
          new MenuItem('Cost Per Resource', 'fas fa-dollar-sign', this.$can('ReadCostPerUser'), [], '/cost_per_user'),
          new MenuItem('Rate Per Resource', 'fas fa-dollar-sign', this.$can('ReadCostPerUser'), [], '/rate_per_user'),
          new MenuItem('Projects', 'fas fa-project-diagram', this.$can('ReadProjects'), [], '/projects'),
          new MenuItem('Project Roles', 'fas fa-glasses', this.$can('ReadProjectRoles'), [], '/project_roles'),
          new MenuItem('Clients', 'fas fa-globe-americas', this.$can('ReadClients'), [], '/clients'),
          new MenuItem('Partners', 'fas fa-handshake', this.$can('ReadPartners'), [], '/partners'),
          new MenuItem('Technologies', 'fas fa-chart-pie', this.$can('ReadTechnologies'), [], '/technologies'),
          new MenuItem('Vertical Markets', 'fas fa-microchip', this.$can('ReadVerticalMarkets'), [], '/vertical_markets'),
          new MenuItem('Opportunities', 'fa-solid fa-hands-holding-circle', this.$can('ReadOpportunities'), [], '/opportunities'),
        ]),
        new MenuItem('Security', 'fas fa-lock', permissionsForSecurity.some((permission) => this.$can(permission)), [
          new MenuItem('Users', 'fas fa-users', this.$can('ReadUsers'), [], '/users'),
          new MenuItem('Bots', 'fas fa-robot', this.$can('ReadBots'), [], '/bots'),
          new MenuItem('Integrations', 'fas fa-puzzle-piece', this.$can('ReadIntegrations'), [], '/integrations'),
          new MenuItem('Permission Matrix', 'fas fa-shield-alt', this.$can('ReadRoles'), [], '/permission_matrix'),
          new MenuItem('User Directory', 'fas fa-users', this.$can('ReadUserDirectory'), [], '/user_directory'),
          new MenuItem('Gatekeeper', 'fas fa-user-shield', this.$can('ManageGatekeeper'), [], '/gatekeeper'),
        ]),
        new MenuItem('Estimations', 'fas fa-tasks', this.$can('ReadBuckets') || this.$can('ReadEstimations'), [
          new MenuItem('Estimation Buckets', 'fas fa-cubes', this.$can('ReadBuckets'), [], '/estimation_buckets'),
          new MenuItem('Estimations', 'fas fa-tasks', this.$can('ReadEstimations'), [], '/estimations'),
        ]),
        new MenuItem('Reports', 'fas fa-chart-line', permissionsForReports.some((permission) => this.$can(permission)), [
          new MenuItem('Account Dashboard', 'fa-solid fa-house-medical', this.$can('ReadAccountManagement'), [], '/reports/account_dashboard'),
          new MenuItem('Revenue By Manager', 'fa-solid fa-dollar-sign', this.$can('ReadAccountManagement'), [], '/reports/revenue_by_manager'),
          new MenuItem('My Time', 'fas fa-business-time', this.$can('LogTime'), [], '/reports/my_time'),
          new MenuItem('Project Member History', 'fas fa-list-alt', this.$can('ReadProjectMemberHistory'), [], '/reports/project_member_history'),
          new MenuItem('Hours Per Project', 'fas fa-clock', this.$can('ReadHoursPerProject'), [], '/reports/hours_per_project'),
          new MenuItem('Timesheet', 'fas fa-clock', this.$can('ReadHoursPerProject'), [], '/reports/timesheet'),
          new MenuItem('People Allocation', 'fas fa-users', this.$can('ReadPeopleAllocation'), [], '/reports/people_allocation'),
          new MenuItem('People Allocation Per Project', 'fas fa-user-friends', this.$can('ReadPeopleAllocationPerProject'), [], '/reports/people_allocation_per_project'),
          new MenuItem('People In Bench', 'fas fa-university', this.$can('ReadPeopleInBench'), [], '/reports/people_in_bench'),
          new MenuItem('Projects Summary', 'fas fa-clipboard-list', this.$can('ReadProjectsSummary'), [], '/reports/projects_summary'),
          new MenuItem('Resume Full Text Search', 'fas fa-search', this.$can('ReadTalentedPeople'), [], '/reports/resume_full_text_search'),
          new MenuItem('Talented People', 'fas fa-star', this.$can('ReadTalentedPeople'), [], '/reports/talented_people'),
          new MenuItem('Technology Distribution', 'fas fa-spray-can', this.$can('ReadTechnologyDistribution'), [], '/reports/technology_distribution'),
          new MenuItem('Timeline', 'fas fa-history', this.$can('ReadTimeLine'), [], '/reports/timeline'),
          new MenuItem('Embed Reports', 'fas fa-chart-line', this.$can('ReadEmbedReport'), [], '/embed_reports'),
          new MenuItem('Project Metrics', 'fas fa-balance-scale', this.$can('ReadProjectMetrics'), [], '/reports/project_metrics'),
          new MenuItem('Weekly Forecast', 'fas fa-calculator', this.$can('ReadProjects'), [], '/reports/weekly_forecast'),
          new MenuItem('Gross Margin Per Resource', 'fas fa-percentage', this.$can('ReadCostPerUser'), [], '/reports/gross_margin_per_resource'),
          new MenuItem('Company Distribution', 'fas fa-globe', this.$can('ReadCountries'), [], '/company_distribution'),
          new MenuItem('People By Client', 'fas fa-users', this.$can('ReadClients'), [], '/reports/people_by_client'),
          new MenuItem('Cost Per Position', 'fas fa-users', this.$can('ReadCostPerUser'), [], '/cost_per_position'),
          new MenuItem('Turnover Dashboard', 'fas fa-users', this.$can('ReadTurnoverDashboard'), [], '/reports/turnover_dashboard'),
        ]),
        new MenuItem('Indicators', 'fas fa-chart-area', this.$can('ReadIndicators') || this.$can('CompareIndicators'), [
          new MenuItem('Indicators', 'fas fa-chart-area', this.$can('ReadIndicators'), [], '/indicators'),
          new MenuItem('Compare Indicators', 'fas fa-balance-scale', this.$can('CompareIndicators'), [], '/reports/indicator_comparator'),
        ]),
        new MenuItem('Talent Management', 'fas fa-seedling', permissionsForTalentManagement.some((permission) => this.$can(permission)), [
          new MenuItem('Users', 'fas fa-users', this.$can('ReadUsers'), [], '/users'),
          new MenuItem('Job Titles', 'fas fa-user-ninja', this.$can('ReadJobTitles'), [], '/job_titles'),
          new MenuItem('Promotions', 'fas fa-seedling', this.$can('ReadPromotions'), [], '/reports/promotions'),
          new MenuItem('People Vacation', 'fas fa-calendar-check', this.$can('ReadPeopleVacation'), [], '/vacation/people_vacation'),
          new MenuItem('PTO Calendar', 'fas fa-calendar-alt', this.$can('ReadPaidTimeOffRequestDays'), [], '/vacation/calendar'),
          new MenuItem('My Vacation Requests', 'fas fa-plane-departure', this.$can('ReadMyPaidTimeOffRequests'), [], '/vacation/my_vacation_requests'),
          new MenuItem('Vacation Requests', 'fas fa-umbrella-beach', this.$can('ReadPaidTimeOffRequests'), [], '/vacation/vacation_requests'),
          new MenuItem('Vacation History', 'fas fa-history', this.$can('ReadPaidTimeOffRequests'), [], '/vacation/vacation_history'),
          new MenuItem('Holidays', 'fas fa-calendar-day', this.$can('ReadHolidays'), [], '/vacation/holidays'),
          new MenuItem('Holidays Calendar', 'fas fa-calendar-xmark', this.$can('ReadHolidays'), [], '/vacation/holiday_calendar'),
          new MenuItem('Birthdays', 'fas fa-birthday-cake', this.$can('ReadBirthdaysAndAnniversaries'), [], '/birthdays'),
          new MenuItem('Anniversaries', 'fas fa-birthday-cake', this.$can('ReadBirthdaysAndAnniversaries'), [], '/anniversaries'),
          new MenuItem('Suggestion Box', 'fas fa-bell', this.$can('ReadSuggestion'), [], '/suggestions'),
          new MenuItem('Learning Paths', 'fas fa-graduation-cap', this.$can('ReadLearningPaths'), [], '/learning_paths'),
        ]),
        new MenuItem('Personal Growth', 'fa-solid fa-arrow-up-right-dots', this.$can('ReadLearningPaths'), [
          new MenuItem('Learning Paths', 'fas fa-graduation-cap', this.$can('ReadLearningPaths'), [], '/learning_paths'),
        ]),
        new MenuItem('Inventory', 'fas fa-boxes', this.$can('ReadEquipment'), [
          new MenuItem('Equipment', 'fas fa-archive', this.$can('ReadEquipment'), [], '/equipment'),
        ]),
        new MenuItem('Talent Acquisition', 'fas fa-search', this.$can('ReadCandidates') || this.$can('ReadInterviewQuestions'), [
          new MenuItem('Candidates', 'fas fa-address-book', this.$can('ReadCandidates'), [], '/external_candidates'),
          new MenuItem('Technical Questions', 'fas fa-tasks', this.$can('ReadInterviewQuestions'), [], '/interview_questions'),
        ]),
        new MenuItem('Net Promoter Score', 'fa-solid fa-gauge-high', this.$can('ReadNetPromoterScoreAnswers') || this.$can('ExportNetPromoterScoreAnswers') || this.$can('ReadInternalNetPromoterScore'), [
          new MenuItem('Send Surveys', 'fa-solid fa-paper-plane', this.$can('ExportNetPromoterScoreAnswers'), [], '/net_promoter_score_survey'),
          new MenuItem('External Answers', 'fa-solid fa-ranking-star', this.$can('ReadNetPromoterScoreAnswers'), [], '/net_promoter_score/external_answers'),
          new MenuItem('External Dashboard', 'fa-solid fa-gauge-high', this.$can('ReadNetPromoterScoreAnswers'), [], '/net_promoter_score/external_dashboard'),
          new MenuItem('Internal Answers', 'fa-solid fa-ranking-star', this.$can('ReadInternalNetPromoterScore'), [], '/net_promoter_score/internal_answers'),
          new MenuItem('Internal Dashboard', 'fa-solid fa-gauge-high', this.$can('ReadInternalNetPromoterScore'), [], '/net_promoter_score/internal_dashboard'),
        ]),
        new MenuItem('Gcoins', 'fa-solid fa-coins', this.$can('ReadGcoinsTransactions'), [
          new MenuItem('Balances', 'fa-solid fa-piggy-bank', this.$can('ReadGcoinsTransactions'), [], '/gcoin_balances'),
          new MenuItem('Transactions', 'fa-solid fa-money-bill-transfer', this.$can('ReadGcoinsTransactions'), [], '/gcoin_transactions'),
          new MenuItem('Products', 'fa-solid fa-shop', this.$can('ReadProducts'), [], '/products'),
          new MenuItem('Badges', 'fa-solid fa-certificate', this.$can('ReadBadges'), [], '/badges'),
        ]),
        new MenuItem('Performance Evaluations', 'fa-solid fa-clipboard', this.$can('ReadMyPerformanceEvaluations'), [
          new MenuItem('My Evaluations', 'fa-solid fa-star', this.$can('ReadMyPerformanceEvaluations'), [], '/my_performance_evaluations'),
          new MenuItem('9 Box', 'fa-solid fa-9', this.$can('ReadPerformanceEvaluations'), [], '/performance_evaluation_9box'),
          new MenuItem('Evaluations', 'fa-solid fa-check', this.$can('ReadPerformanceEvaluations'), [], '/performance_evaluations'),
          new MenuItem('Cycles', 'fa-solid fa-arrows-spin', this.$can('ReadPerformanceEvaluationCycles'), [], '/performance_evaluation_cycles'),
          new MenuItem('Forms', 'fa-solid fa-square-poll-horizontal', this.$can('ReadPerformanceEvaluationForms'), [], '/performance_evaluation_forms'),
        ]),
      ],
    };
  },
};
