<template>
  <div class="row" v-if="profile" id="myProfileBox">
    <div class="col-md-12 col-sm-12 pb-2">
      <div class="card">
        <div class="card-header">
          <div class="d-flex justify-content-between">
            <canvas ref="canvas" width="394px" height="150px" hidden="true"></canvas>
            <button class="btn btn-company-primary" @click="update">
              <i v-if="loading" class="fas fa-sync fa-spin"></i>
              <i v-if="!loading" class="fas fa-save"></i>
              Save
            </button>
            <button id="signature" class="btn btn-sm btn-company-primary" @click="buildSignature">
              <i class="fas fa-signature"></i>
              Signature
            </button>
          </div>
        </div>
        <div class="card-body" v-if="profile">
          <div class="row">
            <div class="col">
              <h5 class="card-title">Biography</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-2">
              <div v-if="!profile.avatar">
                <ProfileImage :user_name="profile.name" :font_size="'100px'"></ProfileImage>
              </div>
              <div v-if="profile.avatar">
                <img :src="profile.avatar" v-if="!show_chart" class="img-thumbnail sortable" @dblclick="show_chart=true"/>
                <SpiderWebChart :user_id="profile.id" v-if="show_chart" @hide="show_chart=false"></SpiderWebChart>
              </div>
            </div>
            <div class="col mb-3">
              <label for="aboutMe">About me</label>
              <textarea v-focus class="form-control h-75"  v-model="profile.bio" id="aboutMe" placeholder="Tell us something about you" rows="3"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <hr>
              <h5 class="card-title">Personal Information</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 mb-3">
              <label for="fullName">Full Name</label>
              <input type="text" disabled class="form-control"  id="fullName" :readonly="true" :placeholder="profile.name"/>
            </div>
            <div class="col-md-6 col-sm-12 mb-3">
              <label for="email">Email</label>
              <input type="text" disabled class="form-control" id="email" :readonly="true" :placeholder="profile.email"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 mb-3">
              <label for="gender">Gender</label>
              <div>
                <input type="radio" id="male" value="male" v-model="profile.gender"/>
                <label for="Male">Male</label>
              </div>
              <div>
                <input type="radio" id="female" value="female" v-model="profile.gender"/>
                <label for="Female">Female</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-sm-12 mb-3">
              <label for="github">GitHub</label>
              <input type="text" class="form-control" id="github" placeholder="Enter your GitHub" v-model="profile.github"/>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 mb-3">
              <label for="softSkills">
                <span :class="{'text-danger': softSkillsCount > 5}">Soft Skills ({{softSkillsCount}} of 5)</span>
              </label>
              <textarea class="form-control" id="softSkills" v-model="profile.soft_skills" placeholder="Enter your soft skills" rows="5"></textarea>
            </div>
            <div class="col-md-6 col-sm-12 mb-3">
              <label for="hobbies">Hobbies</label>
              <textarea class="form-control" id="hobbies" v-model="profile.hobbies" placeholder="Enter your hobbies" rows="5"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 col-sm-12 mb-3">
              <label for="technical_skills">
                <span :class="{'text-danger': techSkillsCount > 12}">Technical skills ({{techSkillsCount}} of 12)</span>
              </label>
              <textarea class="form-control" id="technical_skills" v-model="profile.technical_skills" placeholder="Enter your Technical skills (12 max)" rows="11"></textarea>
            </div>
            <div class="col-md-6 col-sm-12 mb-3">
              <label for="education">
                <span :class="{'text-danger': educationCount > 12}">Education ({{educationCount}} of 12)</span>
              </label>
              <textarea class="form-control" id="education" v-model="profile.education" placeholder="Year, college career, University (12 max)" rows="11"></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mb-3">
              <TechnologyPicker :selected="profile.technologies || []">Technologies:</TechnologyPicker>
            </div>
          </div>
          <hr>
          <div class="row pb-2">
            <div class="col d-flex justify-content-between">
              <h5 class="card-title">Previous Experience</h5>
              <div class="col-sm-6 col-md-6 d-flex justify-content-end">
                <button class="btn btn-company-primary" @click="addExternalCompany">
                  <i class="fas fa-plus"></i>
                  Add Company
                </button>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <ExternalCompany :key="external_company.id" v-for="external_company in profile.external_companies"
                                                        :external_company="external_company"
                                                        @remove="removeExternalCompany"/>
          </div>
          <button class="btn btn-company-primary" @click="update">
            <i v-if="loading" class="fas fa-sync fa-spin"></i>
            <i v-if="!loading" class="fas fa-save"></i>
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { UPDATE_PROFILE, UPDATE_ANY_PROFILE } from './mutations';
import SIGNATURE_QUERY from './signature_queries';
import RequestBuilder from './RequestBuilder';
import ExternalCompany from '../previous_experience/Index.vue';
import SpiderWebChart from '../SpiderWebChart.vue';
import { ProfileImage } from '../../shared';
import TechnologyPicker from '../../technologies/Picker.vue';
import SignatureHelper from '../../signature_settings/signature_helper';

export default {
  props: ['profile'],
  data() {
    return {
      loading: false,
      show_chart: false,
    };
  },
  apollo: {
    signature: {
      query: SIGNATURE_QUERY,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  components: {
    ExternalCompany, SpiderWebChart, ProfileImage, TechnologyPicker,
  },
  computed: {
    softSkillsCount() {
      if (!this.profile.soft_skills) {
        return 0;
      }
      return this.profile.soft_skills.split('\n').length;
    },
    techSkillsCount() {
      if (!this.profile.technical_skills) {
        return 0;
      }
      return this.profile.technical_skills.split('\n').length;
    },
    educationCount() {
      if (!this.profile.education) {
        return 0;
      }
      return this.profile.education.split('\n').length;
    },
    signatureName() {
      const names = this.profile.name.split(/\s+/);
      if (names.length > 3) {
        names.pop();
        return names.join(' ');
      }
      return this.profile.name;
    },
  },
  methods: {
    update() {
      this.loading = true;
      const userId = this.$route.params.id;
      const MUTATION = userId ? UPDATE_ANY_PROFILE : UPDATE_PROFILE;
      const variables = {
        user: RequestBuilder.build(this.profile),
      };
      if (userId) {
        variables.user_id = userId;
      }
      this.$apollo.mutate({
        mutation: MUTATION,
        variables,
      }).finally(() => {
        this.loading = false;
        this.$toasted.success('Profile changes saved!');
      });
    },
    addExternalCompany() {
      this.profile.external_companies.push(
        { name: '', external_projects: [{ name: '', description: '' }] },
      );
    },
    removeExternalCompany(company) {
      this.profile.external_companies.splice(this.profile.external_companies.indexOf(company), 1);
    },
    loadSignatureSettings() {
      const signatureItem = this.signature;
      const divBase = SignatureHelper.renderSignatureWithData(signatureItem, this.profile);
      document.getElementById('myProfileBox').appendChild(divBase);
      SignatureHelper.downloadSignatureImage(divBase).then((canvas) => {
        canvas.style.display = 'none';
        const link = document.createElement('a');
        link.download = 'signature.png';
        link.href = canvas.toDataURL();
        link.click();
      });
      divBase.classList.add('d-none');
      divBase.remove();
    },
    buildSignature() {
      this.loadSignatureSettings();
    },
  },
};
</script>
