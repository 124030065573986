export default class RequestBuilder {
  static build(opportunity) {
    const keys = [
      'name', 'description', 'taken', 'client_id', 'project_id', 'state', 'begins', 'budget',
      'duration', 'seniority', 'positions_amount', 'english_level', 'country_id', 'notes',
    ];
    const requestBody = _.pick(opportunity, keys);
    requestBody.technologies = {
      sync: (opportunity.technologies || []).filter((tech) => tech.id).map((tech) => tech.id),
      create: (opportunity.technologies || []).map((tech) => tech).filter((tech) => !tech.id),
    };
    if (requestBody.budget) {
      requestBody.budget = parseFloat(requestBody.budget);
    }
    if (requestBody.positions_amount) {
      requestBody.positions_amount = parseFloat(requestBody.positions_amount);
    }
    return requestBody;
  }
}
