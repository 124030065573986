<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Learning Path Level</span>
      <span v-if="localRecord.id">{{localRecord.name}}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-3">
        <label for="name">Name</label>
        <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
        <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import Validations from './Validations';
import Blinkable from '../../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
