export default class RequestBuilder {
  static build(record) {
    const requestBody = _.pick(record, ['name']);
    requestBody.questions = {
      delete: (record.questions || []).filter((question) => question.deleted).map((question) => question.id),
      upsert: (record.questions || []).filter((question) => !question.id)
        .map((question) => ({ name: question.name }))
        .concat((record.questions || []).filter((question) => question.id)
          .map((question) => ({ id: question.id, name: question.name }))),
    };
    return requestBody;
  }
}
