import { gql } from 'apollo-boost';

import PROMOTION_FRAGMENT from './fragments';

export const PROMOTIONS_QUERY = gql`
  query ($page: Int,
       $user_id: ID,
       $from_job_title_id: ID,
       $to_job_title_id: ID) {
    promotions(
      page: $page,
      user_id: $user_id,
      from_job_title_id: $from_job_title_id,
      to_job_title_id: $to_job_title_id,
      orderBy: { column: "created_at", order: DESC }
    ) @connection(key: "promotions", filter: ["user_id", "from_job_title_id", "$to_job_title_id"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...promotion
      }
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const PROMOTED_USER_QUERY = gql`
  query ($id: ID!) {
    user(id: $id) {
      id
      job_title {
        id
      }
    }
  }
`;
