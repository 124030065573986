<template>
<div class="mb-5 card">
  <div class="card-header d-flex">
    <div class="col p-0">
      <span>New PTO Request</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body d-grid">
    <div class="row mb-3">
      <div class="col-6">
        <div class="company-control pb-3">
          <label class="form-check-label" for="start_date">Start Date</label>
          <DatePicker :date="start_date"
                placeholder="Select Date"
                @change="start_date = $event"></DatePicker>
        </div>
        <div v-for="error of v$.start_date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="col-6">
        <div class="company-control pb-3">
          <label class="form-check-label" for="end_date">End Date</label>
          <DatePicker :date="end_date"
                placeholder="Select Date"
                @change="end_date = $event"></DatePicker>
        </div>
        <div v-for="error of v$.end_date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3">
        <label for="assigned_to" class="pe-2">Assign to:</label>
        <Select v-model="assigned_to.user_id"
                :name="`user_id`"
                :query="usersQuery"
                :queryVariables="{ orderBy: [{ column: 'name', order: 'ASC' }], is_active: true, is_external: false }"></Select>
        <div v-for="error of v$.assigned_to.user_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="form-check">
      <input type="radio" id="full" class="form-check-input"
             v-model="half_day" v-bind:value="false"/>
      <label class="form-check-label" for="full">Entire Day</label>
    </div>
    <div class="form-check">
      <input type="radio" id="half" class="form-check-input"
             v-model="half_day" v-bind:value="true"/>
      <label class="form-check-label" for="half">Half Day</label>
    </div>

    <button :disabled="v$.assigned_to.$invalid" type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Send
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import Validations from './Validations';
import { USERS } from '../../../shared';
import { Select } from '../../shared';

export default {
  extends: Validations,
  data() {
    return {
      start_date: moment().format('YYYY-MM-DD'),
      end_date: moment().format('YYYY-MM-DD'),
      half_day: false,
      search: {},
      assigned_to: {
        user_id: '',
      },
      usersQuery: USERS,
    };
  },
  components: { Select },
  methods: {
    save() {
      const datesArray = [];
      const start = moment(this.start_date);
      const end = moment(this.end_date);
      while (start.isSameOrBefore(end)) {
        datesArray.push(start.format('YYYY-MM-DD'));
        start.add(1, 'day');
      }
      const status = this.half_day === true ? 'half_day' : 'full_day';
      this.$emit('insert', {
        dates: datesArray,
        type: status,
        assigned_to: this.assigned_to.user_id,
      });
    },
  },
};
</script>
