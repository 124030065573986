<template>
  <div :id="record.id" class="col-md-3 pb-2">
    <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
    <div v-if="!is_editting" class="card h-100">
      <div class="card-body">
        <h5 class="card-title">{{record.name}}</h5>
        <p class="card-text" v-html="record.description"></p>
      </div>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">Documentation Links:</li>
        <li class="list-group-item" :key="link" v-for="link in documentationLinks">
          <a :href="link" target="_blank" class="card-link">{{link}}</a>
        </li>
      </ul>
      <div class="card-body">
        <h5 class="card-subtitle">Evaluation Methodology:</h5>
        <p v-html="record.evaluation_methodology"></p>
      </div>
      <div class="card-body">
        <button v-if="!confirmation && $can('UpdateLearningPathLevelNode')" class="btn btn-company-primary" @click="is_editting = true">
          <i class="fas fa-pen"></i>
          Edit
        </button>
        <Confirmation v-if="$can('DeleteLearningPathLevelNode')"
          v-model="confirmation"
          @accepted="remove()"
        />
      </div>
    </div>
  </div>
</template>

<script>

import Form from './Form.vue';
import { Confirmation } from '../../shared';
import { ErrorHandler } from '../../../shared';
import { UPDATE_LEARNING_PATH_LEVEL_NODE } from './mutations';

export default {
  props: ['record'],
  components: { Confirmation, Form },
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_LEARNING_PATH_LEVEL_NODE,
        variables: {
          id: record.id,
          learning_path_level_node: _.pick(record, ['name', 'description', 'documentation_links', 'evaluation_methodology', 'learning_path_level_id']),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
  computed: {
    documentationLinks() {
      return this.record.documentation_links.split('\n');
    },
  },
};
</script>
