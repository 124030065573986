<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">My Time</SpinnerRouterLink>
          </div>
        </div>
      </div>
    </nav>
    <div class="card-header card mb-4 sticky bg-light p-3">
      <div class="d-lg-flex">
        <div class="col-lg m-auto text-start text-lg-left p-3 m-auto">
          <h3 class="font-weight-bold m-0">Hours Log</h3>
        </div>
        <div class="text-center">
          <div width=255>
            <div class="d-inline-block p-0 m-auto text-start align-middle">
              <i class="fas fa-arrow-circle-left fa-3x sortable" title="Previous Week" @click="Week.prevWeek()"></i>
            </div>
            <div class="d-inline-block mx-4 align-middle">
              <h4 class="m-0">Week {{ Week.currWeek() }}</h4>
              <span class="text-body-secondary">
                {{ Week.get('Sunday').format('MMMM DD, YYYY') }}
                <br>
                {{ Week.get('Saturday').format('MMMM DD, YYYY') }}
              </span>
            </div>
            <div class="d-inline-block p-0 m-auto text-end align-middle">
              <i class="fas fa-arrow-circle-right fa-3x sortable" title="Next Week" @click="Week.nextWeek()"></i>
            </div>
          </div>
        </div>
        <div class="col-lg my-auto p-0">
          <div class="text-center text-lg-end float-lg-end">
            <p class="p-0 m-2 font-weight-bold sticky" :class="{ 'text-danger': State.savedHours > 40 }">
              Saved hours:
              <span v-if="!State.isLoading">
                {{ State.savedHours }}
              </span>
              <span v-else class="spinner-grow text-secondary spinner-grow-sm" role="status">
                <span class="sr-only">...</span>
              </span>
            </p>
            <p class="p-0 m-2 font-weight-bold sticky" :class="{ 'text-danger': State.draftHours > 40 }">
              Draft hours:
              <span v-if="!State.isLoading">
                {{ State.draftHours }}
              </span>
              <span v-else class="spinner-grow text-secondary spinner-grow-sm" role="status">
                <span class="sr-only">...</span>
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <template v-if="State">
      <Logger v-for="(_, index) in State.records" :key="index" :index="index" />
    </template>
  </div>
</template>

<script>
import MY_TIME from './queries';
import { State, Week } from './State';
import { SpinnerRouterLink } from '../shared';
import Logger from './Logger/Component.vue';

export default {
  components: { SpinnerRouterLink, Logger },
  setup() { return { State, Week }; },
  apollo: {
    myTime: {
      query: MY_TIME,
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        this.State.isLoading = true;
        return {
          start_date: this.Week.get('Sunday').format('YYYY-MM-DD 00:00:00'),
          end_date: this.Week.get('Saturday').format('YYYY-MM-DD 23:59:59'),
        };
      },
      update({ myTime }) {
        this.State.next(myTime);
        this.State.isLoading = false;
      },
      error(error) {
        this.$toasted.error(error.message);
        this.State.isLoading = false;
      },
    },
  },
};
</script>
