<template>
  <div>
    <!-- Dynamic menu -->
    <FastNavigation class="d-sm-none"></FastNavigation>
    <template v-for="item in menu_items">
      <!-- Items -->
      <a :key="`ma-${item.anchor}`" v-if="item.isShown" :href="`#${item.anchor}`" data-bs-toggle="collapse" aria-expanded="false" class="company-background-color-mobile list-group-item list-group-item-action flex-column align-items-start d-md-none">
        <div class="d-flex w-100 justify-content-start align-items-center">
          <i :class="`${item.icon} fa-fw me-3`"></i>
          <span class="menu-collapsed">{{item.name}}</span>
          <i class="submenu-icon ms-auto fas fa-sort-down"></i>
        </div>
      </a>
      <div :key="`mdiv-${item.anchor}-${child.anchor}`" :id="item.anchor" class="collapse sidebar-submenu d-md-none" v-for="child in item.children">
        <router-link v-if="child.isShown" class="list-group-item list-group-item-action company-background-color-mobile" :to="child.route">
          <i :class="`${child.icon} fa-fw me-3`"></i>
          <span class="menu-collapsed">{{child.name}}</span>
        </router-link>
      </div>
    </template>
  </div>
</template>
<script>
import BaseMenu from './BaseMenu';
import FastNavigation from '../fast_navigation/Index.vue';

export default {
  extends: BaseMenu,
  components: { FastNavigation },
};
</script>
