<style scoped>
.progress-text{
  transform-origin: 0 0;
}
.progress__meter,
.progress__value {
  fill: none;
}
.progress__value {
  stroke-linecap: round;
}
svg {
  height: 8em;
}
</style>
<template>
  <svg class="progress fa-rotate-270 float-start bg-transparent ps-1" viewBox="0 0 120 120">
    <circle class="progress__meter" cx="60" cy="60" :r="radius" stroke="#e6e6e6" stroke-width="1" />
    <circle class="progress__value" cx="60" cy="60" :r="radius" :stroke="color"
                                                           :style="{'stroke-dasharray': circumference, 'stroke-dashoffset': dashoffset }"
                                                           stroke-width="1" />
        <text class="progress-text fa-rotate-90 fs-5" x="60" :y="-radius"
          text-anchor="middle"
          :stroke="color"
          :fill="color"
          stroke-width="0.2"
          >{{progressText}}%</text>
  </svg>
</template>
<script>

export default {
  props: ['color', 'progress'],
  data() {
    return {
      radius: 54,
      dashoffset: 0,
    };
  },
  computed: {
    circumference() {
      return 2 * Math.PI * this.radius;
    },
    progressText() {
      return this.progress > 100 ? (this.progress - 100) * -1 : this.progress;
    },
  },
  mounted() {
    this.dashoffset = this.circumference * (1 - this.progress / 100);
    if (this.dashoffset < 0) {
      this.dashoffset = 0;
    }
  },
};
</script>
