<template>
<div :id="record.id" class="col-md-4 pb-2">
  <Form v-if="is_editting" :record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <div class="float-end">
        <div class="d-flex pb-1">
          <span v-if="record.is_successful == true">
            <i class="fa fa-check-circle fa-lg text-success"></i>
          </span>
          <span v-if="record.is_successful == false">
            <i class="fa fa-times-circle fa-lg text-danger"></i>
          </span>
        </div>
      </div>
      <h5 class="card-title">
        {{record.date}}
      </h5>
    </div>
    <div class="card-body pt-0 pb-0">
      <div class="row">
        <div class="col-md-12">
          <p class="mb-0"><strong>Notes:</strong></p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p class="card-text" v-html="record.notes"></p>
        </div>
      </div>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateInterview')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteInterview')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_INTERVIEW } from './mutations';
import Form from './Form.vue';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_INTERVIEW,
        variables: {
          id: record.id,
          interview: _.pick(record, ['date', 'is_successful', 'notes', 'user_id']),
        },
      }).then(() => {
        this.is_editting = false;
        this.$emit('refresh');
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
