import { gql } from 'apollo-boost';

export default gql`
  query($orderBy: [OrderByClause!]) {
    peopleAllocationPerProject(orderBy: $orderBy){
      person
      project
      type
      end_date
      status
    }
  }
`;
