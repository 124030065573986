<template>
  <tr v-if="localRecord">
    <td>{{localRecord.project.name}}</td>
    <td>{{localRecord.user.name}}</td>
    <td>{{localRecord.project_role.name}}</td>
    <td>
      <div class="input-group" v-if="editing">
        <input  v-focus
                v-model="localRecord.hourly_rate"
                type="text"
                class="form-control"
                placeholder="Hourly rate in $"
                aria-label="Hourly rate"
                required>
      </div>
      <span v-else>{{localRecord.hourly_rate}}</span>
    </td>
    <td v-if="$can('UpdateCostPerUser')">
      <button
        v-if="editing"
        class="btn btn-company-primary"
        @click="cancel"
        >Cancel
      </button>
      <button
        class="btn"
        :class="editing ? 'btn-company-secondary' : 'btn-company-primary'"
        @click="update">{{editing ? "Save" : "Edit"}}
      </button>
    </td>
  </tr>
</template>

<script>
export default {
  props: ['projectMember'],
  data() {
    return {
      editing: false,
    };
  },
  computed: {
    localRecord() {
      return { ...this.projectMember };
    },
  },
  methods: {
    update() {
      if (this.editing) {
        this.$emit('rateUpdated', this.localRecord);
      }
      this.editing = !this.editing;
    },
    cancel() {
      this.editing = false;
      this.$emit('cancel', this.localRecord);
    },
  },
};
</script>
