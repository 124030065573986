import { gql } from 'apollo-boost';

export default gql`
query($page: Int,
      $name: String,
      $email: String,
      $active_only: Boolean,
      $only_my_team: Boolean,){
  users(page: $page,
        name: $name,
        email: $email,
        is_active: $active_only,
        only_my_team: $only_my_team){
    paginatorInfo{
      hasMorePages
    }
    data{
      id
      name
      gcoin_balance
      gcoin_balance_to_give
      avatar
    }
  }
}
`;
