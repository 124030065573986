import { gql } from 'apollo-boost';

export const CREATE_EMBED_REPORT = gql`
  mutation($embed_report: EmbedReportInput!){
    createEmbedReport(input: $embed_report){
      id
      title
      url
    }
  }
`;

export const DELETE_EMBED_REPORT = gql`
  mutation($id: ID!){
    deleteEmbedReport(id: $id){
      id
    }
  }
`;
