export default {
  methods: {
    showMore(queryName) {
      this.$apollo.queries[queryName].fetchMore({
        variables: { page: this.page++, ...this.queryVariables() },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this._data[queryName].paginatorInfo.hasMorePages = fetchMoreResult[queryName].paginatorInfo.hasMorePages;
          return {
            [queryName]: {
              __typename: previousResult[queryName].__typename,
              data: [...previousResult[queryName].data, ...fetchMoreResult[queryName].data],
              paginatorInfo: this._data[queryName].paginatorInfo,
            },
          };
        },
      });
    },
  },
  data() {
    return { page: 2 };
  },
};
