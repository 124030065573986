<template>
<div :id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting"
    v-bind:record="record"
    @close="is_editting = false"
    @update="update"
  ></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 v-if="!record.documentation" class="card-title">
        {{record.name}}
      </h5>
      <h5 v-if="record.documentation" class="card-title">
        <a :href="record.documentation" target="_blank">{{record.name}}</a>
      </h5>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateTechnology')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteTechnology')"
        v-model="confirmation"
        @accepted="remove()"
      />
      <router-link title="Interview Questions" class="btn btn-company-primary" v-if="!confirmation && $can('ReadInterviewQuestions')" :to="`/interview_questions?technology_id=${record.id}`">
        <i class="fas fa-question"></i>
        Questions
      </router-link>
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_TECHNOLOGY } from './mutations';
import Form from './Form.vue';
import ErrorHandler from '../../shared/ErrorHandler';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(technology) {
      this.$apollo.mutate({
        mutation: UPDATE_TECHNOLOGY,
        variables: {
          id: this.record.id,
          technology: _.pick(technology, ['name', 'documentation']),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
