<style>
.icon-container {
  min-width: 100%;
  height: 65vh;
  font-size: 1700%;
}
.text {
  font-size: 3rem;
}
</style>
<template>
<body>
  <div class="icon-container text-center d-flex flex-column justify-content-center align-items-center opacity-25">
    <i :class="icon"></i>
    <div class="text">
      No records found
    </div>
    <button v-if="can_create" class="fs-5 btn bg-primary text-white fw-bolder" @click="$emit('callCreate')">Create new record</button>
  </div>
</body>
</template>
<script>
export default {
  props: ['icon', 'can_create'],
};
</script>
