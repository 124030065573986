<template>
  <div class="container-fluid">
    <ul class="nav nav-pills mb-2">
      <li class="nav-item">
        <a
          class="nav-link active"
          id="general-tab"
          data-bs-toggle="tab"
          href="#general"
          role="tab"
          aria-controls="general"
          aria-selected="true"
          >General Settings</a
        >
      </li>
      <li class="nav-item" v-if="$can('UpdateNotificationSettings')">
        <a
          class="nav-link"
          id="notifications-tab"
          data-bs-toggle="tab"
          href="#notifications"
          role="tab"
          aria-controls="notifications"
          aria-selected="false"
          >Notification Settings</a
        >
      </li>
      <li class="nav-item" v-if="$can('ConfigureSignature')">
        <a
          class="nav-link"
          id="signature-tab"
          data-bs-toggle="tab"
          href="#signature"
          role="tab"
          aria-controls="signature"
          aria-selected="false"
          >Signature Settings</a
        >
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div
        class="tab-pane fade show active"
        id="general"
        role="tabpanel"
        aria-labelledby="general-tab"
      >
        <Configuration></Configuration>
      </div>
      <div
        v-if="$can('UpdateNotificationSettings')"
        class="tab-pane fade"
        id="notifications"
        role="tabpanel"
        aria-labelledby="notifications-tab"
      >
        <NotificationSettings></NotificationSettings>
      </div>
      <div
        v-if="$can('ConfigureSignature')"
        class="tab-pane fade"
        id="signature"
        role="tabpanel"
        aria-labelledby="signature-tab"
      >
        <SignatureSettings></SignatureSettings>
      </div>
    </div>
  </div>
</template>
<script>
import Configuration from '../configuration/Index.vue';
import SignatureSettings from '../signature_settings/Index.vue';
import NotificationSettings from '../notification_settings/Index.vue';

export default {
  components: { Configuration, SignatureSettings, NotificationSettings },
};
</script>
