import { gql } from 'apollo-boost';
import FRAGMENT from './fragments';

export const QUERY = gql`
  query ($page: Int,
         $name: String,
         $type: String,
         $orderBy: [OrderByClause!]) {
    partners(
      page: $page,
      name: $name,
      type: $type,
      orderBy: $orderBy
    ) @connection(key: "partners", filter: ["name", "type"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...partner
      }
    }
  }
  ${FRAGMENT}
`;

export const QUERY_WITH_COSTS = gql`
  query ($page: Int,
         $name: String,
         $type: String,
         $orderBy: [OrderByClause!]) {
    partners(
      page: $page,
      name: $name,
      type: $type,
      orderBy: $orderBy
    ) @connection(key: "partners", filter: ["name", "type"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...partner
        last_week_cost
        last_month_cost
        last_year_cost
      }
    }
  }
  ${FRAGMENT}
`;
