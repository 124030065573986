<template>
  <div class="row g-0 mt-n2">
    <div
      v-if="$can('UpdateSuggestionStatus')
              && !confirmationDelete
              && !confirmationAccept
              && suggestion.status !== 'rejected'"
      class="col-auto mt-1 ms-md-1">
      <Confirmation v-model="confirmationReject"
                    icon="fa-solid fa-arrow-rotate-left"
                    text="Reject"
                    @accepted="accepted('reject')"
                    @isConfirmating="toggle($event, 'reject')">
      </Confirmation>
    </div>
    <div
      v-if="$can('UpdateSuggestionStatus')
              && !confirmationReject
              && !confirmationDelete
              && suggestion.status !==  'approved'"
      class="col-auto mt-1 ms-md-1">
      <Confirmation
        v-model="confirmationAccept" icon="fa-solid fa-check"
        text="Approve"
        @accepted="accepted('accept')"
        @isConfirmating="toggle($event, 'accept')">
      </Confirmation>
    </div>
    <div v-if="$can('DeleteSuggestion') && !confirmationReject && !confirmationAccept"
         class="col-auto mt-1 ms-md-1">
      <Confirmation v-model="confirmationDelete"
                    @accepted="accepted('delete')"
                    @isConfirmating="toggle($event, 'delete')">
        <i class="fas fa-trash"></i>
      </Confirmation>
    </div>
  </div>
</template>
<script>
import { Confirmation } from '../shared';
import { PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Confirmation,
  },
  props: ['suggestion'],
  data() {
    return {
      confirmationReject: null,
      confirmationDelete: null,
      confirmationAccept: null,
    };
  },
  methods: {
    toggle(state, type) {
      if (type === 'reject') this.confirmationReject = state;
      if (type === 'accept') this.confirmationAccept = state;
      if (type === 'delete') this.confirmationDelete = state;
    },
    accepted(type) {
      if (type === 'reject') this.$emit('reject', this.suggestion);
      if (type === 'accept') this.$emit('accept', this.suggestion);
      if (type === 'delete') this.$emit('remove', this.suggestion);

      this.confirmationReject = false;
      this.confirmationAccept = false;
      this.confirmationDelete = false;
    },
  },
};
</script>
