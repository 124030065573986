<template>
<div :id="record.id" class="col-md-3 pb-2 sortable">
  <Form v-if="is_editting" :record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">{{record.name}}</h5>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateLearningPathLevel')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteLearningPathLevel')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_LEARNING_PATH_LEVEL } from './mutations';
import Form from './Form.vue';
import { Confirmation } from '../../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_LEARNING_PATH_LEVEL,
        variables: {
          id: record.id,
          learning_path_level: _.pick(record, ['name', 'learning_path_id']),
        },
      }).then(() => {
        this.is_editting = false;
        this.$emit('refresh');
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
