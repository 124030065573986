<template>
  <ul class="breadcrumb p-0 m-auto">
    <li class="breadcrumb-item">
      <router-link to="/home">
        <i v-if="!loading" class="fas fa-home"></i>
        <i v-if="loading" class="fas fa-sync ms-1 fa-sm" :class="{'fa-spin': loading}"></i>
      </router-link>
    </li>
    <li class="breadcrumb-item active fw-bold" aria-current="page">
      <slot></slot>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['loading'],
};
</script>
