import { useVuelidate } from '@vuelidate/core';
import { required, minValue } from '@vuelidate/validators';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      localRecord: {
        cost: { required, minValueValue: minValue(1) },
        date: { required },
      },
    };
  },
};
