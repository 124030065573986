import { gql } from 'apollo-boost';

export default gql`
  query($client_id: ID, $manager_id: ID, $orderBy: [OrderByClause!]) {
    account_management(client_id: $client_id, manager_id: $manager_id, orderBy: $orderBy) {
      client_id
      client_name
      is_direct
      project_id
      project_name
      project_status
      manager_id
      manager
      manager_avatar
      end_date
      member_id
      member
      member_avatar
      job_title
      cost
      rate
    }
  }
`;
