<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Vacation History</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-10 m-auto">
              <Search :search.sync="search" :inputs="['dateRanges', 'users']"></Search>
            </div>
            <div class="col-12 col-sm-2 order-last text-end mt-2 pt-2">
              <button
                class="btn btn-company-primary"
                @click="exportFile()"
                >
                <i v-if="is_exporting" class="fas fa-sync fa-spin"></i>
                <i v-if="!is_exporting" class="fa fa-file-excel"></i>
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="paid_time_off_history">
      <div class="col-md-12 text-nowrap h-100 scroll-x">
        <table class="table">
          <tr>
            <SortByColumn :sort_by_field="'user_id'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
            <SortByColumn :sort_by_field="'accumulated_days'" :orderBy="orderBy" @refresh="refresh">Days</SortByColumn>
            <SortByColumn :sort_by_field="'days'" :orderBy="orderBy" @refresh="refresh">Change</SortByColumn>
            <SortByColumn :sort_by_field="'updated_by_user_id'" :orderBy="orderBy" @refresh="refresh">Updated by</SortByColumn>
            <SortByColumn :sort_by_field="'created_at'" :orderBy="orderBy" @refresh="refresh">Updated at</SortByColumn>
            <SortByColumn :sort_by_field="'reason'" :orderBy="orderBy" @refresh="refresh">Reason</SortByColumn>
            <th>Comments</th>
          </tr>
          <tr :key="index" v-for="(row, index) in paid_time_off_history.data">
            <td>{{row.user.name}}</td>
            <td>{{row.accumulated_days}}</td>
            <td>
              <span v-if="row.days > 0" class="text-success">
                +{{row.days}}
              </span>
              <span v-if="row.days < 0" class="text-danger">
                {{row.days}}
              </span>
            </td>
            <td v-if="row.updated_by">{{row.updated_by.name}}</td>
            <td v-else>Vacation Sentry</td>
            <td>{{$displayDate(row.created_at)}}</td>
            <td>{{row.reason}}</td>
            <td>{{row.comments}}</td>
          </tr>
          <SkeletonRow v-if="paid_time_off_history && paid_time_off_history.paginatorInfo.hasMorePages" :colspan=7 :executeScroll="paid_time_off_history != undefined" @showMore="showMore('paid_time_off_history')"></SkeletonRow>
        </table>
      </div>
    </div>
    <Empty v-if="!paid_time_off_history?.data.length" icon="fas fa-history"/>
  </div>
</template>

<script>

import PAID_TIME_OFF_HISTORY from './queries';
import EXPORT_VACATION_HISTORY from './mutations';
import {
  SortByColumn, SkeletonRow, SpinnerRouterLink, Search,
} from '../../shared';
import {
  SearchBuilder,
  ErrorHandler,
  PaginationHandler,
  Base64File,
} from '../../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Search, SkeletonRow, SortByColumn, SpinnerRouterLink,
  },
  data() {
    return {
      search: { start_date: null, end_date: null },
      is_exporting: false,
      orderBy: { column: 'id', order: 'DESC' },
    };
  },
  apollo: {
    paid_time_off_history: {
      query: PAID_TIME_OFF_HISTORY,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables() {
        return {
          page: 1,
          ...SearchBuilder.build(this.search),
          orderBy: [this.orderBy],
        };
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.paid_time_off_history.refetch();
    },
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    exportFile() {
      if (this.paid_time_off_history.data.length === 0) {
        return;
      }

      this.is_exporting = true;

      this.$apollo.mutate({
        mutation: EXPORT_VACATION_HISTORY,
        variables: {
          ...SearchBuilder.build(this.search),
        },
      }).then((res) => {
        let date = new Date();
        date = `${date.getMonth() + 1}-${date.getDate()}-${date.getUTCFullYear()}`;
        Base64File.download(res.data.exportVacationHistoryToExcel, `Vacation-History-Report-${date}.xlsx`);
      }).finally(() => {
        this.is_exporting = false;
      });
    },
  },
};
</script>
