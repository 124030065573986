<style scoped>
.skills-title {
  font-family: Helvetica Neue;
}
.open-sans {
  font-family: Open Sans;
}
.lato {
  font-family: Lato;
}
</style>

<template>
  <div v-if="user" ref="cv_div">
    <div class="container mt-5">
      <div class="row">
        <div class="col-md-12 d-flex justify-content-between align-items-center">
          <button class="btn btn-company-primary float-start" @click="exportResumeToWord">Export to Word</button>
          <div class="bg-company-primary-dark p-2">
            <img width="150px" :src="companyLogo">
          </div>
        </div>
      </div>
      <hr>
      <div class="row mt-5">
        <div class="col-md-12">
          <h2 class="open-sans fs-1 fw-bold">{{userHasNameAndLastName}}</h2>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-md-12">
          <p class="text-justify open-sans">
            {{user.bio}}
          </p>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-md-12">
          <h4 class="skills-title fs-5 text-primary fw-bold">SKILLS</h4>
          <div class="row">
            <div :key="x" class="col-md-6" v-for="(techSkillChunk, x) in techSkillsChunks">
              <ul>
                <li class="fs-6 lato" :key="y" v-for="(techSkill, y) in techSkillChunk">{{techSkill}}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-md-12">
          <h4 class="fs-5 text-primary open-sans fw-bold">PROFESSIONAL EXPERIENCE</h4>
        </div>
      </div>
      <div class="row pt-2 pb-4">
        <div class="col-md-12">
          <h4 class="fs-5 open-sans fw-bold">{{companyName}}</h4>
          {{hiring_date}} - Currently <span class="text-body-secondary lato">({{user.time_in_the_company}})</span>
        </div>
      </div>
      <div :key="index" v-for="(member, index) in projectMembers" class="row">
        <div class="col-md-12">
          <h5 class="project-title fw-bold text-decoration-underline" v-if="!member.project.is_confidential">{{member.project.client.name}} - {{member.project.name}}</h5>
          <h5 class="project-title fw-bold text-decoration-underline" v-if="member.project.is_confidential">Confidential</h5>
          <span v-if="member.end_date">{{formatDate(member.start_date)}} - {{formatDate(member.end_date)}}</span>
          <span v-if="!member.end_date">{{formatDate(member.start_date)}} - Currently</span>
          <span class="text-mute lato">({{member.duration}})</span>
          <p>
            <strong class="lato">Project Description:</strong>
            <span v-html="member.project.description"></span>
          </p>
          <strong v-if="member.responsibilities" class="lato">Assignments:</strong>
          <ul>
            <li class="lato" :key="x" v-for="(assignementChunck, x) in projectAssignments(member.responsibilities)">
              {{ assignementChunck }}
            </li>
          </ul>
          <div v-if="member.project.technologies.length > 0">
            <strong class="lato">Technologies used:</strong>
            <div class="row">
              <div :key="x" class="col-md-3" v-for="(technologyChunk, x) in projectTechnologies(member.project)">
                <ul>
                  <li class="lato" :key="y" v-for="(technology, y) in technologyChunk">{{technology.name}}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div :key="'ec' + company.id" v-for="company in user.external_companies">
        <div class="row pt-2 pb-4">
          <div class="col-md-12">
            <h4 class="fs-5 open-sans fw-bold">{{company.name}}</h4>
            {{formatDate(company.start_date)}} - {{formatDate(company.end_date)}} <span class="text-body-secondary">({{company.duration}})</span>
          </div>
        </div>
        <div :key="'ep' + project.id" v-for="project in company.external_projects" class="row">
          <div class="col-md-12">
            <h5 class="project-title fw-bold text-decoration-underline">{{project.name}} ({{project.duration}})</h5>
            <p>
              <strong>Description of the project:</strong>
              <span v-html="project.description"></span>
            </p>
          </div>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-md-12">
          <h4 class="fs-5 text-primary open-sans fw-bold">EDUCATION</h4>
        </div>
      </div>
      <div class="row pt-2">
        <div class="col-md-12">
          <p :key="index" v-for="(education_line, index) in education_lines">{{education_line}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { EXPORT_RESUME_TO_WORD } from '../mutations';
import Base64File from '../../../shared/Base64File';

export default {
  props: ['user', 'projectMembers'],
  computed: {
    companyName() {
      return window.Company.name;
    },
    companyLogo() {
      return window.Company.logo_url;
    },
    hiring_date() {
      return moment(this.user.hiring_date).format('MMMM YYYY');
    },
    education_lines() {
      return this.user.education.split('\n');
    },
    softSkills() {
      if (!this.user.soft_skills) {
        return [];
      }
      return this.user.soft_skills.split('\n');
    },
    techSkillsChunks() {
      if (!this.user.technical_skills) {
        return [];
      }
      return _.chunk(this.user.technical_skills.split('\n'), 6);
    },
    userHasNameAndLastName() {
      if (this.user.name.split(' ').length === 2) {
        return (`${this.user.name.split(' ')[0]} ${this.user.name.split(' ')[1][0]}.`);
      } if (this.user.name.split(' ').length >= 3) {
        return (`${this.user.name.split(' ')[0]} ${this.user.name.split(' ')[1]} ${this.user.name.split(' ')[2][0]}.`);
      }
      return this.user.name;
    },
    fileName() {
      if (this.user.name.split(' ').length === 2) {
        return (this.user.name.split(' ')[0]);
      } if (this.user.name.split(' ').length >= 3) {
        return (this.user.name.split(' ')[1]);
      }

      return this.user.name;
    },
  },
  updated() {
    const element = this.$refs.cv_div;
    if (this.$route.query.highlight) {
      const regexp = new RegExp(`(${this.$route.query.highlight})`, 'gi');
      element.innerHTML = element.innerHTML.replace(regexp, "<span class='bg-warning'>$1</span>");
    }
  },
  methods: {
    formatDate(date) {
      return moment(date).format('MMMM YYYY');
    },
    projectTechnologies(project) {
      return _.chunk(project.technologies, 6);
    },
    projectAssignments(assignements) {
      if (!assignements) {
        return [];
      }
      return assignements.split('\n');
    },
    exportResumeToWord() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: EXPORT_RESUME_TO_WORD,
        variables: {
          id: this.$route.params.id,
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to export resume: ${error.message}`);
      }).then((res) => {
        Base64File.download(res.data.exportResumeToWord, `${this.fileName}.docx`);
      });
    },
  },
};
</script>
