<style lang="scss" scoped>
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  .minusplusnumber {
    border:1px solid silver;
    border-radius:5px;
    margin:0 5px 0 5px;
  }
  .minusplusnumber div {
    display:inline-block;
  }
  .minusplusnumber #field_container input {
    width:50px;
    text-align:center;
    font-size:15px;
    padding:3px;
    border:none;
  }
  .minusplusnumber .mpbtn {
    padding:3px 10px 3px 10px;
    border-radius:5px;
  }
  .minusplusnumber .mpbtn:hover {
    background-color:#DDD;
  }
  .minusplusnumber .mpbtn:active {
    background-color:#c5c5c5;
  }
</style>

<template>
  <div class="minusplusnumber d-inline-block bg-white user-select-none">
    <div class="mpbtn sortable minus" @click="decrease()">-</div>
    <div id="field_container">
      <input type="number" step="any" v-model="newValue"/>
    </div>
    <div class="mpbtn sortable plus" @click="increment()">+</div>
  </div>
</template>

<script>
export default {
  props: ['id', 'value', 'incrementer'],
  data() {
    return {
      newValue: this.value,
    };
  },
  methods: {
    increment() {
      this.newValue = parseFloat(this.newValue) + this.incrementer;
    },
    decrease() {
      if (this.newValue > 0) {
        this.newValue = parseFloat(this.newValue) - this.incrementer;
      }
    },
  },
  watch: {
    newValue() {
      this.$emit('update', { id: this.id, value: parseFloat(this.newValue) });
    },
  },
};
</script>
