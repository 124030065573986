import { gql } from 'apollo-boost';

export default gql`
  fragment healthScoreProjectValue on HealthScoreProjectValue {
    id
    health_score_configuration_item_id
    user_id
    project_id
    value
    created_at
  }
`;
