<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-6 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link to="/projects">Projects</router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link :to="`/projects?name=${project?.name}`">{{ project?.name }}</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              Health Score Metrics
            </li>
          </ol>
        </div>
        <div class="col-md-6 m-auto p-0">
          <Search
            :search.sync="search"
            :inputs="['name']"
          ></Search>
        </div>
      </div>
    </nav>
    <div class="row">
      <h1>Project Health Score: {{ healthScoreBy }}</h1>
    </div>
    <div class="row" v-if="filledProjectValues">
      <Empty v-if="!filledProjectValues.length" icon="fas fa-chart-pie" :can_create="true" @callCreate="selectedRecord = {}"/>
      <Card :key="index"
        v-for="(record, index) in filledProjectValues"
        :record="record"
        @on-new-item="onNewItem"
      ></Card>
    </div>
  </div>
</template>

<script>
import { gql } from 'apollo-boost';
import HEALTH_SCORE_CONFIGURATION_ITEMS_QUERY from '../../health_score/queries';
import HEALTH_SCORE_PROJECT_VALUES_QUERY from './queries';
import Card from './Card.vue';
import { Search } from '../../shared';
import { SearchBuilder, PaginationHandler } from '../../../shared';

export default {
  extends: PaginationHandler,
  components: { Card, Search },
  apollo: {
    project: {
      query: gql`query($id: ID!) { project(id: $id) { id name }}`,
      errorPolicy: 'all',
      variables() {
        return { id: this.projectId };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    healthScoreConfigurationItems: {
      query: HEALTH_SCORE_CONFIGURATION_ITEMS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    healthScoreProjectValues: {
      query: HEALTH_SCORE_PROJECT_VALUES_QUERY,
      errorPolicy: 'all',
      variables() {
        return { project_id: this.projectId };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      projectId: this.$route.params.id,
      search: {},
      selectedRecord: null,
    };
  },
  computed: {
    healthScoreBy() {
      return 100;
    },
    filledProjectValues() {
      if (!this.healthScoreConfigurationItems || !this.healthScoreProjectValues) {
        return [];
      }
      const projectValuesGrouped = _.groupBy(this.healthScoreProjectValues, 'health_score_configuration_item_id');

      return _.map(this.healthScoreConfigurationItems, (configItem) => {
        const latestProjectValue = _.chain(projectValuesGrouped[configItem.id] || [])
          .sortBy('id')
          .last()
          .value();
        if (latestProjectValue) {
          return { ...latestProjectValue, configItem };
        }

        return {
          user_id: _.get(window, 'User.user_id', null),
          project_id: this.projectId,
          configItem,
        };
      });
    },
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    onNewItem(healthScoreProjectValue) {
      this.healthScoreProjectValues.push(healthScoreProjectValue);
    },
  },
};
</script>
