<template>
  <div>
    <label for="item">
      <slot></slot>
    </label>
    <div class="mb-3 border rounded">
      <span
        :key="item.id"
        v-for="item in localRecord"
        class="badge bg-primary me-1 ms-1"
      >
        {{ item.name }}
        <i class="fas fa-times" @click="untag(item)"></i>
      </span>
      <input
        list="permissions"
        type="text"
        name="item"
        class="border-0 w-25"
        v-model="current_tag"
        @change="tag()"
      />
    </div>
    <datalist id="permissions" v-if="permissionDropDown">
      <option :key="permission.id" v-for="permission in permissionDropDown">
        {{ permission.name }}
      </option>
    </datalist>
  </div>
</template>

<script>
import { dropdownWithAlias } from '../../shared';

export default {
  apollo: {
    permissionDropDown: {
      query: dropdownWithAlias('permissionDropDown'),
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables() {
        return { type: 'Permission', orderBy: [{ column: 'id', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  props: ['selected'],
  data() {
    return {
      current_tag: '',
      localRecord: this.selected,
    };
  },
  methods: {
    tag() {
      const permission = this.permissionDropDown.find(
        (t) => t.name === this.current_tag,
      );
      if (permission) {
        this.localRecord.push({
          id: permission.id,
          name: permission.name,
        });
      }
      this.current_tag = '';
    },
    untag(item) {
      const index = this.localRecord.findIndex((e) => e === item);
      if (index >= 0) {
        this.$delete(this.localRecord, index);
      }
    },
  },
};
</script>
