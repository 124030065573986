<style scoped>
  .custom-warning {
    color: #ffc107;
  }
</style>
<template>
  <span class="fw-bold" :class="valueClass">{{value}}%</span>
</template>
<script>
export default {
  props: ['value', 'configuration'],
  computed: {
    greenMargin() {
      if (!this.configuration || this.configuration.length === 0) {
        return 40;
      }
      return this.configuration.find((c) => c.key === 'Gross Margin Per Resource Green').value;
    },
    yellowMargin() {
      if (!this.configuration || this.configuration.length === 0) {
        return 30;
      }
      return this.configuration.find((c) => c.key === 'Gross Margin Per Resource Yellow').value;
    },
    valueClass() {
      if (this.value >= this.greenMargin) {
        return 'text-success';
      }
      if (this.value >= this.yellowMargin) {
        return 'custom-warning';
      }
      return 'text-danger';
    },
  },
};
</script>
