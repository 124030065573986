import { gql } from 'apollo-boost';

export const CREATE_COST_PER_USER = gql`
mutation($input: CostPerUserInput!) {
  createCostPerUser(input: $input){
    id
    cost
    date
    created_at
  }
}`;

export const UPDATE_COST_PER_USER = gql`
mutation($id: ID!, $input: UpdateCostPerUserInput!) {
  updateCostPerUser(id: $id, input: $input){
    id
    cost
    date
    created_at
  }
}`;
