import { gql } from 'apollo-boost';

export default gql`
query($page: Int, $from_user_id: Int, $to_user_id: Int, $user_id: ID, $start_date: DateTime, $end_date: DateTime){
  GcoinsTransactions(page: $page, from_user_id: $from_user_id, to_user_id: $to_user_id, user_id: $user_id, start_date: $start_date, end_date: $end_date, orderBy: { column: "id", order: DESC }){
    paginatorInfo{
      hasMorePages
    }
    data {
      id
      type
      amount
      comment
      created_at
      from_user{
        id
        name
        avatar
      }
      to_user{
        id
        name
        avatar
      }
      product {
        id
      }
    }
  }
}
`;
