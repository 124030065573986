import { gql } from 'apollo-boost';
import PERFORMANCE_EVALUATION_CYCLE_FRAGMENT from './fragments';

export default gql`
  query($page: Int,
        $name: String,
        $orderBy: [OrderByClause!]) {
    performance_evaluation_cycles(
      page: $page,
      name: $name,
      orderBy: $orderBy) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...performance_evaluation_cycle
      }
    }
  }
  ${PERFORMANCE_EVALUATION_CYCLE_FRAGMENT}
`;
