<style scoped>
.chat-history {
  flex: 1;
}
.chat-input {
  flex: 1;
}
</style>
<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Ask Gacela (Beta)</SpinnerRouterLink>
          </div>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="d-flex flex-column">
        <div class="d-flex p-2">
          <input ref="question" v-focus v-model="query" v-on:keyup.enter="sendQuestion" type="text" class="d-flex me-2 chat-input form-control" placeholder="Type a message">
          <button class="btn btn-company-primary" @click="sendQuestion">
            <i v-if="!loading" class="fas fa-paper-plane"></i>
            <i v-if="loading" class="fas fa-sync ms-1 fa-sm fa-spin"></i>
            Send
          </button>
        </div>
        <div class="overflow-y-auto p-2 chat-history">
          <template v-for="(item) in history">
            <div :key="`q-${item.id}`" class="d-flex align-items-center py-2">
              <img :src="avatar" class="rounded-circle ms-3" width="30em">
              <div class="ms-2">{{item.question}}</div>
            </div>
            <div :key="`a-${item.id}`" v-if="item.answer" class="d-flex align-items-center py-2">
              <img :src="'/imgs/gacela.png'" class="round" width="53em">
              <div class="ms-2">{{item.answer}}</div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SpinnerRouterLink } from '../shared';
import AI_QUERY from './queries';

export default {
  components: { SpinnerRouterLink },
  data() {
    return {
      query: '',
      loading: false,
      history: [],
    };
  },
  computed: {
    avatar() {
      return window.User.avatar;
    },
  },
  methods: {
    sendQuestion() {
      this.loading = true;
      this.history.push({ question: this.query });
      this.$apollo.query({
        query: AI_QUERY,
        variables: { query: this.query },
      }).then(({ data }) => {
        const message = this.history.at(-1);
        Object.assign(message, data.AiQuery);
        this.loading = false;
        this.query = '';
        this.$refs.question.focus();
      }).catch(() => {
        this.loading = false;
      });
    },
  },
};
</script>
