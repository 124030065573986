<template>
  <div class="sortable" :id="internalId" @dblclick="$emit('hide')"></div>
</template>
<script>

import Highcharts from 'highcharts';
import loadHighchartsMore from 'highcharts/highcharts-more';
import { TOP_USER_SKILLS_QUERY } from './queries';

loadHighchartsMore(Highcharts);

export default {
  props: ['user_id'],
  apollo: {
    top_user_skills: {
      query: TOP_USER_SKILLS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { user_id: this.user_id, limit: 8 };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  watch: {
    top_user_skills() {
      if (this.top_user_skills.length > 0) {
        this.drawChart();
      }
    },
  },
  computed: {
    internalId() {
      return `chart_${this.user_id}`;
    },
    categories() {
      return _.map(this.top_user_skills, 'name');
    },
    series() {
      return [{
        name: 'Tech Skills',
        data: _.map(this.top_user_skills, 'frequency'),
        pointPlacement: 'on',
      }];
    },
  },
  methods: {
    drawChart() {
      Highcharts.chart(this.internalId, {
        chart: {
          polar: true,
          type: 'line',
          height: '100%',
        },
        title: {
          text: '',
        },
        pane: {
          size: '80%',
        },
        xAxis: {
          categories: this.categories,
          tickmarkPlacement: 'on',
          lineWidth: 0,
        },
        yAxis: {
          gridLineInterpolation: 'polygon',
          lineWidth: 0,
          min: 0,
        },
        tooltip: {
          shared: true,
          pointFormat: '<span style="color:{series.color}">{series.name}: <b>{point.y}</b><br/>',
        },
        legend: {
          enabled: false,
        },
        series: this.series,
        responsive: {
          rules: [{
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              pane: {
                size: '70%',
              },
            },
          }],
        },
      });
    },
  },
};
</script>
