<template>
  <div class="card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Mapping</span>
        <span v-if="record.id">{{localRecord.model}}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-3">
        <label for="model">Model</label>
        <select id="model" :class="{'is-invalid': v$.localRecord.model.$invalid}" v-model="localRecord.model" class="form-control form-select">
          <option :key="value" v-for="value in models" :value="value">{{value}}</option>
        </select>
        <div v-for="error of v$.localRecord.model.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="internal_id">Internal Id</label>
        <input type="text" class="form-control" :class="{'is-invalid': v$.localRecord.internal_id.$invalid}" id="internal_id" v-model="localRecord.internal_id">
        <div v-for="error of v$.localRecord.internal_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="external_id">External Id</label>
        <input type="text" class="form-control" :class="{'is-invalid': v$.localRecord.external_id.$invalid}" id="phoexternal_idne" v-model="localRecord.external_id">
        <div v-for="error of v$.localRecord.external_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="card-body d-grid pt-0">
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>

import Validations from './Validations';
import Blinkable from '../../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  props: ['record', 'models'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
