import { gql } from 'apollo-boost';
import INTERVIEW_QUESTION_FRAGMENT from './fragments';

export default gql`
query($page: Int!,
      $technology_id: ID,
      $difficulty: String,
      $orderBy: [OrderByClause!]) {
  interviewQuestions(
    page: $page,
    technology_id: $technology_id,
    difficulty: $difficulty,
    orderBy: $orderBy
  ){
    paginatorInfo {
      hasMorePages
    }
    data{
      ...interviewQuestion
    }
  }
}
${INTERVIEW_QUESTION_FRAGMENT}
`;
