import { gql } from 'apollo-boost';

export default gql`query($uPage: Int, $dPage: Int, $rPage: Int, $orderBy: [OrderByClause!]) {
  users(orderBy: $orderBy, page: $uPage) {
    data {
      id
      name
      email
      is_active
      created_at_diff
    }
  }
  white_list_domains(orderBy: $orderBy, page: $dPage) {
    data {
      id
      domain
    }
  }
  login_requests(orderBy: $orderBy, page: $rPage) {
    data {
      id
      email
      created_at_diff
    }
  }
}`;
