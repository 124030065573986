import Vue from 'vue';

const bsToast = {
  delay: 3000,
  htmlToElement: (html) => {
    const template = document.createElement('template');
    html = html.trim();
    template.innerHTML = html;
    return template.content.firstChild;
  },
  show: (message, color) => {
    color = color ?? 'info';
    const html = `<div class="toast rounded-pill align-items-center mt-1 px-1 text-white bg-${color} border-0" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex justify-content-center">
            <div class="toast-body">
                <div>${message}</div>
            </div>
        </div>
    </div>`;
    const toastContainerElement = document.getElementById('toast-container');
    const toastElement = bsToast.htmlToElement(html);
    toastContainerElement.appendChild(toastElement);
    const toast = new window.bootstrap.Toast(toastElement, {
      delay: bsToast.delay,
      animation: true,
    });
    toast.show();
    setTimeout(() => toastElement.remove(), bsToast.delay + 1000); // let a certain margin to allow the "hiding toast animation"
  },
  error: (message) => {
    bsToast.show(message, 'danger');
  },
  success: (message) => {
    bsToast.show(message, 'success');
  },
};

Vue.prototype.$toasted = bsToast;
