<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row mb-3">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">People Allocation Per Project</SpinnerRouterLink>
          </div>
        </div>
        <div class="col-md-10 m-auto p-0" v-if="peopleAllocationPerProject">
          <div class="float-end">
            <div class="d-flex pb-1">
              <span>
                <i class="fas fa-circle text-success"></i>
                <strong>{{green}}</strong>
                Allocated for more than {{ status.green }} week{{ (status.green != 1) ? 's' : '' }}.
              </span>
            </div>
            <div class="d-flex pb-1">
              <span>
                <i class="fas fa-circle text-danger"></i>
                <strong>{{red}}</strong>
                Allocated for less than {{ status.red }} week{{ (status.red != 1) ? 's' : '' }}.
              </span>
            </div>
            <div class="d-flex">
              <span>
                <i class="fas fa-circle text-warning"></i>
                <strong>{{yellow}}</strong>
                Allocated for less than {{ status.green }} week{{ (status.green != 1) ? 's' : '' }} & more than {{ status.red }} week{{ (status.red != 1) ? 's' : '' }}.
              </span>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="peopleAllocationPerProject">
      <div class="col-md-12 text-nowrap scroll-x h-100">
        <table class="table">
          <tr>
            <th class="d-table-cell">#</th>
            <SortByColumn class="d-table-cell" :sort_by_field="'person'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
            <SortByColumn class="d-table-cell" :sort_by_field="'project'" :orderBy="orderBy" @refresh="refresh">Project</SortByColumn>
            <SortByColumn class="d-table-cell" :sort_by_field="'type'" :orderBy="orderBy" @refresh="refresh">Project Type</SortByColumn>
            <SortByColumn class="d-table-cell" :sort_by_field="'end_date'" :orderBy="orderBy" @refresh="refresh">End Date</SortByColumn>
          </tr>
          <tr :key="index" v-for="(row, index) in peopleAllocationPerProject">
            <td>{{index + 1}}</td>
            <td>{{row.person}}</td>
            <td>{{row.project}}</td>
            <td>{{row.type}}</td>
            <td>
              <i class="fas fa-circle" :class="classByStatus(row.status)"></i>
              {{$displayDate(row.end_date)}}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <Empty v-if="!peopleAllocationPerProject?.length" icon="fas fa-user-friends"/>
  </div>
</template>

<script>

import PEOPLE_ALLOCATION_PER_PROJECT from './queries';
import { CONFIGURATION } from '../../configuration/queries';
import { SortByColumn, SpinnerRouterLink } from '../../shared';

export default {
  components: { SortByColumn, SpinnerRouterLink },
  data() {
    return {
      orderBy: { column: 'end_date', order: 'ASC' },
    };
  },
  computed: {
    red() {
      return _.filter(this.peopleAllocationPerProject, ['status', 'red']).length;
    },
    yellow() {
      return _.filter(this.peopleAllocationPerProject, ['status', 'yellow']).length;
    },
    green() {
      return _.filter(this.peopleAllocationPerProject, ['status', 'green']).length;
    },
    status() {
      return this.configuration ? {
        green: _.find(this.configuration, { key: 'Allocation Threshold Green' }).value,
        red: _.find(this.configuration, { key: 'Allocation Threshold Red' }).value,
      } : { };
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.peopleAllocationPerProject.refetch();
    },
    classByStatus(status) {
      const cssClasses = { red: 'text-danger', yellow: 'text-warning', green: 'text-success' };
      return cssClasses[status] || '';
    },
  },
  apollo: {
    peopleAllocationPerProject: {
      query: PEOPLE_ALLOCATION_PER_PROJECT,
      errorPolicy: 'all',
      variables() {
        return { orderBy: [this.orderBy] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    configuration: {
      query: CONFIGURATION,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
