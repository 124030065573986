export default class RequestBuilder {
  static build(record) {
    const keys = [
      'user_id', 'performance_evaluation_cycle_id', 'evaluator_id', 'status',
      'nine_box_potential_score', 'nine_box_performance_score', 'notes', 'performance_evaluation_form_id',
    ];
    const requestBody = _.pick(record, keys);
    requestBody.nine_box_potential_score = parseFloat(requestBody.nine_box_potential_score);
    requestBody.nine_box_performance_score = parseFloat(requestBody.nine_box_performance_score);
    requestBody.goals = {
      delete: (record.goals || []).filter((goal) => goal.deleted).map((goal) => goal.id),
      upsert: (record.goals || []).filter((goal) => !goal.id)
        .map((goal) => ({
          performance_evaluation_id: goal.performance_evaluation_id,
          name: goal.name,
          is_completed: goal.is_completed,
          deadline: goal.deadline,
          notes: goal.notes,
        }))
        .concat(
          (record.goals || []).filter((goal) => goal.id)
            .map((goal) => ({
              id: goal.id,
              performance_evaluation_id: goal.performance_evaluation_id,
              name: goal.name,
              is_completed: goal.is_completed,
              deadline: goal.deadline,
              notes: goal.notes,
            })),
        ),
    };
    requestBody.answers = {
      delete: (record.form.questions || []).filter((question) => question.answers.length > 0 && question.answers[0].id && question.answers[0].score === 'n/a')
        .map((question) => question.answers[0].id),
      upsert: (record.form.questions || []).filter((question) => question.answers.length > 0 && !question.answers[0].id && question.answers[0].score !== 'n/a')
        .map((question) => ({
          score: question.answers[0].score,
          performance_evaluation_form_question_id: question.id,
        })).concat(
          (record.form.questions || []).filter((question) => question.answers.length > 0 && question.answers[0].id && question.answers[0].score !== 'n/a')
            .map((question) => ({
              id: question.answers[0].id,
              performance_evaluation_form_question_id: question.id,
              score: question.answers[0].score,
            })),
        ),
    };
    return requestBody;
  }
}
