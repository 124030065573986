<template>
  <div class="d-flex flex-column justify-content-center align-items-center mt-5">
    <div class="form-group text-center m-0">
      <h3 class="h2"><b>Create the Administrator User</b></h3>
    </div>
    <div style="width: 24rem; max-width: 99vw"><br>
      <div class="form-group">
        <i class="h5 fas fa-user ms-xxl-3 pt-xxl-2 mt-xxl-1 position-absolute" :class="{'text-danger': v$.admin.name.$invalid}"></i>
        <input v-focus type="text" placeholder="Admin User Name" class="fs-5 form-switch form-control" :class="{'is-invalid': v$.admin.name.$invalid}" v-model="admin.name">
        <div v-for="error of v$.admin.name.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="form-group">
        <i class="fas fa-envelope ms-xxl-3 pt-xxl-2 mt-xxl-1 position-absolute" :class="{'text-danger': v$.admin.email.$invalid}"></i>
        <input type="email" placeholder="Admin Email" class="fs-5 form-switch form-control" :class="{'is-invalid': v$.admin.email.$invalid}" v-model="admin.email" required>
        <div v-for="error of v$.admin.email.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="form-group">
        <i class="fas fa-lock ms-xxl-3 pt-xxl-2 mt-xxl-1 position-absolute" :class="{'text-danger': v$.admin.password.$invalid}"></i>
          <input type="password" placeholder="Admin Password" class="fs-5 form-switch form-control" :class="{'is-invalid': v$.admin.password.$invalid}" v-model="admin.password">
          <div v-for="error of v$.admin.password.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
      </div>
      <div class="form-group">
        <i class="fas fa-lock ms-xxl-3 pt-xxl-2 mt-xxl-1 position-absolute" :class="{'text-danger': v$.password_confirmation.$invalid}"></i>
          <input type="password" placeholder="Password Verification" class="fs-5 form-switch form-control" :class="{'is-invalid': v$.password_confirmation.$invalid}" v-model="password_confirmation">
          <div v-for="error of v$.password_confirmation.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
      </div>
      <div class="form-group">
        <div class="d-flex justify-content-center">
          <vue-recaptcha :sitekey="sitekey"
            @verify="setToken"
            @expired="() => { recaptcha = 'Session expired'; recaptcha_token = null; }"
            @render="() => null"
            @error="recaptcha = 'Something went wrong'"
          ></vue-recaptcha>
        </div>
        <div class="invalid-feedback" :class="{'d-block': typeof recaptcha === 'string'}">{{recaptcha}}</div>
      </div>
      <div class="row w-100 mx-auto">
        <button :disabled="v$.invalid || recaptcha != true" type="button" class="fs-5 btn btn-company-primary btn-block mt-3" @click="goTo(1)">
          Register my company
        </button>
        <button type="button" class="fs-5 btn btn-company-primary btn-block mt-3" @click="goTo(-1)">
          Back
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { useVuelidate } from '@vuelidate/core';
import {
  required, email, maxLength, sameAs,
} from '@vuelidate/validators';
import { VueRecaptcha } from 'vue-recaptcha';
import { RECAPTCHA_KEY } from '../../../env';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      password_confirmation: { required, sameAsPassword: sameAs(this.admin.password) },
      admin: {
        name: { required },
        password: { required },
        email: { required, email },
      },
      localRecord: {
        name: { required, maxLengthValue: maxLength(255) },
      },
    };
  },
  props: ['admin'],
  components: { VueRecaptcha },
  data() {
    return {
      password_confirmation: null,
      recaptcha_token: null,
      recaptcha: false,
      sitekey: RECAPTCHA_KEY,
    };
  },
  methods: {
    setToken(token) {
      this.recaptcha_token = token;
      this.recaptcha = true;
    },
    goTo($move) {
      this.$emit('goTo', $move, this.recaptcha_token);
    },
  },
};
</script>
