<template>
  <div>
    <Form v-if="selectedRecord" :record.sync="selectedRecord"
                                        @close="selectedRecord = null"
                                        @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Performance Evaluation Cycles</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6 m-auto mt-2">
              <Search
                :search.sync="search"
                :inputs="['name']"
              ></Search>
            </div>
            <div class="col-12 col-sm-6 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = {}" v-if="$can('CreatePerformanceEvaluationCycle')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="performance_evaluation_cycles">
      <Empty v-if="!performance_evaluation_cycles.data.length" icon="fa-solid fa-arrows-spin" :can_create="true" @callCreate="selectedRecord = {}"/>
      <Card :key="index" v-for="(record, index) in performance_evaluation_cycles.data" :record="record" @remove="remove"></Card>
    </div>
    <SkeletonCards
      v-if="performance_evaluation_cycles && performance_evaluation_cycles.paginatorInfo.hasMorePages"
      :cards=4
      :executeScroll="performance_evaluation_cycles != undefined"
      @showMore="showMore('performance_evaluation_cycles')"
    ></SkeletonCards>
  </div>
</template>

<script>
import PERFORMANCE_EVALUATION_CYCLES_QUERY from './queries';
import { DELETE_PERFORMANCE_EVALUATION_CYCLE, CREATE_PERFORMANCE_EVALUATION_CYCLE } from './mutations';
import { Search, SpinnerRouterLink, SkeletonCards } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';
import Form from './Form.vue';
import Card from './Card.vue';

export default {
  extends: PaginationHandler,
  components: {
    Search, SpinnerRouterLink, SkeletonCards, Form, Card,
  },
  apollo: {
    performance_evaluation_cycles: {
      query: PERFORMANCE_EVALUATION_CYCLES_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    remove(performanceEvaluationCyle) {
      this.$apollo.mutate({
        mutation: DELETE_PERFORMANCE_EVALUATION_CYCLE,
        variables: {
          id: performanceEvaluationCyle.id,
        },
        update: (store, { data: { deletePerformanceEvaluationCycle } }) => {
          const data = store.readQuery({ query: PERFORMANCE_EVALUATION_CYCLES_QUERY, variables: { page: 1, ...this.queryVariables() } });
          data.performance_evaluation_cycles.data = data.performance_evaluation_cycles.data.filter((v) => v.id !== deletePerformanceEvaluationCycle.id);
          store.writeQuery({ query: PERFORMANCE_EVALUATION_CYCLES_QUERY, variables: { page: 1, ...this.queryVariables() }, data });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(record) {
      // We save the user input in case of an error
      const newCycle = record;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_PERFORMANCE_EVALUATION_CYCLE,
        variables: {
          performance_evaluation_cycle: record,
        },
        update: (store, { data: { createPerformanceEvaluationCycle } }) => {
          const data = store.readQuery({ query: PERFORMANCE_EVALUATION_CYCLES_QUERY, variables: { page: 1, ...this.queryVariables() } });
          data.performance_evaluation_cycles.data.unshift(createPerformanceEvaluationCycle);
          store.writeQuery({ query: PERFORMANCE_EVALUATION_CYCLES_QUERY, variables: { page: 1, ...this.queryVariables() }, data });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.selectedRecord = newCycle;
      });
    },
  },
};
</script>
