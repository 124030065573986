import { gql } from 'apollo-boost';

export const HOURS_PER_PROJECT = gql`
query($start_date: DateTime,
      $end_date: DateTime,
      $user_id: ID,
      $project_id: ID,
      $only_my_projects: Boolean,
      $orderBy: [OrderByClause!]) {
    hoursPerProject(start_date: $start_date,
                    end_date: $end_date,
                    user_id: $user_id,
                    project_id: $project_id,
                    only_my_projects: $only_my_projects,
                    orderBy: $orderBy) {
      person
      project
      type
      hours
      user_id
      project_id
    }
  }
`;

export const HOURS_PER_PROJECT_WITH_COSTS = gql`
query($start_date: DateTime,
      $end_date: DateTime,
      $user_id: ID,
      $project_id: ID,
      $only_my_projects: Boolean,
      $orderBy: [OrderByClause!]) {
    hoursPerProject(start_date: $start_date,
                    end_date: $end_date,
                    user_id: $user_id,
                    project_id: $project_id,
                    only_my_projects: $only_my_projects,
                    orderBy: $orderBy) {
      person
      project
      type
      hours
      costs
      user_id
      project_id
    }
  }
`;
