<template>
  <div class="container-fluid">
    <ul class="nav nav-pills mb-2">
      <li class="nav-item">
        <a class="nav-link active" id="resume-tab" data-bs-toggle="tab" href="#resume" role="tab" aria-controls="resume" aria-selected="true">Resume</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="insights-tab" data-bs-toggle="tab" href="#insights" role="tab" aria-controls="insights" aria-selected="false">Insights</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent" v-if="user">
      <div class="tab-pane fade show active" id="resume" role="tabpanel" aria-labelledby="resume-tab">
        <Resume :user="user" :projectMembers="projectMembers"></Resume>
      </div>
      <div class="tab-pane fade" id="insights" role="tabpanel" aria-labelledby="insights-tab">
        <Insights :user="user"></Insights>
      </div>
    </div>
  </div>
</template>

<script>
import Resume from './Resume.vue';
import Insights from './Insights.vue';

import { USER_DETAIL, PROJECT_MEMBERS } from '../queries';

export default {
  components: { Resume, Insights },
  apollo: {
    user: {
      query: USER_DETAIL,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    projectMembers: {
      query: PROJECT_MEMBERS,
      errorPolicy: 'all',
      variables() {
        return { user_id: this.$route.params.id, orderBy: [{ column: 'start_date', order: 'DESC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
