import { gql } from 'apollo-boost';

export default gql`
  query($id: ID!, $entity_indicator_name: EntityIndicatorType!, $start_date: DateTime, $end_date: DateTime, $orderBy: [OrderByClause!]) {
    user(id: $id) {
      name
      indicators(entity_indicator_name: $entity_indicator_name, start_date: $start_date, end_date: $end_date, orderBy: $orderBy) {
        id
        prefix
        suffix
        value
        created_at
      }
    }
  }
`;
