<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Cost Per Position</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search :search.sync="search"
                      :inputs="['countries', 'jobTitles']"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="col-md-12 text-center">
        <div id="chart"></div>
      </div>
    </div>
    <div class="row align-items-center" v-if="!$apollo.loading">
      <div class="col-md-12 text-center text-body-secondary">
        <p>
          <i class="fa-solid fa-lightbulb"></i>
          The cost
          <span v-if="search.job_title_id"> for a {{selectedPosition}}</span>
          <span v-else> for an engineer</span>
          <span v-if="search.country_id"> in {{selectecCountry}}</span>
          ranges between {{range}}
        </p>
      </div>
    </div>
  </div>
</template>

<script>

import Highcharts from 'highcharts';
import COSTS_BY_JOB_TITLE from '../margin_calculator/queries';
import { SpinnerRouterLink, Search } from '../shared';
import { SearchBuilder } from '../../shared';

export default {
  components: { SpinnerRouterLink, Search },
  data() {
    return {
      search: {},
    };
  },
  watch: {
    costsByJobTitle() {
      this.drawChart();
    },
  },
  apollo: {
    costsByJobTitle: {
      query: COSTS_BY_JOB_TITLE,
      errorPolicy: 'all',
      variables() {
        return {
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.show(error.message);
      },
    },
  },
  computed: {
    selectecCountry() {
      if (this.search.country_id) {
        return document.querySelector('#select_country_id .selected').textContent;
      }
      return '';
    },
    selectedPosition() {
      if (this.search.job_title_id) {
        return document.querySelector('#select_job_title_id .selected').textContent;
      }
      return '';
    },
    range() {
      const costs = this.costsByJobTitle.map((row) => row.cost);
      const max = Math.round((_.max(costs) || 0) * 166);
      const min = Math.round((_.min(costs) || 0) * 166);
      return `$${min} and $${max}`;
    },
    chartData() {
      return this.costsByJobTitle.map((row) => [row.user_name, Math.round(row.cost * 166)]);
    },
    dataLabels() {
      const options = {
        enabled: true,
        /* eslint-disable no-template-curly-in-string */
        format: '${y}',
        color: '#ffffff',
        y: 20,
        style: {
          fontFamily: 'Verdana, sans-serif',
        },
      };
      if (this.chartData.length > 10) {
        options.rotation = -90;
        options.align = 'right';
        options.y = 10;
      }
      return options;
    },
  },
  methods: {
    drawChart() {
      Highcharts.chart('chart', {
        colors: ['#072532'],
        chart: {
          type: 'column',
        },
        title: {
          text: 'Costs Per Position',
        },
        xAxis: {
          type: 'category',
          labels: {
            rotation: this.chartData.length > 10 ? -45 : 0,
          },
          lineWidth: 0,
        },
        yAxis: {
          min: 0,
          title: {
            text: 'Cost per month',
          },
        },
        legend: {
          enabled: false,
        },
        series: [{
          name: 'Dollar per hour',
          data: this.chartData,
        }],
        plotOptions: {
          column: {
            dataLabels: this.dataLabels,
          },
        },
        tooltip: {
          formatter() {
            return `${this.key}<br><b>$${Math.round(this.y / 166)}</b> / hour`;
          },
        },
      });
    },
  },
};
</script>
