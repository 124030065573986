<template>
  <div class="modal" tabindex="-1" role="dialog" :id="id">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header" v-if="!hide_header">
          <slot name="header">
            <h5 class="modal-title text-dark">{{ title }}</h5>
            <button type="button" class="btn-close" aria-label="Close" @click="close()">
            </button>
          </slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer" v-if="!hide_footer">
          <slot name="footer">
            <button type="button" class="btn btn-company-primary" :id="`${id}-accept`" @click="accept()">OK</button>
            <button type="button" class="btn btn-secondary" :id="`${id}-close`" @click="close()">Close</button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    id: Number,
    title: String,
    hide_header: Boolean,
    hide_footer: Boolean,
  },
  computed: {
    modalInstance() {
      return new window.bootstrap.Modal(document.getElementById(this.id));
    },
  },
  methods: {
    show() {
      const self = this;
      this.modalInstance.show();
      return new Promise((resolve) => {
        const acceptBtn = document.getElementById(`${self.id}-accept`);
        if (acceptBtn) {
          acceptBtn.addEventListener('click', () => { resolve(true); });
        }
        const closeBtn = document.getElementById(`${self.id}-close`);
        if (closeBtn) {
          closeBtn.addEventListener('click', () => { resolve(false); });
        }
      });
    },
    close() {
      this.modalInstance.hide();
    },
    accept() {
      this.$emit('accept');
      this.modalInstance.hide();
    },
  },
};
</script>
