import { gql } from 'apollo-boost';

export default gql`
  fragment performance_evaluation on PerformanceEvaluation {
    id
    status
    nine_box_potential_score
    nine_box_performance_score
    user_id
    evaluator_id
    performance_evaluation_cycle_id
    performance_evaluation_form_id
    notes
    created_at
    cycle {
      id
      name
    }
    user {
      id
      name
      avatar
    }
    evaluator {
      id
      name
      avatar
    }
    goals {
      id
      name
      deadline
      is_completed
      notes
    }
    form @include(if: $include_forms) {
      id
      name
      questions {
        id
        order
        name
        answers(performance_evaluation_id: $id) {
          id
          score
        }
      }
    }
  }
`;
