<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord"
                                      @close="selectedRecord = null"
                                      @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Project Actions</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6 m-auto">
            </div>
            <div class="col-12 col-sm-6 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="newRecord" v-if="$can('CreateBusinessUnit')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="actionsByProject">
      <Card :key="record.id" v-for="record in actionsByProject.data" :record="record" @refresh="refresh"></Card>
      <Empty v-if="!actionsByProject.data.length" icon="fas fa-project-diagram" :can_create="true" @callCreate="newRecord"/>
    </div>
    <SkeletonCards v-if="actionsByProject && actionsByProject.paginatorInfo.hasMorePages" :cards=4 :executeScroll="actionsByProject != undefined" @showMore="showMore('actionsByProject')"></SkeletonCards>
  </div>
</template>
<script>
import Form from './Form.vue';
import Card from './Card.vue';
import ACTIONS from './queries';
import { SkeletonCards, SpinnerRouterLink } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';
import { CREATE_ACTIONS } from './mutations';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, SkeletonCards, SpinnerRouterLink,
  },
  apollo: {
    actionsByProject: {
      query: ACTIONS,
      errorPolicy: 'all',
      variables() {
        const variables = {
          project_id: this.$route.params.id,
          status: 'pending',
          orderBy: [{ column: 'created_at', order: 'DESC' }],
        };
        if (this.$route.query.project_minute_id) {
          variables.project_minute_id = this.$route.query.project_minute_id;
        }
        if (this.$route.query.project_risk_id) {
          variables.project_risk_id = this.$route.query.project_risk_id;
        }
        return variables;
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    newRecord() {
      this.selectedRecord = {
        project_id: this.$route.params.id,
        status: 'pending',
        name: '',
      };
    },
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    refresh() {
      this.$apollo.queries.actionsByProject.refetch();
    },
    insert(record) {
      // We save the user input in case of an error
      const newRecord = record;
      this.selectedRecord = { project_id: this.$route.params.id };
      this.$apollo.mutate({
        mutation: CREATE_ACTIONS,
        variables: {
          input: record,
        },
      }).then((response) => {
        this.selectedRecord = null;
        this.actionsByProject.data.unshift(response.data.createAction);
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        // We restore the initial user input
        this.selectedRecord = newRecord;
      });
    },
  },
};
</script>
