<template>
  <div class="d-flex justify-content-center m-md-4">
    <div class="card w-100">
      <div class="card-header d-flex pe-2 justify-content-between">
        <div class="p-0">
          <span class="h3">Configuration</span>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col order-last text-end">
              <button type="button" class="btn btn-company-primary" @click="update()" :disabled="!configuration || loading">
                <span v-if="loading" class="spinner-border spinner-border-sm"></span>
                <i v-else class="fas fa-save"></i>
                Sav{{ loading ? 'ing' : 'e' }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body overflow-auto h-100" v-if="configuration">
        <template v-for="row in configuration">
          <div :key="row.id" class="row">
            <div class="col-2">
              {{row.key}}
            </div>
            <div class="col-6">
              <input class="form-control" type="text" v-model="row.value" :class="{'is-invalid': !row.value}">
              <div v-if="!row.value" class="text-danger">Value is required</div>
            </div>
            <div class="col-4">
              {{row.placeholder}}
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { ErrorHandler } from '../../shared';
import UPDATE_COMPANY_CONFIGURATION from './mutations';
import { COMPANY_CONFIGURATION } from './queries';

export default {
  data() { return { loading: false }; },
  apollo: {
    configuration: {
      query: COMPANY_CONFIGURATION,
      errorPolicy: 'all',
      variables: {
        orderBy: [{ column: 'id', order: 'ASC' }],
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    recordGroups() {
      return Object.keys(this.record);
    },
  },
  methods: {
    update() {
      this.loading = true;
      this.$apollo
        .mutate({
          mutation: UPDATE_COMPANY_CONFIGURATION,
          variables: {
            input: this.configuration.map((row) => ({
              id: row.id,
              value: row.value,
            })),
          },
        }).then(() => {
          this.$toasted.success('Configuration Saved!');
          this.loading = false;
        }).catch((error) => {
          ErrorHandler.handle(error, this);
          this.loading = false;
        });
    },
  },
};
</script>
