import { gql } from 'apollo-boost';

export default gql`
  fragment equipment on Equipment {
    id
    acquired_date
    type
    location
    state
    serial_number
    internal_key
    model
    name
    user_id
    acquired_date_diff
    user {
      id
      name
    }
  }
`;
