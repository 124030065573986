import { computed, ref } from 'vue';
import WeekManager from './WeekManager';

export default class {
  constructor() {
    this.records = ref([]);
    this.savedHours = computed(() => _.sumBy(this.records, (o) => o.savedHours));
    this.draftHours = computed(() => _.sumBy(this.records, (o) => o.draftHours));
    this.isLoading = false;
  }

  next(records) {
    this.records = records.map((record) => {
      record.isSaving = false;
      record.savedHours = _.sumBy(record.logs, (o) => o.hours);
      record.draftHours = computed(() => {
        if (record.has_weekends) return _.sumBy(record.logs, (o) => o.hours);
        const logs = record.logs.filter((o) => !WeekManager.isWeekend(o.created_at));
        return _.sumBy(logs, (o) => o.hours);
      });
      return record;
    });
  }

  update(index, logs) {
    this.records[index].logs = logs;
    this.records[index].isSaving = false;
    this.records[index].savedHours = _.sumBy(
      this.records[index].logs,
      (o) => o.hours,
    );
  }
}
