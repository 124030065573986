<template>
<div class="col-md-12 pb-2">
  <div class="card h-100">
    <div class="row g-0">
      <div class="col-md-1 d-flex align-items-center justify-content-center">
        <span class="h1" style="color: #F0BB62;">
          {{record.type === 'Credit' ? '+' : '-'}}
          <img :src="'/imgs/gcoin_symbol.svg'" class="pb-xxl-2" height="40px">
          {{record.amount}}
        </span>
      </div>
      <div class="col-md-11">
        <div class="card-body">
          <h5 class="card-title" v-if="record.from_user">
            <img width="20px" class="rounded-circle" :src="record.from_user.avatar"> {{record.from_user.name}} gives <img :src="'/imgs/gcoin_symbol.svg'" height="15px">{{record.amount}} to <img class="rounded-circle" width="20px" :src="record.to_user.avatar"> {{record.to_user.name}}
          </h5>
          <h5 class="card-title" v-if="record.product">
            <img width="20px" class="rounded-circle" :src="record.to_user.avatar"> {{record.to_user.name}}.
          </h5>

          <blockquote class="blockquote pt-2">
            <p>{{record.comment}}</p>
            <footer v-if="record.from_user" class="blockquote-footer">{{record.from_user.name}}</footer>
          </blockquote>
          <p class="card-text"><small class="text-body-secondary">{{timeAgo}}</small></p>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import moment from 'moment';

export default {
  props: ['record'],
  computed: {
    timeAgo() {
      return moment(this.record.created_at, 'YYYY-MM-DD').endOf('day').fromNow();
    },
  },
};
</script>
