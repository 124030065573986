<template>
  <tr>
    <template v-if="!is_editting">
      <td>
        <del v-if="localRecord.end_date">{{localRecord.user.name}}</del>
        <span v-else>
          {{localRecord.user.name}}
          <i v-if="localRecord.is_manager" title="This person manages the project" class="fa-regular fa-person-chalkboard"></i>
        </span>
      </td>
      <td>
        <del v-if="localRecord.end_date">{{localRecord.project_role.name}}</del>
        <span v-else>{{localRecord.project_role.name}}</span>
      </td>
      <td>
        {{localRecord.hours_per_week}} hours / week
      </td>
      <td>
        <small class="text-body-secondary">({{localRecord.start_date}} - {{localRecord.end_date ? localRecord.end_date : 'present'}})</small>
      </td>
      <td>
        <button class="btn btn-warning" title="Edit member" @click="is_editting=true">
          <i class="fas fa-edit"></i>
        </button>
        <button class="btn btn-company-secondary" title="Remove member" @click="removeMember()">
          <i class="fas fa-trash"></i>
        </button>
        <button class="btn btn-secondary" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i class="fa-solid fa-ellipsis"></i>
        </button>
        <div class="dropdown-menu">
          <a v-if="!localRecord.is_manager" class="dropdown-item sortable" title="Abandon project" @click="toggleIsManager">
            <i class="fa-regular fa-person-chalkboard"></i>
            Turn into manager
          </a>
          <a v-if="localRecord.is_manager" class="dropdown-item sortable" title="Abandon project" @click="toggleIsManager">
            <i class="fa-regular fa-person-chalkboard"></i>
            Turn into member
          </a>
          <a v-if="!localRecord.end_date" class="dropdown-item sortable" title="Abandon project" @click="abandonProject">
            <i class="fas fa-sign-out-alt"></i>
            Abandon project
          </a>
          <a v-if="!localRecord.end_date" class="dropdown-item sortable" title="Undo" @click="extendContractEndDate">
            <i class="fa-solid fa-left-right"></i>
            Extend Contract
          </a>
          <a v-if="localRecord.end_date" class="dropdown-item sortable" title="Undo" @click="abandonProject">
            <i class="fas fa-undo"></i>
            Undo
          </a>
        </div>
      </td>
    </template>
    <template v-else>
      <td>
        <del v-if="localRecord.end_date">{{localRecord.user.name}}</del>
        <span v-else>{{localRecord.user.name}}</span>
      </td>
      <td>
        <select class="form-control form-select me-sm-2" v-model="localRecord.project_role_id">
          <template v-if="projectRolesDropDown">
            <option :key="`${localRecord.id}-${projectRole.id}`" v-for="projectRole in projectRolesDropDown" :value="projectRole.id">
              {{projectRole.name}}
            </option>
          </template>
        </select>
      </td>
      <td>
        <input type="number" class="form-control rounded-0" placeholder="Expected hours per week" v-model="localRecord.hours_per_week"/>
      </td>
      <td>
        <small class="text-body-secondary">({{localRecord.start_date}} - {{localRecord.end_date ? localRecord.end_date : 'present'}})</small>
      </td>
      <td>
        <button v-if="is_editting" class="btn btn-company-secondary" @click="is_editting = false">
          <i class="fas fa-times"></i>
          Cancel
        </button>
        <button v-if="is_editting" class="btn btn-company-primary" @click="edit()">
          <i class="fas fa-check"></i>
          Accept
        </button>
      </td>
    </template>
  </tr>
</template>

<script>

export default {
  props: ['member', 'projectRolesDropDown'],
  data() {
    return {
      is_editting: false,
    };
  },
  computed: {
    localRecord() {
      return { ...this.member };
    },
  },
  methods: {
    edit() {
      this.localRecord.updated = true;
      // eslint-disable-next-line
      this.localRecord.project_role = this.projectRolesDropDown.find((pr) => pr.id == this.localRecord.project_role_id);
      this.$emit('edit', this.localRecord);
      this.is_editting = false;
    },
    toggleIsManager() {
      this.localRecord.updated = true;
      this.localRecord.is_manager = !this.localRecord.is_manager;
      this.$emit('edit', this.localRecord);
    },
    removeMember() {
      this.$emit('removeMember', this.member);
    },
    abandonProject() {
      this.$emit('abandonProject', this.member);
    },
    extendContractEndDate() {
      this.$emit('extendContractEndDate', this.member);
    },
  },
};
</script>
