<template>
  <div class="position-relative">
    <div class="breadcrumb p-2">
      <div class="row">
        <div class="col-auto ms-3 p-0">
          <SpinnerRouterLink :loading="$apollo.loading">Company Distribution</SpinnerRouterLink>
        </div>
      </div>
    </div>
    <div class="position-absolute globe-tabs z-1 mt-3">
      <span class="h4 card p-2 ms-3 text-center">Distribution By</span>
      <button class="btn p-2 ms-3" :class="{'btn-company-primary': showing === 'clients', 'btn-light': showing !== 'clients'}" @click="showing = 'clients'">
        <span class="h4 m-0">Clients</span>
      </button>
      <button class="btn p-2 ms-1" :class="{'btn-company-primary': showing === 'users', 'btn-light': showing !== 'users'}" @click="showing = 'users'">
        <span class="h4 m-0">Users</span>
      </button>
    </div>
    <Globe :record="count_per_country" :showing="showing"/>
  </div>
</template>

<script>
import COUNTS from './queries';
import Globe from './Globe.vue';
import { SpinnerRouterLink } from '../shared';

export default {
  components: { Globe, SpinnerRouterLink },
  data() {
    return {
      showing: 'users',
    };
  },
  apollo: {
    stats_per_country: {
      query: COUNTS,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
      update(data) {
        return _.filter(data.stats_per_country, (row) => (row.users > 0 || row.clients > 0));
      },
    },
  },
  computed: {
    count_per_country() {
      const countryCounts = _.filter(this.stats_per_country, (row) => row[this.showing] > 0);
      return countryCounts.map((row) => ({
        name: row.topo_name || row.name,
        value: row[this.showing],
      }));
    },
  },
};
</script>
