export default class RequestBuilder {
  static build(user, keys = [
    'name', 'identification', 'email', 'hiring_date', 'is_billable', 'is_external',
    'is_active', 'is_contractor', 'is_parent', 'english_level', 'job_title_id',
    'phone_extension', 'personal_phone', 'working_time', 'country_id',
    'date_of_birth', 'partner_id', 'emergency_contact', 'notes',
    'address', 'only_my_team', 'termination_date', 'termination_reason']) {
    const requestBody = _.pick(user, keys);
    if (user.partner_id === '') {
      requestBody.partner_id = null;
    }
    if (user.working_time) {
      requestBody.working_time = parseFloat(user.working_time);
    }
    if (user.roles && (window.Permissions || []).includes('UpdateUserRoles')) {
      requestBody.roles = { sync: _.compact(_.map(user.roles, 'id')) };
    }
    if (user.tags) {
      requestBody.tags = {
        sync: user.tags.filter((tag) => tag.id).map((tag) => tag.id),
        create: user.tags.map((tag) => tag).filter((tag) => !tag.id),
      };
    }
    if (user.technologies) {
      requestBody.technologies = {
        sync: user.technologies.filter((tech) => tech.id).map((tech) => tech.id),
        create: user.technologies.map((tech) => tech).filter((tech) => !tech.id),
      };
    }
    if (user.business_units) {
      requestBody.business_units = {
        sync: user.business_units.filter((bu) => bu.id).map((bu) => bu.id),
        create: user.business_units.map((bu) => bu).filter((bu) => !bu.id),
      };
    }
    if (user.external_companies) {
      requestBody.external_companies = {
        upsert: user.external_companies.map((c) => {
          const company = _.pick(c, ['id', 'name', 'start_date', 'end_date']);
          company.external_projects = {
            upsert: c.external_projects.map((project) => _.pick(project, ['id', 'name', 'description', 'duration', 'external_company_id'])),
          };
          return company;
        }),
      };
    }
    return requestBody;
  }
}
