<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Gross Margin Per Resource</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search
                :search.sync="search"
                :inputs="['projects', 'users', 'onlyMyProjectsCheckbox']"
              ></Search>
            </div>
          </div>
        </div>
        <div class="container-fluid mt-3 ms-3">
          <div class="row">
            <div class="col-md-12">
              <label for="group_by">Group by:</label>
              <div class="form-check form-check-inline">
                <input type="radio" id="None" class="form-check-input" value="None" name="group_by" v-model="group_by"/>
                <label class="form-check-label" for="None">None</label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="Project" class="form-check-input" value="Project" name="group_by"
                      v-model="group_by"/>
                <label class="form-check-label" for="Project">Project</label>
              </div>
              <div class="form-check form-check-inline">
                <input type="radio" id="User" class="form-check-input" value="User" name="group_by" v-model="group_by"/>
                <label class="form-check-label" for="User">User</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="grossMarginPerResource">
      <div class="col-md-12 text-nowrap scroll-x h-100">
        <None
          :grossMarginPerResource="grossMarginPerResource"
          :orderBy="orderBy"
          @refetch="refetch"
          v-if="group_by == 'None'"></None>
        <Project
          :grossMarginPerResource="grossMarginPerResource"
          :orderBy="orderBy"
          @refetch="refetch"
          v-if="group_by == 'Project'"></Project>
        <User
          :grossMarginPerResource="grossMarginPerResource"
          :orderBy="orderBy"
          @refetch="refetch"
          v-if="group_by == 'User'"></User>
      </div>
    </div>
    <Empty v-if="!grossMarginPerResource?.length" icon="fas fa-percentage"/>
  </div>
</template>

<script>

import GROSS_MARGIN_PER_RESOURCE from './queries';
import None from './grouping/None.vue';
import Project from './grouping/Project.vue';
import User from './grouping/User.vue';
import { Search, SpinnerRouterLink } from '../../shared';
import SearchBuilder from '../../../shared/SearchBuilder';

export default {
  components: {
    Search, None, Project, User, SpinnerRouterLink,
  },
  data() {
    return {
      group_by: 'None',
      orderBy: { column: 'gross_margin', order: 'DESC' },
      search: { only_my_projects: false },
    };
  },
  apollo: {
    grossMarginPerResource: {
      query: GROSS_MARGIN_PER_RESOURCE,
      errorPolicy: 'all',
      variables() {
        return {
          orderBy: [this.orderBy],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    refetch() {
      this.$apollo.queries.grossMarginPerResource.refetch();
    },
  },
};
</script>
