<template>
  <div  class="mb-5 card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Estimation</span>
        <span v-if="record.id">{{localRecord.name}}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-3">
        <label for="name">Name</label>
        <input
          v-focus
          type="text"
          class="form-control"
          :class="{'is-invalid': v$.localRecord.name.$invalid}"
          id="name"
          v-model="localRecord.name"/>
        <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <label for="client_id">Client</label>
        <Select v-model="localRecord.client_id"
                :name="`client_id`"
                :query="clientsQuery"
                :customCssClass="'w-100'"
                :queryVariables="{ type: 'Client', orderBy: [{ column: 'name', order: 'ASC' }] }"></Select>
        <div v-for="error of v$.localRecord.client_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3">
        <BucketPicker :selected="localRecord.buckets">Buckets:</BucketPicker>
      </div>
      <div class="row">
        <div class="mb-3 col-md-12">
          <TechnologyPicker :selected="localRecord.technologies">
            Technologies:
          </TechnologyPicker>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import Validations from './Validations';
import { DROPDOWNS, Blinkable } from '../../shared';
import BucketPicker from '../estimation_buckets/Picker.vue';
import TechnologyPicker from '../technologies/Picker.vue';
import { Select } from '../shared';

export default {
  mixins: [Blinkable, Validations],
  components: {
    BucketPicker,
    TechnologyPicker,
    Select,
  },
  props: ['record'],
  data() {
    return {
      clientsQuery: DROPDOWNS,
      localRecord: structuredClone(this.record),
    };
  },
  methods: {
    save() {
      const event = this.localRecord.id ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
