<template>
<div class="mb-5 card">
  <div class="card-header d-flex">
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body pb-0">
      <h5 class="card-title">
        {{localRecord.name}}
      </h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">
        {{localRecord.description}}
      </h6>
    </div>
  <div class="card-body d-grid">
    <div class="mb-3">
      <label for="name">Emails:</label>
      <input v-focus type="text" class="form-control" id="name" v-model="localRecord.emails">
    </div>
    <div class="mb-3">
      <label for="slack_channel">Slack Channel:</label>
      <input type="text" class="form-control" placeholder="#example-channel" id="slack_channel" v-model="localRecord.slack_channel">
    </div>
    <button type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Save
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>

<script>
import Blinkable from '../../shared/Blinkable';

export default {
  extends: Blinkable,
  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  methods: {
    save() {
      this.$emit('update', this.localRecord);
    },
  },
};
</script>
