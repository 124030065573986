<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Project Metrics</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search
                :search.sync="search"
                :inputs="['name', 'clients', 'projectType', 'state', 'onlyMyProjectsCheckbox']"
              ></Search>
            </div>
          </div>
        </div>
        <div class="w-100 text-xxl-center ms-xxl-0">
          <div class="form-check form-check-inline form-switch">
            <input type="checkbox" class="form-check-input" id="layout" v-model="showTable">
            <label class="form-check-label" for="layout">Table/Cards</label>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="projectMetrics">
      <div class="col-md-12">
        <Table
          v-if="showTable"
          :projectMetrics="projectMetrics"
          @showMore="showMore('projectMetrics')"
          @sortBy="sortBy"
        ></Table>
        <Cards
          v-if="!showTable"
          :projectMetrics="projectMetrics"
          @showMore="showMore('projectMetrics')"
        ></Cards>
      </div>
    </div>
    <Empty v-if="!projectMetrics?.data.length" icon="fas fa-balance-scale"/>
  </div>
</template>

<script>

import PROJECT_METRICS_QUERY from './queries';
import { SearchBuilder, PaginationHandler } from '../../../shared';
import { Search, SpinnerRouterLink } from '../../shared';
import Table from './Table.vue';
import Cards from './Cards.vue';

export default {
  extends: PaginationHandler,
  components: {
    Search,
    Table,
    Cards,
    SpinnerRouterLink,
  },
  data() {
    return {
      showTable: false,
      search: { only_my_projects: false, state: 'In Progress' },
      orderBy: { column: 'projects.id', order: 'ASC' },
    };
  },
  mounted() {
    this.showMore('projectMetrics');
  },
  methods: {
    queryVariables() {
      return { orderBy: [this.orderBy], ...SearchBuilder.build(this.search) };
    },
    asc() {
      return this.orderBy.order === 'ASC';
    },
    sortBy(column) {
      this.orderBy.column = column;
      this.orderBy.order = this.asc() ? 'DESC' : 'ASC';
      this.$apollo.queries.projectMetrics.refetch();
    },
  },
  apollo: {
    projectMetrics: {
      query: PROJECT_METRICS_QUERY,
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      variables() {
        return {
          page: 1,
          orderBy: [this.orderBy],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
