import { gql } from 'apollo-boost';
import CLIENT_FRAGMENT from './fragments';

export const CREATE_CLIENT = gql`
  mutation($client: ClientInput!){
    createClient(input: $client){
      ...client
      country {
        id
        name
      }
    }
  }
  ${CLIENT_FRAGMENT}
`;

export const UPDATE_CLIENT = gql`
  mutation($id: ID!, $client: ClientInput!){
    updateClient(id: $id, input: $client){
      ...client
    }
  }
  ${CLIENT_FRAGMENT}
`;

export const DELETE_CLIENT = gql`
  mutation($id: ID!){
    deleteClient(id: $id){
      id
    }
  }
`;
