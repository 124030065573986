<template>
<div :id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting" :record.sync="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="row g-0">
      <div class="col-md-4 d-flex align-items-center justify-content-center">
         <img class="rounded-circle" :src="record.user.avatar">
      </div>
      <div class="col-md-8">
        <div class="card-body">
          <h4 class="card-title fw-bold">
            {{record.user.name}}
          </h4>
          <h5 class="card-title">
            By: {{record.evaluator.name}}
          </h5>
          <h5 class="card-title">
            {{$displayDate(record.created_at)}}
          </h5>
        </div>
      </div>
    </div>
    <div class="card-body">
      <div class="row d-flex justify-content-between align-items-center" v-if="record.nine_box_performance_score">
        <div class="col-md-4 fw-bold">
          Performance:
        </div>
        <div class="col-md-8 flex-wrap">
          <div class="progress" role="progressbar" aria-label="Performance" :aria-valuenow="calculatePercentage('nine_box_performance_score')" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar bg-company-primary" :style="{width: `${calculatePercentage('nine_box_performance_score')}%`}"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-between align-items-center" v-if="record.nine_box_potential_score">
        <div class="col-md-4 fw-bold">
          Potential:
        </div>
        <div class="col-md-8">
          <div class="progress" role="progressbar" aria-label="Potential" :aria-valuenow="calculatePercentage('nine_box_potential_score')" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar bg-company-primary" :style="{width: `${calculatePercentage('nine_box_potential_score')}%`}"></div>
          </div>
        </div>
      </div>
      <div class="row d-flex justify-content-between align-items-center">
        <div class="col-md-4 fw-bold">
          Status:
        </div>
        <div class="col-md-8">
          <span class="badge bg-company-secondary">{{record.status}}</span>
        </div>
      </div>
    </div>
    <div class="card-body" v-if="record.goals.length > 0">
      <ul class="list-group">
        <li class="list-group-item d-flex justify-content-between align-items-center bg-company-secondary">
          <span>Goals</span>
          <span>
            {{record.goals.filter((g) => g.is_completed).length}} out of {{this.record.goals.length}} completed
          </span>
        </li>
        <li :key="goal.id" class="list-group-item d-flex align-items-center" v-for="goal in record.goals">
          <span class="col">{{goal.name}}</span>
          <span class="col" title="Deadline">{{$displayDate(goal.deadline)}}</span>
          <span class="col-1 text-end rounded-circle badge-pill-custom text-success style-chooser" v-if="goal.is_completed">
            <i class="fa-solid fa-circle-check fa-2xl" title="Verified"></i>
          </span>
          <span class="col-1 text-end rounded-circle badge-pill-custom text-danger style-chooser" v-else>
            <i class="fa-solid fa-circle-check fa-2xl" title="Unsuccessful"></i>
          </span>
        </li>
      </ul>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdatePerformanceEvaluation') && record.status !== 'Closed'" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <router-link class="btn btn-company-primary" v-if="!confirmation && $can('ReadMyPerformanceEvaluations')"
      :to="{ name: this.$route.path === '/performance_evaluations' ? 'Performance Evaluation Detail' : 'My Performance Evaluation Detail', params: { id: record.id }}" target="_blank">
        <i class="fas fa-info"></i>
        Details
      </router-link>
      <Confirmation v-if="$can('DeletePerformanceEvaluation')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_PERFORMANCE_EVALUATION } from './mutations';
import Form from './Form.vue';
import { ErrorHandler } from '../../shared';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    calculatePercentage(key) {
      return ((this.record[key] || 0) * 100) / 3;
    },
    update(performanceEvaluation) {
      this.$apollo.mutate({
        mutation: UPDATE_PERFORMANCE_EVALUATION,
        variables: {
          id: performanceEvaluation.id,
          performance_evaluation: _.pick(performanceEvaluation, ['user_id', 'evaluator_id', 'performance_evaluation_cycle_id', 'status', 'performance_evaluation_form_id']),
          include_forms: false,
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
