<template>
  <div class="container-fluid">
    <div class="row mb-2" v-if="!readOnly">
      <div class="col-9">
        <div v-for="error of v$.selectedGoal.name.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
        <input ref="goalName" v-focus class="form-control" type="text" name="goal" placeholder="Add goal here..." v-model="selectedGoal.name" :class="{'is-invalid': v$.selectedGoal.name.$invalid}">
      </div>
      <div class="col-2 d-grid">
        <div v-for="error of v$.selectedGoal.deadline.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
        <input class="form-control" type="date" name="deadline" placeholder="Complete before..." v-model="selectedGoal.deadline" :class="{'is-invalid': v$.selectedGoal.deadline.$invalid}" :min="minDate()">
      </div>
      <div class="col-1 d-grid">
        <button class="btn btn-company-primary" :disabled="v$.selectedGoal.$invalid" @click="addGoal">
          <i class="fas fa-plus"></i>
          Add Goal
        </button>
      </div>
    </div>
    <ul class="list-group mb-2">
      <li class="list-group-item d-flex justify-content-between align-items-center bg-company-secondary">
        <span>Goals</span>
        <span>
          {{localGoals.filter((g) => !g.deleted && g.is_completed).length}} out of {{localGoals.filter((g) => !g.deleted).length}} completed
        </span>
      </li>
      <template v-for="goal in localGoals">
        <li v-if="!goal.deleted" :key="goal.id" class="list-group-item d-flex align-items-center" >
          <span class="col-6">{{goal.name}}</span>
          <span class="col-3" title="Target date">
            <span class="text-body-secondary">Target date:</span>
            {{deadline(goal.deadline)}}
          </span>
          <div class="col-1 form-check">
            <input id="completed" v-model="goal.is_completed" class="form-check-input" type="checkbox" @change="updateGoal" :disabled="readOnly">
            <label class="form-check-label m-auto" for="completed">Completed?</label>
          </div>
          <div class="col-2 text-end">
            <Confirmation v-if="$can('DeletePerformanceEvaluation') && !readOnly"
              class="d-inline"
              @accepted="softRemove(goal)"
            />
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import moment from 'moment';
import { Confirmation } from '../shared';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      selectedGoal: {
        name: { required },
        deadline: { required, afterToday: helpers.withMessage('Must be a date after today', (value) => moment(value).isAfter(moment())) },
      },
    };
  },
  components: { Confirmation },
  props: ['goals', 'readOnly'],
  data() {
    return {
      selectedGoal: { name: '', deadline: null },
    };
  },
  computed: {
    localGoals() {
      return structuredClone(this.goals);
    },
  },
  methods: {
    softRemove(goal) {
      goal.deleted = true;
      this.$emit('updated', this.localGoals);
    },
    updateGoal() {
      this.$emit('updated', this.localGoals);
    },
    addGoal() {
      this.localGoals.push(this.selectedGoal);
      this.selectedGoal = {};
      this.$refs.goalName.focus();
      this.$emit('updated', this.localGoals);
    },
    deadline(date) {
      return moment(date, 'YYYY-MM-DD').format('MMMM DD, YYYY');
    },
    minDate() {
      return moment().add(1, 'day').format('YYYY-MM-DD');
    },
  },
};
</script>
