<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-12 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              Margin Calculator
            </li>
          </ol>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="col-12">
        <div class="mb-3">
          <div class="company-control">
            <label for="country">
              Country <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
            </label>
            <select id="country"  class="form-control form-select" v-model="search.country_id">
              <template v-if="countries">
                <option :key="country.id" v-for="country in countries" :value="country.id">
                  {{country.name}}
                </option>
              </template>
            </select>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="mb-3">
          <div class="company-control">
            <label>Position</label>
            <Select v-model="search.job_title_id"
                  :class="'w-100'"
                  :name="`job_title_id`"
                  :query="queries.job_titles"
                  :queryVariables="{ orderBy: [{ column: 'internal_description', order: 'ASC' }] }">
            </Select>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="mb-3">
          <div class="company-control">
            <label>Person</label>
            <Select v-model="search.user_id"
                    :class="'w-100'"
                    :name="`user_id`"
                    :query="queries.users"
                    :queryVariables="{ orderBy: [{ column: 'name', order: 'ASC' }], is_active: true, is_external: false }">
            </Select>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="mb-3">
          <div class="company-control">
            <label>Cost</label>
            <div class="input-group">
              <span class="input-group-text">$</span>
              <input class="form-control bg-dark bg-opacity-25" type="text" name="cost" v-model="formattedCost" readonly>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="mb-3">
          <div class="company-control">
            <label title="Margin = selling price - cost, expressed as a percentage">Margin</label>
            <div class="input-group">
              <span class="input-group-text">%</span>
              <input class="form-control" :class="marginClass" type="number" name="margin" v-model="margin">
              <div class="warning-feedback w-100 mt-xxl-1 style-chooser text-end" v-if="marginClass === 'border-warning'">
                Margin is below the recommended value required by the company.
              </div>
              <div class="invalid-feedback style-chooser text-end">
                Margin is below the minimum value required by the company.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="mb-3">
          <div class="company-control">
            <label title="Sell Price">Revenue</label>
            <div class="input-group">
              <span class="input-group-text">$</span>
              <input class="form-control bg-dark bg-opacity-25" type="text" name="cost" v-model="formattedRevenue" readonly>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-12">
        <div class="mb-3">
          <div class="company-control">
            <label for="cost" title="Money gained from selling">Profit</label>
            <div class="input-group">
              <span class="input-group-text">$</span>
              <input class="form-control bg-dark bg-opacity-25" type="text" name="cost" v-model="formattedProfit" readonly>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import COSTS_BY_JOB_TITLE from './queries';
import { CONFIGURATION } from '../configuration/queries';
import { Select } from '../shared';
import {
  SearchBuilder, USERS, JOB_TITLES, dropdownWithAlias,
} from '../../shared';

export default {
  components: { Select },
  data() {
    return {
      margin: 0,
      queries: {
        users: USERS,
        job_titles: JOB_TITLES,
      },
      search: {
        user_id: '',
        job_title_id: '',
        country_id: '',
      },
    };
  },
  apollo: {
    costsByJobTitle: {
      query: COSTS_BY_JOB_TITLE,
      errorPolicy: 'all',
      variables() {
        return {
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    configuration: {
      query: CONFIGURATION,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    countries: {
      query: dropdownWithAlias('countries'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Country', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    hourMonthFormat(value) {
      return `$${value.toFixed(2)}/h - $${(value * 166).toFixed(2)}/m`;
    },
  },
  watch: {
    configuration() {
      this.margin = this.greenMargin;
    },
  },
  computed: {
    greenMargin() {
      if (!this.configuration || this.configuration.length === 0) {
        return 40;
      }
      return parseFloat(this.configuration.find((c) => c.key === 'Gross Margin Per Resource Green').value);
    },
    yellowMargin() {
      if (!this.configuration || this.configuration.length === 0) {
        return 30;
      }
      return parseFloat(this.configuration.find((c) => c.key === 'Gross Margin Per Resource Yellow').value);
    },
    marginClass() {
      if (this.margin >= this.greenMargin) {
        return 'is-valid';
      }
      if (this.margin >= this.yellowMargin) {
        return 'border-warning';
      }
      return 'is-invalid';
    },
    cost() {
      if (!this.costsByJobTitle || this.costsByJobTitle.length === 0) {
        return 0;
      }
      return _.maxBy(this.costsByJobTitle, 'cost').cost;
    },
    formattedCost() {
      return this.hourMonthFormat(this.cost);
    },
    revenue() {
      return this.cost / (1 - (this.margin / 100));
    },
    formattedRevenue() {
      return this.hourMonthFormat(this.revenue);
    },
    profit() {
      return this.revenue - this.cost;
    },
    formattedProfit() {
      return this.hourMonthFormat(this.profit);
    },
  },
};
</script>
