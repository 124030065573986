<template>
  <div>
    <Form v-if="selectedRecord"
      v-bind:record="selectedRecord"
      :totalAvailablePercentage="totalAvailablePercentage"
      @close="selectedRecord = null"
      @insert="insert">
    </Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row mb-3">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Health Score Configuration</SpinnerRouterLink>
          </div>
        </div>
        <div class="col-md-10 m-auto p-0" v-if="totalAvailablePercentage">
          <div class="float-end">
            <div class="d-flex pb-1">
              <span>
                <i class="fas fa-circle text-success"></i>
                Available percentage weight: {{ totalAvailablePercentage  }}
              </span>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6">
              <Search
                :search.sync="search"
                :inputs="['name']"
              ></Search>
            </div>
            <div class="col-12 col-sm-6 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = {}" v-if="$can('CreateHealthScoreConfigurationItem')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="healthScoreConfigurationItems">
      <Empty v-if="!healthScoreConfigurationItems.length" icon="fas fa-chart-pie" :can_create="true" @callCreate="selectedRecord = {}"/>
      <Card :key="index"
        v-for="(record, index) in healthScoreConfigurationItems"
        :record="record"
        :totalAvailablePercentage="totalAvailablePercentage"
        @remove="remove"
      ></Card>
    </div>
  </div>
</template>

<script>
import HEALTH_SCORE_CONFIGURATION_ITEMS_QUERY from './queries';
import { DELETE_HEALTH_SCORE_CONFIGURATION_ITEM, CREATE_HEALTH_SCORE_CONFIGURATION_ITEM } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import { Search, SpinnerRouterLink } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, Search, SpinnerRouterLink,
  },
  apollo: {
    healthScoreConfigurationItems: {
      query: HEALTH_SCORE_CONFIGURATION_ITEMS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  computed: {
    totalUsedPercentage() {
      return _.sumBy(this.healthScoreConfigurationItems, 'percentage_weight');
    },
    totalAvailablePercentage() {
      return 100 - this.totalUsedPercentage;
    },
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    remove(healthScoreConfigurationItem) {
      this.$apollo.mutate({
        mutation: DELETE_HEALTH_SCORE_CONFIGURATION_ITEM,
        variables: {
          id: healthScoreConfigurationItem.id,
        },
        update: (store, { data: { deleteHealthScoreConfigurationItem } }) => {
          const data = store.readQuery({ query: HEALTH_SCORE_CONFIGURATION_ITEMS_QUERY, variables: this.queryVariables() });
          data.healthScoreConfigurationItems = data.healthScoreConfigurationItems.filter((t) => t.id !== deleteHealthScoreConfigurationItem.id);
          store.writeQuery({ query: HEALTH_SCORE_CONFIGURATION_ITEMS_QUERY, variables: this.queryVariables(), data });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(healthScoreConfigurationItem) {
      // We save the user input in case of an error
      const newHealthScoreConfigurationItem = healthScoreConfigurationItem;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_HEALTH_SCORE_CONFIGURATION_ITEM,
        variables: {
          healthScoreConfigurationItem,
        },
        update: (store, { data: { createHealthScoreConfigurationItem } }) => {
          const data = store.readQuery({ query: HEALTH_SCORE_CONFIGURATION_ITEMS_QUERY, variables: this.queryVariables() });
          data.healthScoreConfigurationItems.unshift(createHealthScoreConfigurationItem);
          store.writeQuery({ query: HEALTH_SCORE_CONFIGURATION_ITEMS_QUERY, variables: this.queryVariables(), data });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        // We restore the initial user input
        this.selectedRecord = newHealthScoreConfigurationItem;
      });
    },
  },
};
</script>
