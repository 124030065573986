export default {
  methods: {
    updateFilter() {
      const filters = _.pickBy(this.search, (v) => v !== '');
      this.$router.push({ query: filters }).catch(() => {});
      this.$emit('update:search', filters);
    },
  },
  data() {
    return {
      search: {},
    };
  },
  created() {
    // read values from query string and set the object "search"
    this.search = _.merge(this.search, this.$route.query);
    const self = this;
    // make sure boolean params stay boolean and not string
    _.forOwn(this.search, (value, key) => {
      if (['true', 'false'].includes(value)) {
        self.search[key] = (value === 'true');
      }
    });
    this.updateFilter();
  },
};
