<template>
  <div>
    <template v-for="member in members">
      <div class="row rounded p-2 m-1 bg-white shadow-sm d-flex align-items-center justify-content-center" :key="member.id" v-if="member.user && member.project_role">
        <div class="col">
          <del v-if="member.end_date">
            <router-link :to="`/users?id=${member.user.id}`" target="_blank">
              {{member.user.name}}
            </router-link>
          </del>
          <span v-else>
            <router-link :to="`/users?id=${member.user.id}`" target="_blank">
              {{member.user.name}}
              <i v-if="member.is_manager" title="This person manages the project" class="fa-regular fa-person-chalkboard"></i>
            </router-link>
          </span>
        </div>
        <div class="col m-auto">
          <div class="progress" role="progressbar" :aria-label="`${member.hours_per_week} hours per week`" :aria-valuenow="member.hours_per_week" aria-valuemin="0" aria-valuemax="40" :title="`${member.hours_per_week} hours per week`">
            <div class="progress-bar ps-2 bg-success"
                :class="percentage(member.hours_per_week) == 0 ? 'text-dark bg-transparent w-100' : ''"
                :style="{ width: percentage(member.hours_per_week) + '%' }">
              {{member.hours_per_week}} h / week
            </div>
          </div>
        </div>
        <div class="col">
          <small class="text-body-secondary">({{member.start_date}} - {{ member.end_date ? member.end_date : member.contract_end_date}}) </small>
        </div>
        <div class="col">
          <span class="badge bg-company-secondary w-100">
            {{member.project_role.name}}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

export default {
  props: ['members'],
  methods: {
    percentage(hoursPerWeek) {
      return (hoursPerWeek * 100) / 40;
    },
  },
};
</script>
