import { gql } from 'apollo-boost';

export default gql`
query($user_id: ID, $performance_evaluation_cycle_id: ID){
  performance_evaluations_nine_box(user_id: $user_id, performance_evaluation_cycle_id: $performance_evaluation_cycle_id) {
    id
    user {
      id
      name
      avatar
    }
    row_number
    nine_box_potential_score
    nine_box_performance_score
  }
}
`;
