<template>
  <div class="ms-2 sortable">
    <button v-if="!confirmation" class="btn text-center me-2" @click="remove(false)">
      <i class="fas fa-times"></i>
    </button>
    <div v-if="confirmation" class="d-flex justify-content-start">
      <button class="btn btn-company-primary" @click="remove(true)">Accept</button>
      <button class="btn btn-company-secondary" @click="confirmation = false">Cancel</button>
    </div>
    <span v-if="!confirmation" @click="$emit('selected', report)">{{report.title}}</span>
  </div>
</template>

<script>

export default {
  props: ['report'],
  data() {
    return {
      confirmation: false,
    };
  },
  methods: {
    remove(confirm) {
      if (confirm) {
        this.$emit('remove', this.report);
        this.confirmation = false;
        return;
      }
      this.confirmation = true;
    },
  },
};
</script>
