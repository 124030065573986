import { useVuelidate } from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      localRecord: {
        notes: { required, minLengthValue: minLength(7) },
        from_job_title_id: { required },
        to_job_title_id: { required },
        date: { required },
        next_promotion_date: { required },
      },
    };
  },
};
