<template>
  <div class="row task-container flex-nowrap">
    <div class="input-group col-md-6 p-0 flex-fill">
      <span class="input-group-text grabbable text-default rounded-0">
        <i class="fas fa-circle" :style="{ 'color': bucket_estimation_item.deliverable ? bucket_estimation_item.deliverable.color : '#495057' }"></i>
      </span>
      <input v-if="isDuplicated" type="text" class="form-control rounded-0 w-25 text-danger" placeholder="Name" v-model="bucket_estimation_item.name" title="Duplicated Line" />
      <input v-if="!isDuplicated" type="text" class="form-control rounded-0 w-25" placeholder="Name" v-model="bucket_estimation_item.name" :title="bucket_estimation_item.name" />
    </div>
    <div class="input-group col-md-6 p-0 flex-fill">
      <input type="number" class="form-control rounded-0 col-md-3" placeholder="Hours" v-model="bucket_estimation_item.hours"/>
      <select v-model="bucket_estimation_item.deliverable" class="form-control rounded-0 form-select col-md-8">
        <option v-if="bucket_estimation_item.deliverable" :selected="true" :value="bucket_estimation_item.deliverable">
          {{bucket_estimation_item.deliverable.name}}
        </option>
        <option v-for="(deliverable, index) in filterDeliverables(bucket_estimation_item.deliverable)" :value="deliverable" :key="index">
          {{deliverable.name}}
        </option>
      </select>
      <span class="input-group-text sortable rounded-0">
        <input type="checkbox" class="form-check-input sortable" v-model="bucket_estimation_item.is_completed"/>
      </span>
      <span class="input-group-text sortable text-danger rounded-0" @click="$emit('deleteLine', bucket_estimation_item)">
        <i class="fas fa-times"></i>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['bucket_estimation_item', 'isDuplicated', 'deliverables'],
  methods: {
    filterDeliverables(deliverable) {
      if (deliverable) {
        return this.deliverables.filter((item) => item.id !== deliverable.id);
      }
      return this.deliverables;
    },
  },
};
</script>
