import { gql } from 'apollo-boost';
import EQUIPMENT_FRAGMENT from './fragments';

export default gql`
  query($page: Int,
        $orderBy: [OrderByClause!],
        $model: String,
        $serial_number: String,
        $type: String,
        $location: String,
        $state: String,
        $internal_key: String,
        $is_active: Trashed,
        $deleted_at: DateTime,
        $user_id: ID) {
    equipment(
      page: $page,
      orderBy: $orderBy,
      model: $model,
      serial_number: $serial_number,
      type: $type,
      location: $location,
      state: $state,
      internal_key: $internal_key,
      is_active: $is_active,
      deleted_at: $deleted_at,
      user_id: $user_id
    ) @connection(key: "equipment", filter: ["model", "type", "location", "state", "internal_key", "user_id", "serial_number", "deleted_at", "is_active"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...equipment
      }
    }
  }
  ${EQUIPMENT_FRAGMENT}
`;
