<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Integrations</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-12">
              <Search :search.sync="search" :inputs="['name']"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="integrations">
      <Empty v-if="!integrations.data.length" icon="fas fa-puzzle-piece"/>
      <Card
        :key="index" v-for="(integration, index) in integrations.data"
        :integration="integration"
        @refresh="refresh"
      ></Card>
    </div>
    <SkeletonCards
      v-if="integrations && integrations.paginatorInfo.hasMorePages"
      :cards=4 :executeScroll="integrations != undefined"
      @showMore="showMore('integrations')"
    ></SkeletonCards>
  </div>
</template>
<script>
import { GET_INTEGRATIONS } from './queries';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import { SearchBuilder, PaginationHandler } from '../../shared';
import Card from './Card.vue';

export default {
  extends: PaginationHandler,
  components: {
    Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  apollo: {
    integrations: {
      query: GET_INTEGRATIONS,
      errorPolicy: 'all',
      variables() {
        return {
          page: 1,
          orderBy: [{ column: 'id', order: 'ASC' }],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
    };
  },
  methods: {
    queryVariables() {
      return { orderBy: [{ column: 'id', order: 'ASC' }], ...SearchBuilder.build(this.search) };
    },
    refresh() {
      this.$apollo.queries.integrations.refetch();
    },
  },
};
</script>
