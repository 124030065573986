<template>
  <div class="col-md-6 mb-4">
    <div class="card h-100">
      <div class="card-header">
        <h5 class="card-title my-auto">{{ record.learning_path_level_node.name }}</h5>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12">
            <span class="fw-bold">Learning Path: </span> {{ record.learning_path_level_node.learning_path_level.learning_path.name }}
          </div>
          <div class="col-12">
            <span class="fw-bold">Level: </span> {{ record.learning_path_level_node.learning_path_level.name }}
          </div>
          <div class="col-12">
            <span class="fw-bold">Evaluation Methodology: </span>
            <p v-html="record.learning_path_level_node.evaluation_methodology"></p>
          </div>
          <div class="col-12">
            <span class="fw-bold">Notes:</span>
          </div>
          <div class="col-12">
            <trix-editor class="h-100" @trix-change="localRecord.notes = $event.target.value; $forceUpdate()" placeholder="Required note here..."></trix-editor>
          </div>
        </div>
      </div>
      <div class="card-footer mt-4">
        <button class="btn btn-company-primary" :disabled="!enabled" @click="updateStatus('Successful', true)">Approve <i class="fas fa-check"></i></button>
        <button class="btn btn-company-secondary" :disabled="!enabled" @click="updateStatus('Unsuccessful', false)">Reject <i class="fas fa-times"></i></button>
      </div>
    </div>
  </div>
</template>

<script>

import EVALUATE_LEARNING_PATH_NODE_USER from './mutations';

export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      enabled: true,
    };
  },
  methods: {
    updateStatus(status, isSuccessful) {
      if (this.localRecord.notes) {
        this.$apollo.mutate({
          mutation: EVALUATE_LEARNING_PATH_NODE_USER,
          variables: {
            learning_path_node_user: {
              learning_path_level_node_user_id: this.$props.record.id,
              is_successful: isSuccessful,
              status,
              notes: this.localRecord.notes,
            },
          },
        }).then(() => {
          this.enabled = false;
          this.$toasted.success('Node status successfully updated');
        }).catch((error) => {
          this.$toasted.error(error.message);
        });
      } else {
        this.$toasted.error('A Note is required');
      }
    },
  },
  computed: {
    localRecord() {
      return { ...this.record };
    },
  },
};
</script>
