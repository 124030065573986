import { gql } from 'apollo-boost';

export default gql`
  fragment job_title on JobTitle {
    id
    internal_description
    external_description
    user_count
  }
`;
