<template>
  <div :id="record.id" class="col-md-3 pb-2">
    <div class="card h-100 p-2">
      <div class="card-body">
        <h5 class="card-title fs-4">
          {{ record.name }}
        </h5>
        <h6 v-html="record.description" class="card-subtitle mb-2 text-body-secondary"></h6>
        <div class="text-body-secondary pt-3">
          <div class="d-flex justify-content-between">
            <p>Start Date</p>
            <p>{{ $displayDate(record.start_date) }}</p>
          </div>
          <div class="d-flex justify-content-between">
            <p>End Date</p>
            <p>{{ $displayDate(record.end_date) }}</p>
          </div>
          <div>
            <div class="mt-3">
              <ul v-if="record.attachments.length" class="list-group">
                <li v-for="attachment in record.attachments" :key="attachment.id"
                  class="list-group-item text-body-secondary sortable"
                  @click="downloadFile(attachment.url)">
                  <i class="fas fa-file-arrow-down"></i>
                  {{ attachment.name }}
                </li>
              </ul>
              <p class="text-center" v-else>There are no attachments</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['record'],
  methods: {
    downloadFile(url) {
      const key = `attachments/${decodeURI(url.split('/').pop())}`;
      this.$s3GetSignedUrl(key).then((s3Url) => window.open(s3Url, '_blank'));
    },
  },
};
</script>
