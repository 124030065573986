<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">User Directory</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-9">
              <Search
                :search.sync="search"
                :inputs="['name', 'email']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="user_directory">
      <Card
        :key="index" v-for="(record, index) in user_directory.data"
        :record="record"
      ></Card>
      <Empty v-if="!user_directory.data.length" icon="fas fa-users"/>
    </div>
    <SkeletonCards
      v-if="user_directory && user_directory.paginatorInfo.hasMorePages"
      :cards=6 :executeScroll="user_directory != undefined"
      @showMore="showMore('user_directory')"
    ></SkeletonCards>
  </div>
</template>

<script>

import Card from './Card.vue';
import USER_DIRECTORY_QUERY from './queries';
import { Search, SkeletonCards, SpinnerRouterLink } from '../../shared';
import { SearchBuilder, PaginationHandler } from '../../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Search, SkeletonCards, Card, SpinnerRouterLink,
  },
  data() {
    return {
      search: {},
    };
  },
  apollo: {
    user_directory: {
      query: USER_DIRECTORY_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          page: 1,
          orderBy: [{ column: 'id', order: 'ASC' }],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    queryVariables() {
      return { orderBy: [{ column: 'id', order: 'ASC' }], ...SearchBuilder.build(this.search) };
    },
  },
};
</script>
