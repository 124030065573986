<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Holidays Calendar</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search :search.sync="search" :inputs="['countries', 'users']"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="text-start" v-if="holidaysByYear">
        <button class="btn btn-company-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapse" aria-expanded="false" aria-controls="collapse">
          Year Events
        </button>
         <div class="collapse" id="collapse">
            <div class="p-1 py-1 px-0">
              <ViewCalendar :year="year" :record="holidaysByYear"/>
            </div>
         </div>
      <FullCalendar ref="calendar"
        :options="calendarOptions">
        <template v-slot:eventContent='arg' >
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import moment from 'moment';
import HOLIDAYS_QUERY from './queries';
import { ErrorHandler, SearchBuilder, Flags } from '../../../shared';
import { Search, SpinnerRouterLink } from '../../shared';
import ViewCalendar from './view_calendar.vue';

export default {
  components: {
    FullCalendar, Search, SpinnerRouterLink, ViewCalendar,
  },
  apollo: {
    holidaysByYear: {
      query: HOLIDAYS_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          year: parseInt(this.year, 10),
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
  },

  methods: {
    change() {
      const api = this.$refs.calendar.getApi();
      this.year = api.getDate().getFullYear().toString();
    },
  },
  watch: {
    holidaysByYear() {
      this.calendarOptions.events = this.holidaysByYear.map(
        (holiday) => ({
          id: holiday.id,
          title: `${Flags[holiday.country.name]} ${holiday.name} - ${holiday.country.name}`,
          date: holiday.date,
        }),
      );
    },
  },
  data() {
    return {
      search: {},
      year: moment().format('YYYY'),
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        events: [],
        weekends: true,
        eventsSet: this.change,
        eventSources: true,
      },
    };
  },
};
</script>
