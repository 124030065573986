<template>
  <div :id="record.id" class="col-md-2 pb-2">
    <Form
      v-if="is_editting"
      :record="record"
      @close="is_editting = false"
      @update="update"
      :models="models"
    ></Form>
    <div v-if="!is_editting" class="card h-100">
      <div class="card-body pb-0">
        <h5 class="card-title">
          {{ record.name }}
        </h5>
      </div>
      <div class="card-body pt-0 row mt-2">
        <div class="col-md-12">
          Type: {{ record.model }}
        </div>
        <div class="col-md-12">
          Internal Id: {{ record.internal_id }}
        </div>
        <div class="col-md-12 mb-2">
          External Id: {{ record.external_id }}
        </div>
      </div>
      <div class="card-body">
        <button v-if="!confirmation" class="btn btn-company-primary" @click="is_editting = true">
          <i class="fas fa-pen"></i>
          Edit
        </button>
        <Confirmation v-if="$can('DeleteIntegrationMapping')"
          v-model="confirmation"
          @accepted="remove()"
        />
      </div>
    </div>
  </div>
</template>
<script>

import Form from './Form.vue';
import { UPDATE_INTEGRATION_MAPPING } from './mutations';
import ErrorHandler from '../../../shared/ErrorHandler';
import { Confirmation } from '../../shared';

export default {
  components: { Form, Confirmation },
  props: ['record', 'models'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(record) {
      this.$apollo
        .mutate({
          mutation: UPDATE_INTEGRATION_MAPPING,
          variables: {
            id: record.id,
            integrationMapping: _.pick(record, ['model', 'internal_id', 'external_id', 'integration_id']),
          },
        })
        .catch((error) => {
          ErrorHandler.handle(error, this);
        })
        .then(() => {
          this.is_editting = false;
          this.$emit('refresh');
        });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
