<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Gatekeeper</SpinnerRouterLink>
          </div>
        </div>
      </div>
    </nav>
    <div class="d-flex flex-column flex-xl-row">
      <div class="col-left card-header p-0 rounded-3 overflow-hidden border w-100">
        <div v-if="users" class="overflow-auto h-100">
          <Directory
            :record="users"
            @switch="switchIsActive"
          ></Directory>
          <Empty v-if="!users.length" icon="fas fa-user-shield"/>
        </div>
      </div>
      <div class="second-column col-xl-4 w-auto d-flex flex-column mt-3 ms-xl-3 mt-xl-0">
        <div class="card-header p-0 rounded-3 overflow-hidden border w-100 h-25">
          <div class="overflow-auto h-100">
            <WhiteListDomains :record="white_list_domains" :domains="domain"
              @create="createDomain"
              @update="updateDomain"
              @delete="deleteDomain"
            ></WhiteListDomains>
          </div>
        </div>
        <div class="card-header p-0 rounded-3 overflow-hidden border mt-3 table2 w-100 h-75">
          <div class="overflow-auto h-100">
            <AccessRequests
              :record="login_requests"
              @accept="acceptLoginRequest"
              @deny="denyLoginRequest"
            ></AccessRequests>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Directory from './Directory.vue';
import WhiteListDomains from './WhiteListDomains.vue';
import AccessRequests from './AccessRequests.vue';
import GATEKEEPER from './queries';
import { ErrorHandler } from '../../shared';
import { SpinnerRouterLink } from '../shared';
import {
  CREATE_WHITE_LIST_DOMAIN,
  UPDATE_WHITE_LIST_DOMAIN,
  DELETE_WHITE_LIST_DOMAIN,
  ACCEPT_LOGIN_REQUEST,
  DENY_LOGIN_REQUEST,
  SWITCH_IS_ACTIVE,
} from './mutations';

export default {
  components: {
    Directory, WhiteListDomains, AccessRequests, SpinnerRouterLink,
  },
  data() {
    return {
      users: null,
      newDomain: null,
      editDomain: null,
      login_requests: null,
      white_list_domains: null,
      queryVariables: {
        uPage: 1,
        dPage: 1,
        rPage: 1,
        orderBy: [{ column: 'id', order: 'DESC' }],
      },
      domain: {
        edit: null,
        new: null,
      },
    };
  },
  apollo: {
    data: {
      query: GATEKEEPER,
      errorPolicy: 'all',
      loadingKey: 'loading',
      variables() { return this.queryVariables; },
      update(data) {
        this.users = data.users.data;
        this.login_requests = data.login_requests.data;
        this.white_list_domains = data.white_list_domains.data;
      },
      error(error) { this.$toasted.error(error.message); },
    },
  },
  methods: {
    // DIRECTORY METHOD
    switchIsActive(id, isActive) {
      this.$apollo.mutate({
        mutation: SWITCH_IS_ACTIVE,
        variables: { id, is_active: isActive },
      }).then(({ data: { userIsActive } }) => {
        const index = _.findIndex(this.users, (row) => row.id === userIsActive.id);
        this.$toasted.success(userIsActive.is_active ? `User ${this.users[index].name} is Active again!` : `User ${this.users[index].name} was Banned!`);
        this.users[index].is_active = userIsActive.is_active;
      }).catch((error) => ErrorHandler.handle(error, this));
    },

    // DOMAIN METHODS
    createDomain(domain) {
      this.$apollo.mutate({
        mutation: CREATE_WHITE_LIST_DOMAIN,
        variables: { domain },
        update: (store, { data: { createWhiteListDomain } }) => {
          const data = store.readQuery({ query: GATEKEEPER, variables: this.queryVariables });
          data.white_list_domains.data.unshift(createWhiteListDomain);
          store.writeQuery({ query: GATEKEEPER, variables: this.queryVariables, data });
          this.domain.new = null;
        },
      }).catch((error) => ErrorHandler.handle(error, this));
    },
    updateDomain(id, domain) {
      this.$apollo.mutate({
        mutation: UPDATE_WHITE_LIST_DOMAIN,
        variables: { id, domain },
        update: (store, { data: { updateWhiteListDomain } }) => {
          const data = store.readQuery({ query: GATEKEEPER, variables: this.queryVariables });
          const index = _.findIndex(data.white_list_domains.data, (row) => row.id === updateWhiteListDomain.id);
          data.white_list_domains.data[index].domain = updateWhiteListDomain.domain;
          store.writeQuery({ query: GATEKEEPER, variables: this.queryVariables, data });
          this.domain.edit = null;
        },
      }).catch((error) => ErrorHandler.handle(error, this));
    },
    deleteDomain(id) {
      this.$apollo.mutate({
        mutation: DELETE_WHITE_LIST_DOMAIN,
        variables: { id },
        update: (store, { data: { deleteWhiteListDomain } }) => {
          const data = store.readQuery({ query: GATEKEEPER, variables: this.queryVariables });
          const index = _.findIndex(data.white_list_domains.data, (row) => row.id === deleteWhiteListDomain.id);
          data.white_list_domains.data.splice(index, 1);
          store.writeQuery({ query: GATEKEEPER, variables: this.queryVariables, data });
        },
      }).catch((error) => ErrorHandler.handle(error, this));
    },

    // LOGIN_REQUEST METHODS
    acceptLoginRequest(id) {
      this.$apollo.mutate({
        mutation: ACCEPT_LOGIN_REQUEST,
        variables: { id },
      }).then(({ data: { acceptLoginRequest } }) => {
        const index = _.findIndex(this.login_requests, (request) => request.id === acceptLoginRequest);
        this.$toasted.success(`Request from ${this.login_requests[index].email} was Accepted!`);
        this.login_requests.splice(index, 1);
      }).catch((error) => ErrorHandler.handle(error, this));
    },
    denyLoginRequest(id) {
      this.$apollo.mutate({
        mutation: DENY_LOGIN_REQUEST,
        variables: { id },
      }).then(({ data: { denyLoginRequest } }) => {
        const index = _.findIndex(this.login_requests, (request) => request.id === denyLoginRequest.id);
        this.$toasted.success(`Request from ${this.login_requests[index].email} was Denied!`);
        this.login_requests.splice(index, 1);
      }).catch((error) => ErrorHandler.handle(error, this));
    },
  },
};
</script>
