<template>
  <table class="table">
    <tr>
      <th class="d-table-cell sortable" @click="sortBy('person')">
        <i v-if="orderBy.column === 'person'" class="bg-transparent fas" :class="sortIcon"></i>
        Person
      </th>
      <th class="d-table-cell sortable" @click="sortBy('costs.cost')">
        <i v-if="orderBy.column === 'costs.cost'" class="bg-transparent fas" :class="sortIcon"></i>
        Cost
      </th>
      <th class="d-table-cell sortable" @click="sortBy('hourly_rate')" title="What you charge to the client.">
        <i v-if="orderBy.column === 'hourly_rate'" class="bg-transparent fas" :class="sortIcon"></i>
        Rate
      </th>
      <th class="d-table-cell sortable" @click="sortBy('profit')" title="Profit = Rate - Profit">
        <i v-if="orderBy.column === 'profit'" class="bg-transparent fas" :class="sortIcon"></i>
        Profit
      </th>
      <th class="d-table-cell sortable" @click="sortBy('gross_margin')">
        <i v-if="orderBy.column === 'gross_margin'" class="bg-transparent fas" :class="sortIcon"></i>
        Gross Margin
      </th>
    </tr>
    <template v-for="project in projects">
      <tr :key="project">
        <th class="bg-company-primary">{{project}}</th>
        <th class="bg-company-primary">
          <ShowCost :cost="averageBy('project', project, 'cost')"></ShowCost>
        </th>
        <th class="bg-company-primary">
          <ShowCost :cost="averageBy('project', project, 'hourly_rate')"></ShowCost>
        </th>
        <th class="bg-company-primary">
          <ShowCost :cost="averageBy('project', project, 'profit')"></ShowCost>
        </th>
        <th class="bg-company-primary">{{averageBy('project', project, 'gross_margin')}}%</th>
      </tr>
      <tr v-for="line in filterBy('project', project)" :key="line.id">
        <td>{{line.person}}</td>
        <td>
          <ShowCost :cost="line.cost"></ShowCost>
        </td>
        <td>
          <ShowCost :cost="line.hourly_rate"></ShowCost>
        </td>
        <td>
          <ShowCost :cost="line.profit"></ShowCost>
        </td>
        <td>
          <i class="fas fa-circle" :class="`text-${line.status}`"></i>
          {{line.gross_margin.toFixed(2)}}%
        </td>
      </tr>
    </template>
    <tr v-if="grossMarginPerResource && grossMarginPerResource.length === 0">
      <td class="text-center" colspan="4">No records found</td>
    </tr>
  </table>
</template>

<script>

import Helper from '../Helper';

export default {
  extends: Helper,
  props: ['grossMarginPerResource', 'orderBy', 'show_costs'],
};
</script>
