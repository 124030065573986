import { gql } from 'apollo-boost';

export const INSERT_USER = gql`
  mutation($user: UserForClientInput!){
    createUserForClient(input: $user){
      id
      name
      email
      client_id
    }
  }
`;

export const USER_DELETE = gql`
  mutation($id: ID!){
    deleteUser(id: $id){
      id
    }
  }
`;

export const UPDATE_USER = gql`
  mutation($id: ID!, $user: UserForClientInput!){
    updateUserForClient(id: $id, input: $user){
      name
      email
    }
  }
`;
