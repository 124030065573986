export default class RequestBuilder {
  static build(estimation) {
    const requestBody = _.pick(estimation, ['name', 'client_id', 'notes']);
    if (estimation.buckets) {
      requestBody.buckets = { sync: _.compact(_.map(estimation.buckets, 'id')) };
    }
    if (estimation.technologies) {
      requestBody.technologies = {
        sync: _.compact(_.map(estimation.technologies, 'id')),
        create: estimation.technologies.filter((tech) => !tech.id),
      };
    }
    return requestBody;
  }

  static buildBucketEstimation(id, bucketEstimation) {
    const requestBody = _.pick(bucketEstimation, ['order']);
    requestBody.bucket_id = bucketEstimation.bucket.id;
    requestBody.estimation_id = id;
    requestBody.bucket_estimation_items = {
      upsert: _.map((bucketEstimation.bucket_estimation_items || []), (item) => ({
        ..._.pick(item, ['id', 'name', 'is_completed']),
        ...{ hours: parseInt(item.hours, 10), deliverable_id: item.deliverable ? item.deliverable.id : null, order: item.order || 0 },
      })).filter((item) => item.name && item.hours > 0),
      delete: _.map(_.filter((bucketEstimation.bucket_estimation_items || []), (item) => item.name === '' && item.hours === 0), 'id'),
    };
    return requestBody;
  }
}
