import { gql } from 'apollo-boost';
import CANDIDATE_FRAGMENT from './fragments';

export const CREATE_CANDIDATE = gql`
  mutation($input: CandidateInput!){
    createCandidate(input: $input){
      ...candidate
    }
  }
  ${CANDIDATE_FRAGMENT}
`;

export const UPDATE_CANDIDATE = gql`
  mutation($id: ID!, $candidate: CandidateInput!){
    updateCandidate(id: $id, input: $candidate){
      ...candidate
    }
  }
  ${CANDIDATE_FRAGMENT}
`;

export const DELETE_CANDIDATE = gql`
  mutation($id: ID!){
    deleteCandidate(id: $id){
      id
    }
  }
`;
