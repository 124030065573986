<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord"
                                  @close="selectedRecord = null"
                                  @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Holidays</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-lg-9 m-auto">
              <Search
                :search.sync="search"
                :inputs="['name', 'countries', 'year']"
              ></Search>
            </div>
            <div class="col-12 col-lg-3 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = {}" v-if="$can('CreateHoliday')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="holidays">
      <Empty v-if="!holidays.data.length" icon="fas fa-calendar-day" :can_create="true" @callCreate="selectedRecord = {}"/>
      <Card :key="index" v-for="(record, index) in holidays.data" :record="record" @remove="remove"></Card>
    </div>
    <SkeletonCards v-if="holidays && holidays.paginatorInfo.hasMorePages" :cards=4 :executeScroll="holidays != undefined" @showMore="showMore('holidays')"></SkeletonCards>
  </div>
</template>

<script>

import moment from 'moment';
import HOLIDAYS_QUERY from './queries';
import { CREATE_HOLIDAY, DELETE_HOLIDAY } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import { Search, SkeletonCards, SpinnerRouterLink } from '../../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, SkeletonCards, Search, SpinnerRouterLink,
  },
  apollo: {
    holidays: {
      query: HOLIDAYS_QUERY,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables() {
        return { page: 1, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
  },
  data() {
    return {
      search: { year: moment().format('YYYY') },
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_HOLIDAY,
        variables: {
          id: record.id,
        },
        update: (store, { data: { deleteHoliday } }) => {
          const data = store.readQuery({ query: HOLIDAYS_QUERY, variables: this.queryVariables() });
          data.holidays.data = data.holidays.data.filter((h) => h.id !== deleteHoliday.id);
          store.writeQuery({ query: HOLIDAYS_QUERY, variables: this.queryVariables(), data });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    insert(record) {
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_HOLIDAY,
        variables: {
          holiday: record,
        },
        update: (store, { data: { createHoliday } }) => {
          const data = store.readQuery({ query: HOLIDAYS_QUERY, variables: this.queryVariables() });
          data.holidays.data.unshift(createHoliday);
          store.writeQuery({ query: HOLIDAYS_QUERY, variables: this.queryVariables(), data });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        // We restore the initial user input
        this.selectedRecord = record;
      });
    },
  },
};
</script>
