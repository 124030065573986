<template>
  <div :id="record.id" class="col-md-3 pb-2">
    <Form v-if="is_editting" v-bind:record="record" @close="is_editting = false" @update="update"></Form>
    <div v-if="!is_editting" class="card h-100">
      <div class="card-body pb-2">
        <h5 class="card-title">
          {{ record.name }}
        </h5>
        <h6 class="card-subtitle mb-2 text-body-secondary">
          {{ record.email }}
        </h6>
        <button v-if="!confirmation && $can('UpdateUserForClient')" class="btn btn-company-primary" @click="is_editting = true">
          <i class="fas fa-pen"></i>
          Edit
        </button>
        <Confirmation v-if="$can('CreateUserForClient')" v-model="confirmation" @accepted="remove()" />
      </div>
    </div>
  </div>
</template>

<script>

import { UPDATE_USER } from './mutations';
import Form from './Form.vue';
import { Confirmation } from '../../shared';
import QUERY_USERS from './queries';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    queryVariables() {
      return { client_id: this.$route.params.id };
    },
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_USER,
        variables: {
          id: record.id,
          user: _.pick(record, ['name', 'email', 'client_id']),
        },
        refetchQueries: [{
          query: QUERY_USERS,
          variables: this.queryVariables(),
        },
        ],
      }).then(() => {
        this.is_editting = false;
        this.$emit('refresh');
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
