import { gql } from 'apollo-boost';

export const USER_FRAGMENT = gql`
  fragment user on User {
    id
    name
    identification
    email
    avatar
    hiring_date
    is_billable
    is_external
    is_active
    is_contractor
    is_parent
    phone_extension
    personal_phone
    english_level
    job_title_id
    working_time
    country_id
    partner_id
    date_of_birth
    emergency_contact
    notes
    address
    last_known_internal_net_promoter_score_date
    job_title {
      id
      internal_description
    }
    termination_date
    termination_reason
  }
`;

export const USER_FRAGMENT_WITH_INTERNAL_NPS = gql`
  fragment user_with_internal_nps on User {
    id
    name
    identification
    email
    avatar
    hiring_date
    is_billable
    is_external
    is_active
    is_contractor
    is_parent
    phone_extension
    personal_phone
    english_level
    job_title_id
    working_time
    country_id
    partner_id
    date_of_birth
    emergency_contact
    notes
    address
    last_known_internal_net_promoter_score_date
    last_known_internal_net_promoter_score
    job_title {
      id
      internal_description
    }
    termination_date
    termination_reason
  }
`;
