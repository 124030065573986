<template>
  <div class="col-md-6 pb-2">
    <div class="card h-100">
      <div class="card-header d-flex">
        <div class="col p-0">
          <h5 v-if="!external_company.id">New Company</h5>
          <h5 v-if="external_company.id">{{external_company.name}}</h5>
        </div>
        <div class="col text-end pe-0">
          <button v-if="!confirmation" class="btn btn-company-secondary" @click="confirmation = true">
            <i class="fas fa-trash"></i>
            Delete
          </button>
          <button v-if="confirmation" class="btn btn-company-secondary" @click="confirmation = false">
            <i class="fas fa-times"></i>
            Cancel
          </button>
          <button v-if="confirmation" class="btn btn-success" @click="deleteCompany()">
            <i class="fas fa-check"></i>
            Accept
          </button>
        </div>
      </div>
      <div class="card-body pb-0">
        <div class="row">
          <div class="col-md-6 col-sm-12 mb-3">
            <label for="companyName">Company Name</label>
            <input type="text" class="form-control" v-model="external_company.name" id="companyName" placeholder="Company Name"/>
          </div>
          <div class="col-md-3 col-sm-12 mb-3">
            <label for="start_date">Start Date</label>
            <DatePicker :date="external_company.start_date"
                        placeholder="Select Date"
                        @change="external_company.start_date = $event"></DatePicker>
          </div>
          <div class="col-md-3 col-sm-12 mb-3">
            <label for="end_date">End Date</label>
            <DatePicker :date="external_company.end_date"
                        placeholder="Select Date"
                        @change="external_company.end_date = $event"></DatePicker>
          </div>
        </div>
      </div>
      <div class="card-body pb-0 d-flex justify-content-between">
        <h5>Projects:</h5>
        <button class="btn btn-sm btn-success" @click="addProject()">
          <i class="fas fa-plus"></i>
          Add Project
        </button>
      </div>
      <div class="card-body">
        <ul class="list-group">
          <li class="list-group-item" :key="external_project.id" v-for="(external_project) in external_company.external_projects">
            <button type="button" class="btn-close" aria-label="delete" @click="deleteProject(external_project)">
            </button>
            <div class="mb-3">
              <label for="projectName">Project Name</label>
              <input type="text" class="form-control" v-model="external_project.name" id="projectName" placeholder="Project Name"/>
            </div>
            <div class="mb-3">
              <label for="projectDuration">Duration</label>
              <input type="text" class="form-control" v-model="external_project.duration" id="projectDuration" placeholder="1 year"/>
            </div>
            <div class="mb-3">
              <label for="projectDescription">Project Description</label>
              <input :id="`trix-hidden-${external_project.id}`" type="hidden" v-model="external_project.description">
              <trix-editor @trix-change="external_project.description = $event.target.value; $forceUpdate()"
            placeholder="Project Description" :input="`trix-hidden-${external_project.id}`"></trix-editor>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import { DELETE_EXTERNAL_PROJECT, DELETE_EXTERNAL_COMPANY } from '../mutations';

export default {
  data() {
    return {
      confirmation: false,
    };
  },
  props: ['external_company'],
  methods: {
    addProject() {
      this.external_company.external_projects.push(
        { name: '', description: '' },
      );
    },
    deleteCompany() {
      if (this.external_company.id) {
        this.$apollo.mutate({
          mutation: DELETE_EXTERNAL_COMPANY,
          variables: {
            id: this.external_company.id,
          },
        }).catch((error) => {
          this.$toasted.error(`Unable to delete record: ${error.message}`);
        });
      }
      this.$emit('remove', this.external_company);
    },
    deleteProject(project) {
      if (project.id) {
        this.$apollo.mutate({
          mutation: DELETE_EXTERNAL_PROJECT,
          variables: {
            id: project.id,
          },
        }).catch((error) => {
          this.$toasted.error(`Unable to delete record: ${error.message}`);
        });
      }
      this.external_company.external_projects.splice(this.external_company.external_projects.indexOf(project), 1);
    },
  },
};
</script>
