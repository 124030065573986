import { gql } from 'apollo-boost';

import PRODUCT_FRAGMENT from './fragments';

export default gql`
  query($page: Int, $name: String) {
    products(page: $page, name: $name, orderBy: { column: "id", order: ASC }) @connection(key: "products", filter: ["name"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...product
      }
    }
  }
  ${PRODUCT_FRAGMENT}

`;
