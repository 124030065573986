import { gql } from 'apollo-boost';

export default gql`
  query(
    $start_date: DateTime!,
    $end_date: DateTime!,
    $user_id: ID,
    $project_id: ID,
    $project_role_id: ID,
    $page: Int,
    $orderBy: [OrderByClause!]
  ) {
    projectMemberHistory(
      start_date: $start_date,
      end_date: $end_date,
      user_id: $user_id,
      project_id: $project_id,
      project_role_id: $project_role_id,
      page: $page,
      orderBy: $orderBy
    ){
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        event_type
        created_at
        start_date
        end_date
        hours_per_week
        hourly_rate
        created_by
        delta
        creator {
          id
          name
        }
        project_role {
          id
          name
        }
        project {
          id
          name
        }
        user {
          id
          name
        }
      }
    }
  }
`;
