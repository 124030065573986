import { gql } from 'apollo-boost';

export default gql`
  fragment project on Project {
    id
    name
    description
    internal_description
    is_confidential
    is_internal
    type
    state
    start_date
    end_date
    closed_at
    client_id
    project_status_id
    budget
    last_known_net_promoter_score
    project_status {
      id,
      color,
      name
    }
    estimation_id
    client {
      id
      name
    }
    progress
    technologies {
      id
      name
    }
    vertical_markets{
      id
      name
    }
    members {
      id
      user {
        id
        name
      }
      project_role {
        id
        name
      }
      is_manager
      start_date
      end_date
      contract_end_date
      hours_per_week
      project_role_id
    }
    estimation {
      id
      bucket_estimations {
        id
        bucket {
          id
          name
        }
        total_hours
        total_hours_consumed
      }
      deliverables {
        id
        is_completed
      }
    }
    contacts {
      id
      name
      email
      phone
      position
    }
    tags {
      id
      name
    }
  }
`;
