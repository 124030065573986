<template>
  <div class="row">
    <div :key="row.id" v-for="row in projectMetrics.data" class="col-md-3 pb-2">
      <div class="card h-100">
        <div class="card-body pb-0">
          <h4 class="card-title">
            {{row.project}}
          </h4>
        </div>
        <div class="card-body pb-0">
          <h5 class="card-title">
            Time Consumed
          </h5>
          <div class="progress" role="progressbar" aria-label="Time Consumed" :aria-valuenow="row.progress" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar" :class="row.progress == 0 ? 'text-dark bg-transparent w-100' : ''" :style="{width: `${row.progress}%`}">
              {{row.progress}}%
            </div>
          </div>
        </div>
        <div class="card-body pb-0">
          <h5 class="card-title">
            Budget Consumed
          </h5>
          <div class="progress" role="progressbar" aria-label="Budget Consumed" :aria-valuenow="budgetPercentage(row)" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar" :class="budgetPercentage(row) == 0 ? 'text-dark bg-transparent w-100' : ''" :style="{width: `${budgetPercentage(row)}%`}">
              {{budgetPercentage(row)}}%
            </div>
          </div>
        </div>
        <div v-if="row.scope_percent_completed" class="card-body pb-0">
          <h5 class="card-title">
            Scope Completed
          </h5>
          <div class="progress" role="progressbar" aria-label="Scope Completed" :aria-valuenow="row.scope_percent_completed" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar" :class="row.scope_percent_completed == 0 ? 'text-dark bg-transparent w-100' : ''" :style="{width: `${row.scope_percent_completed}%`}">
              {{row.scope_percent_completed}}%
            </div>
          </div>
        </div>
        <div class="card-body">
          <h5 class="card-title">
            Gross Margin
          </h5>
          <div class="progress" role="progressbar" aria-label="Gross Margin" :aria-valuenow="row.gross_margin_percentage" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar" :class="row.gross_margin_percentage == 0 ? 'text-dark bg-transparent w-100' : ''" :style="{width: `${row.gross_margin_percentage}%`}">
              {{row.gross_margin_percentage}}%
            </div>
          </div>
        </div>
      </div>
    </div>
    <SkeletonCards v-if="projectMetrics && projectMetrics.paginatorInfo.hasMorePages" :cards=4 :executeScroll="projectMetrics != undefined" @showMore="showMore"></SkeletonCards>
  </div>
</template>

<script>

import { SkeletonCards } from '../../shared';

export default {
  props: ['projectMetrics'],
  components: { SkeletonCards },
  data() {
    return {
      orderBy: { column: 'projects.id', order: 'ASC' },
    };
  },
  computed: {
    gross_margin_average() {
      if (this.projectMetrics.length === 0) {
        return 0;
      }
      return (this.totalBy('gross_margin_percentage') / this.projectMetrics.data.length).toFixed(2);
    },
  },
  methods: {
    grossMarginPercentageColor(row) {
      if (row.gross_margin_percentage >= 40) {
        return 'bg-primary';
      }
      if (row.gross_margin_percentage >= 30 && row.gross_margin_percentage <= 39) {
        return 'bg-success';
      }
      return 'bg-danger';
    },
    budgetPercentage(row) {
      return ((row.actual_cost * 100) / row.budget).toFixed(2);
    },
    showMore() {
      this.$emit('showMore');
    },
    totalBy(key) {
      return _.sumBy(
        this.projectMetrics.data,
        key,
      );
    },
  },
};
</script>
