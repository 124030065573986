import { gql } from 'apollo-boost';

export default gql`
query($query: String!) {
  AiQuery(query: $query) {
    id
    user_id
    question
    answer
  }
}
`;
