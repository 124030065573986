import { gql } from 'apollo-boost';

import HEALTH_SCORE_PROJECT_VALUE_FRAGMENT from './fragments';

export default gql`
  query($project_id: ID!, $health_score_configuration_item_id: ID) {
    healthScoreProjectValues(
      project_id: $project_id,
      health_score_configuration_item_id: $health_score_configuration_item_id,
      orderBy: { column: "id", order: ASC }) @connection(key: "healthScoreProjectValues") {
        ...healthScoreProjectValue
    }
  }
  ${HEALTH_SCORE_PROJECT_VALUE_FRAGMENT}
`;
