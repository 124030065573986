<template>
<div :id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">{{record.name}}</h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">{{record.version}}</h6>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateLearningPath')" class="btn btn-company-primary mb-1" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <router-link class="btn btn-company-primary mb-1" v-if="!confirmation && $can('ReadLearningPaths')" :to="{ name: 'learning_path_levels', params: { id: record.id }}" target="_blank">
        <i class="fa-solid fa-layer-group"></i>
        Levels
      </router-link>
      <router-link class="btn btn-company-primary mb-1" v-if="!confirmation && $can('ReadLearningPaths')" :to="{ name: 'Learning Path Nodes', params: { id: record.id }}" target="_blank">
        <i class="fa-solid fa-graduation-cap"></i>
        Nodes
      </router-link>
      <Confirmation v-if="$can('DeleteLearningPath')" v-model="confirmation" @accepted="remove()"/>
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_LEARNING_PATH } from './mutations';
import Form from './Form.vue';
import ErrorHandler from '../../shared/ErrorHandler';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_LEARNING_PATH,
        variables: {
          id: this.record.id,
          learning_path: _.pick(record, ['name', 'version']),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
