<template>
  <table class="table m-0 table-hover w-100">
    <thead>
      <tr>
        <th colspan="4" class="text-center p-1">Mails directory</th>
      </tr>
    </thead>
    <tbody>
      <template v-if="users" >
        <tr v-for="user in users" :key="user.id" >
          <td class="align-middle p-1 ps-2">{{ user.name }}</td>
          <td class="align-middle p-1">{{ user.email }}</td>
          <td class="align-middle p-1">{{ user.created_at_diff }}</td>
          <td class="align-middle text-end p-1 pe-2">
            <button class="btn px-1 py-0 my-1" :class="{'btn-company-secondary': user.is_active, 'btn-company-primary': !user.is_active}" @click="$emit('switch', user.id, !user.is_active)"><i class="fas fa-ban"></i> <b>Ban</b></button>
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['record'],
  computed: {
    users() {
      return { ...this.record };
    },
  },
};
</script>
