export default class ErrorHandler {
  static handle(error, instance) {
    error.graphQLErrors.forEach((e) => {
      instance.$toasted.error(e.message);
      _.keys(e.extensions.validation).forEach((v) => {
        e.extensions.validation[v].forEach((message) => {
          instance.$toasted.error(message);
        });
      });
    });
  }
}
