import { gql } from 'apollo-boost';

export default gql`
    fragment projectMinute on ProjectMinute {
        id
        name
        date
        created_at
        project_id
        user {
            id
            name
        }
    }
`;
