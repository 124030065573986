<template>
  <div>
    <label for="item">
      <slot></slot>
    </label>
    <div class="mb-3 border rounded">
      <template v-if="bucketsSelected">
        <span
          v-for="(item, index) in bucketsSelected"
          :key="item.id"
          class="badge bg-primary me-1 ms-1"
        >
          {{ item.name }}
          <i class="fas fa-times" @click="untag(index)"></i>
        </span>
      </template>
      <input
        list="buckets"
        type="text"
        name="item"
        class="border-0 w-25"
        v-model="current_tag"
        @change="tag()"
      />
    </div>
    <datalist id="buckets" v-if="estimationBucketsDropDown">
      <option :key="record.id" v-for="record in estimationBucketsDropDown">
        {{ record.name }}
      </option>
    </datalist>
  </div>
</template>

<script>
import { dropdownWithAlias } from '../../shared';

export default {
  apollo: {
    estimationBucketsDropDown: {
      query: dropdownWithAlias('estimationBucketsDropDown'),
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables() {
        return { type: 'Bucket', orderBy: [{ column: 'id', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  props: ['selected'],
  data() {
    return {
      current_tag: '',
      bucketsSelected: [],
    };
  },
  created() {
    this.bucketsSelected = this.selected;
  },
  methods: {
    tag() {
      const record = this.estimationBucketsDropDown.find(
        (t) => t.name === this.current_tag,
      );
      if (record) {
        const alReadyExist = this.bucketsSelected.findIndex((e) => e.id === record.id) > -1;
        if (!alReadyExist) {
          this.bucketsSelected.push({ id: record.id, name: record.name });
        }
      }
      this.current_tag = '';
    },
    untag(index) {
      this.bucketsSelected.splice(index, 1);
    },
  },
};
</script>
