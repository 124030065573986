import { gql } from 'apollo-boost';

export default gql`
  fragment attachments on Attachment {
    id
    name
    type
    url
    expires_at
  }
`;
