import { gql } from 'apollo-boost';

import { NET_PROMOTER_SCORE_FRAGMENT } from './fragments';

export const SUBMIT_NET_PROMOTER_SCORE_FORM = gql`
  mutation($input: NetPromoterScoreInput!){
    submitNetPromoterScoreForm(input: $input){
      ...netPromoterScore
    }
  }
  ${NET_PROMOTER_SCORE_FRAGMENT}
`;

export const CREATE_SIGNED_URL = gql`
  mutation($client_id: ID!, $project_id: ID){
    createNetPromoterScoreLink(client_id: $client_id, project_id: $project_id)
  }
`;

export const EXPORT_NPS_ANSWERS = gql`
  mutation(
    $client_id: ID,
    $project_id: ID,
    $end_date: DateTime,
    $start_date: DateTime,
    $orderBy: [OrderByClause!]
  ){
    exportNetPromoterScores(
      client_id: $client_id,
      project_id: $project_id,
      start_date: $start_date,
      end_date: $end_date,
      orderBy: $orderBy
    )
  }
`;

export const EXPORT_INTERNAL_NPS_ANSWERS = gql`
  mutation(
    $user_id: ID,
    $end_date: DateTime,
    $start_date: DateTime,
    $orderBy: [OrderByClause!]
  ){
    exportInternalNetPromoterScores(
      user_id: $user_id,
      start_date: $start_date,
      end_date: $end_date,
      orderBy: $orderBy
    )
  }
`;
