export default class RequestBuilder {
  // resume_url: data.Location
  static build(
    candidate,
    keys = [
      'first_name',
      'last_name',
      'email',
      'phone_number',
      'salary_expectation_range',
      'english_level',
      'notes',
      'resume_text',
      'candidate_status_id',
      'resume_url',
      'availability',
      'is_working',
      'company',
      'application_channel',
      'job_experience_started_date',
      'country_id',
    ],
  ) {
    const requestBody = _.pick(candidate, keys);
    if (candidate.technologies) {
      requestBody.technologies = {
        sync: candidate.technologies
          .filter((tech) => tech.id)
          .map((tech) => tech.id),
        create: candidate.technologies
          .map((tech) => tech)
          .filter((tech) => !tech.id),
      };
    }
    return requestBody;
  }
}
