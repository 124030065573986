import { gql } from 'apollo-boost';

export const COMPANY_CONFIGURATION = gql`
  query($orderBy: [OrderByClause!]) {
    configuration(orderBy: $orderBy) {
      id
      key
      value
      placeholder
    }
  }
`;

export const CONFIGURATION = gql`
  query {
    configuration {
      key
      value
    }
  }
`;
