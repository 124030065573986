<template>
  <span class="bg-transparent" :title="title">
    ${{cost | formatNumber}}/hour
  </span>
</template>
<script>
export default {
  props: ['cost'],
  computed: {
    title() {
      return `$${Math.round(this.cost * 166)}/month`;
    },
  },
};
</script>
