export default class RequestBuilder {
  static build(product) {
    const requestBody = _.pick(product, ['name', 'description', 'price', 'image_url']);
    requestBody.countries = {
      sync: (product.countries || [])
        .filter((country) => country.id)
        .map((country) => country.id),
    };
    return requestBody;
  }
}
