import { gql } from 'apollo-boost';

export default gql`
  fragment opportunity on Opportunity {
    id
    name
    description
    project_id
    client_id
    taken
    state
    begins
    budget
    duration
    seniority
    positions_amount
    english_level
    country_id
    notes
    project{
      id
      name
    }
    client{
      id
      name
    }
    technologies{
      id
      name
    }
    country{
      id
      name
    }
  }
`;
