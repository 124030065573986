<template>
<div :id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting" :record.sync="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{record.name}}
      </h5>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdatePerformanceEvaluationForm')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <router-link class="btn btn-company-primary mb-1" v-if="!confirmation && $can('UpdatePerformanceEvaluationForm')" :to="{ name: 'performance_evaluation_form_questions', params: { id: record.id }}">
        <i class="fa-solid fa-question"></i>
        Questions
      </router-link>
      <Confirmation v-if="$can('DeletePerformanceEvaluationForm')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_PERFORMANCE_EVALUATION_FORM } from './mutations';
import Form from './Form.vue';
import ErrorHandler from '../../shared/ErrorHandler';
import { Confirmation } from '../shared';
import RequestBuilder from './RequestBuilder';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(performanceEvaluationForm) {
      this.$apollo.mutate({
        mutation: UPDATE_PERFORMANCE_EVALUATION_FORM,
        variables: {
          id: performanceEvaluationForm.id,
          performance_evaluation_form: RequestBuilder.build(performanceEvaluationForm),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
