import { gql } from 'apollo-boost';

import ATTACHMENT_FRAGMENT from './fragments';

export default gql`
  mutation($upsertAttachment: UpsertAttachmentInput!) {
    UpsertAttachments(input: $upsertAttachment) {
      ...attachments
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;
