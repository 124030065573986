import Vue from 'vue';
import ApolloClient from 'apollo-boost';
import VueApollo from 'vue-apollo';

const headers = () => {
  if (localStorage.getItem('api_token')) {
    return {
      authorization: `Bearer ${localStorage.getItem('api_token')}`,
    };
  }
  return {};
};

const apolloClient = new ApolloClient({
  uri: '/graphql',
  headers: headers(),
  onError: ({ graphQLErrors }) => {
    if (graphQLErrors[0].message === 'Unauthenticated.') {
      setTimeout(() => {
        document.getElementById('logout-form').submit();
      }, 500);
    }
  },
});

Vue.use(VueApollo);

export default new VueApollo({
  defaultClient: apolloClient,
});
