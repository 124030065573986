<template>
  <div v-if="$can('ReadHome')">
    <InternalNetPromoterScoreForm ></InternalNetPromoterScoreForm>
    <nav aria-label="breadcrumb">
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <ul class="breadcrumb p-0 m-auto">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="fas fa-home"></i>
                </router-link>
              </li>
                <li class="breadcrumb-item active fw-bold" aria-current="page">
                  Public KPIs
                </li>

            </ul>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="publicIndicators">
      <Card :key="indicator.id" v-for="indicator in publicIndicators" :indicator="indicator" :readOnlyMode="true"></Card>
    </div>
  </div>
</template>
<script>

import Card from './indicators/Card.vue';
import { PUBLIC_INDICATORS_QUERY } from './indicators/queries';
import InternalNetPromoterScoreForm from './internal_net_promoter_score/Form.vue';

export default {
  components: { Card, InternalNetPromoterScoreForm },
  apollo: {
    publicIndicators: {
      query: PUBLIC_INDICATORS_QUERY,
      variables() {
        return { orderBy: [{ column: 'id', order: 'ASC' }] };
      },
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
