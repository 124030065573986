<template>
  <div>
    <Form
      v-if="selectedRecord != null"
      v-bind:record="selectedRecord"
      @close="selectedRecord = null"
      @insert="insert"
      :models="MODELS">
    </Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <ul class="breadcrumb p-0 m-auto">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i v-if="!$apollo.loading" class="fas fa-home"></i>
                  <i v-if="$apollo.loading" class="fas fa-sync ms-1 fa-sm" :class="{'fa-spin': $apollo.loading}"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active fw-bold" aria-current="page">
                <router-link to="/integrations">
                  Integrations
                </router-link>
              </li>
              <li class="breadcrumb-item active fw-bold" aria-current="page">
                Mappings
              </li>
            </ul>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6 m-auto">
              <Search
                :search.sync="search"
                :models="MODELS"
              ></Search>
            </div>
            <div class="col-12 col-sm-6 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = { integration_id: $route.params.id }">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="integrationMappings">
      <Card
        :key="index" v-for="(record, index) in integrationMappings.data"
        :record="record"
        @remove="remove"
        @refresh="refresh"
        :models="MODELS"
      ></Card>
    </div>
    <SkeletonCards
      v-if="integrationMappings && integrationMappings.paginatorInfo.hasMorePages"
      :cards=6
      :executeScroll="integrationMappings != undefined"
      @showMore="showMore('integrationMappings')"
    ></SkeletonCards>
  </div>
</template>
<script>

import Search from './Search.vue';
import GET_MAPPINGS from './queries';
import { DELETE_INTEGRATION_MAPPING, CREATE_INTEGRATION_MAPPING } from './mutations';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../../shared';
import Card from './Card.vue';
import Form from './Form.vue';
import { SkeletonCards } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Search, Card, SkeletonCards, Form,
  },
  apollo: {
    integrationMappings: {
      query: GET_MAPPINGS,
      errorPolicy: 'all',
      variables() {
        return {
          page: 1,
          integration_id: this.$route.params.id,
          orderBy: [{ column: 'id', order: 'ASC' }],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
      MODELS: ['Project', 'User'],
    };
  },
  methods: {
    queryVariables() {
      return {
        integration_id: this.$route.params.id,
        orderBy: [{ column: 'id', order: 'ASC' }],
        ...SearchBuilder.build(this.search),
      };
    },
    refresh() {
      this.$apollo.queries.integrationMappings.refetch();
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_INTEGRATION_MAPPING,
        variables: {
          id: record.id,
        },
      })
        .then(() => {
          this.refresh();
        })
        .catch((error) => {
          this.$toasted.error(`Unable to delete record: ${error.message}`);
        });
    },
    insert(record) {
      this.$apollo.mutate({
        mutation: CREATE_INTEGRATION_MAPPING,
        variables: {
          mapping: record,
        },
      })
        .then(() => {
          this.selectedRecord = null;
          this.refresh();
        })
        .catch((error) => {
          ErrorHandler.handle(error, this);
        });
    },
  },
};
</script>
