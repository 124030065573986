import { gql } from 'apollo-boost';

export default gql`
    fragment action on Action {
        id
        status
        name
        project {
          id
          name
        }
        project_minute_id
        project_risk_id
        assign_to_id
        user {
          id
          name
        }
        assigned_to {
          id
          name
        }
    }
`;
