<template>
  <div>
    <nav v-if="performance_evaluation">
      <div class="breadcrumb p-2">
        <div class="col-11 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i v-if="!$apollo.loading" class="fas fa-home"></i>
                <i v-if="$apollo.loading" class="fas fa-sync ms-1 fa-sm" :class="{'fa-spin': $apollo.loading}"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link :to="backRoute">Performance Evaluations</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page" v-if="performance_evaluation">
              {{performance_evaluation.user.name}} - {{performance_evaluation.cycle.name}}
            </li>
          </ol>
        </div>
        <div class="col-1 text-end">
          <button type="button" class="btn btn-company-primary" @click="update" v-if="!readOnly">
            <i class="fas fa-save"></i>
            Save
          </button>
        </div>
      </div>
    </nav>
    <div class="container-fluid" v-if="performance_evaluation">
      <div class="row">
        <div class="col-12">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="ninebox-tab" data-bs-toggle="tab" href="#ninebox" role="tab" aria-controls="ninebox" aria-selected="false">9 Box</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="evaluation-tab" data-bs-toggle="tab" href="#evaluation" role="tab" aria-controls="evaluation" aria-selected="true">Evaluation</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="goals-tab" data-bs-toggle="tab" href="#goals" role="tab" aria-controls="goals" aria-selected="true">Goals</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="notes-tab" data-bs-toggle="tab" href="#notes" role="tab" aria-controls="notes" aria-selected="false">Notes</a>
            </li>
          </ul>
          <div class="tab-content">
            <div class="tab-pane fade show border border-top-0 active" id="ninebox" role="tabpanel" aria-labelledby="ninebox-tab">
              <h3 class="text-center pt-2">9 Box</h3>
              <NineBox @updated="nineBoxUpdated" :performance="performance_evaluation.nine_box_performance_score" :potential="performance_evaluation.nine_box_potential_score" :readOnly="readOnly"></NineBox>
            </div>
            <div class="tab-pane fade show border border-top-0" id="evaluation" role="tabpanel" aria-labelledby="evaluation-tab">
              <h3 class="text-center pt-2">Evaluation</h3>
              <Evaluation @updated="evaluationUpdated" :form="performance_evaluation.form" :readOnly="readOnly" v-if="performance_evaluation.form"></Evaluation>
              <p v-else class="text-center">This evaluation does not have a form associated, please talk to your leader about it.</p>
            </div>
            <div class="tab-pane fade show border border-top-0" id="goals" role="tabpanel" aria-labelledby="goals-tab">
              <h3 class="text-center pt-2">Goals</h3>
              <Goals @updated="goalsUpdated" :readOnly="readOnly" :goals="performance_evaluation.goals"></Goals>
            </div>
            <div class="tab-pane fade show border border-top-0" id="notes" role="tabpanel" aria-labelledby="notes-tab">
              <h3 class="text-center pt-2">Notes</h3>
              <input type="hidden" id="performance_evaluation_notes" v-model="performance_evaluation.notes">
              <trix-editor input="performance_evaluation_notes" @trix-change="performance_evaluation.notes = $event.target.value; $forceUpdate()"
              :disabledEditor="readOnly"></trix-editor>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { PERFORMANCE_EVALUATION_QUERY, MY_PERFORMANCE_EVALUATION_QUERY, MY_PERFORMANCE_EVALUATION_QUERY_USER } from './queries';
import { UPDATE_PERFORMANCE_EVALUATION } from './mutations';
import { ErrorHandler } from '../../shared';
import NineBox from './NineBox.vue';
import Goals from './Goals.vue';
import Evaluation from './Evaluation.vue';
import RequestBuilder from './RequestBuilder';

export default {
  components: { NineBox, Goals, Evaluation },
  apollo: {
    performance_evaluations: {
      query() {
        return this.query;
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { id: this.$route.params.id, include_forms: true };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    performance_evaluation() {
      if (this.performance_evaluations) {
        return this.performance_evaluations.data[0];
      }
      return null;
    },
    readOnly() {
      return this.performance_evaluation.status === 'Closed' || !this.canEdit();
    },
    backRoute() {
      return this.$route.name === 'My Performance Evaluation Detail' ? '/my_performance_evaluations' : '/performance_evaluations';
    },
    query() {
      if (this.$route.name === 'My Performance Evaluation Detail') {
        if (this.$can('ReadPerformanceEvaluations')) {
          return MY_PERFORMANCE_EVALUATION_QUERY;
        }
        return MY_PERFORMANCE_EVALUATION_QUERY_USER;
      }
      return PERFORMANCE_EVALUATION_QUERY;
    },
  },
  methods: {
    update() {
      this.$apollo.mutate({
        mutation: UPDATE_PERFORMANCE_EVALUATION,
        variables: {
          id: this.performance_evaluation.id,
          performance_evaluation: RequestBuilder.build(this.performance_evaluation),
          include_forms: true,
        },
      }).then(() => {
        this.$apollo.queries.performance_evaluations.refetch();
        this.$toasted.success('Performance evaluation updated!');
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    goalsUpdated(goals) {
      this.performance_evaluation.goals = goals;
    },
    evaluationUpdated(form) {
      this.performance_evaluation.form = form;
    },
    nineBoxUpdated(performanceScore, potentialScore) {
      this.performance_evaluation.nine_box_performance_score = performanceScore;
      this.performance_evaluation.nine_box_potential_score = potentialScore;
    },
    canEdit() {
      const userId = window.User ? window.User.user_id : null;
      return this.$can('UpdatePerformanceEvaluation')
        || this.performance_evaluation.evaluator_id === userId;
    },
  },
};
</script>
