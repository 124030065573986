<template>
<div class="mb-5 card">
  <div class="card-header d-flex">
    <div class="col p-0">
      <span v-if="!record.id">New Project</span>
      <span v-if="record.id">{{localRecord.name}}</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="mb-3 col-md-4">
        <label for="name">Name</label>
        <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
        <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="client_id">
          Client <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        </label>
        <select id="client_id" class="form-control form-select" :class="{'is-invalid': v$.localRecord.client_id.$invalid}" v-model="localRecord.client_id">
          <template v-if="clientsDropDown">
            <option :key="client.id" v-for="client in clientsDropDown" :value="client.id">
              {{client.name}}
            </option>
          </template>
        </select>
        <div v-for="error of v$.localRecord.client_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <div class="input-group">
          <label for="budget">Budget</label>
        </div>
        <div class="input-group">
          <span class="input-group-text me-1">$</span>
          <input type="number" class="form-control" :class="{'is-invalid': v$.localRecord.budget.$invalid}" id="budget" v-model="localRecord.budget" min="0">
        </div>
        <div v-for="error of v$.localRecord.budget.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="mb-3">
      <label for="description">Description</label>
      <input type="hidden" id="description" v-model="localRecord.description">
      <trix-editor input="description" @trix-change="localRecord.description = $event.target.value; $forceUpdate()"
      :class="{'is-invalid': v$.localRecord.description.$invalid}" placeholder="Description of the project here"></trix-editor>
      <div v-for="error of v$.localRecord.description.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3">
      <label for="description">Internal Description</label>
      <input type="hidden" id="internal_description" v-model="localRecord.internal_description">
      <trix-editor input="internal_description" @trix-change="localRecord.internal_description = $event.target.value; $forceUpdate()"
      placeholder="Links, Repositories, Designs..."></trix-editor>
    </div>
    <div class="row">
      <div class="mb-3 col-md-4">
        <label for="type">Type</label>
        <select id="type" class="form-control form-select" v-model="localRecord.type" :class="{'is-invalid': v$.localRecord.type.$invalid}">
          <option>Fixed Bid</option>
          <option>Time and Materials</option>
          <option>Retainer Fee</option>
          <option>Support</option>
        </select>
        <div v-for="error of v$.localRecord.type.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="state">State</label>
        <select id="state" class="form-control form-select" v-model="localRecord.state" :class="{'is-invalid': v$.localRecord.state.$invalid}">
          <option>In Progress</option>
          <option>Draft</option>
          <option>On Hold</option>
          <option>Cancelled</option>
          <option>Completed</option>
        </select>
        <div v-for="error of v$.localRecord.state.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="estimation_id">
          Estimation <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        </label>
        <select id="estimation_id" class="form-control form-select" v-model="localRecord.estimation_id">
          <option :value="null">None</option>
          <template v-if="estimationsDropDown">
            <option :key="estimation.id" v-for="estimation in estimationsDropDown" :value="estimation.id">
              {{estimation.name}}
            </option>
          </template>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-4">
        <label for="project_status_id">
          Status <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        </label>
        <select id="project_status_id" class="form-control form-select" :class="{'is-invalid': v$.localRecord.project_status_id.$invalid}" v-model="localRecord.project_status_id">
          <template v-if="projectStatusesDropDown">
            <option :key="project_status.id" v-for="project_status in projectStatusesDropDown" :value="project_status.id">
              {{project_status.name}}
            </option>
          </template>
        </select>
        <div v-for="error of v$.localRecord.project_status_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="is_confidential"></label>
        <div class="form-check mt-3">
          <div class="form-check">
            <input id="is_confidential" type="checkbox" class="form-check-input" v-model="localRecord.is_confidential">
            <label class="form-check-label m-auto" for="is_confidential">Confidential?</label>
          </div>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="is_internal"></label>
        <div class="form-check mt-3">
          <div class="form-check">
            <input id="is_internal" type="checkbox" class="form-check-input" v-model="localRecord.is_internal">
            <label class="form-check-label m-auto" for="is_internal">Internal?</label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-4">
        <label for="start_date">Start date</label>
        <i class="fa-solid fa-calendar"></i>
        <DatePicker :date="localRecord.start_date"
                    placeholder="Select Date"
                    :css_class="{'is-invalid': v$.localRecord.start_date.$invalid}"
                    @change="localRecord = { ...localRecord, start_date: $event }"></DatePicker>
        <div v-for="error of v$.localRecord.start_date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-4" >
        <label for="end_date">End date</label>
        <i class="fa-solid fa-calendar"></i>
        <DatePicker :date="localRecord.end_date"
                    placeholder="Select Date"
                    :css_class="{'is-invalid': v$.localRecord.end_date.$invalid}"
                    @change="localRecord = { ...localRecord, end_date: $event }"></DatePicker>
        <div v-for="error of v$.localRecord.end_date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div v-if="['Cancelled', 'Completed'].includes(localRecord.state)" class="mb-3 col-md-4">
        <label for="closed_at">Closed At</label>
        <i class="fa-solid fa-calendar"></i>
        <DatePicker :date="localRecord.closed_at"
                    placeholder="Select Date"
                    :css_class="{'is-invalid': v$.localRecord.closed_at.$invalid}"
                    @change="localRecord = { ...localRecord, closed_at: $event }"></DatePicker>
        <div v-for="error of v$.localRecord.closed_at.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12">
        <TechnologyPicker :selected="localRecord.technologies">
          Technologies:
        </TechnologyPicker>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12">
        <VerticalMarketPicker :selected="localRecord.vertical_markets">
          Vertical Markets:
        </VerticalMarketPicker>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12">
        <TagsPicker :selected="localRecord.tags">
          Tags:
        </TagsPicker>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12">
        <ProjectMemberPicker :selected="localRecord.members" :default_contract_end_date="localRecord.end_date">Team Members:</ProjectMemberPicker>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12">
        <ContactPicker :contacts="localRecord.contacts || []">Contacts:</ContactPicker>
      </div>
    </div>
    <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Save
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>

<script>

import Validations from './Validations';
import { Blinkable, dropdownWithAlias } from '../../shared';
import TechnologyPicker from '../technologies/Picker.vue';
import VerticalMarketPicker from '../vertical_markets/Picker.vue';
import TagsPicker from '../tags/Picker.vue';
import ProjectMemberPicker from '../project_members/Picker.vue';
import ContactPicker from '../contactables/Picker.vue';

export default {
  mixins: [Blinkable, Validations],
  components: {
    TechnologyPicker,
    VerticalMarketPicker,
    TagsPicker,
    ProjectMemberPicker,
    ContactPicker,
  },
  apollo: {
    clientsDropDown: {
      query: dropdownWithAlias('clientsDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Client', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    projectStatusesDropDown: {
      query: dropdownWithAlias('projectStatusesDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'ProjectStatus', orderBy: [{ column: 'id', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    estimationsDropDown: {
      query: dropdownWithAlias('estimationsDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Estimation', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
