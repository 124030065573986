<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Internal Net Promoter Score Answers</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search
                :search.sync="search"
                :inputs="['users', 'dateRanges', 'empty_value']"
              ></Search>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col order-last text-end">
              <button v-if="$can('ExportInternalNetPromoterScoreAnswers')" @click="exportFile()" class="btn btn-company-primary m-1">
                <i class="fas" :class="is_exporting ? 'fa-sync fa-spin' : 'fa-file-excel'"></i>
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <template v-if="internalNetPromoterScoreAnswers">
      <Empty v-if="!internalNetPromoterScoreAnswers.data.length" icon="fa-solid fa-ranking-star"/>
      <Card
        v-for="(record, index) in internalNetPromoterScoreAnswers.data"
        :key="index" :record="record"/>
    </template>
    <SkeletonRow
      v-if="internalNetPromoterScoreAnswers && internalNetPromoterScoreAnswers.paginatorInfo.hasMorePages"
      :colspan=7
      :executeScroll="internalNetPromoterScoreAnswers != undefined"
      @showMore="showMore('internalNetPromoterScoreAnswers')">
    </SkeletonRow>

  </div>
</template>

<script>
import moment from 'moment';
import { SearchBuilder, PaginationHandler, Base64File } from '../../../shared';
import { Search, SkeletonRow, SpinnerRouterLink } from '../../shared';
import { INTERNAL_NPS_ANSWERS_QUERY } from '../queries';
import { EXPORT_INTERNAL_NPS_ANSWERS } from '../mutations';
import Card from '../Card.vue';

export default {
  extends: PaginationHandler,
  components: {
    Card,
    Search,
    SkeletonRow,
    SpinnerRouterLink,
  },
  apollo: {
    internalNetPromoterScoreAnswers: {
      query: INTERNAL_NPS_ANSWERS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, orderBy: [{ column: 'created_at', order: 'DESC' }], ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      style: 'col-md-4',
      is_exporting: false,
      search: {
        end_date: this.$route.query.end_date,
        start_date: this.$route.query.start_date,
      },
    };
  },
  methods: {
    queryVariables() {
      return { orderBy: [{ column: 'created_at', order: 'DESC' }], ...SearchBuilder.build(this.search) };
    },
    exportFile() {
      this.is_exporting = true;
      this.$apollo.mutate({
        mutation: EXPORT_INTERNAL_NPS_ANSWERS,
        variables: {
          orderBy: [{ column: 'created_at', order: 'DESC' }], ...SearchBuilder.build(this.search),
        },
      }).then(({ data: { exportInternalNetPromoterScores } }) => {
        const exportTime = moment().format('yyyy-MM-DD');
        Base64File.download(exportInternalNetPromoterScores, `Internal Net Promoter Scores ${exportTime}.xlsx`);
      }).finally(() => {
        this.is_exporting = false;
      });
    },
  },
};
</script>
