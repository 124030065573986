import { gql } from 'apollo-boost';

import BADGE_FRAGMENT from './fragments';

export default gql`
  query($page: Int,
        $name: String) {
    badges(
      page: $page,
      name: $name,
      orderBy: { column: "id", order: ASC }) @connection(key: "badges", filter: ["name"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...badge
      }
    }
  }
  ${BADGE_FRAGMENT}
`;
