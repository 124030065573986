/* eslint-disable lines-between-class-members, class-methods-use-this */
import moment from 'moment';
import { computed } from 'vue';

export default class WeekManager {
  constructor() {
    this.days = Object.freeze(['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']);
    this.monday = moment().weekday(1);
    this.weekdays = computed(() => this.days.map((day) => {
      const weekday = moment(this.monday).day(day);
      weekday.placeholder = this.getPlaceholder(weekday.isoWeek(), weekday.isoWeekday());
      return weekday;
    }));
  }

  currWeek() { return this.monday.week(); }
  prevWeek() { this.monday = moment(this.monday).subtract(7, 'days'); }
  nextWeek() { this.monday = moment(this.monday).add(7, 'days'); }

  static isWeekend(weekday) { return moment(weekday).day() % 6 === 0; }
  isWeekend(weekday) { return moment(weekday).day() % 6 === 0; }

  get(weekday) {
    if (typeof weekday === 'number') return this.weekdays[weekday];
    const index = this.days.findIndex((value) => value.toLowerCase() === weekday.toLowerCase());
    return (index !== -1) ? this.weekdays[index] : null;
  }

  getPlaceholder(weekNumber, weekDay) {
    const day = moment().isoWeek(weekNumber).isoWeekday(weekDay).format('YYYY-MM-DD');
    if (day === moment().format('YYYY-MM-DD')) {
      return 'What did you do today? 🙂';
    }
    if (day === moment().subtract(1, 'day').format('YYYY-MM-DD')) {
      return 'What did you do yesterday? 🙂';
    }
    if (day === moment().add(1, 'day').format('YYYY-MM-DD')) {
      return 'What do you plan to do tomorrow? 🙂';
    }
    if (moment().isAfter(day)) {
      return `What did you do last ${this.days[weekDay]}? 🙂`;
    }
    return `What do you plan to do this ${this.days[weekDay]}? 🙂`;
  }
}
