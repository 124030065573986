import { gql } from 'apollo-boost';

export default gql`
  query($year: Int!, $month: Int!, $client_id: ID, $project_id: ID, $user_id: ID, $only_my_projects: Boolean) {
    approved_paid_time_off_request_days(year: $year,
                                        month: $month,
                                        client_id: $client_id,
                                        project_id: $project_id,
                                        user_id: $user_id,
                                        only_my_projects: $only_my_projects) {
      id
      date
      type
      paid_time_off_request {
        id
        user {
          id
          name
        }
      }
    }
  }
`;
