import { gql } from 'apollo-boost';

import TEMPLATE_FRAGMENT from './fragments';

export const CREATE_TEMPLATE = gql`
  mutation($newTemplate: NotificationTemplatesInput!){
    createNotificationTemplates(input: $newTemplate){
      ...template
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

export const UPDATE_TEMPLATE = gql`
  mutation($id: ID!, $template: NotificationTemplatesInput!){
    updateNotificationTemplates(id: $id, input: $template){
      ...template
    }
  }
  ${TEMPLATE_FRAGMENT}
`;

export const DELETE_TEMPLATE = gql`
  mutation($id: ID!){
    deleteNotificationTemplates(id: $id){
      id
    }
  }
`;
