import { gql } from 'apollo-boost';

export const DETAILED_HOURS_PER_PROJECT = gql`
query($start_date: DateTime!,
      $end_date: DateTime!,
      $user_id: ID,
      $project_id: ID,
      $page: Int,
      $orderBy: [OrderByClause!]) {
    hours(start_date: $start_date,
          end_date: $end_date,
          user_id: $user_id,
          project_id: $project_id,
          page: $page,
          orderBy: $orderBy)
    {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        hours
        date: created_at
        user_id
        project_id
        activities
        user{
          id
          name
        }
        project{
          id
          name
        }
      }
    }
  }
`;

export const DETAILED_HOURS_PER_PROJECT_WITH_COSTS = gql`
query($start_date: DateTime!,
      $end_date: DateTime!,
      $user_id: ID,
      $project_id: ID,
      $page: Int,
      $orderBy: [OrderByClause!]) {
    hours(start_date: $start_date,
          end_date: $end_date,
          user_id: $user_id,
          project_id: $project_id,
          page: $page,
          orderBy: $orderBy)
    {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        hours
        costs
        date: created_at
        user_id
        project_id
        activities
        user{
          id
          name
        }
        project{
          id
          name
        }
      }
    }
  }
`;
