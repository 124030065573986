<style>
  #Creating {
    --spinner-color: #365aadcc;
    position: fixed;
    padding-top: 50px;
    background-color: #4e80f522;
    top: 0%; left: 0%;
    width: 100%; height: 100%;
  }
  #Creating > div{
    position: relative;
    width: 100%; height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  #Creating > div > p {
    margin: 0;
    color: var(--spinner-color);
    text-align: center;
    justify-content: center;
    max-width: 80vmin;
    font-weight: bold;
    line-height: 1.2;
    font-size: 6vmin !important;
  }
  #Creating > div > span {
    position: absolute;
    top: 0%; left: 0%; right: 0%; bottom: 0%;
    margin: auto;
    border: solid 1.7vmin;
    border-radius: 100%;
    border-color: #0000 var(--spinner-color) var(--spinner-color) var(--spinner-color);
    width: 84vmin; height: 84vmin;
    animation: spinner 1.2s infinite;
  }
  @keyframes spinner {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
<template>
  <div id="Creating">
    <div>
      <span class="spinner"></span>
      <p>{{progress}}<br v-if="progress" />{{status}}</p>
    </div>
  </div>
</template>
<script>

export default {
  props: ['channel_id'],
  data() {
    return {
      progress: null,
      status: 'Starting in few seconds',
    };
  },
  mounted() {
    Echo.channel(`createTenant-status${this.channel_id}`)
      .listen('CreateTenantStatusChangedEvent', (tenant) => {
        this.progress = tenant.progress;
        this.status = tenant.status;
        if (tenant.domain) {
          this.progress = 'Congratulations';
          this.status = 'Redirecting to your new Tenant';
          window.location.href = `${tenant.domain}/login`;
        }
      });
  },
};
</script>
