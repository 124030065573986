<template>
<div class="mb-5 card">
  <div class="card-header d-flex">
    <div class="col p-0">
      <span v-if="!record.id">New Project Action</span>
      <span v-if="record.id">Edit Project Action</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body">
    <div class="mb-3">
      <label for="assign_to_id" class="pe-2">Assign To: <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i></label>
      <Select v-model="localRecord.assign_to_id"
              :name="`assign_to_id`"
              :query="usersQuery"
              :queryVariables="{ orderBy: [{ column: 'name', order: 'ASC' }], is_active: true, is_external: false }"></Select>
      <div v-for="error of v$.localRecord.assign_to_id.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div class="mb-3 col-md-12">
      <label for="name">Description</label>
      <trix-editor @trix-change="localRecord.name = $event.target.value; $forceUpdate()"></trix-editor>
      <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Save
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>

<script>

import Validations from './Validations';
import { Blinkable, USERS } from '../../shared';
import { Select } from '../shared';

export default {
  mixins: [Blinkable, Validations],
  components: { Select },
  data() {
    return {
      usersQuery: USERS,
      localRecord: structuredClone(this.record),
    };
  },
  props: ['record'],
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
