<template>
    <div :id="localRecord.id" class="pb-2" :class="is_editting ? 'col-md-12' : 'col-md-6'">
      <Form v-if="is_editting" v-bind:record="localRecord" @close="is_editting = false" @update="update"></Form>
      <div v-if="!is_editting" class="card h-100">
        <div class="card-body pb-0">
          <h5 class="card-title">
            {{ localRecord.name }}
          </h5>
          <div class="pt-1 mb-2">
            <span class="badge bg-company-third-ligth">{{localRecord.type}}</span>
          </div>
          <div class="pt-0" v-if="$can('ReadCostPerUser')">
            <div class="row">
              <div class="col-md-12 pb-2">
                <div class="company-text-secondary bg-light-gray text-center">
                  <i class="fa-solid fa-hand-holding-dollar"></i>
                  Costs
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <td>Last Week</td>
                      <td>Last Month</td>
                      <td>Last Year</td>
                    </tr>
                    <tr>
                      <td>
                        <span>${{(localRecord.last_week_cost ? localRecord.last_week_cost.toFixed(2) : 0) |formatNumber}}</span>
                      </td>
                      <td>
                        <span>${{(localRecord.last_month_cost ? localRecord.last_month_cost.toFixed(2) : 0)  | formatNumber}}</span>
                      </td>
                      <td>
                        <span>${{(localRecord.last_year_cost ? localRecord.last_year_cost.toFixed(2) : 0) | formatNumber}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="pt-0" v-if="localRecord.users ? (localRecord.users.length > 0) : false">
              <div class="row">
                <div class="col-md-12">
                  <strong>People: ({{localRecord.users.length}})</strong>
                </div>
                <div class="col-md-12">
                  <span :key="localRecord.id" v-for="localRecord in localRecord.users" class="badge bg-primary bg-company-primary me-1">{{localRecord.name}}</span>
                </div>
              </div>
            </div>
            <div class="pt-0" v-if="localRecord.contacts && localRecord.contacts.length > 0">
              <div class="row">
                <div class="col-md-12 pb-2">
                  <div class="company-text-secondary text-center"><i class="fa-solid fa-users"></i> Contacts</div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <ContactViewer :contacts="localRecord.contacts"></ContactViewer>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-footer">
          <button v-if="!confirmation && $can('UpdatePartner')" class="btn btn-company-primary" @click="is_editting = true">
            <i class="fas fa-pen"></i>
            Edit
          </button>
          <confirmation v-if="$can('DeletePartner')"
            v-model="confirmation"
            @accepted="remove()"
            class="d-inline-flex"
          />
          <router-link class="btn btn-company-primary"
                       v-if="!confirmation && $can('AdminPartnerAttachments')"
                       :to="{ name: 'partner_attachments', params: { id: localRecord.id }}">
          <i class="fas fa-paperclip"></i>
          Attachments
          </router-link>
          <a class="btn btn-company-primary" v-if="localRecord.website" :href="localRecord.website" target="_blank">
            <i class="fa-solid fa-square-arrow-up-right"></i>
            Web Site
          </a>
        </div>
      </div>
    </div>
</template>

<script>
import { UPDATE_MUTATION } from './mutations';
import Form from './Form.vue';
import ContactViewer from '../contactables/Viewer.vue';
import ErrorHandler from '../../shared/ErrorHandler';
import RequestBuilder from './RequestBuilder';
import { Confirmation } from '../shared';

export default {
  components: { Form, ContactViewer, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  computed: {
    localRecord() {
      return { ...this.record };
    },
  },
  methods: {
    update(localRecord) {
      this.$apollo.mutate({
        mutation: UPDATE_MUTATION,
        variables: {
          id: localRecord.id,
          record: RequestBuilder.build(localRecord),
        },
      }).then(() => {
        this.$emit('update', localRecord);
        this.is_editting = false;
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    remove() {
      this.$emit('remove', this.localRecord);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
