<template>
  <tr id="skeleton">
    <td :colspan="colspan">
      <vue-content-loading :width="250" :height="10">
        <rect x="0" y="0" rx="0" ry="0" width="250" :height="10" />
      </vue-content-loading>
    </td>
  </tr>
</template>
<script>

import { VueContentLoading } from 'vue-content-loading';

export default {
  components: { VueContentLoading },
  props: ['colspan', 'executeScroll'],
  mounted() {
    this.scroll();
  },
  methods: {
    scroll() {
      const observer = new IntersectionObserver((entries) => {
        if (this.executeScroll && entries[0].isIntersecting === true) {
          this.$emit('showMore');
        }
      }, { threshold: [0.5] });
      observer.observe(document.querySelector('#skeleton'));
    },
  },
};
</script>
