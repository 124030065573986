<style scoped>
.component-gradient-blue {
  background: linear-gradient(256.34deg, #39515b 3.29%, #072532 70.78%);
}
.component-gradient-orange {
  background: linear-gradient(256.34deg, #f08562 3.29%, #ec663b 70.78%);
}
.component-gradient-green {
  background: linear-gradient(66.47deg, #52b797 1.28%, #437f99 100%);
}
</style>

<template>
  <div class="my-career-greeting d-flex flex-column align-items-center my-4" v-if="learning_path">
    <div class="text-warning mb-3">
      <i class="fa-solid fa-star fa-lg"></i>
      <i class="fa-solid fa-star fa-2xl"></i>
      <i class="fa-solid fa-star fa-lg"></i>
    </div>
    <h2>Welcome to your career {{ getCroppedName }}</h2>
    <div class="row text-light" v-if="learning_path">
      <div class="col-xs-12 col-sm-4 mb-3">
        <div class="component-gradient-blue rounded-4 d-flex align-items-center justify-content-between h-100 px-4 py-2 shadow-lg">
          <h4 class="m-1">Paths in progress:</h4>
          <h2 class="fw-bold m-1">{{ getProgressCount(1) }}</h2>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 mb-3">
        <div class="component-gradient-orange rounded-4 d-flex align-items-center justify-content-between h-100 px-4 py-2 shadow-lg">
          <h4 class="m-1">Learning percentage:</h4>
          <h2 class="fw-bold m-1">{{ learningPercentage }}%</h2>
        </div>
      </div>
      <div class="col-xs-12 col-sm-4 mb-3">
        <div class="component-gradient-green rounded-4 d-flex align-items-center justify-content-between h-100 px-4 py-2 shadow-lg">
          <h4 class="m-1">Available paths:</h4>
          <h2 class="fw-bold m-1">{{ getProgressCount(0) }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['learning_path'],
  computed: {
    getCroppedName() {
      const name = document.querySelector("meta[name='user-name']").getAttribute('content');
      return name.split(' ')[0];
    },
    learningPercentage() {
      const totalNodes = _.sum(this.learning_path.map((lp) => lp.total_nodes));
      const completedNodes = _.sum(this.learning_path.map((lp) => lp.completed_nodes));
      if (totalNodes > 0) {
        return Math.round((completedNodes / totalNodes) * 100);
      }
      return 0;
    },
  },
  methods: {
    getProgressCount(determiner) {
      return this.learning_path.filter((lp) => lp.completed_nodes >= determiner).length;
    },
  },
};
</script>
