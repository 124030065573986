<template>
  <div>
    <label for="item">
      <slot></slot>
    </label>

    <div class="row">
      <div class="mb-3 col-md-8">
        <label for="project_role">Project Role</label>
        <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        <select ref="project_role" id="project_role" class="form-control form-select me-sm-2" v-model="currentResource.project_role">
          <template v-if="projectRolesDropDown">
            <option :key="project_role.id" v-for="project_role in projectRolesDropDown" :value="project_role">
              {{project_role.name}}
            </option>
          </template>
        </select>
      </div>
      <div class="mb-3 col-md-2">
        <label for="quantity">Quantity</label>
        <input type="number" min="1" id="quantity" class="form-control me-sm-2" v-model="currentResource.quantity">
      </div>
      <div class="mb-3 col-md-2">
        <label for="add" class="invisible">Add</label>
        <button id="add" type="button" :disabled="!currentResource.quantity || !currentResource.project_role" class="btn btn-company-secondary form-control" @click="addResource()">
          <i class="fas fa-plus"></i>
        </button>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-12">
        <table class="table" v-if="localRecord">
          <tbody>
            <template v-for="resource in localRecord">
              <tr :key="resource.id"  v-if="resource.quantity && resource.project_role" >
                <td>{{resource.project_role.name}}</td>
                <td>{{resource.quantity}}</td>
                <td>
                  <button class="btn btn-company-primary" @click="removeResource(resource)">
                    <i class="fas fa-trash"></i>
                  </button>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>

import { dropdownWithAlias } from '../../../../shared';

export default {
  apollo: {
    projectRolesDropDown: {
      query: dropdownWithAlias('projectRolesDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'ProjectRole', orderBy: [{ column: 'id', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  props: ['selected'],
  data() {
    return {
      localRecord: this.selected,
      currentResource: { quantity: 1 },
    };
  },
  methods: {
    show(resource) {
      return (!resource.deleted === true) || true;
    },
    addResource() {
      this.selected.push({
        quantity: this.currentResource.quantity,
        project_role: {
          id: this.currentResource.project_role.id,
          name: this.currentResource.project_role.name,
        },
      });
      this.currentResource = { quantity: 1 };
      this.$refs.project_role.focus();
    },
    removeResource(projectMember) {
      projectMember.quantity = null;
      projectMember.project_role = null;
    },
  },
};
</script>
