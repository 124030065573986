<template>
  <div class="d-inline card-body m-0 p-0">
    <template v-if="isConfirmating !== true">
      <button class="btn btn-company-primary" @click="toggle()">
        <i :class="icon"></i>
        <template v-if="showLabels">{{ text }}</template>
      </button>
    </template>
    <template v-else>
      <button class="btn btn-company-primary" @click="toggle()">
        <i class="fas fa-times"></i>
        <template v-if="showLabels">Cancel</template>
      </button>
      <button class="btn btn-company-secondary" @click="accept()">
        <i class="fas fa-check"></i>
        <template v-if="showLabels">Accept</template>
      </button>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    id: null,
    value: { type: Boolean, default: null },
    showLabels: { type: Boolean, default: true },
    text: { type: String, default: 'Delete' },
    icon: { type: String, default: 'fas fa-trash' },
  },
  data() {
    return {
      hasModel: this.value !== null,
      handleValue: null,
    };
  },
  computed: {
    isConfirmating: {
      get() { return this.handleValue === null ? this.value : this.handleValue; },
      set(input) { this.handleValue = input; },
    },
  },
  methods: {
    accept() {
      this.$emit('accepted', this.id);
      this.toggle();
    },
    toggle() {
      if (this.hasModel) {
        this.$emit('input', !this.value);
      } else {
        this.isConfirmating = !this.isConfirmating;
        this.$emit('isConfirmating', this.isConfirmating);
      }
    },
  },
};
</script>
