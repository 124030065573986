import { gql } from 'apollo-boost';

export default gql`
  query (
    $page: Int
    $title: String
    $suggestion: String
    $status: String
    $orderBy: [OrderByClause!]
  ) {
    suggestions(
      page: $page
      title: $title
      suggestion: $suggestion
      status: $status
      orderBy: $orderBy
    )
      @connection(
        key: "suggestions"
        filter: ["title", "status", "suggestion"]
      ) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        title
        suggestion
        status
        created_at
      }
    }
  }
`;
