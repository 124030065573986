<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord" @close="selectedRecord = null" @insert="insert">
    </Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Estimations</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-8 m-auto mt-2">
              <Search :search.sync="search" :inputs="['name', 'clients']"></Search>
            </div>
            <div class="col-12 col-sm-4 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = { buckets: [], technologies: [] }" v-if="$can('CreateEstimation')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="estimations">
      <Empty v-if="!estimations.data.length" icon="fas fa-tasks" :can_create="true" @callCreate="selectedRecord = { buckets: [], technologies: [] }"/>
      <Card :key="index" v-for="(estimation, index) in estimations.data" :record="estimation" @refresh="refresh" @remove="remove"></Card>
    </div>
    <SkeletonCards
      v-if="estimations && estimations.paginatorInfo.hasMorePages"
      :cards="3"
      :executeScroll="estimations != undefined"
      @showMore="showMore('estimations')"
    ></SkeletonCards>
  </div>
</template>

<script>
import { ESTIMATIONS_QUERY } from './queries';
import { CREATE_ESTIMATION, DELETE_ESTIMATION } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import RequestBuilder from './RequestBuilder';
import { Search, SkeletonCards, SpinnerRouterLink } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Form,
    Card,
    Search,
    SkeletonCards,
    SpinnerRouterLink,
  },
  apollo: {
    estimations: {
      query: ESTIMATIONS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return SearchBuilder.build(this.search);
    },
    refresh() {
      this.$apollo.queries.estimations.refetch();
    },
    insert(estimation) {
      // We save the user input in case of an error
      const newEstimation = estimation;
      this.selectedRecord = null;
      this.$apollo
        .mutate({
          mutation: CREATE_ESTIMATION,
          variables: {
            estimation: RequestBuilder.build(estimation),
          },
          update: (store, { data: { createEstimation } }) => {
            const data = store.readQuery({ query: ESTIMATIONS_QUERY, variables: this.queryVariables() });
            data.estimations.data.unshift(createEstimation);
            store.writeQuery({ query: ESTIMATIONS_QUERY, variables: this.queryVariables(), data });
          },
        })
        .catch((error) => {
          ErrorHandler.handle(error, this);
          // We restore the initial user input
          this.selectedRecord = newEstimation;
        });
    },
    remove(estimation) {
      this.$apollo.mutate({
        mutation: DELETE_ESTIMATION,
        variables: {
          id: estimation.id,
        },
        update: (store, { data: { deleteEstimation } }) => {
          const data = store.readQuery({ query: ESTIMATIONS_QUERY, variables: this.queryVariables() });
          data.estimations.data = data.estimations.data.filter((e) => e.id !== deleteEstimation.id);
          store.writeQuery({ query: ESTIMATIONS_QUERY, variables: this.queryVariables(), data });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
  },
};
</script>
