import { gql } from 'apollo-boost';

import DELIVERABLE_FRAGMENT from './fragments';

export const CREATE_DELIVERABLE = gql`
  mutation($deliverable: DeliverableInput!){
    createDeliverable(input: $deliverable) {
      ...deliverable
    }
  }${DELIVERABLE_FRAGMENT}
`;

export const UPDATE_DELIVERABLE = gql`
  mutation($id: ID!, $deliverable: DeliverableInput!){
    updateDeliverable(id: $id, input: $deliverable) {
    ...deliverable
  }
} ${DELIVERABLE_FRAGMENT}
`;

export const DELETE_DELIVERABLE = gql`
  mutation($id: ID!){
    deleteDeliverable(id: $id) {
      id
    }
  }
`;

export const EXPORT_DELIVERABLE_TO_YOUTRACK = gql`
  mutation($id: ID!, $youtrack_project_id: String!){
    exportDeliverableToYoutrack(id: $id, youtrack_project_id: $youtrack_project_id)
  }
`;
