<template>
<div :id="record.id" class="pb-2" :class="is_editting ? 'col-md-12' : 'col-md-3'">
  <Form v-if="is_editting"
    v-bind:record="record"
    :totalAvailablePercentage="totalAvailablePercentage"
    @close="is_editting = false"
    @update="update"
  ></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <Progress :color="'Green'" :progress="record.percentage_weight"></Progress>
      <div class="mb-2">
        <div class="row">
          <div class="col-md-12">
            <h5 class="card-title border-bottom mb-2 pb-1">{{record.name}}</h5>
          </div>
          <div class="col-md-12 text-body-secondary">
            <h6 class="card-subtitle mb-2">{{getSuccessMessage(record.success_operator, record.success_value_a, record.success_value_b)}}</h6>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <button v-if="!confirmation && $can('UpdateHealthScoreConfigurationItem')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteHealthScoreConfigurationItem')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_HEALTH_SCORE_CONFIGURATION_ITEM } from './mutations';
import Form from './Form.vue';
import ErrorHandler from '../../shared/ErrorHandler';
import { Confirmation } from '../shared';
import Progress from '../shared/Progress.vue';

export default {
  components: { Form, Confirmation, Progress },
  props: ['record', 'totalAvailablePercentage'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(healthScoreConfigurationItem) {
      this.$apollo.mutate({
        mutation: UPDATE_HEALTH_SCORE_CONFIGURATION_ITEM,
        variables: {
          id: this.record.id,
          healthScoreConfigurationItem: _.pick(healthScoreConfigurationItem, ['name', 'description', 'percentage_weight', 'success_operator', 'success_value_a', 'success_value_b']),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
    getSuccessMessage(operator, valueA, valueB) {
      const messages = {
        equals_to: `Value must be equal to ${valueA}`,
        greater_than: `Value must be greater than ${valueA}`,
        less_than: `Value must be less than ${valueA}`,
        between: `Value must be between ${valueA} and ${valueB}`,
        is_true: 'Value must be true',
        is_false: 'Value must be false',
      };
      return messages[operator];
    },
  },
};
</script>
