<template>
  <tr>
    <td>
      {{bucket_estimation.bucket.name}}
      <br />
      <span
        v-if="bucket_estimation.last_update_at"
        class="text-body-secondary"
      >updated {{bucket_estimation.last_update_at}}</span>
    </td>
    <td>
      <div class="progress" role="progressbar" aria-label="Current Progress" :aria-valuenow="bucket_estimation.total_hours_consumed" aria-valuemin="0" :aria-valuemax="bucket_estimation.total_hours">
        <div class="progress-bar text-start ps-2"
            :class="percentage == 0 ? 'text-dark bg-transparent w-100' : ''"
            :style="{ width: percentage + '%' }">
          ({{bucket_estimation.total_hours_consumed}} of out {{bucket_estimation.total_hours}}) hours consumed {{percentage}}%
        </div>
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-between" v-if="members">
        <select class="mb-2 me-sm-2 form-control form-select" v-model="user_id">
          <option v-for="member in members" :value="member.user.id"
                  v-bind:key="member.user.id">
            {{member.user.name}}
          </option>
        </select>
      </div>
    </td>
    <td>
      <div class="d-flex justify-content-between" v-if="bucket_estimation.deliverables.length">
        <select class="mb-2 me-sm-2 form-control form-select" v-model="selectedDeliverable">
          <option v-for="deliverable in activeDeliverables" :value="deliverable"
                  v-bind:key="deliverable.id">
            {{deliverable.name}}
          </option>
        </select>
      </div>
    </td>
    <td>
      <TimeUnits @addTime="addTime"></TimeUnits>
    </td>
  </tr>
</template>

<script>
import TimeUnits from './TimeUnits.vue';

export default {
  components: { TimeUnits },
  props: ['bucket_estimation', 'members'],
  data() {
    return {
      user_id: '',
      activeDeliverables: this.getActiveDeliverables(),
      selectedDeliverable: this.getActiveDeliverables()
        ? this.getActiveDeliverables()[0]
        : null,
    };
  },
  computed: {
    percentage() {
      return (
        (this.bucket_estimation.total_hours_consumed * 100)
        / this.bucket_estimation.total_hours
      ).toFixed(2);
    },
  },
  methods: {
    addTime(hours) {
      this.bucket_estimation.total_hours_consumed += hours;
      this.$emit('update', {
        bucket_estimation_id: this.bucket_estimation.id,
        deliverable_id: this.selectedDeliverable ? this.selectedDeliverable.id : null,
        hours,
        user_id: this.user_id,
      });
      this.user_id = '';
    },
    getActiveDeliverables() {
      return this.bucket_estimation.deliverables
        .filter((d) => !d.is_completed)
        .sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    },
  },
};
</script>
