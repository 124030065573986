<template>
  <div class="row">
    <div class="col-md-12">
      <ul class="list-group">
        <li class="list-group-item">
          {{user.name}}
          <div class="progress" role="progressbar" aria-label="Resume Score" :aria-valuenow="user.resume_score.score" aria-valuemin="0" aria-valuemax="100">
            <div class="progress-bar text-start ps-2 fw-bold"
                 :class="barColor"
                 :style="{ width: user.resume_score.score + '%' }">
              Resume Score: {{user.resume_score.score}}%
            </div>
          </div>
        </li>
        <li class="list-group-item" :key="`i-${index}`" v-html="log" v-for="(log, index) in user.resume_score.logs"></li>
      </ul>
    </div>
  </div>
</template>

<script>

export default {
  props: ['user'],
  computed: {
    barColor() {
      if (this.user.resume_score.score < 50) {
        return 'bg-danger';
      }
      if (this.user.resume_score.score < 90) {
        return 'bg-warning';
      }
      return 'bg-success';
    },
  },
};
</script>
