import { gql } from 'apollo-boost';

export default gql`
  fragment product on Product {
    id
    name
    description
    price
    image_url
    countries {
      id
      name
    }
  }
`;
