import { gql } from 'apollo-boost';

export default gql`
  query($page: Int!,
        $first: Int,
        $status: String,
        $name: String,
        $type: String,
        $state: String,
        $client_id: ID,
        $project_status_id: ID,
        $description: String,
        $only_my_projects: Boolean,
        $user_id: ID,
        $orderBy: [OrderByClause!]) {
    projects(
      page: $page,
      first: $first,
      name: $name,
      type: $type,
      state: $state,
      client_id: $client_id,
      project_status_id: $project_status_id,
      description: $description,
      only_my_projects: $only_my_projects,
      user_id: $user_id,
      orderBy: $orderBy) @connection(key: "projects_summary", filter: ["name", "client_id", "project_status_id", "type", "state", "only_my_projects", "user_id"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        id
        name
        type
        end_date
        last_known_net_promoter_score
        managers {
          user {
            id
            name
          }
        }
        client {
          id
          name
        }
        project_status {
          id
          name
        }
        actions(status: $status){
          id
          status
        }
      }
    }
  }
`;
