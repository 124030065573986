import { gql } from 'apollo-boost';

import LEARNING_PATH_FRAGMENT from './fragments';

export default gql`
  query($page: Int,
        $name: String,
        $version: String) {
    learning_paths(
      page: $page,
      name: $name,
      version: $version,
      orderBy: { column: "id", order: ASC }) @connection(key: "learning_paths", filter: ["name", "version"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...learning_path
      }
    }
  }
  ${LEARNING_PATH_FRAGMENT}
`;
