import { gql } from 'apollo-boost';

export const EXPORT_TIMESHEET = gql`
mutation($start_date: DateTime!,
         $end_date: DateTime!,
         $group_by: TimesheetGroupBy,
         $user_id: ID,
         $project_id: ID,
         $show_costs: Boolean,
         $orderBy: [OrderByClause!]){
  exportTimesheetToExcel(
    start_date: $start_date,
    end_date: $end_date,
    group_by: $group_by,
    user_id: $user_id,
    project_id: $project_id,
    show_costs: $show_costs,
    orderBy: $orderBy
  )
}
`;

export const DELETE_BUCKET_ESTIMATION_BUCKET = gql`
  mutation($id: ID!){
    deleteBucketEstimationHour(id: $id){
      id
    }
  }
`;
