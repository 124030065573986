import { gql } from 'apollo-boost';

export default gql`
query{
  me {
    client {
      id
      projects(state: "In Progress") {
        id
        name
        members(active: true) {
          id
          project_id
          user {
            id
            name
            email
            avatar
            bio
            country {
              id
              name
            }
          }
        }
      }
    }
  }
}
`;
