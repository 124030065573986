<template>
<div class="col-md-2 pb-2">
  <div class="card h-100">
    <div v-if="!record.avatar" class="mt-4">
      <ProfileImage
        class="card-img-top m-auto"
        :font_size="'100px'"
        :user_name="record.name"
      ></ProfileImage>
    </div>
    <div v-if="record.avatar">
      <img class="card-img-top" :src="record.avatar">
    </div>
    <div class="card-body">
      <h5 class="card-title">
        {{record.name}}
      </h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">{{record.email}}</h6>
      <p v-if="record.personal_phone">
        <i class="fas fa-mobile-alt"></i>
        <a :href="`tel:${record.personal_phone}`">{{record.personal_phone}}</a>
      </p>
      <p v-if="record.phone_extension">
        <i class="fas fa-phone-square"></i>
        <a href="tel:24617400">24617400 ext. {{record.phone_extension}}</a>
      </p>
    </div>
  </div>
</div>
</template>

<script>
import { ProfileImage } from '../../shared';

export default {
  props: ['record'],
  components: { ProfileImage },
};
</script>
