import { gql } from 'apollo-boost';

export default gql`
  mutation($record: TenantInput!){
    createTenant(input: $record){
      name
      domain
      database
    }
  }
`;
