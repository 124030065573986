import lodash from 'lodash';
import Echo from 'laravel-echo';
import * as bootstrap from 'bootstrap';

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

import Pusher from 'pusher-js';
import { PUSHER_APP_KEY, PUSHER_APP_CLUSTER } from './env';

window._ = lodash;

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */

try {
  window.bootstrap = bootstrap;
} catch (e) {

  // empty
}
window.Pusher = Pusher;

window.Echo = new Echo({
  broadcaster: 'pusher',
  key: PUSHER_APP_KEY,
  cluster: PUSHER_APP_CLUSTER ?? 'mt1',
  forceTLS: true,
  encrypted: true,
});
