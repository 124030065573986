<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Users</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search
                :search.sync="search"
                :inputs="['name', 'email', 'countries', 'isActive', 'onlyMyTeamCheckbox']"
              ></Search>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col order-last text-end">
              <button type="button" class="btn btn-company-primary" @click="exportFile()" :disabled="isExporting" v-if="$can('ExportUsersToExcel')">
                <i class="fa fa-file-excel"></i>
                {{ exportButtonLabel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="users">
      <Card
        :key="index" v-for="(record, index) in users.data"
        :record="record"
        @remove="remove"
        @refresh="refresh"
      ></Card>
      <Empty v-if="!users.data.length" icon="fas fa-users"/>
    </div>
    <SkeletonCards
      v-if="users && users.paginatorInfo.hasMorePages"
      :cards=3
      :executeScroll="users != undefined"
      @showMore="showMore('users')"
    ></SkeletonCards>
  </div>
</template>

<script>
import { USERS_QUERY, USERS_QUERY_WITH_INTERNAL_NPS } from './queries';
import { DELETE_USER, EXPORT_USERS } from './mutations';
import Card from './Card.vue';
import { Search, SpinnerRouterLink, SkeletonCards } from '../shared';
import { SearchBuilder, PaginationHandler, Base64File } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  apollo: {
    users: {
      query() {
        return this.$can('ReadInternalNetPromoterScore') ? USERS_QUERY_WITH_INTERNAL_NPS : USERS_QUERY;
      },
      errorPolicy: 'all',
      variables() {
        return {
          page: 1,
          is_external: false,
          orderBy: [{ column: 'id', order: 'ASC' }],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: { only_my_team: false, active_only: true },
      selectedUser: null,
      isExporting: false,
    };
  },
  computed: {
    exportButtonLabel() {
      return this.isExporting ? 'Exporting...' : 'Export';
    },
  },
  methods: {
    queryVariables() {
      return {
        is_external: false,
        orderBy: [{ column: 'id', order: 'ASC' }],
        ...SearchBuilder.build(this.search),
      };
    },
    exportFile() {
      this.isExporting = true;
      this.$apollo.mutate({
        mutation: EXPORT_USERS,
        variables: {
          name: this.search.name,
          email: this.search.email,
          country_id: this.search.country_id,
        },
      }).then((res) => {
        let date = new Date();
        date = `${date.getDay()}-${date.getMonth()}-${date.getUTCFullYear()}`;
        Base64File.download(res.data.exportUsersToExcel, `users-${date}.xlsx`);
      }).finally(() => {
        this.isExporting = false;
      });
    },
    refresh() {
      this.$apollo.queries.users.refetch();
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_USER,
        variables: {
          id: record.id,
        },
      }).then(() => {
        this.refresh();
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
  },
};
</script>
