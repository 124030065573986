<template>
  <div class="d-flex justify-content-center m-md-4">
    <div class="card w-100">
      <div class="card-header d-flex pr-2 justify-content-between">
        <div class="p-0">
          <span class="h3">Signature</span>
        </div>
        <div class="container-fluid">
          <div class="row justify-content-end">
            <div class="col-auto order-last text-right">
              <button
                type="button"
                class="btn btn-company-primary"
                @click="previewSignature"
              >
                <i class="fas fa-magnifying-glass"></i>
                Preview
              </button>
            </div>
            <div class="col-auto order-last text-right">
              <button
                type="button"
                class="btn btn-company-primary"
                @click="saveImage"
              >
                <i class="fas fa-save"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0">
        <div class="col-12">
          <div class="p-1 row" id="signature">
            <div class="col-12 mt-2 row g-0">
              <div class="col-9">
                <h6>Choose your background</h6>
                <label for="file-upload" class="custom-file-upload label sortable">
                  <i class="fa fa-cloud-upload"></i> Select your image
                </label>
                <input
                  type="file"
                  @change="loadBackgroundImage"
                  id="file-upload"
                  class="d-none"
                />
              </div>
              <div class="col-12 my-3 row p-0">
                <div class="col-3">
                  <h6>Select type of text to add</h6>
                  <select
                    id="text-type"
                    class="form-control"
                    v-model="newTextSettings.textTypeSelected"
                  >
                    <option value="0" disable seleted>Select an option</option>
                    <option value="NAME">User Name</option>
                    <option value="EMAIL">User Email</option>
                    <option value="POSITION">User Position</option>
                    <option value="PERSONAL_PHONE">User Phone Number</option>
                    <option value="CUSTOM">Custom Text</option>
                  </select>
                </div>
                <div
                  class="col-3"
                  v-if="newTextSettings.textTypeSelected == 'CUSTOM'"
                >
                  <h6>Add your text</h6>
                  <input
                    type="text"
                    class="form-control"
                    v-model="newTextSettings.customText"
                  />
                </div>
                <div class="col-3">
                  <h6>Select font familiy</h6>
                  <input
                    list="font_family_list"
                    type="text"
                    name="font_family_item"
                    class="form-control"
                    v-model="newTextSettings.font_family"
                  />
                  <datalist
                    id="font_family_list"
                    v-if="
                      newTextSettings.font_family &&
                      newTextSettings.font_family.length > 1
                    "
                  >
                    <option
                      :key="index"
                      v-for="(record, index) in fontList"
                      :value="record"
                    >
                      {{ record }}
                    </option>
                  </datalist>
                </div>
                <div class="col-1">
                  <h6>Color:</h6>
                  <input
                    type="color"
                    v-model="newTextSettings.color"
                    id="font-color-input"
                    class="form-control w-100 m-auto p-0"
                  />
                </div>
                <div class="col-1">
                  <h6>Font size:</h6>
                  <input
                    type="number"
                    v-model="newTextSettings.font_size"
                    value="13"
                    id="font-size-input"
                    class="form-control w-100 m-auto p-0"
                  />
                </div>
                <div class="col-1">
                  <div class="btn-group h-75 mt-4" role="group" aria-label="">
                    <button
                      class="btn"
                      @click="changeFontWeight('bold', true)"
                      v-bind:class="boldNewClass"
                    >
                      <i class="fa-sharp fa-solid fa-bold"></i>
                    </button>
                    <button
                      class="btn"
                      @click="changeFontWeight('italic', true)"
                      v-bind:class="italicNewClass"
                    >
                      <i class="fa-solid fa-italic"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-2 col-xl-1 mb-3 row pl-4">
                <button
                  class="btn btn-primary btn-block"
                  type="button"
                  @click="addText"
                >
                  + ADD
                </button>
              </div>
            </div>

            <!-- MODIFY PICKER -->
            <div class="col-12 mb-3 row" v-if="editableModeSelected > -1">
              <div
                class="col-3"
                v-if="newTextSettings.textTypeSelected == 'CUSTOM'"
              >
                <h6>Modify your text</h6>
                <input
                  type="text"
                  class="form-control"
                  v-model="styleControls.content"
                />
              </div>
              <div class="col-3">
                <h6>Select font familiy</h6>
                <input
                  list="font_family_list"
                  type="text"
                  name="font_family_item"
                  class="form-control"
                  v-model="styleControls.font_family"
                />
                <datalist id="font_family_list">
                  <option
                    :key="index"
                    v-for="(record, index) in fontList"
                    :value="record"
                  >
                    {{ record }}
                  </option>
                </datalist>
              </div>
              <div class="col-1">
                <h6>Color:</h6>
                <input
                  type="color"
                  v-model="styleControls.color"
                  id="font-color-input"
                  class="form-control w-100 m-auto p-0"
                />
              </div>
              <div class="col-1">
                <h6>Font size:</h6>
                <input
                  type="number"
                  v-model="styleControls.font_size"
                  value="13"
                  id="font-size-input"
                  class="form-control w-100 m-auto p-0"
                />
              </div>
              <div class="col-2">
                <div class="btn-group h-75 mt-4" role="group" aria-label="">
                  <button
                    class="btn"
                    @click="changeFontWeight('bold')"
                    v-bind:class="boldClass"
                  >
                    <i class="fa-sharp fa-solid fa-bold"></i>
                  </button>
                  <button
                    class="btn"
                    @click="changeFontWeight('italic')"
                    v-bind:class="italicClass"
                  >
                    <i class="fa-solid fa-italic"></i>
                  </button>
                </div>
              </div>
              <div class="col-1">
                <button
                  class="btn btn-primary btn-block mt-3"
                  type="text"
                  @click="saveEditionChanges"
                >
                  Save
                </button>
              </div>
            </div>

            <div class="col-md-9 col-xl-6 col-12 pe-0">
              <div
                class="col-12 row mt-3"
                :key="`s-${index}`"
                v-for="(text, index) in texts"
              >
                <div class="col-7">
                  <p>{{ text.content }}</p>
                </div>
                <div class="col-auto p-0">
                  <button
                    type="button"
                    class="btn btn-company-primary"
                    @click="(_) => openEditableMode(index)"
                  >
                    <i class="fa fa-regular fa-pen"></i>
                    Edit
                  </button>
                </div>
                <div class="col-auto p-0 ml-n3">
                  <Confirmation @accepted="(_) => removeItem(index)" />
                </div>
              </div>
            </div>

            <div class="col-12 col-xl-6 mt-4 g-0 ml-md-n1">
              <div
                class="position-relative w-100"
                id="signature-box"
                ref="signatureBox"
                v-bind:style="styleSignatureBox"
                @mousemove="mouseMoveText"
                @auxclick="mouseUpText"
              >
                <p
                  v-for="(text, index) in texts"
                  :key="text.id"
                  v-bind:class="[
                    itemSelected == index
                      ? 'border border-primary rounded-1 shadow-sm grabbing'
                      : 'grabbable',
                  ]"
                  :id="`signature-text-${index}`"
                  v-bind:style="text.style"
                  @click="(e) => mouseDownText(e, index)"
                >
                  {{ text.content }}
                </p>
              </div>
            </div>
            <div id="myProfileBox"></div>
            <Modal
              :id="modalId"
              :ref="modalId"
              :hide_footer="true"
              :title="'Signature Preview'"
            >
              <template v-slot:body>
                <div class="row justify-content-center g-0">
                  <div class="col-12 my-5">
                    <img
                      alt="signature preview"
                      :ref="signaturePrevImg"
                      class="w-100"
                    />
                  </div>
                </div>
              </template>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.modal-dialog {
  max-width: 700px !important;
}
.grabbing {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
.custom-file-upload {
  border: 1px solid #ccc;
  padding: 6px 12px;
}
</style>
<script>
import SignatureHelper from './signature_helper';
import Modal from '../shared/Modal.vue';
import PROFILE_AND_SIGNATURE_QUERY from './queries';
import CREATE_SIGNATURE from './mutations';
import Confirmation from '../shared/Confirmation.vue';
import ErrorHandler from '../../shared/ErrorHandler';

export default {
  components: { Confirmation, Modal },
  data() {
    return {
      profile: null,
      signature: null,
      modalId: _.random(0, 100000),
      signaturePrevImg: _.random(0, 100000),
      fontList: [],
      newTextSettings: {
        textTypeSelected: '0',
        customText: '',
        font_family: '',
        color: '',
        font_size: 12,
        bold: false,
        italic: false,
      },
      baseNewTextSettings: {
        textTypeSelected: '0',
        customText: '',
        color: '',
        font_size: 12,
        bold: false,
        italic: false,
      },
      showTypeTextBox: false,
      texts: [],
      image: '',
      editableModeSelected: -1,
      itemSelected: -1,
      isDown: false,
      styleControls: {
        bold: false,
        italic: false,
        color: '#000000',
        font_size: 15,
        content: '',
      },
      sstyleSignatureBox: {
        width: '400px',
        height: '200px',
        'background-repeat': 'no-repeat',
      },
      backgroundSettings: {
        width: '',
        height: '',
        image: '',
      },
    };
  },
  created() {
    this.$apollo.addSmartQuery('profileAndSignature', {
      query: PROFILE_AND_SIGNATURE_QUERY,
      errorPolicy: 'all',
      result(result) {
        this.profile = result.data.profile;
        this.signature = result.data.signature;
        this.loadPreviewsSettings();
      },
    });
    this.loadFontFamilyList();
    document.addEventListener('keydown', this.keyPressSelected);
  },
  computed: {
    signatureName() {
      const names = this.profile.name.split(/\s+/);
      if (names.length > 3) {
        names.pop();
        return names.join(' ');
      }
      return this.profile.name;
    },
    boldClass() {
      return this.styleControls.bold ? 'btn-secondary' : 'btn-light';
    },
    italicClass() {
      return this.styleControls.italic ? 'btn-secondary' : 'btn-light';
    },
    boldNewClass() {
      return this.newTextSettings.bold ? 'btn-secondary' : 'btn-light';
    },
    italicNewClass() {
      return this.newTextSettings.italic ? 'btn-secondary' : 'btn-light';
    },
    styleSignatureBox() {
      const style = { ...this.sstyleSignatureBox };
      if (this.image) {
        style['background-image'] = `url(${this.image})`;
      }
      return style;
    },
  },
  methods: {
    loadFontFamilyList() {
      SignatureHelper.getAvailiableFonts().then((fontList) => {
        this.fontList = Array.from(fontList);
      });
    },
    loadPreviewsSettings() {
      this.image = this.signature.image;
      this.sstyleSignatureBox.height = `${this.signature.height}px`;
      this.sstyleSignatureBox.width = `${this.signature.width}px`;
      this.sstyleSignatureBox['background-image'] = `url(${this.image})`;
      this.signature.details.forEach((item) => {
        const newText = SignatureHelper.newTextItemGeneratos(
          item.content,
          item.color,
          item.font_size,
          item.margin,
          item.padding,
          item.position,
          item.width,
          item.height,
          `${item.left}px`,
          `${item.top}px`,
          item.font_weight,
          item.font_style,
          item.font_family,
        );
        this.texts.push(newText);
      });
      this.backgroundSettings = {
        image: this.signature.image,
        height: parseInt(this.signature.height, 10),
        width: parseInt(this.signature.width, 10),
      };
    },
    addText() {
      let content;
      if (this.newTextSettings.textTypeSelected !== '0') {
        if (this.newTextSettings.textTypeSelected === 'CUSTOM') {
          content = this.newTextSettings.customText;
        } else {
          content = this.newTextSettings.textTypeSelected;
        }
        const newText = SignatureHelper.newTextItemGeneratos(
          content,
          this.newTextSettings.color,
          `${this.newTextSettings.font_size}px`,
          '0',
          '0',
          'absolute',
          'auto',
          'auto',
          0,
          0,
          this.newTextSettings.bold ? 'bold' : 'initial',
          this.newTextSettings.italic ? 'italic' : 'initial',
          this.newTextSettings.font_family,
        );
        this.texts.push(newText);
        this.newTextSettings = this.baseNewTextSettings;
      }
    },
    openEditableMode(id) {
      this.editableModeSelected = id;
      let fontSize = this.texts[id].style['font-size'];
      fontSize = fontSize.substring(0, fontSize.length - 2);
      this.styleControls.bold = this.texts[id].style['font-weight'] === 'bold';
      this.styleControls.italic = this.texts[id].style['font-style'] === 'italic';
      this.styleControls.color = this.texts[id].style.color;
      this.styleControls.font_size = parseInt(fontSize, 10);
      this.styleControls.font_family = this.texts[id].style['font-family'];
      this.styleControls.content = this.texts[id].content;
    },
    removeItem(index) {
      this.texts.splice(index, 1);
    },
    saveEditionChanges() {
      const id = this.editableModeSelected;
      this.texts[id].style['font-size'] = `${this.styleControls.font_size}px`;
      this.texts[id].style['font-weight'] = this.styleControls.bold
        ? 'bold'
        : 'initial';
      this.texts[id].style['font-style'] = this.styleControls.italic
        ? 'italic'
        : 'initial';
      this.texts[id].style.color = this.styleControls.color;
      this.texts[id].content = this.styleControls.content;
      this.texts[id].style['font-family'] = this.styleControls.font_family;
      this.editableModeSelected = -1;
    },
    /** CHANGE BACKGROUND */
    loadBackgroundImage() {
      const file = document.querySelector('input[type="file"]').files[0];
      this.getBase64(file); // prints the base64 string
    },
    getBase64(file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const i = new Image();
        i.onload = () => {
          this.setBackground(reader.result, i.width, i.height);
        };
        i.src = reader.result;
      };
      reader.onerror = () => {};
    },
    setBackground(base64, width, height) {
      this.image = base64;
      this.sstyleSignatureBox.width = `${width}px`;
      this.sstyleSignatureBox.height = `${height}px`;
      this.sstyleSignatureBox['background-image'] = `url(${this.image})`;
      this.backgroundSettings = {
        width: parseInt(width, 10),
        height: parseInt(height, 10),
        image: base64,
      };
    },
    /** MOVE EVENTS */
    mouseDownText(event, itemSelected) {
      if (this.isDown) {
        this.mouseUpText();
      } else {
        this.itemSelected = itemSelected;
        const element = this.texts[this.itemSelected];
        const view = document.getElementById(
          `signature-text-${this.itemSelected}`,
        );
        this.isDown = true;
        element.offset = [
          view.offsetLeft - event.clientX,
          view.offsetTop - event.clientY,
        ];
      }
    },
    mouseUpText() {
      this.isDown = false;
      if (this.itemSelected > -1) {
        this.itemSelected = -1;
      }
    },
    mouseMoveText(event) {
      event.preventDefault();
      const view = document.getElementById(
        `signature-text-${this.itemSelected}`,
      );
      const element = this.texts[this.itemSelected];
      if (this.isDown) {
        view.style.left = `${event.clientX + element.offset[0]}px`;
        view.style.top = `${event.clientY + element.offset[1]}px`;
        this.texts[this.itemSelected].style.left = view.style.left;
        this.texts[this.itemSelected].style.top = view.style.top;
      }
    },
    /** STYLE CHANGES */
    changeFontWeight(type, isNew = false) {
      if (isNew) {
        this.newTextSettings[type] = !this.newTextSettings[type];
      } else {
        this.styleControls[type] = !this.styleControls[type];
      }
    },
    saveStyle() {
      const view = document.getElementById(
        `signature-text-${this.editableModeSelected}`,
      );
      view.contentEditable = false;
      this.texts[this.editableModeSelected].attr.contenteditable = false;
      this.mouseUpText();
      this.editableModeSelected = -1;
      this.styleControls = this.baseStyleControls;
    },
    keyPressSelected(event) {
      if (this.itemSelected > -1) {
        event.preventDefault();
        const view = document.getElementById(
          `signature-text-${this.itemSelected}`,
        );
        SignatureHelper.getKeyAndMove(event, view);
      }
    },
    previewSignature() {
      const signatureItem = this.sstyleSignatureBox;
      signatureItem.image = this.image;
      const details = this.texts.map((item) => ({
        ...item.style,
        content: item.content,
      }));
      signatureItem.details = details;
      const divBase = SignatureHelper.renderSignatureWithData(
        signatureItem,
        this.profile,
      );
      document.getElementById('myProfileBox').appendChild(divBase);
      SignatureHelper.downloadSignatureImage(divBase).then((canvas) => {
        divBase.style.display = 'none';
        divBase.remove();
        canvas.style.display = 'none';
        this.$refs[this.modalId].show();
        this.$refs[this.signaturePrevImg].src = canvas.toDataURL();
      });
    },
    // SAVE SIGNATURE
    saveImage() {
      const signature = {
        ...this.backgroundSettings,
        details: {
          upsert: this.saveSignatureTexts(),
        },
      };
      this.$apollo
        .mutate({
          mutation: CREATE_SIGNATURE,
          variables: {
            signature,
          },
        })
        .catch((error) => {
          ErrorHandler.handle(error, this);
        })
        .then(() => {
          this.$toasted.success('Signature Saved!');
        });
    },
    saveSignatureTexts() {
      return this.texts.map((value) => {
        const details = {
          content: value.content,
          ...value.style,
          font_size: value.style['font-size'],
          font_weight: value.style['font-weight'],
          font_style: value.style['font-style'],
          font_family: value.style['font-family'],
          left: parseInt(
            value.style.left.substring(0, value.style.left.length - 2),
            10,
          ),
          top: parseInt(
            value.style.top.substring(0, value.style.top.length - 2),
            10,
          ),
        };
        delete details['font-family'];
        delete details['font-size'];
        delete details['font-style'];
        delete details['font-weight'];
        return details;
      });
    },
  },
};
</script>
