<template>
  <div>
    <nav>
      <div class="breadcrumb p-2 mb-0">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Timesheet</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search :search.sync="search"
                      :inputs="['projects', 'users', 'dateRanges']"
                      :default_values="defaultDates"></Search>
            </div>
          </div>
        </div>
      </div>
      <div class="breadcrumb p-2">
        <div v-if="$can('ReadCostPerUser')" class="col-md-6" v-text="costsMessage()"></div>
        <div :class="$can('ReadCostPerUser') ? 'col-md-6' : 'col-md-12'" class="text-end">
          <label for="group_by">Group by:</label>
          <div class="form-check form-check-inline">
            <input type="radio" id="None" class="form-check-input" value="None" name="group_by" v-model="group_by"/>
            <label class="form-check-label" for="None">None</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" id="Project" class="form-check-input" value="Project" name="group_by" v-model="group_by"/>
            <label class="form-check-label" for="Project">Project</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" id="User" class="form-check-input" value="User" name="group_by" v-model="group_by"/>
            <label class="form-check-label" for="User">User</label>
          </div>
          <div class="form-check form-check-inline">
            <input type="radio" id="Day" class="form-check-input" value="Day" name="group_by" v-model="group_by"/>
            <label class="form-check-label" for="Day">Day</label>
          </div>
          <div class="form-check form-check-inline" v-if="$can('ReadCostPerUser')">
            <input type="checkbox" id="show_costs" class="form-check-input" name="show_costs" v-model="show_costs"/>
            <label class="form-check-label" for="show_costs">Show Costs</label>
          </div>
          <button class="btn btn-company-primary" @click="exportFile()" v-if="$can('ExportTimesheetToExcel')">
            <i v-if="is_exporting" class="fas fa-sync fa-spin"></i>
            <i v-if="!is_exporting" class="fa fa-file-excel"></i>
            Export
          </button>
        </div>
      </div>
    </nav>
    <div class="row" v-if="hours">
      <div class="col-md-12 text-nowrap scroll-x h-100">
        <None
          :hours="hours"
          :orderBy="orderBy"
          :show_costs="show_costs"
          @refetch="refetch"
          v-if="group_by == 'None'"
          @showMore="showMore"
          @remove="remove"
        ></None>
        <Project
          :hours="hours"
          :orderBy="orderBy"
          :show_costs="show_costs"
          @refetch="refetch"
          v-if="group_by == 'Project'"
          @showMore="showMore"
        ></Project>
        <User
          :hours="hours"
          :orderBy="orderBy"
          :show_costs="show_costs"
          @refetch="refetch"
          v-if="group_by == 'User'"
          @showMore="showMore"
        ></User>
        <Day
          :hours="hours"
          :orderBy="orderBy"
          :show_costs="show_costs"
          @refetch="refetch"
          v-if="group_by == 'Day'"
          @showMore="showMore"
        ></Day>
      </div>
    </div>
    <Empty v-if="!hours?.data.length" icon="fas fa-clock"/>
  </div>
</template>

<script>

import moment from 'moment';
import { DETAILED_HOURS_PER_PROJECT, DETAILED_HOURS_PER_PROJECT_WITH_COSTS } from './queries';
import { EXPORT_TIMESHEET, DELETE_BUCKET_ESTIMATION_BUCKET } from './mutations';
import None from './grouping/None.vue';
import Project from './grouping/Project.vue';
import User from './grouping/User.vue';
import Day from './grouping/Day.vue';
import Helper from './Helper';
import { Search, SpinnerRouterLink } from '../../shared';
import { SearchBuilder, Base64File } from '../../../shared';

export default {
  extends: Helper,
  components: {
    Search, None, Project, User, Day, SpinnerRouterLink,
  },
  computed: {
    defaultDates() {
      return {
        start_date: moment().subtract(1, 'weeks').startOf('isoweek').format('YYYY-MM-DD'),
        end_date: moment().subtract(1, 'weeks').endOf('isoweek').format('YYYY-MM-DD'),
      };
    },
  },
  data() {
    return {
      orderBy: { column: 'created_at', order: 'DESC' },
      group_by: 'None',
      is_exporting: false,
      show_costs: false,
      search: {},
      page: 2,
    };
  },
  apollo: {
    hours: {
      query() {
        return this.$can('ReadCostPerUser') ? DETAILED_HOURS_PER_PROJECT_WITH_COSTS : DETAILED_HOURS_PER_PROJECT;
      },
      errorPolicy: 'all',
      variables() {
        return {
          orderBy: [this.orderBy],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    remove(id) {
      this.$apollo.mutate({
        mutation: DELETE_BUCKET_ESTIMATION_BUCKET,
        variables: {
          id,
        },
        update: (store, { data: { deleteBucketEstimationHour } }) => {
          const query = this.$can('ReadCostPerUser') ? DETAILED_HOURS_PER_PROJECT_WITH_COSTS : DETAILED_HOURS_PER_PROJECT;
          const data = store.readQuery({ query, variables: this.queryVariables() });
          data.hours.data = data.hours.data.filter((c) => c.id !== deleteBucketEstimationHour.id);
          store.writeQuery({ query, variables: this.queryVariables(), data });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    queryVariables() {
      return { orderBy: [this.orderBy], ...SearchBuilder.build(this.search) };
    },
    showMore() {
      this.$apollo.queries.hours.fetchMore({
        variables: { page: this.page++, ...this.queryVariables() },
        updateQuery: (previousResult, { fetchMoreResult }) => {
          this.hours.paginatorInfo.hasMorePages = fetchMoreResult.hours.paginatorInfo.hasMorePages;
          return {
            hours: {
              __typename: previousResult.hours.__typename,
              data: [...previousResult.hours.data, ...fetchMoreResult.hours.data],
              paginatorInfo: this._data.hours.paginatorInfo,
            },
          };
        },
      });
    },
    exportFile() {
      this.is_exporting = true;
      this.$apollo.mutate({
        mutation: EXPORT_TIMESHEET,
        variables: {
          orderBy: [this.orderBy],
          group_by: this.group_by,
          show_costs: this.show_costs,
          ...SearchBuilder.build(this.search),
        },
      }).then((res) => {
        const keys = [];
        const project = document.querySelector('#select_project_id span').textContent.trim();
        const user = document.querySelector('#select_user_id span').textContent.trim();
        if (project) {
          keys.push(project);
        }
        if (user) {
          keys.push(user);
        }
        if (this.search.start_date && this.search.end_date) {
          keys.push(
            `${this.search.start_date} - ${this.search.end_date}`,
          );
        }
        Base64File.download(res.data.exportTimesheetToExcel, `${keys.join(' ')}.xlsx`);
      }).finally(() => {
        this.is_exporting = false;
      });
    },
    refetch() {
      this.$apollo.queries.hours.refetch();
    },
  },
};
</script>
