<style scoped>
/* show only one card when the device is Small or Extra small */
@media (max-width: 767.98px) {
  .skeleton:not(:first-child) {
    display: none;
  }
}
</style>

<template>
  <div class="row" id="skeleton">
    <div :key="item" class="skeleton" :class="bootstrapClass" v-for="(item) of cards">
      <vue-content-loading :width="250" :height="200">
        <rect x="0" y="0" rx="5" ry="5" width="250" :height="200" />
      </vue-content-loading>
    </div>
  </div>
</template>
<script>

import { VueContentLoading } from 'vue-content-loading';

export default {
  components: { VueContentLoading },
  props: ['cards', 'executeScroll'],
  computed: {
    bootstrapClass() {
      const col = Math.floor(12 / this.cards);
      return [
        `col-md-${col}`,
        'col-sm-12',
      ];
    },
  },
  mounted() {
    this.scroll();
  },
  methods: {
    scroll() {
      const observer = new IntersectionObserver((entries) => {
        if (this.executeScroll && entries[0].isIntersecting === true) {
          this.$emit('showMore');
        }
      }, { threshold: [0.5] });
      observer.observe(document.querySelector('#skeleton'));
    },
  },
};
</script>
