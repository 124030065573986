<template>
  <div class="form-inline">
    <div class="company-control">
      <label for="model">Model</label>
      <select id="model" v-model="search.model" class="form-control form-select" @change="updateFilter">
        <option :value="''"></option>
        <option :key="value" v-for="value in models" :value="value">{{value}}</option>
      </select>
    </div>
  </div>
</template>

<script>
import Searchable from '../../../shared/Searchable';

export default { extends: Searchable, props: ['models'] };
</script>
