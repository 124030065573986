import { gql } from 'apollo-boost';

export default gql`
  query($query: String, $orderBy: [OrderByClause!]){
    resume_full_text_search(query: $query, orderBy: $orderBy){
      id
      name
      score
    }
  }
`;
