import { gql } from 'apollo-boost';
import PROJECT_MINUTE_FRAGMENT from './fragments';

export default gql`
query($page: Int!, $project_id: ID!, $name: String) {
  projectMinutes(page: $page, project_id: $project_id, name: $name, orderBy: { column: "date", order: DESC }) {
    paginatorInfo {
      hasMorePages
    }
    data{
      ...projectMinute
    }
  }
}
${PROJECT_MINUTE_FRAGMENT}
`;
