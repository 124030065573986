<template>
    <div>
      <nav>
        <div class="breadcrumb p-2">
          <div class="row">
            <div class="col ms-3 p-0 pb-2">
              <SpinnerRouterLink :loading="$apollo.loading">My Teams</SpinnerRouterLink>
            </div>
            <div class="col-12">
              <div v-if="me" class="nav-pills">
                <button :key="record.id" v-for="(record, index) in me.client.projects.length" :record="record" class="btn" :class="{'bg-info': index === selectedProject}" @click="selectedProject = index">
                  <i class="fa-solid fa-folder"></i>
                  {{ me.client.projects[record-1].name }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div v-if="me" class="row">
        <Card :key="records.id" v-for="records in selectedMembers" :record="records"></Card>
      </div>
  </div>
</template>

<script>
import TEAM from './queries';
import Card from './Card.vue';
import { SpinnerRouterLink } from '../../shared';

export default {
  data() {
    return {
      selectedProject: 0,
    };
  },
  components: { Card, SpinnerRouterLink },
  apollo: {
    me: {
      query: TEAM,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    selectedMembers() {
      return this.me.client.projects[this.selectedProject]?.members;
    },
  },
};
</script>
