<template>
  <div :id="record.id" class="pb-2" :class="is_editting ? 'col-md-12' : 'col-md-4'">
    <Form v-if="is_editting" v-bind:record="record"
                                @close="is_editting = false"
                                @update="update"></Form>
    <div v-if="!is_editting" class="card h-100">
      <div class="card-body pb-0">
        <h5 class="card-title">
           <span :title="record.country.name" v-if="record.country">{{flag(record.country.name)}}</span>
          {{ record.name }} ({{ record.is_direct ? 'Direct' : 'Indirect' }})
        </h5>
        <h6 class="card-subtitle mb-2 text-body-secondary">{{ record.email }}</h6>
        <div class="pt-1 mb-2">
            <span :title="`Net Promoter Score: ${record.last_known_net_promoter_score}`" class="badge bg-company-third-ligth ">NPS: {{record.last_known_net_promoter_score}}</span>
            <span v-if="record.is_prospect" class="badge bg-company-third-ligth ">Prospect</span>
          </div>
      </div>
      <div class="card-body pt-0" v-if="record.contacts && record.contacts.length > 0">
        <div class="row">
          <div class="col">
            Contacts:
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ContactViewer :contacts="record.contacts"></ContactViewer>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button v-if="!confirmation && $can('UpdateClient')" class="btn btn-company-primary" @click="is_editting = true">
          <i class="fas fa-pen"></i>
          Edit
        </button>
        <Confirmation v-if="!is_editting && $can('DeleteClient')"
          v-model="confirmation"
          @accepted="remove()"
        />
        <router-link class="btn btn-company-primary"
                     v-if="!confirmation && $can('AdminClientAttachments')"
                     :to="{ name: 'client_attachments', params: { id: record.id }}">
          <i class="fas fa-paperclip"></i>
          Attachments
        </router-link>
        <router-link class="btn btn-company-primary"
                     v-if="!confirmation && $can('ReadOpportunities')"
                     :to="{ name: 'client_opportunities', params: { id: record.id }}">
          <i class="fa-solid fa-hands-holding-circle"></i>
          Opportunities
        </router-link>
        <router-link :to="{ path: 'projects', query: { client_id: record.id, state: '' }}" v-if="!confirmation" class="btn btn-company-primary" @click="confirmation = true" target="_blank">
          <i class="fas fa-project-diagram"></i>
          Projects
        </router-link>
        <router-link :to="{ name: 'users_for_client' , params: { id: record.id } }" v-if="!confirmation && $can('CreateUserForClient')" class="btn btn-company-primary" @click="confirmation = true" target="_blank">
          <i class="fas fa-user"></i>
          Users
        </router-link>
        <CopyButton v-if="!confirmation && $can('CreateNetPromoterScoreLink')" :client_id="record.id"></CopyButton>
      </div>
    </div>
  </div>
</template>

<script>
import { UPDATE_CLIENT } from './mutations';
import Form from './Form.vue';
import { ErrorHandler, Flags } from '../../shared';
import ContactViewer from '../contactables/Viewer.vue';
import RequestBuilder from './RequestBuilder';
import { Confirmation } from '../shared';
import CopyButton from '../net_promoter_score/CopyButton.vue';

export default {
  components: {
    Form, ContactViewer, Confirmation, CopyButton,
  },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    flag(name) {
      return Flags[name];
    },
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_CLIENT,
        variables: {
          id: record.id,
          client: RequestBuilder.build(record),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.record.country = record.country;
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
