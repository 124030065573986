<template>
  <div class="d-flex justify-content-center my-4">
    <div class="card" style="width: 22rem;">
      <div class="card-body p-4">
        <div class="d-grid gap-1">
          <div class="mb-3">
            <label class="m-0 ms-1 h6" for="email">Email</label>
            <input type="email" class="form-control px-2" name="email" id="email"  v-model="localRecord.email">
            <div v-for="error of v$.localRecord.email.$silentErrors" :key="error.$uid">
              <div class="text-danger">{{ error.$message }}</div>
            </div>
          </div>
          <div class="mb-3">
            <label class="m-0 ms-1 h6" for="password">Old password</label>
            <input type="password" class="form-control px-2" v-model="localRecord.oldPassword">
            <div v-for="error of v$.localRecord.oldPassword.$silentErrors" :key="error.$uid">
              <div class="text-danger">{{ error.$message }}</div>
            </div>
          </div>
          <div class="mb-3">
            <label class="m-0 ms-1 h6" for="newPassword">New Password</label>
            <input type="password" class="form-control px-2" v-model="localRecord.password">
            <div v-for="error of v$.localRecord.password.$silentErrors" :key="error.$uid">
              <div class="text-danger">{{ error.$message }}</div>
            </div>
          </div>
          <div class="mb-3">
            <label class="m-0 ms-1 h6" for="confirmNewPassword" >Confirm new password</label>
            <input type="password" class="form-control px-2" v-model="localRecord.confirmNewPassword">
            <div v-for="error of v$.localRecord.confirmNewPassword.$silentErrors" :key="error.$uid">
              <div class="text-danger">{{ error.$message }}</div>
            </div>
          </div>
          <vue-recaptcha :sitekey="sitekey"
            ref="recaptcha"
            @verify="setToken"
            @expired="() => { recaptcha = 'Session expired'; recaptcha_token = null; }"
            @render="() => null"
            @error="recaptcha = 'Something went wrong'"
          ></vue-recaptcha>
          <button type="submit" class="btn btn-login shadow-sm" :disabled="v$.localRecord.$invalid || !recaptcha" @click="update">
            <h5 class="m-0">Change password</h5>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VueRecaptcha } from 'vue-recaptcha';
import Validations from './Validations';
import UPDATE_PASSWORD from './mutations';
import { ErrorHandler } from '../../shared';
import { RECAPTCHA_KEY } from '../../env';

export default {
  mixins: [Validations],
  components: { VueRecaptcha },
  data() {
    return {
      localRecord: [],
      sitekey: RECAPTCHA_KEY,
      recaptcha: false,
    };
  },
  created() {
    this.localRecord.email = this.$route.query.email;
  },
  methods: {
    setToken(token) {
      this.localRecord.recaptcha = token;
      this.recaptcha = true;
    },
    update() {
      this.$apollo.mutate({
        mutation: UPDATE_PASSWORD,
        variables: {
          user: _.pick(this.localRecord, ['email', 'password', 'oldPassword', 'recaptcha']),
        },

      }).then(() => {
        this.$toasted.success('Password changed successfuly!');
        this.recaptcha = false;
        setTimeout(() => {
          window.location.href = `/login?email=${this.localRecord.email}`;
        }, 2000);
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.$refs.recaptcha.reset();
      });
    },
  },
};
</script>
