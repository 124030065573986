<template>
<div :id="record.id"  class="col-md-4 pb-2">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"
                            ></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body p-1 pt-3">
      <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="w-50 float-start">
                <h5 class="card-title">{{record.first_name}} {{record.last_name}}</h5>
                <div><a :href="'mailto: '+record.email" class="card-title mb-2 text-body-secondary">{{record.email}}</a></div>
                <div><a :href="'tel: '+record.phone_number" class="card-title"> {{record.phone_number}}</a></div>
              </div>
              <h6 style="color: #898989; float: right;">score : {{record.score}}</h6>
            </div>
            <div class="accordion accordion-flush pb-1">
              <div class="accordion-item border border-0">
                <div class="accordion-header custom-accordion p-0">
                  <h2 class="mb-0">
                    <button class="btn accordion-button collapsed py-2 px-3" type="button" data-bs-toggle="collapse" :data-bs-target="`#p-${record.id}`" aria-expanded="true" :aria-controls="`p-${record.id}`" @click="is_description_visible=!is_description_visible">
                      INFORMATION
                    </button>
                  </h2>
                </div>
                <div :id="`p-${record.id}`" class="accordion-collapse collapse">
                  <div class="card-body card-row pt-0 pb-0">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="w-50 float-start">
                          <p class="mb-0"><strong>English Level:</strong>
                            <span v-if="record.english_level.indexOf('A') >-1" class="badge bg-company-third-ligth" >
                              {{record.english_level}}
                            </span>
                            <span v-if="record.english_level.indexOf('B') >-1" class="badge bg-company-secondary-ligth" >
                              {{record.english_level}}
                            </span>
                            <span v-if="record.english_level.indexOf('C') >-1" class="badge bg-company-fiveth-ligth" >
                              {{record.english_level}}
                            </span>
                          </p>
                        </div>
                        <div v-if="record.country" class="float-start">
                          <span  :title="record.country.name">{{flag}}</span>
                          {{ record.country.name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body card-row pt-0 pb-0">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="w-50 float-start">
                          <p class="mb-0"><strong>Salary Expectation Range:</strong>
                            <span class="bg-company-primary-ligth" >{{record.salary_expectation_range}}</span>
                          </p>
                        </div>
                        <div class="w-50 float-start">
                          <div class="col-md-12">
                            <p class="mb-0"><strong>Status:</strong>
                              <span class="badge bg-company-third-ligth ">
                                <i class="fa-solid fa-user" ></i>
                                {{record.candidate_status.name}}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="card-body card-row pt-0 pb-0">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="w-50 float-start">
                          <p class="mb-0"><strong>Availability:</strong>
                            <span :class="record.availability === 'Immediately' ? 'badge bg-company-fiveth-ligth' : 'badge bg-company-primary'">
                              <i :class="record.availability === 'Immediately' ? 'fa-solid fa-check' :'fa-regular fa-calendar'"></i>
                              {{record.availability}}
                            </span>
                          </p>
                        </div>
                        <div class="w-50 float-start">
                          <p class="mb-0"><strong>Application Channel:</strong> <span v-if="record.application_channel">{{record.application_channel}}</span></p>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="card-body card-row pt-0 pb-0">
                  <div class="row">
                    <div class="col-md-12">
                      <strong>Technologies:</strong>
                    </div>
                    <div class="col-md-12">
                      <span :key="technology.id" v-for="technology in record.technologies" class="badge bg-company-fourth-ligth me-1">{{technology.name}}</span>
                    </div>
                  </div>
                </div>
                <div v-if="record.is_working" class="card-body card-row pt-0 pb-0">
                  <div class="row">
                    <div class="col-md-12">
                      <p class="mb-0"><strong>Currently working at:</strong>
                        <span v-if="record.company" class="badge bg-company-third-ligth">
                          <i class="fa-solid fa-building"></i>
                          {{record.company}}
                      </span></p>
                    </div>
                  </div>
                </div>
                <div v-if="!record.is_working" class="card-body card-row pt-0 pb-0">
                  <div class="row">
                    <div class="col-md-12">
                      <p class="mb-0"><strong>Currently:</strong>
                        <span v-if="!record.is_working" class="badge bg-company-fiveth-ligth">
                          <i class="fa-solid fa-person-walking-luggage"></i>
                          unemployed
                        </span></p>
                      </div>
                    </div>
                  </div>
                  <div class="card-body card-row pt-0 pb-0">
                   <div class="row">
                      <div class="col-md-12">
                       <p class="mb-0"><strong>Job experience started at date:</strong> <span v-if="record.job_experience_started_date">{{relative_job_experience_started_date}}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    </div>
    <div class="card-footer">
      <button v-if="!confirmation && $can('UpdateCandidate')" class="btn btn-company-primary mb-1" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteCandidate')"
        v-model="confirmation"
        @accepted="remove()"
      />
      <button v-if="!confirmation && record.resume_url" class="btn btn-company-primary mb-1" @click="openResume">
        <i class="fas fa-file-download"></i>
        Resume
      </button>
      <router-link v-if="!confirmation" class="btn btn-company-primary mb-1" :to="`/external_candidates/${record.id}`">
        <i class="fas fa-search-plus"></i>
        Details
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
import moment from 'moment';
import Form from './Form.vue';
import { UPDATE_CANDIDATE } from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';
import RequestBuilder from './RequestBuilder';
import { Confirmation } from '../shared';
import { Flags } from '../../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  computed: {
    flag() {
      return Flags[this.record.country.name];
    },
  },
  data() {
    return {
      is_description_visible: false,
      is_editting: false,
      confirmation: false,
      relative_job_experience_started_date: moment(this.record.job_experience_started_date, 'YYYYMMDD').fromNow(),
    };
  },
  methods: {
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_CANDIDATE,
        variables: {
          id: record.id,
          candidate: RequestBuilder.build(record),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
        this.$emit('refresh');
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
    openResume() {
      this.$emit('open-resume', this.record.resume_url);
    },
  },
};
</script>
