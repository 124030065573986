<template>
  <ul class="list-group list-group-flush">
      <li v-for="contact in contacts" :key="contact.id" class="list-group-item d-flex justify-content-between align-items-center">
        <p class="m-0">
          <small>
            {{contact.name}}
          </small>
        </p>
        <p class="m-0">
          <small class="text-body-secondary ms-2">
            {{contact.email}} / {{contact.phone}}
          </small>
        </p>
        <span class="badge bg-primary bg-company-primary text-white">
          {{contact.position}}
        </span>
      </li>
  </ul>
</template>

<script>

export default {
  props: ['contacts'],
};
</script>
