<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
              <ul class="breadcrumb p-0 m-auto">
                <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="fas fa-home"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active fw-bold" aria-current="page">
                Rate Per Resource
              </li>
            </ul>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 m-auto mt-2">
              <Search
                :search.sync="search"
                :inputs="['projects', 'users']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <table v-if="activeMembers" class="table table-sm">
      <thead>
        <tr>
          <th>Project</th>
          <th>Person</th>
          <th>Role</th>
          <th>Rate</th>
          <th v-if="$can('UpdateCostPerUser')">Actions</th>
        </tr>
      </thead>
      <tbody>
        <Row v-for="projectMember in activeMembers"
            :projectMember="projectMember"
            :key="projectMember.id"
            @rateUpdated="rateUpdated"
            @cancel="cancel"></Row>
      </tbody>
    </table>
    <Empty v-if="!activeMembers.length" icon="fas fa-dollar-sign"/>
  </div>
</template>

<script>

import RATE_PER_USER from './queries';
import UPDATE_PROJECT_MEMBER_RATE from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';
import Row from './Row.vue';
import { Search } from '../shared';

export default {
  data() {
    return {
      search: {
        user_id: this.$route.query.user_id ?? null,
        project_id: this.$route.query.project_id ?? null,
      },
    };
  },
  components: { Search, Row },
  apollo: {
    projectMembers: {
      errorPolicy: 'all',
      query: RATE_PER_USER,
      variables() {
        return {
          project_id: this.search.project_id,
          user_id: this.search.user_id,
          orderBy: [{ column: 'created_at', order: 'DESC' }],
        };
      },
      skip() {
        return !(this.search.user_id || this.search.project_id);
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    activeMembers() {
      return _.filter(this.projectMembers, (pm) => pm.project.state === 'In Progress' && pm.end_date == null);
    },
  },
  methods: {
    cancel(projectMember) {
      const updatedProjectMember = _.find(this.projectMembers, (pm) => pm.id === projectMember.id);
      projectMember.hourly_rate = updatedProjectMember.hourly_rate;
    },
    rateUpdated(projectMember) {
      const updatedProjectMember = _.find(this.projectMembers, (pm) => pm.id === projectMember.id);
      updatedProjectMember.hourly_rate = Number(projectMember.hourly_rate);
      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_MEMBER_RATE,
        variables: {
          id: projectMember.id,
          input: {
            hourly_rate: updatedProjectMember.hourly_rate,
          },
        },
      }).then(() => {
        this.$toasted.success('New rate was saved!');
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
  },
};
</script>
