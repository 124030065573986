<style>
@media only screen and (min-width: 1000px) {
  .people-vacation-table-not-shown{
    display: none;
  }
}
@media only screen and (max-width: 500px) {
  .people-vacation-table-padding-responsive {
    padding: 0.15rem !important;
  }
}
</style>
<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">People Vacation</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6">
              <Search
                :search.sync="search"
                :inputs="['users']"
              ></Search>
            </div>
            <div class="col-12 col-sm-6 order-last text-end mt-2 pt-2">
              <button type="button" class="btn  btn-company-primary" @click="save()">
                <i v-if="loading" class="fas fa-sync fa-spin"></i>
                <i v-if="!loading" class="fas fa-save"></i>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="peopleVacation">
      <div class="col-md-12 text-nowrap h-100 scroll-x">
        <table class="table">
          <thead>
            <tr>
              <th>#</th>
              <SortByColumn :sort_by_field="'person'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
              <SortByColumn :css_class="'text-center'" :sort_by_field="'days'" :orderBy="orderBy" @refresh="refresh">Days</SortByColumn>
              <th>Comment</th>
              <SortByColumn :sort_by_field="'since'" :orderBy="orderBy" @refresh="refresh">Last approved request</SortByColumn>
            </tr>
          </thead>
          <tbody>
            <tr :key="row.user_id" v-for="(row, index) in peopleVacation">
              <td>{{index + 1}}</td>
              <td class="people-vacation-table-padding-responsive">{{row.person}}</td>
              <td class="text-center people-vacation-table-padding-responsive">
                <PlusMinusField
                  :id="row.user_id"
                  :incrementer="row.working_time"
                  :value="row.days || 0" @update="addDay"></PlusMinusField>
              </td>
              <td class="people-vacation-table-padding-responsive">
                <input class="form-control" :class="row.user_id in commentEnabled ? { 'is-invalid': v$.updatedPtoDays.comments.$invalid } : null"
                  :disabled="!commentEnabled[row.user_id]" v-model="row.comments" placeholder="Comment"
                  @input="addComment(row)">
                <template v-if="row.user_id in commentEnabled">
                  <div v-for="error of v$.updatedPtoDays.comments.$silentErrors" :key="error.$uid">
                    <div class="text-danger">{{ error.$message }}</div>
                  </div>
                </template>
              </td>
              <td>{{lastApprovedRequest(row)}}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Empty v-if="!peopleVacation?.length" icon="fas fa-calendar-check"/>
  </div>
</template>
<script>

import Vue from 'vue';
import PlusMinusField from './PlusMinusField.vue';
import VACATION_QUERY from './queries';
import UPDATE_PEOPLE_VACATION from './mutations';
import { Search, SortByColumn, SpinnerRouterLink } from '../../shared';
import { SearchBuilder, ErrorHandler } from '../../../shared';
import Validations from './Validations';

export default {
  components: {
    PlusMinusField, Search, SortByColumn, SpinnerRouterLink,
  },
  mixins: [Validations],
  data() {
    return {
      orderBy: { column: 'days', order: 'DESC' },
      search: { },
      loading: false,
      updatedPtoDays: {},
      commentEnabled: {},
    };
  },
  apollo: {
    peopleVacation: {
      query: VACATION_QUERY,
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
      variables() {
        return {
          orderBy: [this.orderBy],
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
  },
  methods: {
    lastApprovedRequest(row) {
      if (!row.last_pto_date) {
        return '';
      }
      if (row.since.includes('-')) {
        return `(${row.last_pto_date})`;
      }
      return `${row.since} since (${row.last_pto_date})`;
    },
    refresh() {
      this.$apollo.queries.peopleVacation.refetch();
    },
    addDay(newValue) {
      this.updatedPtoDays[newValue.id] = { value: newValue.value, comments: '' };
      Vue.set(this.commentEnabled, newValue.id, true);
    },
    addComment(row) {
      this.updatedPtoDays[row.user_id].comments = row.comments;
    },
    save() {
      this.loading = true;
      const input = Object.keys(this.updatedPtoDays).map((k) => ({ id: k, days: this.updatedPtoDays[k].value, comments: this.updatedPtoDays[k].comments }));
      this.$apollo.mutate({
        mutation: UPDATE_PEOPLE_VACATION,
        variables: {
          input,
        },
      }).then(() => {
        this.commentEnabled = {};
        this.refresh();
        this.loading = false;
        this.updatedPtoDays = {};
        this.$toasted.success('Updated vacations!');
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.loading = false;
      });
    },
  },
};
</script>
