<template>
<div class="card">
  <div class="card-header d-flex">
    <div class="col p-0">
      <span v-if="!record.id">New User</span>
      <span v-if="record.id">{{localRecord.name}}</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body">
    <div class="row">
      <div class="mb-3 col-md-4">
        <label for="name">Name</label>
        <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
        <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="identification">Legal ID</label>
        <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.identification.$invalid}" id="identification" v-model="localRecord.identification">
        <div v-for="error of v$.localRecord.identification.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="email">Email</label>
        <input type="email" class="form-control" id="email" v-model="localRecord.email" readonly>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-6">
        <label for="hiring_date">Hiring Date</label>
        <DatePicker :date="localRecord.hiring_date"
                    placeholder="Select Date"
                    :css_class="{'is-invalid': v$.localRecord.hiring_date.$invalid}"
                    @change="localRecord = { ...localRecord, hiring_date: $event }"></DatePicker>
        <div v-for="error of v$.localRecord.hiring_date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-6">
        <label for="english_level">English level</label>
        <select id="english_level" :class="{'is-invalid': v$.localRecord.english_level.$invalid}" v-model="localRecord.english_level" class="form-control form-select">
          <option value="A1">Basic (A1)</option>
          <option value="A2">Basic (A2)</option>
          <option value="B1">Intermediate (B1)</option>
          <option value="B2">Intermediate (B2)</option>
          <option value="C1">Advanced (C1)</option>
          <option value="C2">Advanced (C2)</option>
        </select>
        <div v-for="error of v$.localRecord.english_level.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-4">
        <label for="emergency_contact">Emergency Contact</label>
        <input type="text" :class="{'is-invalid': v$.localRecord.emergency_contact.$invalid}" v-model="localRecord.emergency_contact" class="form-control" id="emergency_contact">
        <div v-for="error of v$.localRecord.emergency_contact.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-4">
        <label for="personal_phone">Phone Number</label>
        <input type="tel" class="form-control" id="personal_phone" v-model="localRecord.personal_phone">
      </div>
      <div class="mb-3 col-md-4">
        <label for="phone_extension">Phone extension.</label>
        <input type="text" class="form-control" id="phone_extension" v-model="localRecord.phone_extension">
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-6">
        <label for="client_id">
          Job Title <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        </label>
        <select id="job_title_id" class="form-control form-select" :class="{'is-invalid': v$.localRecord.job_title_id.$invalid}" v-model="localRecord.job_title_id">
          <template v-if="jobTitlesDropDown">
            <option :key="job_title.id" v-for="job_title in jobTitlesDropDown" :value="job_title.id">
              {{job_title.internal_description}}
            </option>
          </template>
        </select>
        <div v-for="error of v$.localRecord.job_title_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-6">
        <label for="working_time">Working Time</label>
        <input type="number" class="form-control" max="1" min="0" step="0.01" :class="{'is-invalid': v$.localRecord.working_time.$invalid}" id="working_time" v-model="localRecord.working_time">
        <div v-for="error of v$.localRecord.working_time.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-6">
        <label for="birthday">Birthdate</label>
        <DatePicker :date="localRecord.date_of_birth"
                    placeholder="Select Date"
                    :css_class="{'is-invalid': v$.localRecord.date_of_birth.$invalid}"
                    @change="localRecord = { ...localRecord, date_of_birth: $event }"></DatePicker>
        <div v-for="error of v$.localRecord.date_of_birth.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-6">
        <label for="country">
          Country <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        </label>
        <select id="country" class="form-control form-select" :class="{'is-invalid': v$.localRecord.country_id.$invalid}" v-model="localRecord.country_id">
          <template v-if="countries">
            <option :key="country.id" v-for="country in countries" :value="country.id">
              {{country.name}}
            </option>
          </template>
        </select>
        <div v-for="error of v$.localRecord.country_id.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12">
        <label for="partner">
          Partner <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
        </label>
        <select id="partner" class="form-control form-select" v-model="localRecord.partner_id">
          <template v-if="partnersDropDown">
            <option></option>
            <option :key="`partner-${partner.id}`" v-for="partner in partnersDropDown" :value="partner.id">
              {{partner.name}}
            </option>
          </template>
        </select>
      </div>
    </div>
    <div v-if="!localRecord.is_active" class="row">
      <div class="mb-3 col-md-6">
        <label for="termination_date">Termination Date</label>
        <DatePicker
          :date="localRecord.termination_date"
          placeholder="Select Date"
          :css_class="{'is-invalid': v$.localRecord.termination_date.$invalid}"
          @change="localRecord = { ...localRecord, termination_date: $event }"
        ></DatePicker>
        <div class="mt-1" v-for="error of v$.localRecord.termination_date.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="mb-3 col-md-6">
        <label for="termination_reason">Termination Reason</label>
        <select id="termination_reason" :class="{'is-invalid': v$.localRecord.termination_reason.$invalid}" v-model="localRecord.termination_reason" class="form-control form-select">
          <option>Better Salary</option>
          <option>Better Opportunity</option>
          <option>Performance Issues</option>
          <option>Contract Finished</option>
          <option>No Opportunities</option>
          <option>Mutual Agreement</option>
          <option>Other</option>
        </select>
        <div v-for="error of v$.localRecord.termination_reason.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-check">
        <div class="form-check">
          <input id="is_billable" type="checkbox" class="form-check-input" v-model="localRecord.is_billable"/>
          <label for="is_billable" class="form-check-label m-auto">Billable</label>
        </div>
      </div>
      <div class="form-check">
        <div class="form-check">
          <input id="is_external" type="checkbox" class="form-check-input" v-model="localRecord.is_external"/>
          <label for="is_external" class="form-check-label m-auto">External</label>
        </div>
      </div>
      <div class="form-check">
        <div class="form-check">
          <input id="is_active" type="checkbox" class="form-check-input" v-model="localRecord.is_active"/>
          <label for="is_active" class="form-check-label m-auto">Active</label>
        </div>
      </div>
      <div class="form-check">
        <div class="form-check">
          <input id="is_contractor" type="checkbox" class="form-check-input" v-model="localRecord.is_contractor"/>
          <label for="is_contractor" class="form-check-label m-auto">Contractor</label>
        </div>
      </div>
      <div class="form-check">
        <div class="form-check">
          <input id="is_parent" type="checkbox" class="form-check-input" v-model="localRecord.is_parent"/>
          <label for="is_parent" class="form-check-label m-auto">Parent</label>
        </div>
      </div>
    </div>
    <div class="row" v-if="$can('UpdateUserRoles')">
      <div class="mb-3 col-md-12 mb-0">
        <RolePicker :selected="localRecord.roles || []">Roles:</RolePicker>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12 mb-0">
        <TechnologyPicker :selected="localRecord.technologies || []">Technologies:</TechnologyPicker>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12 mb-0">
        <BusinessUnitsPicker :selected="localRecord.business_units || []">Business Units:</BusinessUnitsPicker>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12">
        <TagsPicker :selected="localRecord.tags || []">Tags:</TagsPicker>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12">
        <label for="address">Address</label>
        <textarea v-model="localRecord.address" id="address" class="form-control"></textarea>
      </div>
    </div>
    <div class="row">
      <div class="mb-3 col-md-12">
        <label for="notes">Notes</label>
        <textarea v-model="localRecord.notes" id="notes" class="form-control"></textarea>
      </div>
    </div>
    <div class="d-grid mb-3 col-md-12">
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</div>
</template>

<script>
import Validations from './Validations';
import RolePicker from '../roles/Picker.vue';
import TechnologyPicker from '../technologies/Picker.vue';
import BusinessUnitsPicker from '../business_units/Picker.vue';
import TagsPicker from '../tags/Picker.vue';
import { Blinkable, JOB_TITLES, dropdownWithAlias } from '../../shared';

export default {
  mixins: [Blinkable, Validations],
  components: {
    RolePicker,
    TechnologyPicker,
    BusinessUnitsPicker,
    TagsPicker,
  },
  apollo: {
    jobTitlesDropDown: {
      query: JOB_TITLES,
      errorPolicy: 'all',
      variables() {
        return { orderBy: [{ column: 'id', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    partnersDropDown: {
      query: dropdownWithAlias('partnersDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Partner', orderBy: [{ column: 'id', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    countries: {
      query: dropdownWithAlias('countries'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Country', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  computed: {
    countryChanged() {
      return this.localRecord?.country?.id !== this.localRecord.country_id;
    },
  },
  methods: {
    save() {
      if (this.countryChanged) {
        this.localRecord.country = this.countries.find((c) => c.id === this.localRecord.country_id);
      }
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.localRecord.termination_date = this.localRecord.is_active ? null : this.localRecord.termination_date;
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
