/* eslint no-plusplus: ["error", { "allowForLoopAfterthoughts": true }] */

export default class Base64File {
  static download(base64, fileName) {
    const byteCharacters = atob(base64);
    const byteArrays = [];
    const sliceSize = 512;
    let slice;
    let byteNumbers;
    let byteArray;
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      slice = byteCharacters.slice(offset, offset + sliceSize);
      byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    const blob = new Blob(byteArrays, { type: 'octet/stream' });

    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style = 'display: none';
    a.href = url;
    a.download = fileName;
    a.click();
    document.body.appendChild(a);
    window.URL.revokeObjectURL(url);
  }
}
