<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord"
                                @close="selectedRecord = null"
                                @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Clients</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-7 m-auto">
              <Search :search.sync="search" :inputs="['name']"></Search>
            </div>
            <div class="col-12 col-sm-5 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = { contacts: [], is_prospect: false, is_direct: true}" v-if="$can('CreateClient')">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="clients">
      <Card :key="index" v-for="(record, index) in clients.data" :record="record" @remove="remove"></Card>
      <Empty v-if="!clients.data.length" icon="fas fa-globe-americas" :can_create="true" @callCreate="selectedRecord = {}"/>
    </div>
    <SkeletonCards v-if="clients && clients.paginatorInfo.hasMorePages" :cards=3 :executeScroll="clients != undefined" @showMore="showMore('clients')"></SkeletonCards>
  </div>
</template>

<script>
import CLIENTS_QUERY from './queries';
import { CREATE_CLIENT, DELETE_CLIENT } from './mutations';
import Form from './Form.vue';
import Card from './Card.vue';
import RequestBuilder from './RequestBuilder';
import { SkeletonCards, SpinnerRouterLink, Search } from '../shared';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, Search, SkeletonCards, SpinnerRouterLink,
  },
  apollo: {
    clients: {
      errorPolicy: 'all',
      query: CLIENTS_QUERY,
      variables() {
        return { page: 1, orderBy: [{ column: 'id', order: 'ASC' }], ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: { },
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return { orderBy: [{ column: 'id', order: 'ASC' }], ...SearchBuilder.build(this.search) };
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_CLIENT,
        variables: {
          id: record.id,
        },
        update: (store, { data: { deleteClient } }) => {
          const data = store.readQuery({ query: CLIENTS_QUERY, variables: this.queryVariables() });
          data.clients.data = data.clients.data.filter((c) => c.id !== deleteClient.id);
          store.writeQuery({ query: CLIENTS_QUERY, variables: this.queryVariables(), data });
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(record) {
      const newClient = record;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_CLIENT,
        variables: {
          client: RequestBuilder.build(record),
        },
        update: (store, { data: { createClient } }) => {
          const data = store.readQuery({ query: CLIENTS_QUERY, variables: this.queryVariables() });
          data.clients.data.unshift(createClient);
          store.writeQuery({ query: CLIENTS_QUERY, variables: this.queryVariables(), data });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.selectedRecord = newClient;
      });
    },
  },
};
</script>
