import { useVuelidate } from '@vuelidate/core';
import { required, minValue, maxLength } from '@vuelidate/validators';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      localRecord: {
        name: { required, maxLengthValue: maxLength(255) },
        description: { required },
        price: { required, minValueValue: minValue(0) },
      },
    };
  },
};
