<template>
  <div class="col-md-4 pb-2">
    <div class="card h-100">
      <div class="row g-0">
        <div class="col-md-4 d-flex align-items-center justify-content-center">
          <img class="rounded-circle" :src="record.avatar" />
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">
              {{ record.name }}
            </h5>
            <hr />
            <h5 class="card-title">
              Balance: <img :src="'/imgs/gcoin_symbol.svg'" height="20px" />
              {{ record.gcoin_balance }}
            </h5>
            <h5 class="card-title">
              Balance to give:
              <img :src="'/imgs/gcoin_symbol.svg'" height="20px" />
              {{ record.gcoin_balance_to_give }}
            </h5>
            <div
              class="justify-content-end"
              v-if="!rechargeActive && $can('RechargeGcoins')"
            >
              <button
                class="btn btn-company-primary"
                @click="rechargeActive = !rechargeActive"
              >
                Recharge
              </button>
            </div>
            <div
              class="row g-0"
              v-if="rechargeActive && $can('RechargeGcoins')"
            >
              <div class="col-6">
                <input
                  type="number"
                  class="form-control w-100"
                  v-focus
                  v-model="rechargeValue"
                />
              </div>
              <div class="col-6 ps-2">
                <button
                  class="btn btn-company-primary d-inline-block col-5"
                  :disabled="saving"
                  @click="save()"
                >
                  {{ saving ? "Saving..." : "Save" }}
                </button>
                <button
                  v-if="!saving"
                  class="btn btn-company-secondary d-inline-block px-0 ms-1 me-0 col-5"
                  @click="cancel()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RECHARGE_GCOINS from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';

export default {
  props: ['record'],
  emits: ['recharged'],
  data() {
    return {
      rechargeValue: 0,
      rechargeActive: false,
      saving: false,
    };
  },
  methods: {
    save() {
      this.saving = true;
      this.$apollo
        .mutate({
          mutation: RECHARGE_GCOINS,
          variables: {
            input: {
              amount: parseFloat(this.rechargeValue),
              to_user_id: parseInt(this.record.id, 10),
            },
          },
        })
        .catch((error) => {
          this.rechargeValue = 0;
          this.saving = false;
          ErrorHandler.handle(error, this);
        })
        .then(() => {
          this.saving = false;
          this.rechargeValue = 0;
          this.$emit('recharged', true);
          this.rechargeActive = false;
          this.$toasted.success('Gcoins was recharged!');
        });
    },
    cancel() {
      this.rechargeValue = 0;
      this.rechargeActive = false;
    },
  },
};
</script>
