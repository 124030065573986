<template>
<div :id="record.id"  class="col-md-3 pb-2">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{record.name}}
      </h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">
        {{record.description}}
      </h6>
    </div>
    <div class="card-body pt-0 pb-0 mb-3" v-if="record.emails">
      <div class="row">
        <div class="col-md-12">
          <strong>Sends emails to:</strong>
        </div>
        <div class="col-md-12">
          <template v-for="(email, index) in emails">
            <span
              :key="`e-${index}`"
              class="badge bg-company-secondary me-1">
                {{email}}
            </span>
          </template>
        </div>
      </div>
    </div>
    <div class="card-body">
      <button v-if="$can('UpdateBusinessUnit')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <router-link class="btn btn-company-primary" :to="{ name: 'notification_templates', params: { id: record.id }}" >
        <i class="fa-solid fa-bell"></i>
        Templates
      </router-link>
    </div>
  </div>
</div>
</template>

<script>

import UPDATE_NOTIFICATION_SETTING from './mutations';
import Form from './Form.vue';
import ErrorHandler from '../../shared/ErrorHandler';

export default {
  components: { Form },
  props: ['record'],
  data() {
    return {
      is_editting: false,
    };
  },
  computed: {
    emails() {
      return (this.record.emails || '').split(',');
    },
  },
  methods: {
    update(notificationSetting) {
      this.$apollo.mutate({
        mutation: UPDATE_NOTIFICATION_SETTING,
        variables: {
          id: notificationSetting.id,
          notification_setting: _.pick(notificationSetting, ['emails', 'slack_channel']),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
  },
};
</script>
