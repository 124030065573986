<template>
  <div class="row">
    <div class="col-md-12">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default { };
</script>
