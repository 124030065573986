<template>
<div class="mb-5 card">
  <div class="card-header d-flex">
    <div class="col-10 p-0">
      <span>Health Score Project Value</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body d-grid">
    <div class="mb-3">
      <h5>{{this.record.configItem.name}}</h5>
    </div>
    <div v-if="!['is_true', 'is_false'].includes(record.configItem.success_operator)" class="mb-3">
      <label for="value">New Value</label>
      <input v-focus type="number" class="form-control" :class="{'is-invalid': v$.localRecord.value.$invalid}" id="value" v-model.number="localRecord.value">
      <div v-for="error of v$.localRecord.value.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div v-else class="mb-3">
      <div class="form-check form-switch">
        <label class="form-check-label m-auto" for="value">Meets?</label>
        <input type="checkbox" class="form-check-input" id="value" @change="localRecord.value ? localRecord.value = 1 : localRecord.value = 0" v-model="localRecord.value" :true-value="1"
:false-value="0">
      </div>
      <div v-for="error of v$.localRecord.value.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Save
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>

<script>
import Validations from './Validations';
import Blinkable from '../../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  props: ['record'],
  data() {
    return {
      localRecord: {
        health_score_configuration_item_id: this.record.configItem.id,
        user_id: this.record.user_id,
        project_id: this.record.project_id,
      },
    };
  },
  methods: {
    save() {
      this.$emit('insert', this.localRecord);
    },
  },
};
</script>
