import { gql } from 'apollo-boost';
import ACTION_FRAGMENT from './fragments';

export default gql`
  query($project_id: ID!, $status: String!, $project_minute_id: ID, $project_risk_id: ID) {
    actionsByProject(project_id: $project_id, status: $status, project_minute_id: $project_minute_id, project_risk_id: $project_risk_id) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...action
      }
    }
  }
  ${ACTION_FRAGMENT}
`;
