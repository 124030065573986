import { gql } from 'apollo-boost';

import VERTICAL_MARKET_FRAGMENT from './fragments';

export const CREATE_VERTICAL_MARKET = gql`
  mutation($vertical_market: VerticalMarketInput!){
    createVerticalMarket(input: $vertical_market){
      ...vertical_market
    }
  }
  ${VERTICAL_MARKET_FRAGMENT}
`;

export const UPDATE_VERTICAL_MARKET = gql`
  mutation($id: ID!, $vertical_market: VerticalMarketInput!){
    updateVerticalMarket(id: $id, input: $vertical_market){
      ...vertical_market
    }
  }
  ${VERTICAL_MARKET_FRAGMENT}
`;

export const DELETE_VERTICAL_MARKET = gql`
  mutation($id: ID!){
    deleteVerticalMarket(id: $id){
      id
    }
  }
`;
