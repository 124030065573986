<template>
  <div>
    <Form v-if="selectedRecord" v-bind:record="selectedRecord"
                                  @close="selectedRecord = null"
                                  @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-5 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i v-if="!$apollo.loading" class="fas fa-home"></i>
                <i v-if="$apollo.loading" class="fas fa-sync ms-1 fa-sm" :class="{'fa-spin': $apollo.loading}"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link to="/users">Users</router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link :to="`/users?name=${user?.name}`">{{ user?.name }}</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              Promotions
            </li>
          </ol>
        </div>
        <div class="col-md-5 m-auto p-0">
          <Search :search.sync="search"></Search>
        </div>
        <div class="col-md-2 text-end">
          <button type="button" class="btn btn-company-primary" @click="newPromotion()" v-if="$can('CreatePromotion')">
            <i class="fas fa-plus"></i>
            New
          </button>
        </div>
      </div>
    </nav>
    <div class="row" v-if="promotions">
      <Empty v-if="!promotions.data.length" icon="fas fa-users"/>
      <Card :key="index" v-for="(record, index) in promotions.data" :record="record" @remove="remove" @refresh="refresh"></Card>
    </div>
    <SkeletonCards v-if="promotions && promotions.paginatorInfo.hasMorePages" :cards=4 :executeScroll="promotions != undefined" @showMore="showMore('promotions')"></SkeletonCards>
  </div>
</template>

<script>
import moment from 'moment';
import { gql } from 'apollo-boost';
import { PROMOTIONS_QUERY } from './queries';
import { CREATE_PROMOTION, DELETE_PROMOTION } from './mutations';
import { SkeletonCards } from '../shared';
import Form from './Form.vue';
import Card from './Card.vue';
import Search from './Search.vue';
import { ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    SkeletonCards, Form, Card, Search,
  },
  apollo: {
    user: {
      query: gql`query ($id: ID!) { user (id: $id) { id name }}`,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    promotions: {
      query: PROMOTIONS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, user_id: this.$route.params.id, ...this.search };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
    };
  },
  methods: {
    queryVariables() {
      return { user_id: parseInt(this.$route.params.id, 10), ...this.search };
    },
    newPromotion() {
      this.selectedRecord = {
        user_id: parseInt(this.$route.params.id, 10),
        user: { job_title: '' },
        date: moment().format('YYYY-MM-DD'),
        next_promotion_date: moment().add(1, 'years').format('YYYY-MM-DD'),
        notes: '',
      };
    },
    refresh() {
      this.$apollo.queries.promotions.refetch();
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_PROMOTION,
        variables: {
          id: record.id,
        },
      }).then(() => {
        this.refresh();
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      });
    },
    insert(record) {
      const newPromotion = record;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_PROMOTION,
        variables: {
          promotion: _.pick(record, ['notes', 'user_id', 'from_job_title_id',
            'to_job_title_id', 'date', 'next_job_title_id',
            'next_promotion_date']),
        },
      }).then(() => {
        this.refresh();
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        this.selectedRecord = newPromotion;
      });
    },
  },
};
</script>
