import { useVuelidate } from '@vuelidate/core';
import {
  required, numeric, maxLength, minValue, maxValue, helpers, requiredIf,
} from '@vuelidate/validators';

export const validatePercentage = (value, _, vm) => value <= vm.totalAvailablePercentage + (vm.record.percentage_weight || 0);

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      localRecord: {
        name: { required, maxLengthValue: maxLength(255) },
        description: { required },
        percentage_weight: {
          required,
          numeric,
          minValue: minValue(0),
          maxValue: maxValue(100),
          validatePercentage: helpers.withMessage('The total percentage exceeds the available limit', validatePercentage),
        },
        success_operator: { required },
        success_value_a: {
          numeric,
          requiredIfFuction: requiredIf(
            () => ['between', 'equals_to', 'greater_than', 'less_than'].includes(this.localRecord.success_operator),
          ),
        },
        success_value_b: {
          numeric,
          requiredIfFuction: requiredIf(
            () => this.localRecord.success_operator === 'between',
          ),
        },
      },
    };
  },
};
