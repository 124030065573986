import { gql } from 'apollo-boost';

import PAID_TIME_OFF_REQUEST_FRAGMENT from '../vacation_requests/fragments';

export const MY_VACATION_REQUEST = gql`
  query(
    $page: Int,
    $id: ID,
    $start_date: DateTime,
    $end_date: DateTime,
    $orderBy: [OrderByClause!]) {
    my_vacation_request(
      page: $page,
      id: $id,
      start_date: $start_date,
      end_date: $end_date,
      orderBy: $orderBy) {
      data {
        ...paid_time_off_request
      }
      paginatorInfo {
        hasMorePages
      }
    }
  }
  ${PAID_TIME_OFF_REQUEST_FRAGMENT}
`;

export const AVAILABLE_PTO_DAYS = gql`
  query {
    me {
      id
      available_pto_days
    }
  }
`;
