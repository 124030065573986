import { gql } from 'apollo-boost';

import BUCKET_FRAGMENT from './fragments';

export const CREATE_BUCKET = gql`
  mutation($bucket: BucketInput!){
    createBucket(input: $bucket){
      ...bucket
    }
  }
  ${BUCKET_FRAGMENT}
`;

export const UPDATE_BUCKET = gql`
  mutation($id: ID!, $bucket: BucketInput!){
    updateBucket(id: $id, input: $bucket){
      ...bucket
    }
  }
  ${BUCKET_FRAGMENT}
`;

export const DELETE_BUCKET = gql`
  mutation($id: ID!){
    deleteBucket(id: $id){
      id
    }
  }
`;
