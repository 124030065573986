<template>
  <div class="mb-5 card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Client</span>
        <span v-if="record.id">{{localRecord.name}}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body d-grid">
      <div class="mb-3">
          <label for="name">Name</label>
          <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
          <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
      </div>
      <div class="mb-3">
          <label for="email">Email</label>
          <input type="text" class="form-control" :class="{'is-invalid': v$.localRecord.email.$invalid}" id="email" v-model="localRecord.email">
          <div v-for="error of v$.localRecord.email.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
      </div>
      <div class="mb-3">
          <label for="website">Website</label>
          <input type="text" class="form-control" :class="{'is-invalid': v$.localRecord.website.$invalid}" id="website" v-model="localRecord.website">
          <div v-for="error of v$.localRecord.website.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
      </div>
      <div class="mb-3">
          <label for="country">
            Country <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
          </label>
          <select id="country" class="form-control form-select" :class="{'is-invalid': v$.localRecord.country_id.$invalid}" v-model="localRecord.country_id">
            <template v-if="countries">
              <option :key="country.id" v-for="country in countries" :value="country.id">
                {{country.name}}
              </option>
            </template>
          </select>
          <div v-for="error of v$.localRecord.country_id.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
      </div>
      <div class="mb-3">
        <div class="form-check">
          <div class="form-check">
            <input id="is_prospect" type="checkbox" class="form-check-input" v-model="localRecord.is_prospect">
            <label class="form-check-label m-auto" for="is_prospect">Prospect?</label>
          </div>
        </div>
        <div class="form-check">
          <div class="form-check">
            <input id="is_direct" type="checkbox" class="form-check-input" v-model="localRecord.is_direct">
            <label class="form-check-label m-auto" for="is_direct">Is Direct?</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-md-12">
          <ContactPicker :contacts="localRecord.contacts">Contacts:</ContactPicker>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>

import Validations from './Validations';
import ContactPicker from '../contactables/Picker.vue';
import Blinkable from '../../shared/Blinkable';
import { dropdownWithAlias } from '../../shared';

export default {
  mixins: [Blinkable, Validations],
  components: {
    ContactPicker,
  },
  apollo: {
    countries: {
      query: dropdownWithAlias('countries'),
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      variables() {
        return { type: 'Country', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  computed: {
    countryChanged() {
      return this.localRecord?.country?.id !== this.localRecord.country_id;
    },
  },
  methods: {
    save() {
      if (this.countryChanged) {
        this.localRecord.country = this.countries.find((c) => c.id === this.localRecord.country_id);
      }
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
