import moment from 'moment';
import { SkeletonRow } from '../../shared';

export default {
  components: { SkeletonRow },
  computed: {
    users() {
      return _.uniqBy(
        _.map(this.hours.data, (h) => _.pick(h.user, ['id', 'name'])),
        'id',
      );
    },
    projects() {
      return _.uniqBy(
        _.map(this.hours.data, (h) => _.pick(h.project, ['id', 'name'])),
        'id',
      );
    },
    days() {
      return _.uniq(_.map(this.hours.data, (h) => this.dateFormat(h.date)));
    },
    sortIcon() {
      return this.asc() ? 'fa-sort-alpha-down' : 'fa-sort-alpha-down-alt';
    },
    total() {
      return _.sumBy(this.hours.data, 'hours');
    },
    totalCosts() {
      return _.sumBy(this.hours.data, 'costs').toFixed(2);
    },
    showCosts() {
      return this.$can('ReadCostPerUser') && this.show_costs;
    },
  },
  methods: {
    remove(id) {
      this.$emit('remove', id);
    },
    showMore() {
      this.$emit('showMore');
    },
    filterBy(key, value) {
      if (key === 'date') {
        return _.filter(this.hours.data, (h) => this.dateFormat(h.date) === value);
      }
      return _.filter(this.hours.data, (h) => h[key] === value);
    },
    totalBy(key, value, sumKey) {
      return _.sumBy(this.filterBy(key, value), sumKey);
    },
    costsMessage() {
      return '* The Costs information must not be disclosed to anyone without a written consent. 👀';
    },
    dateFormat(date) {
      return moment(date).format('LL');
    },
    asc() {
      return this.orderBy.order === 'ASC';
    },
    sortBy(column) {
      this.orderBy.column = column;
      this.orderBy.order = this.asc() ? 'DESC' : 'ASC';
      this.$emit('refetch');
    },
  },
};
