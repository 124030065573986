<template>
  <div class="mb-5 card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!localRecord.id">New Opportunity</span>
        <span v-if="localRecord.id">{{ localRecord.name }}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="mb-3 col-md-4">
          <label for="name">Name</label>
          <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
          <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="state">
            State
          </label>
          <select id="state" class="form-control form-select" :class="{'is-invalid': v$.localRecord.state.$invalid}" v-model="localRecord.state">
            <option :value="true">Hot</option>
            <option :value="false">Cold</option>
          </select>
          <div v-for="error of v$.localRecord.state.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <div class="input-group">
            <label for="budget">Budget</label>
          </div>
          <div class="input-group">
            <span class="input-group-text me-1">$</span>
            <input type="number" class="form-control" :class="{'is-invalid': v$.localRecord.budget.$invalid}" id="budget" v-model="localRecord.budget" min="0">
          </div>
          <div v-for="error of v$.localRecord.budget.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label>Description</label>
        <input type="hidden" id="description" v-model="localRecord.description">
        <trix-editor input="description" @trix-change="localRecord.description = $event.target.value; $forceUpdate()"></trix-editor>
        <div v-for="error of v$.localRecord.description.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-md-4">
          <label for="start_date">Start date</label>
          <i class="fa-solid fa-calendar"></i>
          <DatePicker :date="localRecord.begins"
                      placeholder="Select Date"
                      :css_class="{'is-invalid': v$.localRecord.begins.$invalid}"
                      @change="localRecord = { ...localRecord, begins: $event }"></DatePicker>
          <div v-for="error of v$.localRecord.begins.$silentErrors" :key="error.$uid">
            <div class="text-danger pt-1">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="name">Estimated duration</label>
          <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.duration.$invalid}" id="name" v-model="localRecord.duration" placeholder="Ex: 6 months">
          <div v-for="error of v$.localRecord.duration.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="hours_per_week">Positions amount</label>
          <input placeholder="Ex: 12" type="number" min="0" class="form-control rounded-0" :class="{'is-invalid': v$.localRecord.positions_amount.$invalid}" v-model="localRecord.positions_amount"/>
          <div v-for="error of v$.localRecord.positions_amount.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="mb-3 col-md-4">
          <label for="state">
            Seniority
          </label>
          <select id="state" class="form-control form-select" :class="{'is-invalid': v$.localRecord.seniority.$invalid}" v-model="localRecord.seniority">
            <option>Junior</option>
            <option>Mid</option>
            <option>Senior</option>
          </select>
          <div v-for="error of v$.localRecord.seniority.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="state">
            English level
          </label>
          <select id="state" class="form-control form-select" :class="{'is-invalid': v$.localRecord.english_level.$invalid}" v-model="localRecord.english_level">
            <option>A1</option>
            <option>A2</option>
            <option>B1</option>
            <option>B2</option>
            <option>C1</option>
            <option>C2</option>
          </select>
          <div v-for="error of v$.localRecord.english_level.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <div>
            <label for="country">Country</label>
            <select id="country" class="form-control form-select" v-model="localRecord.country_id">
              <template v-if="countries">
                <option :key="'none'" :value="null">N/A</option>
                <option :key="country.id" v-for="country in countries" :value="country.id">
                  {{country.name}}
                </option>
              </template>
            </select>
          </div>
        </div>
        <div class="mb-3">
          <TechnologyPicker :selected="localRecord.technologies">
            Technologies:
          </TechnologyPicker>
          <div v-for="error of v$.localRecord.technologies.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <label>Notes</label>
        <input type="hidden" id="notes" v-model="localRecord.notes">
        <trix-editor input="notes" @trix-change="localRecord.notes = $event.target.value; $forceUpdate()"></trix-editor>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import { dropdownWithAlias } from '../../shared';
import TechnologyPicker from '../technologies/Picker.vue';
import Validations from './Validations';

export default {

  mixins: [Validations],
  components: { TechnologyPicker },

  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },

  apollo: {
    countries: {
      query: dropdownWithAlias('countries'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Country', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    clientsDropDown: {
      query: dropdownWithAlias('clientsDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Client', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    projectsDropDown: {
      query: dropdownWithAlias('projectsDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Project', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};

</script>
