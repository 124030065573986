<template>
  <div class="mb-5 card">
    <div class="card-header d-flex">
      <div class="col-11 p-0">
        <span v-if="!record.id">New Interview Question</span>
        <span v-if="record.id">{{localRecord.question | truncate(30)}}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body">
      <div class="mb-3">
        <div class="company-control">
          <label for="technology_id">
            Technology <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
          </label>
          <select id="technology_id" class="form-control form-select" :class="{'is-invalid': v$.localRecord.technology_id.$invalid}" v-model="localRecord.technology_id">
            <template v-if="technologiesDropDown">
              <option :key="technology.id" v-for="technology in technologiesDropDown" :value="technology.id">
                {{technology.name}}
              </option>
            </template>
          </select>
          <div v-for="error of v$.localRecord.technology_id.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="company-control">
          <label for="question">Question</label>
          <input type="hidden" id="question" v-model="localRecord.question">
          <trix-editor input="question" @trix-change="localRecord.question = $event.target.value; $forceUpdate()"
          placeholder="Enter content" class="form-control" buttons="bold italic"></trix-editor>
          <div v-for="error of v$.localRecord.question.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="company-control">
          <label for="answer">Answer</label>
          <input type="hidden" id="answer" v-model="localRecord.answer">
          <trix-editor input="answer" @trix-change="localRecord.answer = $event.target.value; $forceUpdate()"
          placeholder="Enter content" class="form-control"></trix-editor>
          <div v-for="error of v$.localRecord.answer.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <div class="company-control">
          <label for="difficulty">Type</label>
          <select id="difficulty" class="form-control form-select" :class="{'is-invalid': v$.localRecord.difficulty.$invalid}" v-model="localRecord.difficulty">
            <option>Easy</option>
            <option>Normal</option>
            <option>Hard</option>
          </select>
          <div v-for="error of v$.localRecord.difficulty.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
      </div>
      <div class="mb-3">
        <TagsPicker :selected="localRecord.tags || []">Tags:</TagsPicker>
      </div>
    </div>
    <div class="card-footer">
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>

import Validations from './Validations';
import TagsPicker from '../tags/Picker.vue';
import Blinkable from '../../shared/Blinkable';
import { dropdownWithAlias } from '../../shared';

export default {
  mixins: [Blinkable, Validations],
  components: { TagsPicker },
  props: ['record'],
  apollo: {
    technologiesDropDown: {
      query: dropdownWithAlias('technologiesDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'Technology', orderBy: [{ column: 'name', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
