<template>
  <table class="table m-0 table-hover">
    <thead>
      <tr>
        <th class="align-middle p-1 overflow-hidden" :class="{'border-0': domains.new != null}" colspan="2">
          <div class="row px-3">
            <div class="col"></div>
            <span class="col-8 text-center">Domain White List</span>
            <div class="col text-end p-0 mx-1">
              <button class="btn btn-company-primary px-1 py-0" @click="domains.new = ''" v-if="domains.new == null"><i class="fas fa-plus"></i></button>
            </div>
          </div>
        </th>
      </tr>
      <tr class="bg-company-primary" v-if="domains.new != null">
        <td class="align-middle p-0 ps-1 mb-3 border-0 w-100">
          <input type="text" v-model="domains.new" class="form-control px-1 py-0 mb-1" v-focus>
        </td>
        <td class="d-flex text-end align-middle p-0 pe-1 border-0 text-end">
          <button class="btn btn-company-primary px-2 py-1 ms-2" @click="$emit('create', domains.new)"><i class="fas fa-save"></i></button>
          <button class="btn btn-company-secondary  px-2 py-1 ms-1" @click="domains.new = null"><i class="fas fa-times"></i></button>
        </td>
      </tr>
    </thead>
    <tbody>
      <template v-if="white_list_domains" >
        <tr :key="row.id" v-for="row in white_list_domains">
          <template v-if="domains.edit ? domains.edit.id == row.id : false">
            <td class="align-middle p-0 ps-1 w-100">
              <input type="text" v-model="domains.edit.domain" class="form-control px-1 py-0 mb-1" v-focus>
            </td>
            <td class="d-flex p-0 pe-1">
              <button class="btn btn-company-primary px-2 py-1 ms-2" @click="$emit('update', domains.edit.id, domains.edit.domain)"><i class="fas fa-save"></i></button>
              <button class="btn btn-company-secondary px-2 py-1 ms-1" @click="domains.edit = null"><i class="fas fa-times"></i></button>
            </td>
          </template>
          <template v-else>
            <td class="align-middle p-1 ps-2">{{row.domain}}</td>
            <td class="text-end align-middle p-1 pe-2" v-if="deleteDomain !== row.id">
              <button class="btn btn-company-primary px-1 py-0 my-1" @click="domains.edit = {...row}"><i class="fas fa-pen"></i></button>
              <button class="btn btn-company-primary px-1 py-0 my-1" @click="deleteDomain = row.id"><i class="fas fa-trash"></i></button>
            </td>
            <td class="text-end align-middle p-1 pe-2" v-else>
              <button class="btn btn-company-primary px-1 py-0 my-1" @click="$emit('delete', row.id)"><i class="fas fa-check"></i></button>
              <button class="btn btn-company-secondary px-2 py-0 my-1" @click="deleteDomain = null"><i class="fas fa-times"></i></button>
            </td>
          </template>
        </tr>
      </template>
    </tbody>
  </table>
</template>

<script>
export default {
  props: ['record', 'domains'],
  data() {
    return {
      deleteDomain: null,
    };
  },
  computed: {
    white_list_domains() {
      return { ...this.record };
    },
  },
};
</script>
