<style scoped>
  .custom-warning {
    color: #ffc107;
  }
</style>
<template>
  <span class="fw-bold" :class="valueClass">{{$displayDate(value)}}</span>
</template>
<script>
import moment from 'moment';

export default {
  props: ['value', 'configuration'],
  computed: {
    greenMargin() {
      if (!this.configuration || this.configuration.length === 0) {
        return 8;
      }
      return this.configuration.find((c) => c.key === 'Allocation Threshold Green').value;
    },
    redMargin() {
      if (!this.configuration || this.configuration.length === 0) {
        return 4;
      }
      return this.configuration.find((c) => c.key === 'Allocation Threshold Red').value;
    },
    valueClass() {
      if (moment().isSameOrAfter(moment(this.value))) {
        return 'text-danger';
      }
      const diffInWeeks = Math.abs(moment().diff(this.value, 'week'));
      if (diffInWeeks >= this.greenMargin) {
        return 'text-success';
      }
      if (diffInWeeks <= this.redMargin) {
        return 'text-danger';
      }
      return 'custom-warning';
    },
  },
};
</script>
