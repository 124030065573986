<template>
  <div class="col-md-4 col-sm-12 pb-2">
    <DeliverableForm v-if="is_editing" :deliverable="deliverable" @close="is_editing = false" @update="update"/>
    <ul v-if="!is_editing" class="list-group h-100">
      <li class="list-group-item">
        <template v-if="!is_exporting">
          <strong>{{deliverable.name}} (<strong title="Billable hours">{{totalHours}}</strong> / <strong title="Time to finish">{{effective_hours}}</strong> Hours)</strong>
          <small>
            <i class="fas fa-circle fa-2x float-end sortable" :style="{ 'color': deliverable.color }" @click="$refs.color.click()"></i>
            <i class="fas fa-cloud-upload-alt fa-2x float-end sortable pe-2" title="Export to youtrack" @click="is_exporting=true" v-if="$can('ExportDeliverableToYoutrack')"></i>
          </small>
          <input ref="color" type="color" class="d-none" v-model="deliverable.color" @change="update(deliverable)"/>
        </template>
        <div class="row" v-if="is_exporting">
          <input v-focus type="text" placeholder="Youtrack Project Id" class="form-control col-md-8" v-model="youtrack_project_id">
          <button :disabled="youtrack_project_id === ''" class="btn btn-company-primary col-md-2" @click="exportToYoutrack()">OK</button>
          <button class="btn btn-company-primary col-md-2" @click="is_exporting=false">Cancel</button>
        </div>
      </li>
      <li class="list-group-item">
        <div class="form-check">
          <input :id="`is_completed${deliverable.id}`" type="checkbox" class="form-check-input" v-model="deliverable.is_completed" @change="update(deliverable)">
          <label :for="`is_completed${deliverable.id}`" class="form-check-label">{{progress}}% Completed</label>
        </div>
      </li>
      <li class="list-group-item">
        <div class="row">
          <div class="col-md-12 pb-2">
            <strong>Resources:</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ul class="list-group" v-if="deliverable.resources">
              <template v-for="resource in deliverable.resources">
                <li :key="resource.id" v-if="resource.project_role && resource.quantity"
                    class="list-group-item d-flex justify-content-between">
                  <span>{{resource.project_role.name}}</span>
                  {{resource.quantity}}
                </li>
              </template>
            </ul>
          </div>
        </div>
      </li>
      <li class="list-group-item">
        <button v-if="!confirmation" class="btn btn-company-primary" @click="is_editing = true">
          <i class="fas fa-pen"></i>
          Edit
        </button>
        <Confirmation v-if="!is_editing && $can('DeleteClient')"
                      v-model="confirmation"
                      @accepted="destroy()"/>
      </li>
    </ul>
  </div>
</template>

<script>
import { UPDATE_DELIVERABLE, DELETE_DELIVERABLE, EXPORT_DELIVERABLE_TO_YOUTRACK } from './mutations';
import DeliverableForm from './DeliverableForm.vue';
import RequestBuilder from './RequestBuilder';
import ErrorHandler from '../../../../shared/ErrorHandler';
import { Confirmation } from '../../../shared';

export default {
  components: { DeliverableForm, Confirmation },
  props: ['deliverable'],
  data() {
    return {
      confirmation: false,
      is_editing: false,
      is_removing: false,
      is_exporting: false,
      youtrack_project_id: '',
    };
  },
  computed: {
    totalHours() {
      return _.sumBy(this.deliverable.bucket_estimation_items, 'hours');
    },
    development_hours() {
      return _.sumBy(this.deliverable.bucket_estimation_items.filter((element) => !element.bucket_estimation.bucket.is_parallel), 'hours');
    },
    parallel_hours_array() {
      return _.map(this.deliverable.bucket_estimation_items.filter((element) => element.bucket_estimation.bucket.is_parallel), 'hours');
    },
    effective_hours() {
      const developmentHours = this.development_hours;
      const maxParallelHours = Math.max(...this.parallel_hours_array);
      if (maxParallelHours > developmentHours) {
        return developmentHours + (maxParallelHours - developmentHours);
      }
      return developmentHours;
    },
    progress() {
      const completedTime = _.sumBy(_.filter(this.deliverable.bucket_estimation_items.filter((i) => i.is_completed), 'is_completed'), 'hours');
      return ((completedTime * 100) / this.totalHours).toFixed(2);
    },
  },
  methods: {
    exportToYoutrack() {
      this.$apollo.mutate({
        mutation: EXPORT_DELIVERABLE_TO_YOUTRACK,
        variables: {
          id: this.deliverable.id,
          youtrack_project_id: this.youtrack_project_id,
        },
      }).catch((error) => {
        error.graphQLErrors.forEach((e) => {
          this.$toasted.error(JSON.parse(e.debugMessage).value || e.message);
        });
      }).then(() => {
        this.is_exporting = false;
        this.youtrack_project_id = '';
        this.$toasted.success(`Tasks from ${this.deliverable.name} are being imported to youtrack`);
      });
    },
    update(deliverable) {
      this.$apollo.mutate({
        mutation: UPDATE_DELIVERABLE,
        variables: {
          id: this.deliverable.id,
          deliverable: RequestBuilder.build(deliverable),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.$emit('refresh');
        this.is_editing = false;
      });
    },
    destroy() {
      this.$apollo.mutate({
        mutation: DELETE_DELIVERABLE,
        variables: {
          id: this.deliverable.id,
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      }).then(() => {
        this.$emit('refresh');
      });
    },
  },
};
</script>
