<template>
  <div class="row">
    <label class="me-sm-2" for="notes">Notes:</label>
    <input v-focus type="text" class="form-control me-sm-2" id="notes" placeholder="Notes" v-model="search.notes" @change="updateFilter">

    <label class="me-sm-2" for="interview">Is Successful:</label>
    <select id="type" class="form-control form-select me-sm-2" v-model="search.is_successful" @change="updateFilter">
        <option value="">Any</option>
        <option value="true">Yes</option>
        <option value="false">No</option>

    </select>
  </div>
</template>

<script>
import Searchable from '../../shared/Searchable';

export default {
  extends: Searchable,
  methods: {
    updateFilter() {
      Object.keys(this.search).forEach((key) => {
        if (key === 'is_successful' && this.search.is_successful !== '') {
          this.search.is_successful = this.search.is_successful.toLowerCase() === 'true';
        }
      });
      const filters = _.pickBy(this.search, (v) => v !== '');
      this.$router.push({ query: filters }).catch(() => {});
      this.$emit('update:search', filters);
    },
  },
};
</script>
