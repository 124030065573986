import { gql } from 'apollo-boost';

import NOTIFICATION_SETTING_FRAGMENT from './fragments';

export default gql`
  mutation($id: ID!, $notification_setting: NotificationSettingsInput!){
    updateNotificationSetting(id: $id, input: $notification_setting){
      ...notification_setting
    }
  }
  ${NOTIFICATION_SETTING_FRAGMENT}
`;
