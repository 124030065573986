import { gql } from 'apollo-boost';

export default gql`
  query($ids: [ID!]!, $start_date: DateTime, $end_date: DateTime) {
    compareIndicators(ids: $ids, start_date: $start_date, end_date: $end_date){
      created_at
      ActiveClients
      ActiveFixedBidProjects
      ActiveProjects
      ActiveRetainerFeeProjects
      ActiveSupportProjects
      ActiveTimeAndMaterialsProjects
      AverageAccumulatedPTODays
      AverageActiveProjectsDuration
      AverageAge
      AverageCostPerHour
      AverageGrossMarginPercentagePerHour
      AverageGrossMarginPerHour
      AverageHeadCountPerProject
      AverageRatePerHour
      AverageRemainingDaysPerPerson
      AverageRemainingDaysPerProject
      AverageResumeScore
      AverageTimeStay
      BenchPercentage
      BillableContractors
      BillablePeople
      BillableVsNonBillableRelation
      CompletedProjects
      EstimationConversionRate
      HeadCount
      HoursPerMonth
      HoursPerWeek
      InternalNetPromoterScore
      InterviewSuccessfulRate
      NetPromoterScore
      NonBillablePeople
      NumberOfPeopleInBench
      PercentageOfBillablePeople
      PercentageOfNonBillablePeople
      TotalProjects
    }
  }
`;
