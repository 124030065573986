import { gql } from 'apollo-boost';

import ESTIMATION_FRAGMENT from './fragments';

export const CREATE_ESTIMATION = gql`
  mutation($estimation: EstimationInput!){
    createEstimation(input: $estimation){
      ...estimation
    }
  }
  ${ESTIMATION_FRAGMENT}
`;

export const UPDATE_ESTIMATION = gql`
  mutation($id: ID!, $estimation: EstimationInput!){
    updateEstimation(id: $id, input: $estimation){
      ...estimation
    }
  }
  ${ESTIMATION_FRAGMENT}
`;

export const DELETE_ESTIMATION = gql`
  mutation($id: ID!){
    deleteEstimation(id: $id){
      id
    }
  }
`;

export const CREATE_BUCKET_ESTIMATION_HOURS = gql`
  mutation($bucket_estimation_hours: [BucketEstimationHourInput!]){
    createBucketEstimationHours(input: $bucket_estimation_hours)
  }
`;

export const UPDATE_BUCKET_ESTIMATION = gql`
  mutation($id: ID!, $bucket_estimation: BucketEstimationInput!) {
    updateBucketEstimation(id: $id, input: $bucket_estimation){
      id,
      bucket_estimation_items{
        id,
        name
        hours,
        is_completed,
        order,
        deliverable {
          id
          name
          color
          created_at
        }
      }
    }
  }
`;

export const COPY_ESTIMATION = gql`
  mutation($id: ID!){
    copyEstimation(id: $id){
      id
    }
  }
`;
export const EXPORT_ESTIMATION = gql`
  mutation($id: ID!){
    exportEstimationToExcel(id: $id)
  }
`;
