import { gql } from 'apollo-boost';
import PAID_TIME_OFF_REQUEST_FRAGMENT from './fragments';

export default gql`
  query($page: Int,
        $id: ID,
        $user_id: ID,
        $status: String,
        $assigned_to_id: ID,
        $orderBy: [OrderByClause!]) {
    paid_time_off_requests(
      page: $page,
      id: $id,
      user_id: $user_id,
      status: $status,
      assigned_to_id: $assigned_to_id,
      orderBy: $orderBy) @connection(key: "paid_time_off_requests", filter: ["user_id", "status", "assigned_to_id"]) {
        data {
          ...paid_time_off_request
        }
        paginatorInfo {
          hasMorePages
        }
    }
  }
  ${PAID_TIME_OFF_REQUEST_FRAGMENT}
`;
