import { gql } from 'apollo-boost';

import BUCKET_FRAGMENT from './fragments';

export default gql`
  query($page: Int, $name: String) {
    buckets(
      page: $page,
      name: $name,
      orderBy: { column: "id", order: ASC }) @connection(key: "buckets", filter: ["name"]) {
      paginatorInfo {
        hasMorePages
      }
      data {
        ...bucket
      }
    }
  }
  ${BUCKET_FRAGMENT}
`;
