<template>
  <div class="col-xxl-4 col-xl-4 col-lg-6 col-md-6 col-sm-6 mb-4">
    <div class="card mb-1 h-100">
      <div class="row g-0">
        <div class="col-lg-5 d-flex justify-content-center align-items-center">
          <h1 v-if="!localRecord.user.avatar" class="company-text-primary">
            <ProfileImage :user_name="localRecord.user.name" :font_size="'125px'"></ProfileImage>
          </h1>
          <div class="d-flex flex-column align-items-center" v-if="localRecord.user.avatar">
            <img class="rounded-circle mt-4 mb-4" height="120px" :src="localRecord.user.avatar">
          </div>
        </div>
        <div class="col-lg-7 text-end">
          <div class="card-body ps-xl-0">
            <h5 class="card-title">
              <span :title="localRecord.user.country.name" v-if="localRecord.user.country">{{flag}}</span>
              {{ localRecord.user.name }}
            </h5>
            <h6 class="card-subtitle mb-2 text-body-secondary">
              {{ localRecord.user.email }}
            </h6>
          </div>
        </div>
        <div class="card-body">
          {{localRecord.user.bio}}
        </div>
        <div class="card-body">
          <button class="btn btn-company-primary position-absolute bottom-1 start-1" style="bottom: 10px;" @click="showModal">
            <i class="fa-solid fa-star"></i>
            Send Kudos
          </button>
          <Modal ref="modal" :id="parseInt(localRecord.user.id)" :title="modalTitle" :localRecord="localRecord" @accept="handleAccept">
            <template v-slot:body>
              <div class="row justify-content-center g-0">
                <div class="col-12 my-2">
                  <div class="card">
                    <trix-editor @trix-change="localRecord.notes = $event.target.value; $forceUpdate()"></trix-editor>
                  </div>
                </div>
              </div>
            </template>
          </Modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ProfileImage } from '../../shared';
import Modal from '../../shared/Modal.vue';
import CREATE_KUDOS from './mutations';
import { ErrorHandler, Flags } from '../../../shared';

export default {
  components: {
    ProfileImage, Modal,
  },
  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  computed: {
    flag() {
      return Flags[this.localRecord.user.country.name];
    },
    modalTitle() {
      return `Send Kudos to ${this.localRecord.user.name} 🎉`;
    },
  },
  methods: {
    showModal() {
      if (this.$refs.modal.modalInstance === null) {
        return;
      }
      this.$refs.modal.show();
    },
    handleAccept() {
      const kudos = {
        project_id: this.localRecord.project_id,
        to_user_id: this.localRecord.user.id,
        message: this.localRecord.notes,
      };
      this.$apollo.mutate({
        mutation: CREATE_KUDOS,
        variables: {
          kudos,
        },
      }).then(() => {
        this.$toasted.success('Kudos was sent successfully!');
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
  },
};
</script>
