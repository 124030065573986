<template>
  <div class="row">
    <template v-for="input in inputs">
      <template v-if="input === 'description'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="description">Description:</label>
            <input id="description" v-model="search.description" v-focus class="form-control"
                  placeholder="Description"
                  type="text" @change="updateFilter">
          </div>
        </div>
      </template>

      <template v-if="input === 'name'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="name">Name:</label>
            <input id="name" v-model="search.name" v-focus class="form-control" placeholder="Name" type="text"
                  @change="updateFilter">
          </div>
        </div>
      </template>

      <template v-if="input === 'domain'">
        <div :key="`input-${input}`" :class="cols">
          <label for="domain">Domain:</label>
          <input id="domain" v-model="search.domain" class="form-control" placeholder="Domain" type="text"
                @change="updateFilter">
        </div>
      </template>

      <template v-if="input === 'provider'">
        <div :key="`input-${input}`" :class="cols">
          <label for="provider">Provider:</label>
          <input id="provider" v-model="search.provider" class="form-control" placeholder="Provider" type="text"
                @change="updateFilter">
        </div>
      </template>

      <template v-if="input === 'email'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="email">Email:</label>
            <input id="email" v-model="search.email" class="form-control" placeholder="Email" type="text"
                  @change="updateFilter">
          </div>
        </div>
      </template>

      <template v-if="input === 'clients'">
        <div :key="`input-${input}`" :class="cols">
          <Select
            v-model="search.client_id"
            :name="`client_id`"
            :query="queries.dropdowns"
            :queryVariables="{ type: 'Client', orderBy: [{ column: 'name', order: 'ASC' }] }"
            @change="updateFilter">Client:</Select>
        </div>
      </template>

      <template v-if="input === 'learningPaths'">
        <div :key="`input-${input}`" :class="cols">
          <Select
            v-model="search.learning_path_id"
            :name="`learning_path_id`"
            :query="queries.dropdowns"
            :queryVariables="{ type: 'LearningPath', orderBy: [{ column: 'name', order: 'ASC' }] }"
            @change="updateFilter">Learning Path:</Select>
        </div>
      </template>

      <template v-if="input === 'status'">
        <div :key="`input-${input}`" :class="cols">
          <Select
            v-model="search.project_status_id"
            :loadDefaultData="true"
            :name="`project_status_id`"
            :query="queries.dropdowns"
            :queryVariables="{ type: 'ProjectStatus', orderBy: [{ column: 'id', order: 'ASC' }] }"
            @change="updateFilter">Status:</Select>
        </div>
      </template>

      <template v-if="input === 'projectType'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="client">Type:</label>
            <select id="type" v-model="search.type" class="form-control form-select " @change="updateFilter">
              <option value="">Any</option>
              <option>Fixed Bid</option>
              <option>Time and Materials</option>
              <option>Retainer Fee</option>
              <option>Support</option>
            </select>
          </div>
        </div>
      </template>

      <template v-if="input === 'chartType'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="client">Type:</label>
            <select id="chart_type" v-model="search.chart_type" class="form-control form-select " @change="updateFilter">
              <option value='wordcloud'>Word Cloud</option>
              <option value='bubble'>Bubble</option>
              <option value='treemap'>Tree Map</option>
            </select>
          </div>
        </div>
      </template>

      <template v-if="input === 'partnerType'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="client">Type:</label>
            <select id="type" v-model="search.type" class="form-control form-select " @change="updateFilter">
              <option value="">Any</option>
              <option>Consultant</option>
              <option>Recruitment</option>
            </select>
          </div>
        </div>
      </template>

      <template v-if="input === 'state'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="state">State:</label>
            <select id="state" v-model="search.state" class="form-control form-select " @change="updateFilter">
              <option value="">Any</option>
              <option>In Progress</option>
              <option>Draft</option>
              <option>On Hold</option>
              <option>Cancelled</option>
              <option>Completed</option>
            </select>
          </div>
        </div>
      </template>

      <template v-if="input === 'projects'">
        <div :key="`input-${input}`" :class="cols">
          <Select
            v-model="search.project_id"
            :name="`project_id`"
            :query="queries.dropdowns"
            :queryVariables="{ type: 'Project', orderBy: [{ column: 'name', order: 'ASC' }] }"
            @change="updateFilter">Project:</Select>
        </div>
      </template>

      <template v-if="input === 'jobTitles'">
        <div :key="`input-${input}`" :class="cols">
          <Select
            v-model="search.job_title_id"
            :name="`job_title_id`"
            :query="queries.job_titles"
            :queryVariables="{ orderBy: [{ column: 'internal_description', order: 'ASC' }] }"
            @change="updateFilter">Position:</Select>
        </div>
      </template>

      <template v-if="input === 'users'">
        <div :key="`input-${input}`" :class="cols">
          <Select
            v-model="search.user_id"
            :name="`user_id`"
            :query="queries.users"
            :queryVariables="{ orderBy: [{ column: 'name', order: 'ASC' }], is_active: true, is_external: false }"
            @change="updateFilter">User:</Select>
        </div>
      </template>

      <template v-if="input === 'countries'">
        <div :key="`input-${input}`" :class="cols">
          <Select
            v-model="search.country_id"
            :name="`country_id`"
            :query="queries.dropdowns"
            :queryVariables="{ type: 'Country', orderBy: [{ column: 'name', order: 'ASC' }] }"
            @change="updateFilter">Country:</Select>
        </div>
      </template>

      <template v-if="input === 'isActive'">
        <div :key="`input-${input}`" :class="cols">
          <label for="active_only"></label>
          <div class="form-check">
            <div class="form-check">
              <input id="active_only" v-model="search.active_only" class="form-check-input" type="checkbox"
                    @change="updateFilter">
              <label class="form-check-label m-auto" for="active_only">Active only</label>
            </div>
          </div>
        </div>
      </template>

      <template v-if="input === 'onlyMyProjectsCheckbox'">
        <div :key="`input-${input}`" :class="cols">
          <label for="only_my_projects"></label>
          <div class="form-check">
            <div class="form-check">
              <input id="only_my_projects" v-model="search.only_my_projects" class="form-check-input" type="checkbox"
                    @change="updateFilter">
              <label class="form-check-label m-auto" for="only_my_projects">My projects</label>
            </div>
          </div>
        </div>
      </template>

      <template v-if="input === 'onlyMyTeamCheckbox'">
        <div :key="`input-${input}`" :class="cols">
          <label for="only_my_team"></label>
          <div class="form-check">
            <div class="form-check">
              <input id="only_my_team" v-model="search.only_my_team" class="form-check-input" type="checkbox"
                    @change="updateFilter">
              <label class="form-check-label m-auto" for="only_my_team">My team</label>
            </div>
          </div>
        </div>
      </template>

      <template v-if="input === 'dateRanges'">
        <div :key="`input-${input}-start`" class="mb-3" :class="cols">
            <div class="company-control">
              <label for="start_date">
                <i class="fa-solid fa-calendar"></i>
                Start Date:
              </label>
              <DatePicker :date="search.start_date"
                          placeholder="Select Date"
                          @change="search = { ...search, start_date: $event }; updateFilter()"></DatePicker>
            </div>
        </div>
        <div :key="`input-${input}-end`" :class="cols">
          <div class="company-control">
            <label for="end_date">
              <i class="fa-solid fa-calendar"></i>
              End Date:
            </label>
            <DatePicker :date="search.end_date"
                        placeholder="Select Date"
                        @change="search = { ...search, end_date: $event }; updateFilter()"></DatePicker>
          </div>
        </div>

      </template>

      <template v-if="input === 'year'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="year">Year:</label>
            <input id="year" v-model="search.year" class="form-control" type="number" @change="updateFilter">
          </div>
        </div>
      </template>

      <template v-if="input === 'difficulty'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="difficulty">Difficulty:</label>
            <select id="difficulty" v-model="search.difficulty" class="form-control form-select "
                    @change="updateFilter">
              <option value="">Any</option>
              <option>Easy</option>
              <option>Normal</option>
              <option>Hard</option>
          </select>
          </div>
        </div>
      </template>

      <template v-if="input === 'candidateStatus'">
        <div :key="`input-${input}`" :class="cols">
          <Select
            v-model="search.candidate_status_id"
            :name="`candidate_status_id`"
            :query="queries.dropdowns"
            :queryVariables="{ type: 'CandidateStatus', orderBy: [{ column: 'name', order: 'ASC' }] }"
            @change="updateFilter">Status:</Select>
        </div>
      </template>

      <template v-if="input === 'internalDescription'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="internal_description">Internal Description:</label>
            <input id="internal_description" v-model="search.internal_description" v-focus class="form-control"
                    placeholder="Internal Description"
                    type="text"
                    @change="updateFilter">
          </div>
        </div>
      </template>

      <template v-if="input === 'equipmentType'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="equipmentType">Type:</label>
            <select id="equipmentType" v-model="search.type" class="form-control form-select "
                    @change="updateFilter">
              <option value="">Any</option>
              <option>Other</option>
              <option>Desktop</option>
              <option>Laptop</option>
              <option>Cellphone</option>
              <option>Tablet</option>
              <option>Monitor</option>
              <option>Peripheral</option>
              <option>Furniture</option>
              <option>Tools</option>
              <option>Camera</option>
              <option>Charger</option>
              <option>Cables</option>
            </select>
          </div>
        </div>
      </template>

      <template v-if="input === 'technologies'">
        <div :key="`input-${input}`" :class="cols">
          <Select
            v-model="search.technology_id"
            :name="`technology_id`"
            :query="queries.dropdowns"
            :queryVariables="{ type: 'Technology', orderBy: [{ column: 'name', order: 'ASC' }] }"
            @change="updateFilter">Technology:</Select>
        </div>
      </template>

      <template v-if="input === 'evaluation_cycles'">
        <div :key="`input-${input}`" :class="cols">
          <Select
            v-model="search.performance_evaluation_cycle_id"
            :name="`performance_evaluation_cycle_id`"
            :query="queries.dropdowns"
            :queryVariables="{ type: 'PerformanceEvaluationCycle', orderBy: [{ column: 'name', order: 'ASC' }] }"
            @change="updateFilter">Evaluation Cycle:</Select>
        </div>
      </template>

      <template v-if="input === 'model'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="model">Model:</label>
            <input id="model" v-model="search.model" v-focus class="form-control" placeholder="Model" type="text"
                @change="updateFilter">
          </div>
        </div>
      </template>

      <template v-if="input === 'serie'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="serial_number">Serial:</label>
            <input id="serial_number" v-model="search.serial_number" v-focus class="form-control"
                  placeholder="Serie"
                  type="text" @change="updateFilter">
          </div>
        </div>
      </template>

      <template v-if="input === 'equipment_state'">
        <div :key="`input-${input}`" :class="cols">
          <div class="company-control">
            <label for="state">State:</label>
            <select id="state" v-model="search.state" class="form-control form-select " @change="updateFilter">
                <option value="">Any</option>
                <option>New</option>
                <option>Like new</option>
                <option>Damaged</option>
                <option>Discarted</option>
                <option>In repair</option>
            </select>
          </div>
        </div>
      </template>

      <template v-if="input === 'key'">
        <div :key="`input-${input}`" :class="cols" >
          <div class="company-control">
            <label for="internal_key">Key:</label>
            <input id="internal_key" v-model="search.internal_key" v-focus class="form-control" placeholder="Key"
                  type="text" @change="updateFilter">
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import moment from 'moment';
import {
  USERS,
  DROPDOWNS,
  Searchable,
  JOB_TITLES,
} from '../../shared';
import Select from './Select.vue';

export default {
  components: { Select },
  extends: Searchable,
  props: {
    inputs: { type: Array, default: () => [] },
    default_values: { type: Object, default: () => {} },
  },
  data() {
    const data = {
      search: {},
      queries: {
        users: USERS,
        job_titles: JOB_TITLES,
        dropdowns: DROPDOWNS,
      },
    };
    // Set default values when needed
    if (this.inputs.includes('onlyMyProjectsCheckbox')) {
      data.search.only_my_projects = false;
    }
    if (this.inputs.includes('onlyMyTeamCheckbox')) {
      data.search.only_my_team = false;
    }
    if (this.inputs.includes('isActive')) {
      data.search.active_only = true;
    }
    if (this.inputs.includes('state')) {
      data.search.state = 'In Progress';
    }
    if (this.inputs.includes('year')) {
      data.search.year = moment().format('YYYY');
    }
    data.search = { ...data.search, ...this.default_values };
    return data;
  },
  computed: {
    cols() {
      const cols = this.inputs.length;
      if (cols < 3) {
        if (cols === 2) {
          return 'col-sm-12 col-md-6';
        }
        return 'col-sm-12 col-md-6';
      }
      if (cols < 4) {
        return 'col-sm-12 col-md-3';
      }
      if (cols === 5) {
        return 'col-sm-12 col-md-4 col-lg-3 ';
      }
      return `col-sm-12 col-md-${(12 / cols)}`;
    },
  },
};
</script>
