<template>
<div v-bind:id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting" v-bind:record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{record.name}}
      </h5>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateVerticalMarket')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <Confirmation v-if="$can('DeleteVerticalMarket')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_VERTICAL_MARKET } from './mutations';
import Form from './Form.vue';
import ErrorHandler from '../../shared/ErrorHandler';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(verticalMarket) {
      this.$apollo.mutate({
        mutation: UPDATE_VERTICAL_MARKET,
        variables: {
          id: verticalMarket.id,
          vertical_market: _.pick(verticalMarket, ['name']),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
