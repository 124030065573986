import { gql } from 'apollo-boost';

export default gql`
  fragment performance_evaluation_cycle on PerformanceEvaluationCycle {
    id
    name
    is_active
    start_date
    end_date
  }
`;
