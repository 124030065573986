<template>
<div class="">
    <button class="btn btn-success mb-3 w-100" @click="addHour()">+1 Hour</button>
    <button class="btn btn-success mb-3 w-100" @click="addDay()">+1 Day</button>
    <button class="btn btn-success mb-3 w-100" @click="addWeek()">+1 Week</button>
    <input class="form-control mb-3" type="text" placeholder="4h, 1d, 1w" v-model="time" @change="addTime()"/>
</div>
</template>

<script>

export default {
  data() {
    return {
      time: '',
      hour_equivalences: { h: 1, d: 8, w: 40 },
    };
  },
  methods: {
    timeAndUnit() {
      // eslint-disable-next-line
      const timeunit = this.time.match(/[-?\d\.]+|\D/g);
      return { value: timeunit[0] || 0, unit: timeunit[1] || 'h' };
    },
    addHour() {
      if (this.time === '') {
        this.time = '1h';
      }
      this.addTime();
    },
    addDay() {
      if (this.time === '') {
        this.time = '1d';
      }
      this.addTime();
    },
    addWeek() {
      if (this.time === '') {
        this.time = '1w';
      }
      this.addTime();
    },
    addTime() {
      const timeAndUnit = { ...this.timeAndUnit() };
      this.$emit('addTime', timeAndUnit.value * this.hour_equivalences[timeAndUnit.unit]);
      this.time = '';
    },
  },
};
</script>
