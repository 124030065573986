import { gql } from 'apollo-boost';

import PROMOTION_FRAGMENT from './fragments';

export const CREATE_PROMOTION = gql`
  mutation($promotion: PromotionInput!){
    createPromotion(input: $promotion){
      ...promotion
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const UPDATE_PROMOTION = gql`
  mutation($id: ID!, $promotion: PromotionInput!){
    updatePromotion(id: $id, input: $promotion){
      ...promotion
    }
  }
  ${PROMOTION_FRAGMENT}
`;

export const DELETE_PROMOTION = gql`
  mutation($id: ID!){
    deletePromotion(id: $id){
      id
    }
  }
`;
