<template>
  <div class="row align-items-start">
    <div class="col-md-6 mb-3">
      <div class="row">
        <div class="col-6 mb-3">
          <select v-if="members" class="form-control form-select w-100" v-model="user_id">
            <option v-for="member in activeUsers" :value="member.user.id"
                    v-bind:key="member.user.id">
              {{member.user.name}}
            </option>
          </select>
        </div>
        <div class="col-6 mb-3">
          <DatePicker :date="date" placeholder="Select Date" @change="date = $event"></DatePicker>
        </div>
        <div class="col-12">
          <input type="hidden" id="activities" v-model="activities">
          <trix-editor input="activities" @trix-change="activities = $event.target.value; $forceUpdate()"></trix-editor>
        </div>
      </div>
    </div>
    <div class="col-md-6 mb-3">
      <TimeUnits @addTime="addTime"></TimeUnits>
    </div>
  </div>
</template>

<script>

import TimeUnits from './TimeUnits.vue';

export default {
  components: { TimeUnits },
  props: ['members'],
  data() {
    return {
      user_id: '',
      date: null,
      activities: '',
    };
  },
  computed: {
    activeUsers() {
      return this.members.filter((m) => m.end_date === null);
    },
    getDate() {
      return (this.date) ? `${this.date} 00:00:00` : undefined;
    },
  },
  methods: {
    addTime(hours) {
      this.$emit('update', {
        bucket_estimation_id: null,
        deliverable_id: null,
        hours,
        activities: this.activities,
        user_id: this.user_id,
        created_at: this.getDate,
      });
      this.user_id = '';
      this.activities = '';
    },
  },
};
</script>
