import {
  S3Client, PutObjectCommand, GetObjectCommand, DeleteObjectCommand,
} from '@aws-sdk/client-s3';
import { getSignedUrl } from '@aws-sdk/s3-request-presigner';
import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import Vue from 'vue';
import { BUCKET_NAME, BUCKET_REGION, IDENTITY_POOL_ID } from './env';

const client = new S3Client({
  region: BUCKET_REGION,
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: BUCKET_REGION }),
    identityPoolId: IDENTITY_POOL_ID,
  }),
});

Vue.prototype.$s3Upload = (params) => {
  params.Bucket = BUCKET_NAME;
  const command = new PutObjectCommand(params);
  return client.send(command);
};

Vue.prototype.$s3Delete = (params) => {
  params.Bucket = BUCKET_NAME;
  const command = new DeleteObjectCommand(params);
  return client.send(command);
};

Vue.prototype.$s3Location = (key) => `https://${BUCKET_NAME}.s3.${BUCKET_REGION}.amazonaws.com/${key}`;

Vue.prototype.$s3GetSignedUrl = (key) => {
  const command = new GetObjectCommand({ Bucket: BUCKET_NAME, Key: key });
  return getSignedUrl(client, command, { expiresIn: 60 });
};
