import { gql } from 'apollo-boost';
import FRAGMENT from './fragments';

export const CREATE_MUTATION = gql`
  mutation($record: PartnerInput!){
    createPartner(input: $record){
      ...partner
    }
  }
  ${FRAGMENT}
`;

export const CREATE_MUTATION_WITH_COST = gql`
  mutation($record: PartnerInput!){
    createPartner(input: $record){
      ...partner
      last_week_cost
      last_month_cost
      last_year_cost
    }
  }
  ${FRAGMENT}
`;

export const UPDATE_MUTATION = gql`
  mutation($id: ID!, $record: PartnerInput!){
    updatePartner(id: $id, input: $record){
      ...partner
    }
  }
  ${FRAGMENT}
`;

export const DELETE_MUTATION = gql`
  mutation($id: ID!){
    deletePartner(id: $id){
      id
    }
  }
`;
