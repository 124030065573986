import { gql } from 'apollo-boost';

export default gql`
fragment holiday on Holiday {
  id
  date
  year
  name
  country_id
  country {
    id
    name
  }
}
`;
