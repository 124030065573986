<template>
<div class="mb-5 card">
  <div class="card-header d-flex">
    <div class="col p-0">
      <span v-if="!record.id">New Product</span>
      <span v-if="record.id">{{localRecord.description}}</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body d-grid">
    <div class="mb-3">
        <label for="name">Name</label>
        <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name">
        <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
    </div>
    <div class="mb-3">
        <label for="description">Description</label>
        <input type="text" class="form-control" :class="{'is-invalid': v$.localRecord.description.$invalid}" id="name" v-model="localRecord.description">
        <div v-for="error of v$.localRecord.description.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
    </div>
    <div class="mb-3">
        <label for="price">Precio Gcoins</label>
        <input type="number" class="form-control" :class="{'is-invalid': v$.localRecord.price.$invalid}" id="name" v-model.number="localRecord.price">
        <div v-for="error of v$.localRecord.price.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
    </div>
    <div class="mb-3">
      <CountryPicker :selected="localRecord.countries">
        Countries:
      </CountryPicker>
    </div>
    <div>
      <DropArea :message="'Drop Image here'" @drop="setFile" />
    </div>
    <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Save
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>

<script>
import Validations from './Validations';
import CountryPicker from '../countries/Picker.vue';
import Blinkable from '../../shared/Blinkable';
import { DropArea } from '../shared';
import { ALLOWED_LOGO_TYPES } from '../../shared/AllowedFileTypes';

export default {
  mixins: [Blinkable, Validations],
  components: {
    CountryPicker,
    DropArea,
  },
  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  methods: {
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      if (!this.localRecord.id) {
        if (this.localRecord.image_url) {
          this.$emit(event, this.localRecord);
          return;
        }
        this.$toasted.error('You must include a company logo');
      }
      this.$emit(event, this.localRecord);
    },
    setFile(file) {
      const fileExtension = file.name.split('.').pop();
      if (ALLOWED_LOGO_TYPES.indexOf(fileExtension) === -1) {
        this.$toasted.error(`The logo extension is not supported. Only supports: ${ALLOWED_LOGO_TYPES}`);
        return;
      }
      const url = this.localRecord.image_url;
      this.localRecord.image_url = file;
      this.localRecord.image_url.prev_url = url;
    },
  },
};
</script>
