import { gql } from 'apollo-boost';

export const USER = gql`
query($id: ID!) {
  user(id: $id){
    id
    name
    job_title {
      id
      internal_description
    }
  }
}`;

export const COST_PER_USER = gql`
query($user_id: ID!, $orderBy: [OrderByClause!]) {
  costsPerUser(user_id: $user_id, orderBy: $orderBy){
    id
    cost
    date
    created_at
  }
}`;
