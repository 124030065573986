<template>
  <div class="container-fluid">
    <ul class="nav nav-pills mb-2">
      <li class="nav-item">
        <a class="nav-link active" id="profile-tab" data-bs-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="true">My Profile</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" id="project-history-tab" data-bs-toggle="tab" href="#project-history" role="tab" aria-controls="project-history" aria-selected="false">My Project History</a>
      </li>
    </ul>
    <div class="tab-content" id="myTabContent">
      <div class="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
        <MyProfile :profile="profile"></MyProfile>
      </div>
      <div class="tab-pane fade" id="project-history" role="tabpanel" aria-labelledby="project-history-tab">
        <MyProjectHistory :profile="profile"></MyProjectHistory>
      </div>
    </div>
  </div>
</template>

<script>

import MyProfile from './MyProfile.vue';
import MyProjectHistory from './MyProjectHistory.vue';
import PROFILE_QUERY from './queries';

export default {
  components: { MyProfile, MyProjectHistory },
  apollo: {
    profile: {
      query: PROFILE_QUERY,
      errorPolicy: 'all',
      variables() {
        return { user_id: this.user_id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      user_id: this.$route.params.id,
    };
  },
  watch: {
    $route() {
      this.user_id = '';
    },
  },
};
</script>
