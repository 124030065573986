<template>
  <div :id="record.id" class="pb-2" :class="is_editting ? 'col-md-12' : 'col-md-6'">
    <Form v-if="is_editting" v-bind:record="record" @close="is_editting = false" @update="update"></Form>
    <div v-if="!is_editting" class="card h-100">
      <div class="card-body pb-0">
        <Progress :color="record.project_status.color" :progress="record.last_known_health_score ? record.last_known_health_score : record.progress"></Progress>
        <div class="border-bottom mb-2">
          <div class="row">
            <div class="col-md-12">
              <h5 class="card-title border-bottom mb-2 pb-1">{{record.name}}</h5>
            </div>
            <div class="col-md-12 text-body-secondary">
              <h6 class="card-subtitle mb-2">{{record.client.name}}</h6>
            </div>
            <div class="col-md-12 text-body-secondary" :title="`Net Promoter Score: ${record.last_known_net_promoter_score}`">
              <h6 class="card-subtitle mb-2">NPS: {{record.last_known_net_promoter_score}}</h6>
            </div>
            <div class="col-md-12 text-body-secondary">
              <h6 class="card-subtitle mb-2">BUDGET: ${{record.budget | formatNumber}}</h6>
            </div>
          </div>
        </div>
        <div class="accordion accordion-flush pb-1" v-if="record.description">
          <div class="accordion-item border border-0">
            <div class="accordion-header custom-accordion p-0">
                <h2 class="mb-0">
                  <button class="btn accordion-button collapsed py-2 px-3" type="button" data-bs-toggle="collapse" :data-bs-target="`#p-${record.client_id}`" aria-expanded="true" :aria-controls="`p-${record.id}`" @click="is_description_visible=!is_description_visible">
                    Description
                </button>
                </h2>
            </div>
            <div :id="`p-${record.client_id}`" class="accordion-collapse collapse">
              <div class="accordion-body" v-html="record.description"></div>
            </div>
          </div>
        </div>
        <div class="accordion accordion-flush pb-1" v-if="record.internal_description">
          <div class="accordion-item border border-0">
            <div class="accordion-header custom-accordion p-0">
              <h2 class="mb-0">
                <button class="btn accordion-button collapsed py-2 px-3 " type="button" data-bs-toggle="collapse" :data-bs-target="`#p-${record.id}`" aria-expanded="true" :aria-controls="`p-${record.id}`" @click="is_internal_description_visible=!is_internal_description_visible">
                  Internal Information
                </button>
              </h2>
            </div>
            <div :id="`p-${record.id}`" class="accordion-collapse collapse bg-light">
              <div class="accordion-body" v-html="record.internal_description"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body pt-0">
        <span class="badge bg-company-primary-ligth">{{record.type}}</span>
        <span class="badge bg-company-primary-ligth">{{record.state}}</span>
        <span v-if="record.is_confidential" class="badge bg-company-primary-ligth">Confidential</span>
        <span v-if="record.is_internal" class="badge bg-company-primary-ligth">Internal</span>
      </div>
      <div class="card-body pt-0" v-if="record.estimation && record.estimation.bucket_estimations">
        <div class="row">
          <div class="col-md-12">
            <h6 class="card-subtitle text-body-secondary mb-2">Estimation vs Current Progress:</h6>
          </div>
        </div>
        <div class="row">
          <div :key="bucket_estimation.id" class="col-md-12 pb-1" v-for="bucket_estimation in record.estimation.bucket_estimations">
            <div class="progress" role="progressbar" aria-label="Progress" :aria-valuenow="bucket_estimation.total_hours_consumed" aria-valuemin="0" :aria-valuemax="bucket_estimation.total_hours">
              <div class="progress-bar text-start ps-2 bg-company-primary"
                  :class="percentage(bucket_estimation) == 0 ? 'text-dark bg-transparent' : ''"
                  :style="{ width: percentage(bucket_estimation) + '%' }">
              </div>
              <span class="ps-2 position-absolute">
                {{bucket_estimation.bucket.name}} ({{bucket_estimation.total_hours_consumed}} of out {{bucket_estimation.total_hours}}) hours consumed
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="card-body card-row pt-0 pb-0">
        <div class="row">
          <div class="col-md-3">
            <strong>Start Date:</strong>
          </div>
          <div class="col-md-3">{{$displayDate(record.start_date)}}</div>
          <div class="col-md-3">
            <strong>End Date:</strong>
          </div>
          <div class="col-md-3">{{$displayDate(record.end_date)}}</div>
        </div>
      </div>
      <div class="card-body card-row pt-0 pb-0" v-if="record.technologies.length > 0">
        <div class="row">
          <div class="col-md-3">
            <strong>Technologies:</strong>
          </div>
          <div class="col-md-9">
            <template v-for="(technology, index) in record.technologies">
              <span v-if="index < 8 || show_all_technologies" :key="technology.id"  class="badge bg-company-third-ligth me-1 ms-1">{{technology.name}}</span>
            </template>
            <span v-if="record.technologies.length > 8" class="badge bg-company-secondary me-1 sortable" @click="show_all_technologies = !show_all_technologies">
              {{show_all_technologies ? 'Show less...' : 'Show more...' }}
            </span>
          </div>
        </div>
      </div>
      <div class="card-body card-row pt-0 pb-0" v-if="record.vertical_markets.length > 0">
        <div class="row">
          <div class="col-md-3">
            <strong>Vertical Markets:</strong>
          </div>
          <div class="col-md-9">
            <span :key="vertical_market.id" v-for="vertical_market in record.vertical_markets" class="badge bg-company-third-ligth me-1 ms-1">
              {{vertical_market.name}}
            </span>
          </div>
        </div>
      </div>
      <div class="card-body card-row pt-0 pb-0" v-if="record.tags.length > 0">
        <div class="row">
          <div class="col-md-3">
            <strong>Tags:</strong>
          </div>
          <div class="col-md-9">
            <span :key="tag.id" v-for="tag in record.tags" class="badge bg-company-third-ligth me-1 ms-1">
              {{tag.name}}
            </span>
          </div>
        </div>
      </div>
      <div class="card-body card-row pt-0 pb-0" v-if="record.members && record.members.length">
        <div class="row">
          <div class="col-md-12 pb-2">
            <strong>Team:</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ProjectMemberViewer :members="members"></ProjectMemberViewer>
          </div>
        </div>
        <button class="btn btn-company-primary" @click="show_all_members = !show_all_members">
          {{this.show_all_members ? "Hide inactive members" : "Show inactive members" }}
        </button>
      </div>
      <div class="card-body card-row pt-0 pb-0" v-if="record.contacts && record.contacts.length > 0">
        <div class="row">
          <div class="col-md-12 pb-2">
            <strong>Contacts:</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <ContactViewer :contacts="record.contacts"></ContactViewer>
          </div>
        </div>
      </div>
      <div class="card-body card-row pt-0 pb-0" v-if="hasDeliverables">
        <div class="row">
          <div class="col-md-3">
            <strong>deliverables:</strong>
          </div>
          <div class="col-md-8">
          {{this.completed_deliverables}} of {{this.record.estimation.deliverables.length}} deliverables completed
          </div>
        </div>
      </div>
      <div class="card-footer">
        <button v-if="!confirmation && $can('UpdateProject')" class="btn btn-company-primary" @click="is_editting = true">
          <i class="fas fa-pen"></i>
          Edit
        </button>
        <Confirmation v-if="$can('DeleteProject')"
          v-model="confirmation"
          @accepted="remove()"
        />
        <router-link v-if="!confirmation && $can('ReadProjectMinutes')" class="btn btn-company-primary" :to="{name:'project_minutes', params: { id: record.id }}">
          <i class="fas fa-tasks"></i>
          Minutes
        </router-link>
        <router-link v-if="!confirmation && $can('ReadProjectRisks')" class="btn btn-company-primary" :to="{name:'project_risks', params: { id: record.id }}">
          <i class="fas fa-triangle-exclamation"></i>
          Risks
        </router-link>
        <router-link class="btn btn-company-primary"
                     v-if="!confirmation"
                     :to="{ name: 'project_hours', params: { id: record.id }}">
          <i class="fas fa-clock"></i>
          Time Spent
        </router-link>
        <router-link class="btn btn-company-primary"
                     v-if="!confirmation && $can('AdminProjectAttachments')"
                     :to="{ name: 'project_attachments', params: { id: record.id }}">
          <i class="fas fa-paperclip"></i>
          Attachments
        </router-link>
        <router-link class="btn btn-company-primary"
                     v-if="!confirmation && canLogTime"
                     :to="{ name: 'estimation_detail', params: {id: record.estimation.id} }">
          <i class="fas fa-list"></i>
          Estimation
        </router-link>
        <router-link class="btn btn-company-primary"
                     v-if="!confirmation && $can('ReadOpportunities')"
                     :to="{ name: 'project_opportunities', params: { id: record.id }}">
          <i class="fa-solid fa-hands-holding-circle"></i>
          Opportunities
        </router-link>
        <router-link class="btn btn-company-primary"
                     v-if="!confirmation && $can('ReadHealthScoreProjectValue')"
                     :to="{ name: 'Health Score Project Values', params: { id: record.id }}">
          <i class="fa-solid fa-medkit"></i>
          Health Score
        </router-link>
        <CopyButton v-if="!confirmation && $can('CreateNetPromoterScoreLink')" :client_id="record.client_id" :project_id="record.id"></CopyButton>
      </div>
    </div>
  </div>
</template>

<script>
import { UPDATE_PROJECT } from './mutations';
import RequestBuilder from './RequestBuilder';
import Form from './Form.vue';
import Progress from '../shared/Progress.vue';
import { ErrorHandler } from '../../shared';
import ProjectMemberViewer from '../project_members/Viewer.vue';
import ContactViewer from '../contactables/Viewer.vue';
import { Confirmation } from '../shared';
import CopyButton from '../net_promoter_score/CopyButton.vue';

export default {
  components: {
    Form, Progress, ProjectMemberViewer, ContactViewer, Confirmation, CopyButton,
  },
  props: ['record'],
  data() {
    return {
      is_description_visible: false,
      is_internal_description_visible: false,
      is_editting: false,
      confirmation: false,
      show_all_technologies: false,
      show_all_members: false,
    };
  },
  computed: {
    members() {
      const list = this.show_all_members ? this.record.members : [...this.record.members.filter((member) => !member.end_date)];
      return _.sortBy(list, ['end_date']).reverse();
    },
    canLogTime() {
      return (
        this.record.estimation
        && this.record.estimation.bucket_estimations.length > 0
      );
    },
    hasDeliverables() {
      return this.record.estimation && (this.record.estimation.deliverables || []).length > 0;
    },
    completed_deliverables() {
      if (this.record.estimation && this.record.estimation.deliverables) {
        return this.record.estimation.deliverables.filter((d) => d.is_completed).length;
      }
      return 0;
    },
  },
  methods: {
    percentage(bucketEstimation) {
      return (
        (bucketEstimation.total_hours_consumed * 100)
        / bucketEstimation.total_hours
      );
    },
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_PROJECT,
        variables: {
          id: record.id,
          project: RequestBuilder.build(record),
        },
      }).then(({ data: { updateProject } }) => {
        this.$emit('update', updateProject);
        this.is_editting = false;
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
