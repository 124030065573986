<template>
  <div :id="localRecord.id" class="card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!localRecord.id">New Deliverable</span>
        <span v-if="localRecord.id">{{localRecord.name}}</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body d-grid">
      <div class="mb-3">
        <label for="name">Name</label>
        <input v-focus type="text" class="form-control" :class="{'is-invalid': v$.localRecord.name.$invalid}" id="name" v-model="localRecord.name"/>
        <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
          <div class="text-danger">{{ error.$message }}</div>
        </div>
      </div>
      <div class="row" v-if="localRecord.resources">
        <div class="mb-3 col-md-12">
          <DeliverableResourceSelector :selected="localRecord.resources">Resources:</DeliverableResourceSelector>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-secondary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-primary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>
import Validations from './Validations';
import DeliverableResourceSelector from './DeliverableResourceSelector.vue';

export default {
  extends: Validations,
  components: { DeliverableResourceSelector },
  props: ['deliverable'],
  data() {
    return {
      localRecord: structuredClone(this.deliverable),
    };
  },
  methods: {
    save() {
      const event = this.localRecord.id ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
  mounted() {
    if (!this.localRecord.id) {
      const randomColor = Math.floor(Math.random() * 16777215).toString(16);
      this.localRecord.color = `#${randomColor}`;
    }
  },
};
</script>
