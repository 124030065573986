import { gql } from 'apollo-boost';
import LEARNING_PATH_LEVEL_NODE_FRAGMENT from './fragments';

export const LEARNING_PATH_LEVELS_QUERY = gql`
  query($id: ID!){
    learning_path(id: $id){
      id
      name
      levels {
        id
        name
      }
    }
  }
`;

export const LEARNING_PATH_LEVEL_NODES_QUERY = gql`
  query($learning_path_level_id: ID!){
    learning_path_level(id: $learning_path_level_id){
      id
      nodes {
        ...learning_path_level_node
      }
    }
  }
  ${LEARNING_PATH_LEVEL_NODE_FRAGMENT}
`;
