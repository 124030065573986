import { gql } from 'apollo-boost';
import ATTACHMENT_FRAGMENT from './fragments';

export const PROJECT_ATTACHMENTS_QUERY = gql`
  query($entity_id: ID!, $name: String) {
    entityAttachments: project(id: $entity_id){
      id
      name
      attachments(name: $name){
        ...attachments
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const USER_ATTACHMENTS_QUERY = gql`
  query($entity_id: ID!, $name: String) {
    entityAttachments: user(id: $entity_id){
      id
      name
      attachments(name: $name){
        ...attachments
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const CLIENT_ATTACHMENTS_QUERY = gql`
  query($entity_id: ID!, $name: String) {
    entityAttachments: client(id: $entity_id){
      id
      name
      attachments(name: $name){
        ...attachments
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;

export const PARTNER_ATTACHMENTS_QUERY = gql`
  query($entity_id: ID!, $name: String) {
    entityAttachments: partner(id: $entity_id){
      id
      name
      attachments(name: $name){
        ...attachments
      }
    }
  }
  ${ATTACHMENT_FRAGMENT}
`;
