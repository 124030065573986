export default class SearchBuilder {
  static build(search) {
    const requestBody = _.clone(search);

    const likeKeys = ['description', 'name', 'internal_description', 'person', 'notes', 'domain', 'provider', 'model', 'internal_key', 'serial_number'];
    likeKeys.forEach((k) => {
      if (search[k]) {
        requestBody[k] = `%${search[k]}%`;
      }
    });
    if (search.end_date) {
      requestBody.end_date = `${search.end_date} 23:59:59`;
    }
    if (search.start_date) {
      requestBody.start_date = `${search.start_date} 00:00:00`;
    }
    if (search.score) {
      requestBody.score = parseInt(requestBody.score, 10);
    }
    if (search.year) {
      requestBody.year = parseInt(requestBody.year, 10);
    }
    return requestBody;
  }
}
