import { gql } from 'apollo-boost';

export default gql`
  fragment template on NotificationTemplate {
    id
    name
    text
    notification_setting_id
  }
`;
