export default class RequestBuilder {
  static build(deliverable) {
    const requestBody = _.pick(deliverable, ['name', 'estimation_id', 'color', 'is_completed']);
    requestBody.resources = {
      delete: (deliverable.resources || [])
        .filter((resource) => !resource.quantity && !resource.project_role)
        .map((resource) => resource.id),
      upsert: (deliverable.resources || [])
        .filter((resource) => !resource.id)
        .map((resource) => ({
          deliverable_id: deliverable.id,
          project_role_id: resource.project_role.id,
          quantity: parseInt(resource.quantity, 10),
        })),
    };
    return requestBody;
  }
}
