/*
Queries to load select dropdowns
*/

import { gql } from 'apollo-boost';

export const USERS = gql`
  query($id: ID, $name: String, $is_active: Boolean, $is_external: Boolean, $is_contractor: Boolean, $orderBy: [OrderByClause!]) {
    usersDropDown(id: $id, name: $name, is_active: $is_active, is_external: $is_external, is_contractor: $is_contractor, orderBy: $orderBy) {
      id
      name
    }
  }
`;

export const LEARNING_PATH_LEVELS = gql`
  query($id: ID, $learning_path_id: ID, $name: String, $orderBy: [OrderByClause!]) {
    learningPathLevelsDropdown(id: $id, learning_path_id: $learning_path_id, name: $name, orderBy: $orderBy) {
      id
      name
    }
  }
`;

export const JOB_TITLES = gql`
  query($id: ID, $description: String, $orderBy: [OrderByClause!]) {
    jobTitlesDropDown(id: $id, internal_description: $description, orderBy: $orderBy) {
      id
      internal_description
    }
  }
`;

export const INDICATORS = gql`
  query($orderBy: [OrderByClause!]) {
    indicatorsDropDown(orderBy: $orderBy) {
      id
      name
      frequency
      dynamic_column_name
    }
  }
`;

export function dropdownWithAlias(alias) {
  return gql`
    query ($id: ID, $name: String, $type: DropDownType!, $orderBy: [OrderByClause!]) {
      ${alias}: dropdown(id: $id, name: $name, type: $type, orderBy: $orderBy) {
        id
        name
      }
    }
  `;
}

export const DROPDOWNS = gql`
  query ($id: ID, $name: String, $type: DropDownType!, $orderBy: [OrderByClause!]) {
    dropdown(id: $id, name: $name, type: $type, orderBy: $orderBy) {
      id
      name
    }
  }
`;
