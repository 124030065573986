<template>
<div :id="record.id" class="col-md-6 pb-2">
  <Form v-if="is_editting" :record="record"
                            @close="is_editting = false"
                            @update="update"></Form>
  <div v-if="!is_editting" class="card h-100">
    <div class="card-body pb-0">
      <h5 class="card-title">
        {{$displayDate(record.created_at)}}
      </h5>
      <h6 class="card-subtitle mb-2 text-body-secondary">{{record.name}}</h6>
      <p v-html='record.description'></p>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-3 text-center p-2">
          <h5>Probability</h5>
          <span class="card-title">{{record.probability}} out of 5 ({{probability}})</span>
        </div>
        <div class="col text-center p-2">
          <i class="fa-solid fa-xmark"></i>
        </div>
        <div class="col-3 text-center p-2">
          <h5>Impact</h5>
          <span class="card-title">{{record.impact}} out of 5 ({{impact}})</span>
        </div>
        <div class="col text-center p-2">
          <i class="fa-solid fa-equals"></i>
        </div>
        <div class="col-3 text-center p-2">
          <h5>Risk <i class="fas fa-circle" :class="circleColor"></i></h5>
          <span class="card-title">{{record.probability * record.impact}} out of 25 ({{record.risk}})</span>
        </div>
      </div>
    </div>
    <div class="card-body">
      <button v-if="!confirmation && $can('UpdateProjectRisk')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Edit
      </button>
      <router-link class="btn btn-company-primary" :to="{name:'Actions', params: { id: record.project_id }, query: {project_risk_id: record.id}}">
        <i class="fa-solid fa-bolt"></i>
          Actions
      </router-link>
      <Confirmation v-if="$can('DeleteProjectRisk')"
        v-model="confirmation"
        @accepted="remove()"
      />
    </div>
  </div>
</div>
</template>

<script>

import { UPDATE_PROJECT_RISK } from './mutations';
import Form from './Form.vue';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  computed: {
    probability() {
      const map = {
        1: 'Rare',
        2: 'Unlikely',
        3: 'Possible',
        4: 'Likely',
        5: 'Almost Certain',
      };
      return map[this.record.probability];
    },
    impact() {
      const map = {
        1: 'Insignificant',
        2: 'Minor',
        3: 'Moderate',
        4: 'Major',
        5: 'Severe',
      };
      return map[this.record.probability];
    },
    circleColor() {
      if (this.record.risk.includes('Low')) {
        return 'text-success';
      }
      if (this.record.risk.includes('Medium')) {
        return 'text-warning';
      }
      return 'text-danger';
    },
  },
  methods: {
    update(record) {
      record.project_id = parseInt(this.$route.params.id, 10);
      record.probability = parseInt(record.probability, 10);
      record.impact = parseInt(record.impact, 10);
      this.$apollo.mutate({
        mutation: UPDATE_PROJECT_RISK,
        variables: {
          id: parseInt(record.id, 10),
          projectRisk: _.pick(record, ['name', 'description', 'project_id', 'probability', 'impact']),
        },
      }).then(() => {
        this.record.name = record.name;
        this.is_editting = false;
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
  },
};
</script>
